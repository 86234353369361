<template>
  <div class="global-div">
    <div class="wrapper">
      <div class="block-login">
        <div class="avatar">
          <img src="assets/images/9.jpg" alt="">
        </div>
        <div class="avatar">
          <img src="assets/images/13.jpg" alt="">
        </div>
        <div class="avatar">
          <img src="assets/images/7.jpg" alt="">
        </div>
        <div class="avatar">
          <img src="assets/images/8.jpg" alt="">
        </div>
        <div class="logo-app">
          <router-link to="/">
            <img src="assets/images/logo-app.png" alt="">
          </router-link>
        </div>
        <div class="block-cube">
          <div class="cube cube-1">
            <i class="bx bx-book"></i>
          </div>
          <div class="cube cube-2">
            <i class="bx bxs-graduation"></i>
          </div>
          <div class="cube cube-3">
            <i class="bx bxs-envelope"></i>
          </div>
          <div class="cube cube-4">
            <i class="bx bx-music"></i>
          </div>
          <div class="cube cube-5">
            <i class="bx bx-book-open"></i>
          </div>
          <div class="cube cube-6">
            <i class="bx bx-mobile"></i>
          </div>
        </div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-5 col-md-7 col-xl-4 col-xxl-4">
              <div v-if="this.form.code_confirm == null || this.form.code_confirm == ''" class="card card-login">
                <div class="text-center">
                  <h5 class="title-lg">Récupération de <br> mot de passe</h5>
                </div>
                <form @submit.prevent="sendCodeConfirmation">
                  <div class="form-group row g-lg-4 g-3">

                    <div class="col-12" @click.prevent="this.form.login = null">
                        <ul class="nav nav-tabs nav-sm-form mb-0 mt-2" id="myTab" role="tablist">
                          <li class="nav-item" role="presentation">
                            <a @click="this.form.request_number_login=true" class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#phone-connect" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Par numéro de tél</a>
                          </li>
                          <li class="nav-item" role="presentation">
                            <a @click="this.form.request_number_login=false" class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#email-connect" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Par email</a>
                          </li>
                        </ul>
                    </div>

                    <div class="col-12 position-relative" style="z-index: 3;">
                      <div class="tab-content" id="myTabContent">
                          <div class="tab-pane fade show active" id="phone-connect" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                              <div class="col-12 mb-1" style="margin-top: -10px;">
                                  <label for="" style="color: #fff;font-size:14px;">Téléphone</label>
                              </div>
                              <div class="col-12" style="z-index: 3;">
                                  <div class="col-focus">
                                      <input v-if="this.form.request_number_login=true" type="text"  v-model="this.form.login" required="required"  class="form-control"  placeholder="téléphone" id="phone-connect-reset" @keyup="bg_isNumberKeywords">
                                      <i class="bx bx-phone"></i>
                                  </div>
                              </div>
                          </div>
                          <div class="tab-pane fade" id="email-connect" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                              <div class="col-focus">
                                  <input type="text" class="form-control" v-model="this.form.login" required="required" placeholder="Num ou email">
                                  <div class="fake-label">Email</div>
                                  <i class="bx bx-envelope"></i>
                              </div>
                          </div>
                      </div>
                    </div>

                    <div class="col-12 d-flex justify-content-center">
                      <button class="btn btn-primary">
                        <span>Valider</span>
                        <span>Valider</span>
                        <span>Valider</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div v-if="this.form.code_confirm != null && this.form.code_confirm != '' && !this.form_change_password"
                class="card card-login">
                <div class="text-center">
                  <h5 class="title-lg">Vérification compte</h5>
                </div>
                <form action="" @submit.prevent="recuperation_compte">
                  <div class="form-group row g-lg-4 g-3">
                    <div class="col-12">
                      <p class="mb-0 text-center" style="color: #fff; font-size: 14px;font-family: 'Poppins-Regular';"> Un code de
                        confirmation vous a été envoyé.</p>
                    </div>
                    <div class="col-12">
                      <div class="col-focus">
                        <input v-model="this.form.code_input" type="text" class="form-control" required placeholder="numéro de tél ou email">
                        <div class="fake-label">Code de confirmation</div>
                        <i class="bx bx-user"></i>
                      </div>
                    </div>
                    <div class="col-12 d-flex justify-content-center">
                      <button class="btn btn-primary">
                        <span>Confirmer</span>
                        <span>Confirmer</span>
                        <span>Confirmer</span>
                      </button>
                    </div>
                    <div class="col-12 text-center">
                      <p v-if="this.minutes >= 0" class="text-white text-center">
                        <span class="border border-2 border-warning p-2" style="border-radius:50%">{{ this.minutes }}</span>
                      </p>
                      <p class="text-white text-center" style="font-size: 14px;"> Le code envoyé expire dans un espace d'une minute.</p>
                    </div>
                  </div>
                </form>
              </div>
              <div v-if="this.form.code_confirm != null && this.form.code_confirm != '' && this.form_change_password"
                class="card card-login">
                <div class="text-center">
                  <h5 class="title-lg">Changer vos accès</h5>
                </div>
                <form action="" @submit.prevent="ResetPassword">
                  <div class="form-group row g-lg-4 g-3">
                    <div class="col-12">
                      <div class="col-focus">
                        <input v-model="this.form.password" :type="!this.type_input_password ? 'password':'text'"
                          class="form-control input-password" required="required" placeholder=" mot de passe">
                        <div class="fake-label">Mot de passe</div>
                        <i class="bx bx-lock"></i>
                        <div :class="'block-show-password ' + (this.form.password !=null  && this.form.password.length > 0 ? 'show '  :' ')"
                          @click.prevent="change_type_input">
                          <i :class="'bx bx-hide' + (!this.type_input_password ? ' ' : ' bx-show ')"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                        <div class="col-focus">
                          <input v-model="this.form.conf_password" :type="!this.type_input_conf_password ? 'password':'text'"
                            class="form-control input-password" required="required" placeholder=" mot de passe">
                          <div class="fake-label">Confimer le nouveau Mot de passe</div>
                          <i class="bx bx-lock"></i>
                          <div :class="'block-show-password ' + (this.form.conf_password !=null  && this.form.conf_password.length > 0 ? 'show '  :' ')"
                            @click.prevent="change_type_conf_input">
                            <i :class="'bx bx-hide' + (!this.type_input_conf_password ? ' ' : ' bx-show ')"></i>
                          </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-center">
                      <button class="btn btn-primary">
                        <span>Confirmer</span>
                        <span>Confirmer</span>
                        <span>Confirmer</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div class="text-center">
                <p class="paragraph">Vous avez déjà un compte ? <br>
                  <a href="/login" class="link-reset">
                    Connectez-vous
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useToast } from "vue-toastification";
export default {
  name: 'LoginPage',
  data() {
    return {
      user: null,
      form: {
        login: null,
        password: null,
        conf_password: null,
        code_confirm: null,
        code_input: null,
        nbre_essayer: 0,
        request_number_login:true
      },
      requesting: false,
      form_change_password: false,
      type_input_password: false,
      type_input_conf_password: false,
      minutes: 60,
      compteur: '',
      requestingText: "Veuillez patienter",
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  mounted() {
    this.gb_loadInputTel_login_reset();

    // if (this.$store.state.user_auth != "bloc" || this.$store.state.user_auth != null) {
    //   this.$store.state.user_auth = localStorage.getItem('AUTHENTIFICATE_USER');
    //   this.tchek_this_user();
    // }
  },

  methods: {

    async sendCodeConfirmation() {
      if (this.Online()) {

        if (this.form.login != null && this.form.login.trim() != "") {
          var postData = new Object();

          if (this.getValidNumber(this.form.login)) {
            postData.login = this.getNumberFormat(this.form.login);
          } else if (this.IsEmail(this.form.login)) {
            postData.login = this.form.login;
          } else {
            postData.login = null;
          }

          if (postData.login !=null) {
            await axios.post("https://www.login.ckeck.user.schoolbac.com", postData).then(({ data }) => {
              if (data.error == "false") {
               
                var codeUser = Math.random().toString(20).slice(4);
                this.form.code_confirm = codeUser;
                console.log(codeUser);
                var message = "Bonjour. Voici le code de confirmation du compte crée.  Code : " + codeUser + " .  Ce code expire dans une minute, ne le partager pas.";
                let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=basiluantemo@gmail.com&password=systeminnov2022&message=" + message + "&expediteur=Schoolbac&telephone=" + this.form.login;
  
                axios.get(urlgetsms).then(() => { });
  
                this.decrementation_minutes();
  
              } else {
  
                if (data.statut =="user") {
                  this.toast.error("Il n' y a pas de compte disponible avec cette info, veuillez créer un compte  !!!", {
                    timeout: 3500
                  });
  
                  setTimeout(() => {
                    this.refreshForm();
                    this.$router.push({ name: 'Register' });
                  }, 3700);
                } else {
                  this.toast.info("Une erreur est survennue, veuillez réessayer  !!!", {
                    timeout: 3500
                  });
                  this.refreshForm();
                }
  
              }
            }).catch(function (error) {
              console.log(error);
              this.toast.info("Une erreur est survenue, veuillez réessayer  !!!", {
                timeout: 2000
              });
            });
          } else {
            this.toast.error("Veuillez vérifier votre numéro de téléphone ou email  !!!", {
              timeout: 2000
            });
          }

        } else {
          this.toast.error("Veuillez compléter les champs manquants  !!!", {
            timeout: 2000
          });
        }


      } 
    },

    recuperation_compte() {
      if (this.form.nbre_essayer < 3) {
        if (this.form.code_confirm != null && this.form.code_confirm != "") {

          this.form.nbre_essayer = parseInt(this.form.nbre_essayer) + 1;
          this.requesting = true;

          if (this.form.code_input == this.form.code_confirm) {
            this.form_change_password = true;
          } else {
            this.toast.error("Le code de confirmation est invalide, veuillez réessayer !!!", {
              timeout: 6000
            });
            if (this.form.nbre_essayer == 3) {
              setTimeout(() => {
                this.$router.push({ name: 'Login' });
              }, 6001);
            }
          }
          this.requesting = false;
        } else {
          this.toast.error("Veuillez remplir le code de confirmation envoyé  !!!", {
            timeout: 2000
          });
        }
      }
    },

    async ResetPassword() {
      if (this.Online()) {
        if (this.form.login != null && this.form.login != null && this.form.conf_password != null && this.form.password != null && this.form.conf_password != "" && this.form.password != "") {

          if (this.form.conf_password == this.form.password) {
            await axios.post("https://www.login.schoolbac.com", this.form).then(({ data }) => {
              if (data.error == "false") {
                this.toast.success("Votre compte vient d'etre réinitialiser avec success. Veuillez dès à présent vous connecter !!!", {
                  timeout: 4300
                });
                setTimeout(() => {
                  this.refreshForm();
                  this.$router.push({ name: 'Login' });
                }, 4500);
              } else {
                this.toast.error(data.message, {
                  timeout: 6000
                });
              }
            }).catch(function (error) {
              console.log(error);
              this.toast.info("Une érreur est survenue, vueillez réessayer  !!!", {
                timeout: 2000
              });
            });

          }
        } else {
          this.toast.error("Veuillez compléter les champs manquants !!!", {
            timeout: 6000
          });
        }
      } 
    },

    refreshForm() {
        this.form.login = null,
        this.form.code_confirm = null,
        this.form.conf_password = null,
        this.form.password = "xxxx",
        this.messageErrors = null,
        this.form_change_password = false;
    },


    getValidNumber(value) {
      if (value.length == 10 || value.length == 12 || value.length == 13) {

        if (value.length == 10) {
          if (value.substring(0, 1) == '0') {
            if (value.substring(1, 3) == "81" || value.substring(1, 3) == "82" || value.substring(1, 3) == "83" || value.substring(1, 3) == "84" || value.substring(1, 3) == "85" ||
              value.substring(1, 3) == "89" || value.substring(1, 3) == "90" || value.substring(1, 3) == "91" || value.substring(1, 3) == "97" || value.substring(1, 3) == "98" ||
              value.substring(1, 3) == "80" || value.substring(1, 3) == "99") {
              return true;
            } else {
              // this.toast.error("Numéro de téléphone Invalide", {
              //   timeout: 3000
              // });
              return false;
            }

          } else {
            // this.toast.error("Numéro de téléphone Invalide", {
            //   timeout: 3000
            // });
            return false;
          }

        } else if (value.length == 12) {

          if (value.substring(0, 3) == '243') {

            if (value.substring(3, 5) == "81" || value.substring(3, 5) == "82" || value.substring(3, 5) == "83" || value.substring(3, 5) == "84" || value.substring(3, 5) == "85" ||
              value.substring(3, 5) == "89" || value.substring(3, 5) == "90" || value.substring(3, 5) == "91" || value.substring(3, 5) == "97" || value.substring(3, 5) == "98" ||
              value.substring(3, 5) == "80" || value.substring(3, 5) == "99") {
              return true;
            } else {
              // this.toast.error("Numéro de téléphone Invalide", {
              //   timeout: 3000
              // });
              return false;
            }

          } else {
            // this.toast.error("Numéro de téléphone Invalide", {
            //   timeout: 3000
            // });
            return false;
          }
        } else if (value.length == 13) {

          if (value.substring(0, 1) == '+') {

            if (value.substring(1, 4) == '243') {
              if (value.substring(4, 6) == "81" || value.substring(4, 6) == "82" || value.substring(4, 6) == "83" || value.substring(4, 6) == "84" || value.substring(4, 6) == "85" ||
                value.substring(4, 6) == "89" || value.substring(4, 6) == "90" || value.substring(4, 6) == "97" || value.substring(4, 6) == "98" ||
                value.substring(4, 6) == "80" || value.substring(4, 6) == "99") {
                return true;
              } else {
                // this.toast.error("Numéro de téléphone Invalide", {
                //   timeout: 3000
                // });
                return false;
              }

            } else {
              // this.toast.error("Numéro de téléphone Invalide", {
              //   timeout: 3000
              // });
              return false;
            }

          } else {
            // this.toast.error("Numéro de téléphone Invalide", {
            //   timeout: 3000
            // });
            return false;
          }
        }

      } else {
        // this.toast.error("Numéro de téléphone Invalide", {
        //   timeout: 3000
        // });
        return false;
      }
      return false;
    },

    change_type_input() {
      if (this.type_input_password == false) {
        this.type_input_password = true;
      } else {
        this.type_input_password = false;
      }
    },

    change_type_conf_input() {
      if (this.type_input_conf_password == false) {
        this.type_input_conf_password = true;
      } else {
        this.type_input_conf_password = false;
      }
    },

    decrementation_minutes() {
      this.compteur = setInterval(() => {
        if (this.minutes >= 0) {
          this.minutes = parseInt(this.minutes) - 1;
        } else {
          if (this.minutes <= 0) {
            this.toast.error("Votre compte est en suspend, veuillez contactez le support  !!!", {
              timeout: 2000
            });
          }
          clearInterval(this.compteur);
          setTimeout(() => {
            this.$router.push({ name: 'Login' });
          }, 2001);
        }
      }, 1000);
    },

    tchek_this_user() {
      if (this.$store.state.user_auth != null) {
        this.$router.push({ name: 'Home' });
      }
    },

    IsEmail(email) {
      var regex = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;
      if (!regex.test(email)) {
        // this.toast.error("Adresse email invalide", {
        //   timeout: 5000
        // });
        return false;
      } else {
        return true;
      }
    },

    getNumberFormat(value) {
      if (value.length > 0) {
        if (value.length == 13 && value.substring(0, 1) == '+') {
          return value;
        } else if (value.length == 12) {
          return '+' + value;
        } else if (value.length == 10) {
          return '+243' + value.substring(1, value.length);
        }
      }
    },

    bg_isNumberKeywords(event) {
      const value = event.target.value
      const val = value.replace(/\D+/,'');
      this.form.login = val;
    },
  }
}
</script>

