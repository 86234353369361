<template>
    <div class="block-app">
      <div class="container-fluid px-lg-3  px-1">
        <div class="row justify-content-center g-lg-3 g-3 ">

          <div class="col-lg-3 position-relative">
            <div class="h-100">
              <div class="card-lg-tools card card-nav sticky-top">
                <h2 class="title-page">Camarades</h2>
                <ul class="list-nav">
                  <li>
                    <a href="about-user.html"><i class="bx bxs-home"></i>Accueil</a>
                  </li>
                  <li>
                    <!-- <a href="about-user.html" class="active"><i class="bx bxs-user-plus"></i>Invitations</a> -->
                      <router-link  to="/all-view-friends-demands" :class="($route.path == '/all-view-friends-demands' ? 'active' : '')">
                        <i class="bx bxs-user-plus"></i>Invitations
                      </router-link>
                  </li>
                  <li>
                    <!-- <a href="friends-sugg.html"><i class="bx bxs-user"></i> Suggestions</a> -->
                      <router-link  to="/all-view-friends-suggestions" :class="($route.path == '/all-view-friends-suggestions' ? 'active' : '')">
                          <i class="bx bxs-user"></i> Suggestions
                        </router-link>
                  </li>
                  <li>
                    <a href="#"><i class="bx bxs-group"></i>Tous les amis</a>
                  </li>
                  <li>
                    <!-- <a href="anniv.html"><i class="bx bx-cake"></i> Anniversaires</a> -->
                      <router-link  to="/all-view-friends-birthday" :class="($route.path == '/all-view-friends-birthday' ? 'active' : '')">
                            <i class="bx bx-cake"></i> Anniversaires
                      </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <all-demandes v-if="$route.path == '/all-view-friends-demands'" />
          <all-friendsugg v-if="$route.path == '/all-view-friends-suggestions'" />
          <all-anniv v-if="$route.path == '/all-view-friends-birthday'" />
        </div>
      </div>
      <a href="#" class="btn-float">
        <i class="bx bx-message-alt-edit"></i>
      </a>
    </div>
</template>

<script>

import axios from 'axios';
import { useToast } from "vue-toastification";

import AllDemandes from './AllDemandes.vue';
import AllFriendsugg from './AllFriendsugg.vue';
import AllAnniv from './AllAnniv.vue';

// import MajInfos from '../infos/MajInfos.vue';

export default {
  name: 'PicturesUser',
  components: {
    AllDemandes,
    AllFriendsugg,
    AllAnniv
    // MajInfos
  },
  data() {
    return {
      user: null,
      ImgsUserData: [],
      ImgsUser:null,
      requesting: false,
      typeImages: "alls_photos",
      requestingText: "Chargement des données",
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  mounted() {
    var setLoad = setInterval(() => {
      if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {
        if (this.typeImages == "corbeille") {
          this.corbeille();
        } else {
          this.load();
        }
        clearInterval(setLoad);

        setInterval(() => {
          if (this.typeImages == "corbeille") {
            this.corbeille();
          } else {
            this.load();
          }
        }, 120000);
      }
    }, 7000);

   
  },


  methods: {
    load() {
      if (this.bg_Online) {
        if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {


          var postData = new Object();
          if (!this.gb_viewUserOrOtherUser(this.$route) && this.$store.state.user_auth != null) {
            postData.mailUser = this.$store.state.user_auth.mailUser;
            postData.codingUser = this.$store.state.user_auth.codingUser;

          } else if (this.gb_viewUserOrOtherUser(this.$route) && this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.codingUserCamarade != null && this.$store.state.user_auth_other.codingUserCamarade.trim() != '' && this.$store.state.params == this.$route.params.friend) {
            postData.mailUser = this.$store.state.user_auth_other.mailCamarade.trim();
            postData.codingUser = this.$store.state.user_auth_other.codingUserCamarade.trim();
          }


          if (postData.mailUser != null && postData.mailUser.trim() != "" && postData.codingUser != null && postData.codingUser.trim() != "") {
            axios.post("https://www.all.img.schoolbac.com", postData)
              .then(({ data }) => {
                if (data.statut=="false") {
                  this.ImgsUserData = data;

                  this.blockImageView(this.typeImages);
                } else {
                  this.toast.error(data.message, {
                    timeout: 4000
                  });
                }
              })
              .catch((error) => {
                this.errors = error;
                // this.refresh();
              });
          }
        }
      } 
    },

    blockImageView(imageCall) {
      this.ImgsUser = null;
      this.typeImages = imageCall;
      if (this.ImgsUserData != null) {
        if (imageCall == "alls_photos") {
          this.ImgsUser = [];
          if (this.ImgsUserData.album_avatars != null) {
            this.ImgsUser = [...this.ImgsUser, ...this.ImgsUserData.album_avatars.images];
          }
          if (this.ImgsUserData.album_posters != null) {
            this.ImgsUser = [...this.ImgsUser, ...this.ImgsUserData.album_posters.images];
          }
          if (this.ImgsUserData.album_mes_photos != null) {
            this.ImgsUser = [...this.ImgsUser, ...this.ImgsUserData.album_mes_photos.images];
          }
          if (this.ImgsUserData.album_camarades != null) {
            this.ImgsUser = [...this.ImgsUser, ...this.ImgsUserData.album_camarades.images];
          }
        } else if (imageCall == "photos_avatars") {
          this.ImgsUser = [];
          if (this.ImgsUserData.album_avatars != null) {
            this.ImgsUser = [...this.ImgsUser, ...this.ImgsUserData.album_avatars.images];
          } else {
            this.ImgsUser = [];
          }
        } else if (imageCall == "mes_photos") {
          this.ImgsUser = [];
          if (this.ImgsUserData.album_mes_photos != null) {
            this.ImgsUser = [...this.ImgsUser, ...this.ImgsUserData.album_mes_photos.images];
          } else {
            this.ImgsUser = [];
          }
        } else if (imageCall == "photos_camarades") {
          this.ImgsUser = [];
          if (this.ImgsUserData.album_camarades !=null) {
            this.ImgsUser = [...this.ImgsUser, ...this.ImgsUserData.album_camarades.images];
          } else {
            this.ImgsUser = [];
          }
        } else if (imageCall == "photos_posters") {
          this.ImgsUser = [];
          if (this.ImgsUserData.album_posters != null) {
            this.ImgsUser = [...this.ImgsUser, ...this.ImgsUserData.album_posters.images];
          } else {
            this.ImgsUser = [];
          }
        } else if (imageCall == "albums") {
          this.ImgsUser = [];
        } else if (imageCall == "corbeille") {
          this.ImgsUser = [];
          if (this.ImgsUserData != null) {
            // this.ImgsUser = [...this.ImgsUser, ...this.ImgsUserData];
            for (let h = 0; h < this.ImgsUserData.length; h++) {
              var dataImg = {
                "targetpicture": this.ImgsUserData[h].contenu,
                "name_etat": "Public",
                "index_etat": "One",
                "id_pub": this.ImgsUserData[h].id_pub
              } 
              this.ImgsUser.push(dataImg);
            }
          } else {
            this.ImgsUser = [];
          }
        }
      }

    },

    reload(id_img) {
      var imgDiffs  = this.ImgsUser.filter(function (val) {
        return val.id_pub != id_img;
      });

      this.ImgsUser = imgDiffs;
    },

    corbeille() {
      if (this.bg_Online) {
        if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {

          // var postData = {
          //   "mailUser": this.$store.state.user_auth.mailUser,
          //   "codingUser": this.$store.state.user_auth.codingUser
          // };
          var postData;
          if (!this.gb_viewUserOrOtherUser(this.$route) && this.$store.state.user_auth != null) {
          postData = {
              "mailUser": this.$store.state.user_auth.mailUser,
              "codingUser": this.$store.state.user_auth.codingUser
            };

          } else if (this.gb_viewUserOrOtherUser(this.$route) && this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.codingUserCamarade != null && this.$store.state.user_auth_other.codingUserCamarade.trim() != '' && this.$store.state.params == this.$route.params.friend) {

            postData = {
              "mailUser": this.$store.state.user_auth_other.mailCamarade.trim(),
              "codingUser": this.$store.state.user_auth_other.codingUserCamarade.trim()
            };
          }

          if (postData.mailUser != null && postData.mailUser.trim() != "" && postData.codingUser != null && postData.codingUser.trim() != "") {
            axios.post("https://www.all.corbeille.schoolbac.com", postData)
              .then(({ data }) => {
                // if (data.statut == "false") {
                  this.ImgsUserData = data;
                  this.blockImageView("corbeille");
                // } else {
                //   this.toast.error(data.message, {
                //     timeout: 4000
                //   });
                // }
              })
              .catch((error) => {
                this.errors = error;
                // this.refresh();
              });
          }
        }
      }
    },
  },
}
</script>
