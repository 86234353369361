<template>
  <div  v-if="!this.gb_viewUserOrOtherUser($route) &&  this.$store.state.user_auth != null && this.$store.state.user_auth.posterUser != null && this.$store.state.user_auth.posterUser != ''" class="container-fluid px-lg-3 px-1">
    <div class="row g-3">
      <div class="col-lg-12">
        <div class="block-card-profil">
          <div class="card card-profil-lg">
            <div class="block-img-cover">
              <img
                v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.posterUser != null && this.$store.state.user_auth.posterUser != ''"
                :src="this.$store.state.user_auth.posterUser" alt="photo de" class="img-cover"
                id="ph_couverture_loading">
              <div class="block-overplay"></div>
              <button class="btn btn-plus">
                <i class="bx bx-camera"></i>
                <div class="block-drop">
                  <ul>
                    <li>
                      <a href="#" id="ph_couverture_file">Changer la photo de poster</a>
                      <input type="file" id="photo_couverture_file" @change="previewfile_ph_couverture"
                        class="d-none">
                    </li>
                    <!-- <li>
                      <a href="#">Voir la photo de couverture</a>
                    </li> -->
                    <li
                      v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.avatarUser != null && this.$store.state.user_auth.avatarUser != ''">
                      <a href="#" @click.prevent="deleteImg('POSTER')">Supprimer la photo de poster</a>
                    </li>
                  </ul>
                </div>
              </button>
              <p>Photo de couverture</p>
            </div>
            <div class="block-tools">
              <div class="row w-100 m-0 align-items-center">
                <!-- // Zone a probleme -->
                <!-- <div class="col-lg-5">
                  <div class="d-flex align-items-center block-user-lg block-1">
                    <div class="avatar-user-lg">
                      <img
                        v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.avatarUser != null && this.$store.state.user_auth.avatarUser != ''"
                        :src="this.$store.state.user_auth.avatarUser" alt="Avatar" id="avatar_loading">
                      <button class="btn btn-plus">
                        <i class="bx bx-camera"></i>
                        <div class="block-drop">
                          <ul>
                            <li>
                              <a href="#" id="a_file">Changer la photo de profil</a>
                              <input type="file" id="avatar_file" @change="previewfile" class="invisible position-absolute">
                            </li>
                            <li
                              v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.avatarUser != null && this.$store.state.user_auth.avatarUser != ''">
                              <a href="#" @click.prevent="deleteImg('AVATAR')">Supprimer la photo de profil</a>
                            </li>
                          </ul>
                        </div>
                      </button>
                    </div>
                    <div v-if="this.$store.state.user_auth != null" class="block-name-info-user">
                      <h2 class="text-uppercase">{{ (this.$store.state.user_auth.fistnameUser != null &&
                        this.$store.state.user_auth.fistnameUser != "" ? this.$store.state.user_auth.fistnameUser : "") + ' ' +
                        (this.$store.state.user_auth.nameUser != null && this.$store.state.user_auth.nameUser != "" ?
                        ore.state.user_auth.nameUser : "")}}
                      </h2>
                      <span class="tag">@{{(this.$store.state.user_auth.fistnameUser != null && this.$store.state.user_auth.fistnameUser
                        != "" ? this.$store.state.user_auth.fistnameUser : "") + (this.$store.state.user_auth.nameUser != null &&
                        e.user_auth.nameUser != "" ? this.$store.state.user_auth.nameUser : "") }}</span>
                      <p class="friends-num">
                        4,6 K camarades
                      </p>
                    </div>
                  </div>
                </div> -->


                <div class="col-lg-5">
                  <div class="d-flex align-items-center block-user-lg block-1">
                    <div class="avatar-user-lg">
                      <img
                        v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.avatarUser != null && this.$store.state.user_auth.avatarUser != ''"
                        :src="this.$store.state.user_auth.avatarUser" alt="Avatar" id="avatar_loading">
                      <button class="btn btn-plus">
                        <i class="bx bx-camera"></i>
                        <div class="block-drop">
                          <ul>
                            <li>
                              <a href="#" id="a_file">Changer la photo de profil</a>
                              <input type="file" id="avatar_file" @change="previewfile" class="d-none">
                            </li>
                            <!-- <li>
                                              <a href="#">Voir la photo de profil</a>
                                            </li> -->
                            <li>
                              <a href="#" @click.prevent="deleteImg('AVATAR')">Supprimer la photo de profil</a>
                            </li>
                          </ul>
                        </div>
                      </button>
                    </div>
                    <div v-if="this.$store.state.user_auth != null" class="block-name-info-user pt-md-5 pt-2">
                      <!-- // User -->
                      <h2  class="text-uppercase">{{ (this.$store.state.user_auth.fistnameUser != null &&
                        this.$store.state.user_auth.fistnameUser != "" ? this.$store.state.user_auth.fistnameUser : "") + ' ' +
                        (this.$store.state.user_auth.nameUser != null && this.$store.state.user_auth.nameUser != "" ?
                        this.$store.state.user_auth.nameUser : "")}}
                      </h2>

                        <!-- // Other User -->
                        <h2 v-if="this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.codingUserCamarade != null && this.$store.state.user_auth_other.codingUserCamarade.trim() != '' && this.$store.state.user_auth_other.nameCamarade != null && this.$store.state.user_auth_other.nameCamarade.trim() != ''" class="text-uppercase">{{ this.$store.state.user_auth_other.nameCamarade.trim() }}
                        </h2>


                      <!-- <p class="friends-num">
                        4,6 K camarades
                      </p> -->
                      <div class="d-flex align-items-center block-content-badge-user">
                        <div class="block-user-friends d-flex align-items-center">
                          <a v-for="(cam, index) in DatasFriendsUser.slice(0, 5)" :key="index"  href="#" class="avatar-user-friend">
                            <img  :src="(cam.avatarCamarade != null && cam.avatarCamarade != '' ? cam.avatarCamarade : 'assets/images/team.jpg')" alt="Photo de ">
                            <div class="block-tooltip">
                              {{ cam.nameCamarade }}
                            </div>
                          </a>
                        </div>
                        <p class="friends-num">
                          4,6 K camarades
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- // Zone a probleme -->

                <div class="col-lg-7 d-flex align-items-center justify-content-end block-col"
                  style="flex-direction: column;">

                  <div class="d-flex align-items-center block-user-lg block justify-content-end">
                    <div class="items">
                      <p>Follow: <span>55K</span></p>
                    </div>
                    <div class="items">
                      <p>Followers: <span>25K</span></p>
                    </div>
                    <div class="items">
                      <p>Post: <span>3045</span></p>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-end block-btns w-100 d-none">
                    <button class="btn btn-add">
                      <i class="bx bxs-user-plus"></i>
                      <span> Ajouter comme camarade</span>
                    </button>
                    <button class="btn btn-follow">
                      <i class="bx bx-wifi"></i>
                      <span>Suivre</span>
                    </button>
                    <button class="btn btn-chat">
                      <i class="bx bxs-message"></i>
                      <span>Envoyer un message</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row w-100 align-items-center m-0 row-nav">
              <div class="col-lg-9 col-9">
                <ul class="nav nav-tabs nav-profil" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <router-link to="/posters-user" class="nav-link "
                      :class="($route.path == '/posters-user'  ? 'active' : '')">
                      Posts
                    </router-link>
                  </li>
                  <li class="nav-item" role="presentation">
                    <router-link to="/pictures-user" class="nav-link "
                      :class="($route.path   == '/pictures-user'  ? 'active' : '')">
                      Pictures
                    </router-link>
                  </li>
                  <li class="nav-item" role="presentation">
                    <router-link to="/pictures-user" class="nav-link ">
                      Pensées
                    </router-link>
                  </li>
                  <li class="nav-item" role="presentation">
                    <router-link to="/pictures-user" class="nav-link ">
                      Enseignants/Professeurs
                    </router-link>
                  </li>
                  <li class="nav-item" role="presentation">
                    <router-link to="/pictures-user" class="nav-link ">
                      Cours
                    </router-link>
                  </li>
                  <li class="nav-item d-none" role="presentation">
                    <router-link to="/videos-user" class="nav-link "
                      :class="($route.path   == '/videos-user'  ? 'active' : '')">
                      Vidéos
                    </router-link>
                  </li>
                  <li class="nav-item" role="presentation">
                    <router-link to="/friends-user" class="nav-link "
                      :class="($route.path   == '/friends-user'  ? 'active' : '')">
                      Camarades
                    </router-link>
                  </li>
                  <li class="nav-item" role="presentation">
                    <router-link to="/about-user" class="nav-link "
                      :class="($route.path   == '/about-user' || $route.path == '/about-user-education' || $route.path == '/about-user-places' || $route.path == '/about-user-infos' ? 'active' : '') || ($route.path == '/profil-pictures-user/' + $route.params.friend) && this.$store.state.params == $route.params.friend">
                      A propos
                    </router-link>
                  </li>
                  <li class="nav-item" role="presentation">
                    <router-link to="/about-user" class="nav-link ">
                      Corbeille
                    </router-link>
                  </li>
                </ul>
              </div>
              <div class="col-lg-3 col-3">
                <div class="d-flex justify-content-end align-items-center">
                  <button class="btn btn-search-sm">
                    <i class="bx bx-search"></i>
                  </button>
                </div>
                <!-- <div class="block-search d-flex align-items-center">
                  <i class="bx bx-search"></i>
                  <input type="text" class="form-control" placeholder="Recherche...">
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="this.gb_viewUserOrOtherUser($route) && this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.codingUserCamarade != null && this.$store.state.user_auth_other.codingUserCamarade.trim() !='' && this.$store.state.params == $route.params.friend" class="container-fluid px-lg-5 px-1">
    <div class="row g-3">
      <div class="col-lg-12">
        <div class="block-card-profil">
          <div class="card card-profil-lg">
            <div class="block-img-cover">
                <img
                  v-if="this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.codingUserCamarade != null && this.$store.state.user_auth_other.codingUserCamarade.trim() !='' && this.$store.state.user_auth_other.posterCamarade != null && this.$store.state.user_auth_other.posterCamarade.trim() != ''"
                  :src="this.$store.state.user_auth_other.posterCamarade.trim()" alt="photo de" class="img-cover"
                  id="ph_couverture_loading">
              <div class="block-overplay"></div>
              <button class="btn btn-plus">
                <i class="bx bx-camera"></i>
                <div class="block-drop">
                  <ul>
                    <li>
                      <a href="#" id="ph_couverture_file">Changer la photo de poster</a>
                      <input type="file" id="photo_couverture_file" @change="previewfile_ph_couverture"
                        class="d-none">
                    </li>
                    <li
                      v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.avatarUser != null && this.$store.state.user_auth.avatarUser != ''">
                      <a href="#" @click.prevent="deleteImg('POSTER')">Supprimer la photo de poster</a>
                    </li>
                  </ul>
                </div>
              </button>
              <p>Photo de couverture</p>
            </div>
            <div class="block-tools">
              <div class="row w-100 m-0 align-items-center">


                <div class="col-lg-5">
                  <div class="d-flex align-items-center block-user-lg block-1">
                    <div class="avatar-user-lg">
                      <img
                        v-if="this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.codingUserCamarade != null && this.$store.state.user_auth_other.codingUserCamarade.trim() != '' && this.$store.state.user_auth_other.avatarCamarade != null && this.$store.state.user_auth_other.avatarCamarade.trim() != ''"
                        :src="this.$store.state.user_auth_other.avatarCamarade.trim()" alt="Avatar" id="avatar_loading">
                      <button class="btn btn-plus">
                        <i class="bx bx-camera"></i>
                        <div class="block-drop">
                          <ul>
                            <li>
                              <a href="#" id="a_file">Changer la photo de profil</a>
                              <input type="file" id="avatar_file" @change="previewfile" class="d-none">
                            </li>
                            <li>
                              <a href="#" @click.prevent="deleteImg('AVATAR')">Supprimer la photo de profil</a>
                            </li>
                          </ul>
                        </div>
                      </button>
                    </div>
                    <div v-if="this.$store.state.user_auth != null" class="block-name-info-user pt-md-5 pt-2">
                      <!-- // User -->

                        <!-- // Other User -->
                        <h2 v-if="this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.codingUserCamarade != null && this.$store.state.user_auth_other.codingUserCamarade.trim() != '' && this.$store.state.user_auth_other.nameCamarade != null && this.$store.state.user_auth_other.nameCamarade.trim() != ''" class="text-uppercase">{{ this.$store.state.user_auth_other.nameCamarade.trim() }}
                        </h2>
                        <span v-if="this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.codingUserCamarade != null && this.$store.state.user_auth_other.codingUserCamarade.trim() != '' && this.$store.state.user_auth_other.nameCamarade != null && this.$store.state.user_auth_other.nameCamarade.trim() != ''" class="tag">@{{ this.$store.state.user_auth_other.nameCamarade.trim() }}</span>

                      <!-- <p class="friends-num">
                        4,6 K camarades
                      </p> -->
                      <div class="d-flex align-items-center block-content-badge-user">
                        <div class="block-user-friends d-flex align-items-center">
                          <a v-for="(cam, index) in DatasFriendsUser.slice(0, 5)" :key="index"  href="#" class="avatar-user-friend">
                            <img  :src="(cam.avatarCamarade != null && cam.avatarCamarade != '' ? cam.avatarCamarade : 'assets/images/team.jpg')" alt="Photo de ">
                            <div class="block-tooltip">
                              {{ cam.nameCamarade }}
                            </div>
                          </a>
                        </div>
                        <p class="friends-num">
                          4,6 K camarades
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- // Zone a probleme -->

                <div class="col-lg-7 d-flex align-items-center justify-content-end block-col"
                  style="flex-direction: column;">
                  <div class="d-flex align-items-center block-user-lg block justify-content-end">
                    <div class="items">
                      <p>Follow: <span>55K</span></p>
                    </div>
                    <div class="items">
                      <p>Followers: <span>25K</span></p>
                    </div>
                    <div class="items">
                      <p>Post: <span>3045</span></p>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-end block-btns w-100">
                    <button class="btn btn-add">
                      <i class="bx bxs-user-plus"></i>
                      <span> Ajouter comme camarade</span>
                    </button>
                    <button class="btn btn-follow">
                      <i class="bx bx-wifi"></i>
                      <span>Suivre</span>
                    </button>
                    <button class="btn btn-chat">
                      <i class="bx bxs-message"></i>
                      <span>Envoyer un message</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row w-100 align-items-center m-0 row-nav">
              <div class="col-lg-8 col-9">
                <ul class="nav nav-tabs nav-profil" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <router-link :to="'/profil-user/'+ $route.params.friend" class="nav-link "
                      :class="($route.path == '/profil-user/' + $route.params.friend  ? 'active' : '')">
                      Posts
                    </router-link>
                  </li>
                  <li class="nav-item" role="presentation">
                    <router-link :to="'/profil-pictures-user/'+ $route.params.friend" class="nav-link "
                      :class="($route.path   == '/profil-pictures-user/' + $route.params.friend  ? 'active' : '')">
                      Pictures
                    </router-link>
                  </li>
                  <li class="nav-item" role="presentation">
                    <router-link to="/videos-user" class="nav-link "
                      :class="($route.path   == '/videos-user'  ? 'active' : '')">
                      Vidéos
                    </router-link>
                  </li>
                  <li class="nav-item" role="presentation">
                    <router-link :to="'/profil-friends-user/'+ $route.params.friend" class="nav-link "
                      :class="($route.path   == '/profil-friends-user/'+ $route.params.friend  ? 'active' : '')">
                      Camarades
                    </router-link>
                  </li>
                  <li class="nav-item" role="presentation">
                    <router-link :to="'/profil-about-user/'+$route.params.friend" class="nav-link "
                      :class="($route.path   == '/about-user' || $route.path == '/about-user-education' || $route.path == '/about-user-places' || $route.path == '/about-user-infos'  || $route.path == '/profil-about-user-places/' + $route.params.friend || $route.path == '/profil-about-user/' + $route.params.friend  || $route.path == '/profil-about-user-education/' + $route.params.friend || $route.path == '/profil-about-user-infos/' + $route.params.friend ? 'active' : '')">
                      A propos
                    </router-link>
                  </li>
                </ul>
              </div>
              <div class="col-lg-4 col-3">
                <div class="d-flex justify-content-end align-items-center">
                  <button class="btn btn-search-sm">
                    <i class="bx bx-search"></i>
                  </button>
                </div>
                <!-- <div class="block-search d-flex align-items-center">
                  <i class="bx bx-search"></i>
                  <input type="text" class="form-control" placeholder="Recherche...">
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import {
    useToast
  } from "vue-toastification";


  export default {
  name: 'BlocUserAvatarInProfil',
    props:['DatasFriendsUser'],
    data() {
      return {
        user: null,
        Allcitations: [],
        Allsondages: [],
        Allnews: [],
        AllNumberCountUsers: 0,
        form: {
          code: null,
          picture: null,
          file_avatar: null,
          picture_ph_couverture: null,
          file_ph_couverture: null,
        },
        requesting: false,
        errors: false,
        requestingText: "Chargement des données",
      };
    },
    setup() {
      const toast = useToast();
      return {
        toast
      }
    },
    mounted() {

      if (this.$store.state.user_auth != null) {
        this.user = this.$store.state.user_auth;
      }
    },

    methods: {

      previewfile(e) {
        e.preventDefault();
        this.form.picture = e.target.files[0].name;
        this.form.file_avatar = e.target.files[0];

        if (this.bg_verif_extension_img(e.target.files[0].name)) {

          if (this.bg_verif_size_img(e.target.files[0].size)) {
            var image = document.getElementById("avatar_loading");
            image.src = window.URL.createObjectURL(e.target.files[0]);

            if (image != null) {
              this.submitcreateposter();
            }
            var fileselectx = document.getElementById('avatar_file').files;
            if (fileselectx.length > 0) {
              var fileReader = new FileReader();

              fileReader.onload = function (fileLoadedEvent) {
                var scrData = fileLoadedEvent.target.result;
                document.getElementById("#avatar_loading").attr('src', scrData);
              }
            }

          }
        }
      },

      previewfile_ph_couverture(e) {
        e.preventDefault();
        this.form.picture_ph_couverture = e.target.files[0].name;
        this.form.file_ph_couverture = e.target.files[0];

        if (this.bg_verif_extension_img(e.target.files[0].name)) {

          if (this.bg_verif_size_img(e.target.files[0].size)) {
            var image = document.getElementById("ph_couverture_loading");
            image.src = window.URL.createObjectURL(e.target.files[0]);

            if (image != null) {
              this.submitcreateph_poster();
            }
            var fileselectx = document.getElementById('photo_couverture_file').files;
            if (fileselectx.length > 0) {
              var fileReader = new FileReader();

              fileReader.onload = function (fileLoadedEvent) {
                var scrData = fileLoadedEvent.target.result;
                document.getElementById("#ph_couverture_loading").attr('src', scrData);
              }
            }
          }
        }
      },

      submitcreateposter() {
        if (this.Online()) {
          if (this.$store.state.user_auth != null && this.form.file_avatar != null && this.form.file_avatar != "" &&
            this.form.file_avatar != "") {
            this.requesting = true;

            if (this.form.file_avatar != null) {
              const config = {
                headers: {
                  "content-type": "multipart/form-data",
                },
              };
              // form data
              let formData = new FormData();
              formData.append("file", this.form.file_avatar);
              axios
                .post("https://www.upload.img.schoolbac.com/uploader_image.php", formData, config)
                .then(({
                  data
                }) => {
                  if (data.status == 200) {
                    this.requesting = false;

                    var postData = new Object();
                    postData.mailUser = this.$store.state.user_auth.mailUser;
                    postData.codingUser = this.$store.state.user_auth.codingUser;
                    postData.statut_index = "index_1";
                    postData.name_file = this.form.picture;

                    axios.post("https://www.add.image.schoolbac.com", postData)
                      .then(({
                        data
                      }) => {
                        if (data.error == "false") {


                          this.toast.success("Votre photo de profil vient d'etre actualisé!", {
                            timeout: 4000
                          });

                          var avatarObj = new Object();
                          avatarObj.mailUser = this.$store.state.user_auth.mailUser;
                          avatarObj.codingUser = this.$store.state.user_auth.codingUser;

                          axios.post("https://www.avatar.user.schoolbac.com", avatarObj)
                            .then(({
                              data
                            }) => {
                              if (data.error == "false") {
                                this.$store.state.user_auth.avatarUser = data.avatarUser;
                                var news_dataUser = this.$store.state.user_auth;

                                this.$store.commit('AUTHENTIFICATE_USER', news_dataUser);
                                localStorage.setItem('AUTHENTIFICATE_USER', JSON.stringify(news_dataUser));
                                this.$store.state.user_auth = news_dataUser;

                              } else {
                                this.toast.info(data.message, {
                                  timeout: 4000
                                });

                              }
                            })
                            .catch((error) => {
                              this.errors = error;
                              this.refresh();
                            });

                        } else {
                          if (data.statut == "dev") {
                            this.toast.info(
                              "Erreur survenu lors de la  sauvegarde de la photo, veuillez réessayer  !!!", {
                                timeout: 4000
                              });
                          }
                        }
                      })
                      .catch((error) => {
                        this.errors = error;
                        this.refresh();
                      });
                  } else {
                    this.toast.info(data.message, {
                      timeout: 4000
                    });
                  }
                }).error(function (error) {
                  this.errors = error;
                  console.log(error);
                });

            } else {
              this.toast.error("Erreur survenu lors du sauvegarder, veuillez réessayer  plus tard !!!", {
                timeout: 4000
              });
            }

          } else {
            this.toast.error("Veuillz compléter les champs manquants", {
              timeout: 4000
            });
          }
        }
      },

      submitcreateph_poster() {
        if (this.Online()) {
          if (this.$store.state.user_auth != null && this.form.file_ph_couverture != null && this.form
            .file_ph_couverture != "" && this.form.file_ph_couverture != "") {
            this.requesting = true;

            if (this.form.file_ph_couverture != null) {
              const config = {
                headers: {
                  "content-type": "multipart/form-data",
                },
              };
              // form data
              let formData = new FormData();
              formData.append("file", this.form.file_ph_couverture);
              axios
                .post("https://www.upload.img.schoolbac.com/uploader_image.php", formData, config)
                .then(({
                  data
                }) => {
                  if (data.status == 200) {
                    this.requesting = false;

                    var postData = new Object();
                    postData.mailUser = this.$store.state.user_auth.mailUser;
                    postData.codingUser = this.$store.state.user_auth.codingUser;
                    postData.statut_index = "index_2";
                    postData.name_file = this.form.picture_ph_couverture;

                    axios.post("https://www.add.image.schoolbac.com", postData)
                      .then(({
                        data
                      }) => {
                        if (data.error == "false") {

                          this.toast.success("Votre photo de couverture vient d'etre actualisé!", {
                            timeout: 4000
                          });

                          var posterObj = new Object();
                          posterObj.mailUser = this.$store.state.user_auth.mailUser;
                          posterObj.codingUser = this.$store.state.user_auth.codingUser;

                          axios.post("https://www.poster.user.schoolbac.com", posterObj)
                            .then(({
                              data
                            }) => {
                              if (data.error == "false") {
                                this.$store.state.user_auth.posterUser = data.posterUser;
                                var news_dataUser = this.$store.state.user_auth;

                                this.$store.commit('AUTHENTIFICATE_USER', news_dataUser);
                                localStorage.setItem('AUTHENTIFICATE_USER', JSON.stringify(news_dataUser));
                                this.$store.state.user_auth = news_dataUser;

                              } else {
                                this.toast.info(data.message, {
                                  timeout: 4000
                                });

                              }
                            })
                            .catch((error) => {
                              this.errors = error;
                              this.refresh();
                            });

                        } else {
                          if (data.statut == "dev") {
                            this.toast.info(
                              "Erreur survenu lors de la  sauvegarde de la photo, veuillez réessayer  !!!", {
                                timeout: 4000
                              });
                          }
                        }
                      })
                      .catch((error) => {
                        this.errors = error;
                        this.refresh();
                      });
                  } else {
                    this.toast.info(data.message, {
                      timeout: 4000
                    });
                  }
                }).error(function (error) {
                  this.errors = error;
                  console.log(error);
                });

            } else {
              this.toast.error("Erreur survenu lors du sauvegarder, veuillez réessayer  plus tard !!!", {
                timeout: 4000
              });
            }

          } else {
            this.toast.error("Veuillz compléter les champs manquants", {
              timeout: 4000
            });
          }
        }
      },

      deleteImg(type) {
        if (this.bg_Online) {
          if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {

            var postData = new Object();
            postData.mailUser = this.$store.state.user_auth.mailUser;
            postData.codingUser = this.$store.state.user_auth.codingUser;
            postData.statut_index = type == "AVATAR" ? "index_1" : "index_2";

            if (postData.mailUser != null && postData.mailUser.trim() != "" && postData.codingUser != null && postData
              .codingUser.trim() != "") {
              axios.post("https://www.delete.img.schoolbac.com", postData)
                .then(({
                  data
                }) => {
                  if (data.error == "false") {

                    var avatarObj = new Object();
                    avatarObj.mailUser = this.$store.state.user_auth.mailUser;
                    avatarObj.codingUser = this.$store.state.user_auth.codingUser;

                    if (type == "AVATAR") {
                      this.toast.success("Votre avatar a été supprimer avec succès  !!!");
                      axios.post("https://www.avatar.user.schoolbac.com", avatarObj)
                        .then(({
                          data
                        }) => {
                          if (data.error == "false") {
                            this.$store.state.user_auth.avatarUser = data.avatarUser;
                            var news_dataUser = this.$store.state.user_auth;

                            this.$store.commit('AUTHENTIFICATE_USER', news_dataUser);
                            localStorage.setItem('AUTHENTIFICATE_USER', JSON.stringify(news_dataUser));
                            this.$store.state.user_auth = news_dataUser;

                          } else {
                            this.toast.info(data.message, {
                              timeout: 4000
                            });

                          }
                        })
                        .catch((error) => {
                          this.errors = error;
                          this.refresh();
                        });

                    } else {
                      this.toast.success("Votre poster a été supprimer avec succès  !!!");
                      axios.post("https://www.poster.user.schoolbac.com", avatarObj)
                        .then(({
                          data
                        }) => {
                          if (data.error == "false") {
                            this.$store.state.user_auth.posterUser = data.posterUser;
                            var news_dataUser = this.$store.state.user_auth;

                            this.$store.commit('AUTHENTIFICATE_USER', news_dataUser);
                            localStorage.setItem('AUTHENTIFICATE_USER', JSON.stringify(news_dataUser));
                            this.$store.state.user_auth = news_dataUser;
                            document.getElementById("#ph_couverture_loading").attr('src', this.$store.state
                              .user_auth.posterUser);
                          } else {
                            this.toast.info(data.message, {
                              timeout: 4000
                            });

                          }
                        })
                        .catch((error) => {
                          this.errors = error;
                          this.refresh();
                        });
                    }

                  }

                })
                .catch((error) => {
                  this.errors = error;
                  this.requesting = false;
                });
            }
          }
        }
      }
    },
  }
</script>