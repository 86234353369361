<template>
<div class="block-user-widget">
  <div v-if="this.$store.state.user_auth != null" class="block-header">
    <button class="btn btn-close-block">
      <i class="bx bx-plus"></i>
    </button>
    <div v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.avatarUser != null && this.$store.state.user_auth.avatarUser != ''" class="d-flex block-widget-user align-items-center">
      <div class="avatar-user">
        <img :src="this.$store.state.user_auth.avatarUser" alt="photo de">
      </div>
      <div v-if="this.$store.state.user_auth != null" class="block-info-user">
        <h5 class="text-uppercase">{{ (this.$store.state.user_auth.fistnameUser != null && this.$store.state.user_auth.fistnameUser != "" ?
        this.$store.state.user_auth.fistnameUser : "") + ' ' + (this.$store.state.user_auth.nameUser != null &&
        this.$store.state.user_auth.nameUser != "" ?
        this.$store.state.user_auth.nameUser : "")}}</h5>
        <span class="tag">@{{ (this.$store.state.user_auth.fistnameUser != null && this.$store.state.user_auth.fistnameUser != "" ?
    this.$store.state.user_auth.fistnameUser : "") + (this.$store.state.user_auth.nameUser != null &&
      this.$store.state.user_auth.nameUser != "" ? this.$store.state.user_auth.nameUser : "") }}</span>
      </div>
    </div>
    <div class="d-flex items-list justify-content-between">
      <div class="items">
        <p>Follow<span>22K</span></p>
      </div>
      <div class="items">
        <p>Followers<span>42K</span></p>
      </div>
      <div class="items">
        <p>Posts<span>100</span></p>
      </div>
    </div>
  </div>
  <div class="block-body">
    <h6>Menu</h6>
    <ul class="list">
      <li>
        <router-link to="/posters-user" :class="($route.path == '/posters-user'  ? 'active' : '')">
          <i class="bx bx-globe"></i>Mes posts
        </router-link>
      </li>
      <li>
          <router-link to="/pictures-user" :class="($route.path == '/pictures-user'  ? 'active' : '')">
            <i class="bx bx-image"></i>
            Mes photos
          </router-link>
      </li>
      <li>
        <router-link to="/videos-user" :class="($route.path == '/videos-user'  ? 'active' : '')">
            <i class="bx bx-movie-play"></i>
            Mes vidéos
        </router-link>
      </li>
      <li>
          <router-link to="/friends-user" :class="($route.path == '/friends-user'  ? 'active' : '')">
            <i class="bx bx-user"></i> Mes camarades
          </router-link>
      </li>
      <li>
        <router-link to="/about-user" class="nav-link "
          :class="($route.path == '/about-user' || $route.path == '/about-user-education' || $route.path == '/about-user-places' || $route.path == '/about-user-infos' ? 'active' : '')">
            <i class="bx bx-pencil"></i>
            A propos de moi
        </router-link>
      </li>
    </ul>
    <h6>Menu</h6>
    <ul class="list">
      <li>
        <a href="#">
          <div class="indice"></div>
          <i class="bx bxs-user-plus"></i>
          <div class="d-flex justify-content-between w-100">
            <span>Demandes d'amis</span>
            <span class="d-block ms-auto">1</span>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="indice"></div>
          <i class="bx bxs-message"></i>
          <div class="d-flex justify-content-between w-100">
            <span>Messages</span>
            <span class="d-block ms-auto">1</span>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="indice"></div>
          <i class="bx bxs-bell"></i>
          <div class="d-flex justify-content-between w-100">
            <span>Notifications</span>
            <span class="d-block ms-auto">1</span>
          </div>
        </a>
      </li>
    </ul>
  </div>
  <div class="block-footer">
    <a href="#" @click.prevent="gb_deconnexionUser">Deconnexion</a>
  </div>
</div>
</template>

<script>
export default {
  name: 'LinkImportantUser',
}
</script>
