<template>
  <div class="card card-sugg">
    <div class="block-header">
      <h5>Anniversaires</h5>
      <!-- <a href="#">Voir plus</a> -->
         <router-link  to="/all-view-friends-birthday">
          Voir plus
        </router-link>
    </div>
    <div class="block-sugg">
      <div class="items-block items-anniv">
        <div class="avatar-friend">
          <i class="bx bx-cake"></i>
        </div>
        <div class="block-name-friend">
          <a href="#">
            C'est l'anniversaire de <span class="user-anniv">Estalla Nguma et 8 autres personnes</span>
            aujourd'hui
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
  name: 'BirthaysInFosUser',
  data() {
    return {
      requesting: false,
      requestingText: "Chargement des données",
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },

  mounted() {
    this.load();

    setInterval(() => {
      this.load();
    }, 120000);
  },

  methods: {
    load() {
      this.requesting = true;
      this.requesting = false;
    },
  },
}
</script>
