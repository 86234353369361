<template>
  <div class="global-div">
    <div class="wrapper">
      <div class="block-login">
        <div class="avatar">
          <img src="assets/images/9.jpg" alt="">
        </div>
        <div class="avatar">
          <img src="assets/images/13.jpg" alt="">
        </div>
        <div class="avatar">
          <img src="assets/images/7.jpg" alt="">
        </div>
        <div class="avatar">
          <img src="assets/images/8.jpg" alt="">
        </div>
        <div class="logo-app">
          <router-link to="/">
            <img src="assets/images/logo-app.png" alt="">
          </router-link>
        </div>
        <div class="block-cube">
          <div class="cube cube-1">
            <i class="bx bx-book"></i>
          </div>
          <div class="cube cube-2">
            <i class="bx bxs-graduation"></i>
          </div>
          <div class="cube cube-3">
            <i class="bx bxs-envelope"></i>
          </div>
          <div class="cube cube-4">
            <i class="bx bx-music"></i>
          </div>
          <div class="cube cube-5">
            <i class="bx bx-book-open"></i>
          </div>
          <div class="cube cube-6">
            <i class="bx bx-mobile"></i>
          </div>
        </div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-5 col-md-7 col-xl-4 col-xxl-4">
              <div class="card card-login">
                <div class="text-center">
                  <h2 class="title-lg">Connectez-vous</h2>
                </div>
                <form @submit.prevent="authentification">
                  <div class="form-group row g-lg-4 g-3">

                    <div class="col-12" @click.prevent="this.form.login = null">
                        <ul class="nav nav-tabs nav-sm-form mb-0 mt-2" id="myTab" role="tablist">
                          <li class="nav-item" role="presentation">
                            <a @click="this.form.request_number_login=true" class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#phone-connect" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Par numéro de tél</a>
                          </li>
                          <li class="nav-item" role="presentation">
                            <a @click="this.form.request_number_login=false" class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#email-connect" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Par email</a>
                          </li>
                        </ul>
                    </div>

                    <div class="col-12 position-relative" style="z-index: 3;">
                      <div class="tab-content" id="myTabContent">
                          <div class="tab-pane fade show active" id="phone-connect" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                              <div class="col-12 mb-1" style="margin-top: -10px;">
                                  <label for="" style="color: #fff;font-size:14px;">Téléphone</label>
                              </div>
                              <div class="col-12" style="z-index: 3;">
                                  <div class="col-focus">
                                        <!-- <input type="text"  v-model="this.form.login" class="form-control" required="required" placeholder="téléphone" id="phone"> -->

                                      <input v-if="this.form.request_number_login=true" type="text"  v-model="this.form.login" required="required"  class="form-control"  placeholder="téléphone" id="tel-connect" @keyup="bg_isNumberKeywords">
                                      <!-- <input type="text" class="form-control" required="required" placeholder="téléphone" id="tel-connect"> -->
                                      <i class="bx bx-phone"></i>
                                  </div>
                              </div>
                          </div>
                          <div class="tab-pane fade" id="email-connect" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                              <div class="col-focus">
                                  <input type="text" class="form-control" v-model="this.form.login" required="required" placeholder="Num ou email">
                                  <div class="fake-label">Email</div>
                                  <i class="bx bx-envelope"></i>
                              </div>
                          </div>
                      </div>
                    </div>

                     <!-- <div class="col-12" style="z-index: 2;">
                          <div class="col-focus">
                              <input type="text"  v-model="this.form.login" class="form-control" required="required" placeholder="téléphone" id="telephone">
                              <i class="bx bx-phone"></i>
                          </div>
                      </div> -->
                    <div class="col-12">
                      <div class="col-focus">
                        <input v-model="this.form.password" :type="!this.type_input_password ? 'password' : 'text'"
                          class="form-control input-password" required="required" placeholder="mot de passe">
                        <div class="fake-label">Mot de passe</div>
                        <i class="bx bx-lock"></i>
                        <div
                          :class="'block-show-password ' + (this.form.password != null && this.form.password.length > 0 ? 'show ' : ' ')"
                          @click.prevent="change_type_input">
                          <i :class="'bx bx-hide' + (!this.type_input_password ? ' ' : ' bx-show ')"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 text-center">
                      <a href="/reset-password" class="link-reset-1">
                        Mot de passe oublié ?
                      </a>
                    </div>
                    <div class="col-12 d-flex justify-content-center">
                      <button v-if="!this.requesting" class="btn btn-primary">
                        <span>Se connecter</span>
                        <span>Se connecter</span>
                        <span>Se connecter</span>
                      </button>
                      <p v-else class="paragraph text-center text-white">Veuillez patientez...</p>
                    </div>
                    <div class="col-12 text-center col-hidden">
                      <p>Vous n'avez pas de compte ? <br>
                        <a href="/register">
                          Créer maintenant
                        </a>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
              <div class="text-center col-hidden-mobile">
                <p class="paragraph">Vous n'avez pas de compte? <br>
                  <a href="/register" class="link-reset">
                    Créer
                    maintenant
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useToast } from "vue-toastification";
export default {
  name: 'LoginPage',
  data() {
    return {
      user: null,
      form: {
        login: null,
        password: null,
        request_number_login:true
      },
      requesting: false,
      minutes: 300,
      type_input_password: false,
      requestingText: "Veuillez patienter",
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  mounted() {
    this.gb_loadInputTel_login();

    console.log(this.$store.state.user_auth);
    console.log(localStorage.getItem('AUTHENTIFICATE_USER'));
    if (localStorage.getItem('AUTHENTIFICATE_USER') != null) {
    // if (this.$store.state.user_auth != null) {
      this.$store.state.user_auth = JSON.parse(localStorage.getItem("AUTHENTIFICATE_USER"));
      this.tchek_this_user();
    }
  },

  methods: {
    authentification() {
      if (this.bg_Online()) {

        if (this.form.login != null && this.form.password != null) {
          this.requesting = true;

          if(this.form.request_number_login){
            var valCountryCode = document.getElementsByClassName('iti__selected-dial-code')[0].innerText;
            if (this.getValidNumber_v1(this.form.login) && valCountryCode !=null && valCountryCode.trim() !="" && valCountryCode.trim() =="+243" ) {
              this.form.login = this.getNumberFormat(this.form.login);
            }
          }

          axios.post("https://www.connexion.schoolbac.com", this.form).then(({ data }) => {
            this.requesting = false;
            if (data.error == "false") {
              this.$store.commit('AUTHENTIFICATE_USER', data);
              localStorage.setItem('AUTHENTIFICATE_USER', JSON.stringify(data));
              this.$store.state.user_auth = data;
              // this.$router.push({ name: 'AboutUser' });
              this.$router.push({ name: 'PostersUser' });
            } else {
              if (data.statut == "password") {
                this.$store.state.user_auth = data;
                this.toast.error("Votre mot de passe est incorrect, veuillez confirmer si vous en etes le propriètaire !!!", {
                  timeout: 6000
                });
                setTimeout(() => {
                  this.$router.push({ name: 'VerifConnexion' });
                }, 6000);

              } else if (data.statut == "bloc") {
                this.$store.state.user_auth = data;
                this.$store.state.user_pass_reinitialisation = this.form.login;
                this.toast.error("Le compte  a subit plusieurs tentatives d'accès, veuillez le réinitialiser !!!", {
                  timeout: 6000
                });
                setTimeout(() => {
                  this.$router.push({ name: 'RestPassword' });
                }, 6000);
              } else {
                this.toast.error("Ce compte n'existe pas, veuillez vous inscrire !!!", {
                  timeout: 6000
                });
              }
            }
          }).catch(function (error) {
            console.log(error);
            this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
              timeout: 2000
            });
          });

        } else {
          this.toast.error("Veuillez remplir les champs manquants  !!!", {
            timeout: 2000
          });
        }
      }
    },

    tchek_this_user() {
      if (this.$store.state.user_auth != null) {
        this.$router.push({ name: 'AboutUser' });
      }
    },

    change_type_input() {
      if (this.type_input_password == false) {
        this.type_input_password = true;
      } else {
        this.type_input_password = false;
      }
    },

    getNumberFormat(value) {
      if (value.length > 0) {
        if (value.length == 13 && value.substring(0, 1) == '+') {
          return value;
        } else if (value.length == 12) {
          return '+' + value;
        } else if (value.length == 10) {
          return '+243' + value.substring(1, value.length);
        } else if (value.length == 9) {
          return '+243' + value;
        }
      }
    },

    getValidNumber_v1(value) {
      if (value.length == 9 || value.length == 10 || value.length == 12 || value.length == 13) {

        if (value.length == 9) {
          if (value.substring(0, 2) == "81" || value.substring(0, 2) == "82" || value.substring(0, 2) == "83" || value.substring(0, 2) == "84" || value.substring(0, 2) == "85" ||
            value.substring(0, 2) == "89" || value.substring(0, 2) == "90" || value.substring(0, 2) == "91" || value.substring(0, 2) == "97" || value.substring(0, 2) == "98" ||
            value.substring(0, 2) == "80" || value.substring(0, 2) == "99") {
            return true;
          } else {
            return false;
          }
        } else if (value.length == 10) {
          if (value.substring(0, 1) == '0') {
            if (value.substring(1, 3) == "81" || value.substring(1, 3) == "82" || value.substring(1, 3) == "83" || value.substring(1, 3) == "84" || value.substring(1, 3) == "85" ||
              value.substring(1, 3) == "89" || value.substring(1, 3) == "90" || value.substring(1, 3) == "91" || value.substring(1, 3) == "97" || value.substring(1, 3) == "98" ||
              value.substring(1, 3) == "80" || value.substring(1, 3) == "99") {
              return true;
            } else {
              return false;
            }

          } else {
            return false;
          }

        } else if (value.length == 12) {

          if (value.substring(0, 3) == '243') {

            if (value.substring(3, 5) == "81" || value.substring(3, 5) == "82" || value.substring(3, 5) == "83" || value.substring(3, 5) == "84" || value.substring(3, 5) == "85" ||
              value.substring(3, 5) == "89" || value.substring(3, 5) == "90" || value.substring(3, 5) == "91" || value.substring(3, 5) == "97" || value.substring(3, 5) == "98" ||
              value.substring(3, 5) == "80" || value.substring(3, 5) == "99") {
              return true;
            } else {
              return false;
            }

          } else {
            return false;
          }
        } else if (value.length == 13) {

          if (value.substring(0, 1) == '+') {

            if (value.substring(1, 4) == '243') {
              if (value.substring(4, 6) == "81" || value.substring(4, 6) == "82" || value.substring(4, 6) == "83" || value.substring(4, 6) == "84" || value.substring(4, 6) == "85" ||
                value.substring(4, 6) == "89" || value.substring(4, 6) == "90" || value.substring(4, 6) == "97" || value.substring(4, 6) == "98" ||
                value.substring(4, 6) == "80" || value.substring(4, 6) == "99") {
                return true;
              } else {
                return false;
              }

            } else {
              return false;
            }

          } else {
            return false;
          }
        }

      } else {
        return false;
      }
      return false;
    },

    getValidNumber(value) {
      if (value.length == 10 || value.length == 12 || value.length == 13) {

        if (value.length == 10) {
          if (value.substring(0, 1) == '0') {
            if (value.substring(1, 3) == "81" || value.substring(1, 3) == "82" || value.substring(1, 3) == "83" || value.substring(1, 3) == "84" || value.substring(1, 3) == "85" ||
              value.substring(1, 3) == "89" || value.substring(1, 3) == "90" || value.substring(1, 3) == "91" || value.substring(1, 3) == "97" || value.substring(1, 3) == "98" ||
              value.substring(1, 3) == "80" || value.substring(1, 3) == "99") {
              return true;
            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }

        } else if (value.length == 12) {

          if (value.substring(0, 3) == '243') {

            if (value.substring(3, 5) == "81" || value.substring(3, 5) == "82" || value.substring(3, 5) == "83" || value.substring(3, 5) == "84" || value.substring(3, 5) == "85" ||
              value.substring(3, 5) == "89" || value.substring(3, 5) == "90" || value.substring(3, 5) == "91" || value.substring(3, 5) == "97" || value.substring(3, 5) == "98" ||
              value.substring(3, 5) == "80" || value.substring(3, 5) == "99") {
              return true;
            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }
        } else if (value.length == 13) {

          if (value.substring(0, 1) == '+') {

            if (value.substring(1, 4) == '243') {
              if (value.substring(4, 6) == "81" || value.substring(4, 6) == "82" || value.substring(4, 6) == "83" || value.substring(4, 6) == "84" || value.substring(4, 6) == "85" ||
                value.substring(4, 6) == "89" || value.substring(4, 6) == "90" || value.substring(4, 6) == "97" || value.substring(4, 6) == "98" ||
                value.substring(4, 6) == "80" || value.substring(4, 6) == "99") {
                return true;
              } else {
                this.toast.error("Numéro de téléphone invalide", {
                  timeout: 5000
                });
                return false;
              }

            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }
        }

      } else {
        this.toast.error("Numéro de téléphone invalide", {
          timeout: 5000
        });
        return false;
      }
      return false;
    },

    decrementation_minutes() {
      setInterval(() => {
        if (this.minutes != 0) {
          this.minutes = parseInt(this.minutes) - 1;
        } else {
          this.nbre_essayer = 0;
        }
      }, 1000);
    },

    
    bg_isNumberKeywords(event) {
      const value = event.target.value
      const val = value.replace(/\D+/,'');
      this.form.login = val;
    },

  }
}
</script>

