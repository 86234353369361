<template>
  <div class="col-lg-9 col-xl-9 col-xxl-9 order-1 order-lg-2">
    <h5 class="title-page">
      Anniversaires
    </h5>
    <div class="row g-lg-3 g-3">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="card card-friend-sugg-lg card-anniv-lg">
          <div class="d-flex align-items-center bock-content-card">
            <div class="avatar-camara-sugg">
              <div class="block-icone">
                <i class="bx bx-cake"></i>
              </div>
            </div>
            <div class="content-text w-100">
            
              <p class="anniv-para">Aujourd'hui c'est l'anniversaire de <a href="#">John doe</a></p>
              <div class="d-flex btns justify-content-end">
                <a href="#" class="btn">
                  <i class="bx bxs-user-plus"></i>
                  <span>Souhaiter un veoux</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="card card-friend-sugg-lg card-anniv-lg">
          <div class="d-flex align-items-center bock-content-card">
            <div class="avatar-camara-sugg">
              <div class="block-icone">
                <i class="bx bx-cake"></i>
              </div>
            </div>
            <div class="content-text w-100">
            
              <p class="anniv-para">Aujourd'hui c'est l'anniversaire de <a href="#">John doe</a></p>
              <div class="d-flex btns justify-content-end">
                <a href="#" class="btn">
                  <i class="bx bxs-user-plus"></i>
                  <span>Souhaiter un veoux</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="card card-friend-sugg-lg card-anniv-lg">
          <div class="d-flex align-items-center bock-content-card">
            <div class="avatar-camara-sugg">
              <div class="block-icone">
                <i class="bx bx-cake"></i>
              </div>
            </div>
            <div class="content-text w-100">
            
              <p class="anniv-para">Aujourd'hui c'est l'anniversaire de <a href="#">John doe</a></p>
              <div class="d-flex btns justify-content-end">
                <a href="#" class="btn">
                  <i class="bx bxs-user-plus"></i>
                  <span>Souhaiter un veoux</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="card card-friend-sugg-lg card-anniv-lg">
          <div class="d-flex align-items-center bock-content-card">
            <div class="avatar-camara-sugg">
              <div class="block-icone">
                <i class="bx bx-cake"></i>
              </div>
            </div>
            <div class="content-text w-100">
            
              <p class="anniv-para">Aujourd'hui c'est l'anniversaire de <a href="#">John doe</a></p>
              <div class="d-flex btns justify-content-end">
                <a href="#" class="btn">
                  <i class="bx bxs-user-plus"></i>
                  <span>Souhaiter un veoux</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="card card-friend-sugg-lg card-anniv-lg">
          <div class="d-flex align-items-center bock-content-card">
            <div class="avatar-camara-sugg">
              <div class="block-icone">
                <i class="bx bx-cake"></i>
              </div>
            </div>
            <div class="content-text w-100">
            
              <p class="anniv-para">Aujourd'hui c'est l'anniversaire de <a href="#">John doe</a></p>
              <div class="d-flex btns justify-content-end">
                <a href="#" class="btn">
                  <i class="bx bxs-user-plus"></i>
                  <span>Souhaiter un veoux</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="card card-friend-sugg-lg card-anniv-lg">
          <div class="d-flex align-items-center bock-content-card">
            <div class="avatar-camara-sugg">
              <div class="block-icone">
                <i class="bx bx-cake"></i>
              </div>
            </div>
            <div class="content-text w-100">
            
              <p class="anniv-para">Aujourd'hui c'est l'anniversaire de <a href="#">John doe</a></p>
              <div class="d-flex btns justify-content-end">
                <a href="#" class="btn">
                  <i class="bx bxs-user-plus"></i>
                  <span>Souhaiter un veoux</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="card card-friend-sugg-lg card-anniv-lg">
          <div class="d-flex align-items-center bock-content-card">
            <div class="avatar-camara-sugg">
              <div class="block-icone">
                <i class="bx bx-cake"></i>
              </div>
            </div>
            <div class="content-text w-100">
            
              <p class="anniv-para">Aujourd'hui c'est l'anniversaire de <a href="#">John doe</a></p>
              <div class="d-flex btns justify-content-end">
                <a href="#" class="btn">
                  <i class="bx bxs-user-plus"></i>
                  <span>Souhaiter un veoux</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="card card-friend-sugg-lg card-anniv-lg">
          <div class="d-flex align-items-center bock-content-card">
            <div class="avatar-camara-sugg">
              <div class="block-icone">
                <i class="bx bx-cake"></i>
              </div>
            </div>
            <div class="content-text w-100">
            
              <p class="anniv-para">Aujourd'hui c'est l'anniversaire de <a href="#">John doe</a></p>
              <div class="d-flex btns justify-content-end">
                <a href="#" class="btn">
                  <i class="bx bxs-user-plus"></i>
                  <span>Souhaiter un veoux</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import { useToast } from "vue-toastification";
// import MajInfos from '../infos/MajInfos.vue';

export default {
  name: 'PicturesUser',
  components: {
    // ComponentImgs,
    // MajInfos
  },
  data() {
    return {
      user: null,
      ImgsUserData: [],
      ImgsUser:null,
      requesting: false,
      typeImages: "alls_photos",
      requestingText: "Chargement des données",
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  mounted() {
    var setLoad = setInterval(() => {
      if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {
        if (this.typeImages == "corbeille") {
          this.corbeille();
        } else {
          this.load();
        }
        clearInterval(setLoad);

        setInterval(() => {
          if (this.typeImages == "corbeille") {
            this.corbeille();
          } else {
            this.load();
          }
        }, 120000);
      }
    }, 7000);
  },

  methods: {
    load() {
      if (this.bg_Online) {
        if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {


          var postData = new Object();
          if (!this.gb_viewUserOrOtherUser(this.$route) && this.$store.state.user_auth != null) {
            postData.mailUser = this.$store.state.user_auth.mailUser;
            postData.codingUser = this.$store.state.user_auth.codingUser;

          } else if (this.gb_viewUserOrOtherUser(this.$route) && this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.codingUserCamarade != null && this.$store.state.user_auth_other.codingUserCamarade.trim() != '' && this.$store.state.params == this.$route.params.friend) {
            postData.mailUser = this.$store.state.user_auth_other.mailCamarade.trim();
            postData.codingUser = this.$store.state.user_auth_other.codingUserCamarade.trim();
          }


          if (postData.mailUser != null && postData.mailUser.trim() != "" && postData.codingUser != null && postData.codingUser.trim() != "") {
            axios.post("https://www.all.img.schoolbac.com", postData)
              .then(({ data }) => {
                if (data.statut=="false") {
                  this.ImgsUserData = data;

                  this.blockImageView(this.typeImages);
                } else {
                  this.toast.error(data.message, {
                    timeout: 4000
                  });
                }
              })
              .catch((error) => {
                this.errors = error;
                // this.refresh();
              });
          }
        }
      } 
    },
  },
}
</script>
