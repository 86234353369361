<template>
    <div v-if="$route.path == '/friends-user'" class="container-fluid px-lg-5 mt-lg-4 mt-2 px-1">
      <div class="row justify-content-center g-lg-4 g-3">
        <div class="col-lg-12 col-xl-12 col-xxl-12">
          <div class="card card-lg-tools">
            <h2 class="title-page">Camarades <span class="badge">30</span></h2>
            <ul class="nav nav-tabs nav-sm" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#all-picture"
                  type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Toutes les
                  camarades</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-photo" type="button"
                  role="tab" aria-controls="profile-tab-pane" aria-selected="false">Family
                  friends</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#cover-photo" type="button"
                  role="tab" aria-controls="contact-tab-pane" aria-selected="false">Close
                  friends</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#album" type="button"
                  role="tab" aria-controls="contact-tab-pane" aria-selected="false">Mutual Friends</button>
              </li>
            </ul>
            <div class="container-fluid p-0">
              <div v-if="this.DatasFriendsUser.length > 0" class="row g-3">
                <div  v-for="(camarade, index) in DatasFriendsUser"  :key="index" class="col-lg-3 col-12">
                    <div class="card card-friend">
                      <div class="block-option dropdown">
                        <div class="block-dots" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                          <i class="bx bx-dots-vertical-rounded"></i>
                        </div>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                          <h6>Actions</h6>
                          <li><a class="dropdown-item" href="#"><i class="bx bxs-pencil"></i>Modifier</a></li>
                          <li><a class="dropdown-item" href="#"><i class="bx bxs-download"></i> Enregistrer</a></li>
                          <li><a class="dropdown-item" href="#"><i class="bx bx-trash"></i> Supprimer</a></li>
                        </ul>
                      </div>
                      <div class="block-content">
                        <div class="block-avatar-friend">
                          <img :src="(camarade.avatarCamarade != null && camarade.avatarCamarade != '' ? camarade.avatarCamarade : 'assets/images/team.jpg')" alt="photo de ">
                        </div>
                        <div class="text-center">
                          <a href="#">
                            <h6>{{ camarade.nameCamarade }}</h6>
                          </a>
                          <p>{{  camarade.nbrCamaradeCommun }}  {{ parseInt(camarade.nbrCamaradeCommun) > 1 ? ' amis(es)':' ami(e)' }} en commun</p>
                          <a href="#" class="btn btn-follow">
                            <i class="bx bx-wifi"></i>
                            Suivre
                          </a>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              <div v-if="this.DatasFriendsUser.length == 0 && this.requeteNumber !=0" class="row  g-3 justify-content-center">
                  <div class="col-lg-6 text-center">
                    <small>Vous ne disposez d'aucun(e) ami(e) à ce jour !!!</small>
                  </div>
              </div>
              <div v-if="this.DatasFriendsUser.length == 0 && this.requeteNumber ==0" class="row  g-3 justify-content-center">
                  <div class="col-lg-6 text-center">
                      <lottie-player class="text-center" :src="this.$store.state.app_url_img_localhost + '/assets/lotties/loading/load7.json'" background="transparent" speed="1" style="width: 100%; height: 90px;" loop autoplay></lottie-player>
                  </div>
              </div>
            </div>
          </div>
        </div>
    
      </div>
    </div>

    <!-- End Camarade -->
    <div v-if="($route.path == '/profil-friends-user/' + $route.params.friend) && this.$store.state.params == $route.params.friend" class="container-fluid px-lg-5 mt-lg-4 mt-2 px-1">
      <div class="row justify-content-center g-lg-4 g-3">
        <div class="col-lg-12 col-xl-12 col-xxl-12">
          <div class="card card-lg-tools">
            <h2 class="title-page">Camarades <span class="badge">30</span></h2>
            <ul class="nav nav-tabs nav-sm" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#all-picture"
                  type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Toutes les
                  camarades</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-photo" type="button"
                  role="tab" aria-controls="profile-tab-pane" aria-selected="false">Family
                  friends</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#cover-photo" type="button"
                  role="tab" aria-controls="contact-tab-pane" aria-selected="false">Close
                  friends</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#album" type="button"
                  role="tab" aria-controls="contact-tab-pane" aria-selected="false">Mutual Friends</button>
              </li>
            </ul>
            <div class="container-fluid p-0">
              <div v-if="this.DatasFriendsUser.length > 0" class="row g-3">
                <div  v-for="(camarade, index) in DatasFriendsUser"  :key="index" class="col-lg-3 col-12">
                    <div class="card card-friend">
                      <div class="block-option dropdown">
                        <div class="block-dots" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                          <i class="bx bx-dots-vertical-rounded"></i>
                        </div>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                          <h6>Actions</h6>
                          <li><a class="dropdown-item" href="#"><i class="bx bxs-pencil"></i>Modifier</a></li>
                          <li><a class="dropdown-item" href="#"><i class="bx bxs-download"></i> Enregistrer</a></li>
                          <li><a class="dropdown-item" href="#"><i class="bx bx-trash"></i> Supprimer</a></li>
                        </ul>
                      </div>
                      <div class="block-content">
                        <div class="block-avatar-friend">
                          <img :src="(camarade.avatarCamarade != null && camarade.avatarCamarade != '' ? camarade.avatarCamarade : 'assets/images/team.jpg')" alt="photo de ">
                        </div>
                        <div class="text-center">
                          <a href="#">
                            <h6>{{ camarade.nameCamarade }}</h6>
                          </a>
                          <p>{{  camarade.nbrCamaradeCommun }}  {{ parseInt(camarade.nbrCamaradeCommun) > 1 ? ' amis(es)':' ami(e)' }} en commun</p>
                          <a href="#" class="btn btn-follow">
                            <i class="bx bx-wifi"></i>
                            Suivre
                          </a>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              <div v-if="this.DatasFriendsUser.length == 0 && this.requeteNumber !=0" class="row  g-3 justify-content-center">
                  <div class="col-lg-6 text-center">
                    <small>Vous ne disposez d'aucun(e) ami(e) à ce jour !!!</small>
                  </div>
              </div>
              <div v-if="this.DatasFriendsUser.length == 0 && this.requeteNumber ==0" class="row  g-3 justify-content-center">
                  <div class="col-lg-6 text-center">
                      <lottie-player class="text-center" :src="this.$store.state.app_url_img_localhost + '/assets/lotties/loading/load7.json'" background="transparent" speed="1" style="width: 100%; height: 90px;" loop autoplay></lottie-player>
                  </div>
              </div>
            </div>
          </div>
        </div>
    
      </div>
    </div>
    <!-- End Camarade -->

</template>

<script>

import axios from 'axios';
import { useToast } from "vue-toastification";


export default {
  name: 'FriendUserPage',
  data() {
    return {
      user: null,
      DatasFriendsUser: [],
      requesting: false,
      requestingText: "Chargement des données",
      requeteNumber: 0
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  mounted() {
      this.gb_loadReFreshUser();

    if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {
      this.requeteNumber = 0;
      this.load();

      setInterval(() => {
        this.load();
      }, 120000);
    }
  },

  methods: {
    load() {
      if (this.bg_Online) {
        if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {
          
          if (this.$store.state.user_auth.mailUser != null && this.$store.state.user_auth.mailUser.trim() != "" && this.$store.state.user_auth.codingUser != null && this.$store.state.user_auth.codingUser.trim() != "") {

            var postData;
             if (!this.gb_viewUserOrOtherUser(this.$route) && this.$store.state.user_auth != null) {
              postData = {
                "mailUser": this.$store.state.user_auth.mailUser,
                "codingUser": this.$store.state.user_auth.codingUser,
                "statut": "index_1"
              };
            } else if (this.gb_viewUserOrOtherUser(this.$route) && this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.codingUserCamarade != null && this.$store.state.user_auth_other.codingUserCamarade.trim() != '' && this.$store.state.params == this.$route.params.friend) {
              postData = {
                "mailUser": this.$store.state.user_auth_other.mailCamarade.trim(),
                "codingUser": this.$store.state.user_auth_other.codingUserCamarade.trim(),
                "statut": "index_1"
              };
            }

            // var postData = {
            //   "mailUser": this.$store.state.user_auth.mailUser,
            //   "codingUser": this.$store.state.user_auth.codingUser,
            //   "statut": "index_1"
            // };


            axios.post("https://www.camarade.schoolbac.com", postData)
              .then(({ data }) => {
                if (data.length > 0) {
                  this.DatasFriendsUser = data;
                  this.requeteNumber++;
                } else {
                  this.gb_notifications(0, data.message);
                }
              })
              .catch((error) => {
                this.errors = error;
                // this.refresh();
              });

          }
        }
      }
    },


  },

}
</script>
