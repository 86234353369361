<template>
  <div v-if="$route.path == '/about-user-places'" class="col-lg-8">
    <div v-if="InfosDatas != null && AllsInfos != null && AllsInfos.title_second.length > 0" class="block-items-about">
      <div class="item-about" v-for="(seconLevel, index) in AllsInfos.title_second"  :key="index">
        <h6 class="title-about">{{seconLevel.titlesecond }}</h6>
        <a href="#" class="add-items" data-bs-toggle="modal" data-bs-target="#modalMajInfosUser">
          <i class="bx bx-plus"></i>
          <span v-if="seconLevel.titlesecond.includes('résidence')" @click.prevent="gb_modalMajTitre('Ajouter une ville de résidence', 'Nouvelle ville de résidence', null, 'bx bxs-map', 'STORE')">Ajouter une ville de résidence</span>
          <span v-if="seconLevel.titlesecond.includes('origine')" @click.prevent="gb_modalMajTitre('Ajouter une ville d\'origine', 'Nouvelle ville d\'origine', null, 'bx bxs-map', 'STORE')">Ajouter une ville d'origine</span>
          <span v-if="seconLevel.titlesecond.includes('actuelle')" @click.prevent="gb_modalMajTitre('Ajouter une ville actuelle', 'Nouvelle ville actuelle', null, 'bx bxs-home','STORE')">Ajouter une ville actuelle</span>
        </a>
        <div v-if="seconLevel.elements.length > 0">
          <div v-for="elemt in seconLevel.elements"  :key="elemt.index" class="d-flex align-items-center justify-content-between">
            <div class="block-left">
              <div class="d-flex align-items-center">
                <i class="bx bxs-map"></i>
                <p  v-if="seconLevel.titlesecond.includes('résidence')" class="mb-0">Ville de résidence <span>{{ elemt.description }}</span></p>
                <p v-if="seconLevel.titlesecond.includes('origine')" class="mb-0">Ville d'origine <span>{{ elemt.description }}</span></p>
                <p v-if="seconLevel.titlesecond.includes('actuelle')" class="mb-0">Ville actuelle <span>{{ elemt.description }}</span></p>
              </div>
            </div>
            <div class="block-right">
              <div class="d-flex align-items-center">
                <button @click.prevent="loadstateElement(elemt.index, elemt.index_etat, seconLevel.titlesecond)" class="btn btn-view" data-bs-toggle="modal" data-bs-target="#modalMajInfosUserVisibility">
                  <i v-if="elemt.index_etat =='three'" class="bx bxs-lock"></i>
                  <i v-if="elemt.index_etat =='two'" class="bx bx-user"></i>
                  <i v-if="elemt.index_etat =='one'" class="bx bx-globe"></i>
                </button>
                <button class="btn btn-edit">
                  <i class="bx bx-dots-horizontal-rounded"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        </div>
    </div>
    <div v-if="AllsInfos == null || AllsInfos.title_second.length == 0">
      <p class="text-center my-5 py-5">Chargement....</p>
    </div>
  </div>

  <!-- Camarade -->
  <div v-if="($route.path == '/profil-about-user-places/' + $route.params.friend) && this.$store.state.params == $route.params.friend" class="col-lg-8">
    <div v-if="InfosDatas != null && AllsInfos != null && AllsInfos.title_second.length > 0" class="block-items-about">
      <div class="item-about" v-for="(seconLevel, index) in AllsInfos.title_second"  :key="index">
        <h6 class="title-about">{{seconLevel.titlesecond }}</h6>
        <a href="#" class="add-items" data-bs-toggle="modal" data-bs-target="#modalMajInfosUser">
          <i class="bx bx-plus"></i>
          <span v-if="seconLevel.titlesecond.includes('résidence')" @click.prevent="gb_modalMajTitre('Ajouter une ville de résidence', 'Nouvelle ville de résidence', null, 'bx bxs-map', 'STORE')">Ajouter une ville de résidence</span>
          <span v-if="seconLevel.titlesecond.includes('origine')" @click.prevent="gb_modalMajTitre('Ajouter une ville d\'origine', 'Nouvelle ville d\'origine', null, 'bx bxs-map', 'STORE')">Ajouter une ville d'origine</span>
          <span v-if="seconLevel.titlesecond.includes('actuelle')" @click.prevent="gb_modalMajTitre('Ajouter une ville actuelle', 'Nouvelle ville actuelle', null, 'bx bxs-home','STORE')">Ajouter une ville actuelle</span>
        </a>
        <div v-if="seconLevel.elements.length > 0">
          <div v-for="elemt in seconLevel.elements"  :key="elemt.index" class="d-flex align-items-center justify-content-between">
            <div class="block-left">
              <div class="d-flex align-items-center">
                <i class="bx bxs-map"></i>
                <p  v-if="seconLevel.titlesecond.includes('résidence')" class="mb-0">Ville de résidence <span>{{ elemt.description }}</span></p>
                <p v-if="seconLevel.titlesecond.includes('origine')" class="mb-0">Ville d'origine <span>{{ elemt.description }}</span></p>
                <p v-if="seconLevel.titlesecond.includes('actuelle')" class="mb-0">Ville actuelle <span>{{ elemt.description }}</span></p>
              </div>
            </div>
            <div class="block-right">
              <div class="d-flex align-items-center">
                <button @click.prevent="loadstateElement(elemt.index, elemt.index_etat, seconLevel.titlesecond)" class="btn btn-view" data-bs-toggle="modal" data-bs-target="#modalMajInfosUserVisibility">
                  <i v-if="elemt.index_etat =='three'" class="bx bxs-lock"></i>
                  <i v-if="elemt.index_etat =='two'" class="bx bx-user"></i>
                  <i v-if="elemt.index_etat =='one'" class="bx bx-globe"></i>
                </button>
                <button class="btn btn-edit">
                  <i class="bx bx-dots-horizontal-rounded"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        </div>
    </div>
    <div v-if="AllsInfos == null || AllsInfos.title_second.length == 0">
      <p class="text-center my-5 py-5">Chargement....</p>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
  name: 'Places',
  props: ['InfosDatas', 'ObjectRefreshElement'],
  data() {
    return {
      AllsInfos: null,
      requesting: false,
      requestingText: "Chargement des données",
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  mounted() {
    setTimeout(() => {
      var setLoad = setInterval(() => {
        if (this.InfosDatas != null) {
          if (this.InfosDatas.titre_principal != null) {
            this.mounted_load_start();
            clearInterval(setLoad);
          }
        }
      }, 10000);
    }, 1500);
  },


  methods: {
    load() {
      if (this.bg_Online) {

        if (this.InfosDatas != null) {
          // Object
          var objtitleprim = new Object();
          objtitleprim.statut = false;
          objtitleprim.message = this.InfosDatas.message;
          objtitleprim.title = this.InfosDatas.titre_principal;
          objtitleprim.title_index = this.InfosDatas.index_titre;
          objtitleprim.title_second = [];
          this.AllsInfos = objtitleprim;

          // Request
          // var postDataSecondary = this.gb_objectRequestTheBase();
          var postDataSecondary = {...this.gb_objectRequestTheBase(),...{"index_titre" : this.InfosDatas.index_titre }};

          // var postDataSecondary = new Object();
          // postDataSecondary.mailUser = this.$store.state.user_auth.mailUser;
          // postDataSecondary.codingUser = this.$store.state.user_auth.codingUser;
          // postDataSecondary.index_titre = this.InfosDatas.index_titre;

          if (this.InfosDatas.index_titre != null && this.InfosDatas.index_titre.trim() != "" && this.AllsInfos != null) {

            axios.post("https://www.title.secondary.schoolbac.com", postDataSecondary)
              .then(({ data }) => {
                var dataSecondTitle = data;

                if (dataSecondTitle.length > 0) {

                  for (let g = 0; g < dataSecondTitle.length; g++) {
                    // Object
                    var objtitlesecond = new Object();
                    objtitlesecond.statut = false;
                    objtitlesecond.message = dataSecondTitle[g].message;
                    objtitlesecond.titlesecond = dataSecondTitle[g].titre_secondaire;
                    objtitlesecond.soustitle = dataSecondTitle[g].sous_titre;
                    objtitlesecond.second_index_statut = dataSecondTitle[g].index_statut;
                    objtitlesecond.elements = [];
                    this.AllsInfos.title_second.push(objtitlesecond);

                    // Request
                    var postObjBlockElem = { ...this.gb_objectRequestTheBase(), ...{ "index_statut": dataSecondTitle[g].index_statut } };
                    // var postObjBlockElem = new Object();
                    // postObjBlockElem.mailUser = this.$store.state.user_auth.mailUser;
                    // postObjBlockElem.codingUser = this.$store.state.user_auth.codingUser;
                    // postObjBlockElem.index_statut = dataSecondTitle[g].index_statut;

                    if (dataSecondTitle[g].index_statut != null && dataSecondTitle[g].index_statut.trim() != "") {
                      axios.post("https://www.element.ci.schoolbac.com", postObjBlockElem)
                        .then(({ data }) => {
                          var dataE = data;
                          this.AllsInfos.title_second[g].elements = dataE;
                        })
                        .catch((error) => {
                          this.errors = error;
                          this.bg_messagesError();
                        });
                    }

                  }

                }
              })
              .catch((error) => {
                console.log(error);
                this.bg_messagesError();
              });
          }
          // var Datas = null;
          // Datas = this.gb_loadBlockTypeInfosUser(this.InfosDatas);

          // console.log("resultats");
          // console.log(Datas);
          // console.log(Datas.value);
          // console.log(this.$store.state.DatasInfoslockUser);
          // // console.log(Datas.status);
          // if (Datas.status == 200) {
          //   this.AllsInfos = Datas.data;
          //   console.log(this.AllsInfos);
          // }

          // console.log(this.bg_isObjectEmpty(Datas));
        }



      }
    },

    mounted_load_start() {
      if (this.InfosDatas != null) {
        this.load();

        setInterval(() => {
          this.load();
        }, 300000);

        setInterval(() => {
          this.refreshDataInVisibility();
        }, 20000);
      }
    },

    loadstateElement(id, state, levelParent) {
      this.gb_loadstateElement(id, state, levelParent);
    },

    refreshDataInVisibility() {
      if (this.ObjectRefreshElement != null) {
        if (this.ObjectRefreshElement.state != null && this.ObjectRefreshElement.idElement != null) {
          if (this.AllsInfos.title_second.length > 0 && this.$store.state.visibilityElementParent != null) {
            for (let a = 0; a < this.AllsInfos.title_second.length; a++) {
              if (this.AllsInfos.title_second[a].titlesecond == this.$store.state.visibilityElementParent) {
                for (let b = 0; b < this.AllsInfos.title_second[a].elements.length; b++) {
                  if (this.AllsInfos.title_second[a].elements[b].index == this.ObjectRefreshElement.idElement) {
                    this.AllsInfos.title_second[a].elements[b].index_etat = this.ObjectRefreshElement.state;
                    this.$emit('emitSuccess');
                    break;
                  }
                }
              }
            }
          }
        }
      }
    },

  },

}
</script>
