<template>
  <div>
    <div class="modal fade" id="modalMajInfosUser" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"  style="z-index:999999999 !important">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title d-flex align-items-center" id="exampleModalLabel">
              <span>{{ this.$store.state.titleOptionMaj }}</span>
            </h5>
            <button type="button" id="modal_close" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <i class="bx bx-plus"></i>
            </button>
          </div>
          <div class="modal-body">
            <form method="post" action="">
              <div class="form-group row g-4">
                <div
                  v-if="this.$store.state.titleOptionMajIcon != null && this.$store.state.titleOptionMajIcon != 'bx bx-male' && this.$store.state.titleOptionMajIcon !='bx bxs-calendar'"
                  class="col-lg-12 col-row">
                  <input type="text" name="lieux" class="form-control"
                    :placeholder="this.$store.state.titleOptionMajPlaceholder" required v-model="this.form.params">
                  <i
                    :class="(this.$store.state.titleOptionMajIcon != null ? this.$store.state.titleOptionMajIcon : 'bx bxs-briefcase')"></i>
                </div>
                 <div
                    v-if="this.$store.state.titleOptionMajIcon != null &&  this.$store.state.titleOptionMajIcon == 'bx bxs-calendar'"
                    class="col-lg-12 col-row">
                    <input type="date" name="birthday" class="form-control"
                      :placeholder="this.$store.state.titleOptionMajPlaceholder" required v-model="this.form.params">
                    <i
                      :class="(this.$store.state.titleOptionMajIcon != null ? this.$store.state.titleOptionMajIcon : 'bx bxs-briefcase')"></i>
                  </div>
                <div
                  v-if="this.$store.state.titleOptionMajIcon != null && this.$store.state.titleOptionMajIcon == 'bx bx-male'"
                  class="col-lg-12 col-row">
                  <i class="bx bx-male"></i>
                  <select v-model="this.form.params" class="form-select form-control" name="employe_id"
                    aria-label="Default select example">
                    <option :value="this.form.params" selected>
                      {{ this.form.params == 'M' ? 'Homme' : 'Femme' }}
                    </option>
                    <option :value="this.form.params == 'M' ? 'F' : 'M'">
                      {{ this.form.params == 'M' ? 'Femme' : 'Homme' }}
                    </option>
                  </select>
                </div>
                <div class="col-lg-12 text-end">
                  <button class="btn btn-add bg-danger" v-if="this.$store.state.MajParamDataUpdate !=null && !this.$store.state.MajParamDataUpdate.includes('nom') && !this.$store.state.MajParamDataUpdate.includes('surname')" style="margin-right: 5px;">Supprimer</button>
                  <button @click.prevent="UpdateIdentity" class="btn btn-add">{{ (this.$store.state.titleOptionMajAction
                    != null && this.$store.state.titleOptionMajAction.trim() != '' &&
                    this.$store.state.titleOptionMajAction.trim() == "STORE") ? 'Ajouter' : 'Modifier' }}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="modalMajInfosUserVisibility" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div  class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title d-flex align-items-center" id="exampleModalLabel">
              <span>Visibilité</span>
            </h5>
            <button type="button"  id="modal_close_visibility" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <i class="bx bx-plus"></i>
            </button>
          </div>
          <div class="modal-body">
            <form method="post" action="">
              <div class="form-group row g-2">
                <div class="col-lg-12 col-row">
                  <div class="block-select">
                    <input type="radio" id="radio-1" name="radio-select">
                    <label @click.prevent="loadStateVisibilityElement(this.$store.state.visibilityElementId,'one')" for="radio-1" class="label-select"  :style="'background: ' + (this.formVisibility.stateElement == 'one' ? '#eaf5f2;' : '')">
                      <span> <i  v-if="this.$store.state.visibilityElement== 'one'" class="bx bx-check text-success"></i> Public</span>
                      <i class="bx bx-globe"></i>
                    </label>
                  </div>
                </div>
                <div class="col-lg-12 col-row">
                  <div class="block-select">
                    <input type="radio" id="radio-2" name="radio-select">
                    <label @click.prevent="loadStateVisibilityElement(this.$store.state.visibilityElementId, 'two')" for="radio-2" class="label-select" :style="'background: ' + (this.formVisibility.stateElement == 'two' ? '#eaf5f2;' : '')">
                      <span><i  v-if="this.$store.state.visibilityElement== 'two'" class="bx bx-check text-success"></i> Camarade</span>
                      <i class="bx bxs-user"></i>
                    </label>
                  </div>
                </div>
                <div class="col-lg-12 col-row">
                  <div class="block-select">
                    <input type="radio" id="radio-3" name="radio-select">
                    <label @click.prevent="loadStateVisibilityElement(this.$store.state.visibilityElementId, 'three')" for="radio-3" class="label-select" :style="'background: ' + (this.formVisibility.stateElement == 'three' ? '#eaf5f2;' : '')">
                      <span><i v-if="this.$store.state.visibilityElement== 'three'" class="bx bx-check text-success"></i> Privé</span>
                      <i class="bx bxs-lock"></i>
                    </label>
                  </div>
                </div>
                <div class="col-lg-12 text-end">
                  <button @click.prevent="executeMajVisibility" class="btn btn-add">Modifier</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
  name: 'Education',
  data() {
    return {
      user: null,
      requesting: false,
      requestingText: "Chargement des données",
      formIdentite: {
        firstnameUser: null,
        nameUser: null,
        nicknameUser: null,
        mailUser: null,
        codingUser: null,
      },
      form: {
        params: null,
      },
      formVisibility: {
        stateElement: null,
        idElement: null,
      },
      isModal: false,
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  mounted() {
    // this.loadMajIdentity();
    // this.loadMajElement();
  },

  methods: {

    async UpdateIdentity() {
      if (this.Online()) {
        if (this.$store.state.user_auth.mailUser != null && this.$store.state.user_auth.mailUser != "" && this.$store.state.user_auth.codingUser != null && this.$store.state.user_auth.codingUser != "") {

          if (this.$store.state.titleOptionMajValue != null && this.$store.state.titleOptionMajValue.trim() != "" && this.form.params != null && this.form.params.trim() != "" && this.form.params.trim() != this.$store.state.titleOptionMajValue.trim()) {

            if (this.$store.state.titleOptionMajAction != null && this.$store.state.titleOptionMajAction.trim() == "STORE") {
              console.log('data');
            } else {
              if (this.$store.state.MajParamDataUpdate.includes('prenom')) {
                if (this.form.params.trim().length >= 4 && this.form.params.trim().length <= 16) {
                  var firstnameObject = new Object();
                  firstnameObject.mailUser = this.$store.state.user_auth.mailUser;
                  firstnameObject.codingUser = this.$store.state.user_auth.codingUser;
                  firstnameObject.fistnameUser = this.gb_capitalisingFormat(this.form.params.trim());

                  this.executeMaj("https://www.update.fistname.user.schoolbac.com", firstnameObject, "prenom");

                } else {
                  if (this.form.params.trim().trim().length < 4 || this.form.params.trim().trim().length < 4) {
                    this.toast.info("Le prénom ne doit pas etre inférieur à 4 caractères !!!", {
                      timeout: 6000
                    });
                  } else {
                    this.toast.info("Le prénom ne doit pas dépasser 16 caractères !!!", {
                      timeout: 6000
                    });
                  }
                  // this.messageErrors = "Le surnom ne doivent pas dépasser 16 caractères !!!";
                }
              } else if (this.$store.state.MajParamDataUpdate.includes('nom')) {
                if (this.form.params.trim().length >= 4 && this.form.params.trim().length <= 16) {

                  var nameObject = new Object();
                  nameObject.mailUser = this.$store.state.user_auth.mailUser;
                  nameObject.codingUser = this.$store.state.user_auth.codingUser;
                  nameObject.nameUser = this.gb_capitalisingFormat(this.form.params.trim());

                  this.executeMaj("https://www.update.name.user.schoolbac.com", nameObject, "nom");

                } else {
                  if (this.form.params.trim().trim().length < 4 || this.form.params.trim().trim().length < 4) {
                    this.toast.info("Le nom ne doit pas etre inférieur à 4 caractères !!!", {
                      timeout: 6000
                    });
                  } else {
                    this.toast.info("Le nom ne doit pas dépasser 16 caractères !!!", {
                      timeout: 6000
                    });
                  }
                }
              } else if (this.$store.state.MajParamDataUpdate.includes('surname')) {
                if (this.form.params.trim().length >= 4 && this.form.params.trim().length <= 16) {

                  var nicknameObject = new Object();
                  nicknameObject.mailUser = this.$store.state.user_auth.mailUser;
                  nicknameObject.codingUser = this.$store.state.user_auth.codingUser;
                  nicknameObject.nicknameUser = this.gb_capitalisingFormat(this.form.params.trim());

                  this.executeMaj("https://www.update.nickname.user.schoolbac.com", nicknameObject, "surname");

                } else {
                  if (this.form.params.trim().trim().length < 4 || this.form.params.trim().trim().length < 4) {
                    this.toast.info("Le surnom ne doit pas etre inférieur à 4 caractères !!!", {
                      timeout: 6000
                    });
                  } else {
                    this.toast.info("Le surnom ne doit pas dépasser 16 caractères !!!", {
                      timeout: 6000
                    });
                  }
                }
              } else if (this.$store.state.MajParamDataUpdate.includes('genre')) {
                if (this.form.params != null && this.form.params.trim() != "") {

                  var genreObject = new Object();
                  genreObject.mailUser = this.$store.state.user_auth.mailUser;
                  genreObject.codingUser = this.$store.state.user_auth.codingUser;
                  genreObject.sexUser = this.form.params.trim();
                  this.executeMaj("https://www.update.sex.user.schoolbac.com", genreObject, "genre");
                } else {
                  this.toast.info("Veuillez selectionné le genre  !!!", {
                    timeout: 6000
                  });
                }
              } else if (this.$store.state.MajParamDataUpdate.includes('mobile')) {

                if (this.form.params != null && this.form.params.trim() != "") {

                  var mobileObject = new Object();
                  mobileObject.mailUser = this.$store.state.user_auth.mailUser;
                  mobileObject.codingUser = this.$store.state.user_auth.codingUser;
                  mobileObject.numberUser = this.form.params.trim();
                  this.executeMaj("https://www.update.number.mobile.user.schoolbac.com", mobileObject, "mobile");
                } else {
                  this.toast.info("Veuillez entrer le numéro de téléphone  !!!", {
                    timeout: 6000
                  });
                }

              } else if (this.$store.state.MajParamDataUpdate.includes('town')) {

                if (this.form.params != null && this.form.params.trim() != "") {
                  var townObject = new Object();
                  townObject.mailUser = this.$store.state.user_auth.mailUser;
                  townObject.codingUser = this.$store.state.user_auth.codingUser;
                  townObject.townUser = this.form.params.trim();
                  this.executeMaj("https://www.update.town.user.schoolbac.com", townObject, "town");
                } else {
                  this.toast.info("Veuillez entrer  le lieu de résidence  !!!", {
                    timeout: 6000
                  });
                }
              } else if (this.$store.state.MajParamDataUpdate.includes('business')) {

                if (this.form.params != null && this.form.params.trim() != "") {
                  var businessObject = new Object();
                  businessObject.mailUser = this.$store.state.user_auth.mailUser;
                  businessObject.codingUser = this.$store.state.user_auth.codingUser;
                  businessObject.businessUser = this.form.params.trim();
                  this.executeMaj("https://www.update.business.user.schoolbac.com", businessObject, "town");
                } else {
                  this.toast.info("Veuillez entrer  la profession  !!!", {
                    timeout: 6000
                  });
                }
              } else if (this.$store.state.MajParamDataUpdate.includes('birthday')) {

                if (this.form.params != null && this.form.params.trim() != "") {
                  var birthdayObject = new Object();
                  birthdayObject.mailUser = this.$store.state.user_auth.mailUser;
                  birthdayObject.codingUser = this.$store.state.user_auth.codingUser;
                  birthdayObject.birthdayUser = this.form.params.trim();
                  this.executeMaj("https://www.update.birthday.user.schoolbac.com", birthdayObject, "birthday");
                } else {
                  this.toast.info("Veuillez entrer  la date de naissance  !!!", {
                    timeout: 6000
                  });
                }
              }
            }
          } else {
            this.toast.error("Veuillez remplir le champ manquant !!!", {
              timeout: 5000
            });
          }
        } else {
          this.toast.error("Vous n'etes pas autorisé à accéder à cette page !!!", {
            timeout: 5000
          });
        }
      }
    },

    refreshFormIdentite() {
      this.formIdentite.fistnameUser = null,
        this.formIdentite.nameUser = null,
        this.formIdentite.nicknameUser = null,
        this.formIdentite.mailUser = null,
        this.formIdentite.codingUser = null
    },

    loadMajIdentity() {
      if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {
        this.formIdentite.firstnameUser = (this.$store.state.user_auth.fistnameUser != null && this.$store.state.user_auth.fistnameUser != '') ? this.$store.state.user_auth.fistnameUser : null;

        this.formIdentite.nameUser = (this.$store.state.user_auth.nameUser != null && this.$store.state.user_auth.nameUser != '') ? this.$store.state.user_auth.nameUser : null;

        this.formIdentite.nicknameUser = (this.$store.state.user_auth.nicknameUser != null && this.$store.state.user_auth.nicknameUser != '') ? this.$store.state.user_auth.nicknameUser : null;
      }
    },

    loadMajElement() {
      if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {
        this.form.params = (this.$store.state.titleOptionMajValue != null && this.$store.state.titleOptionMajValue != '') ? this.$store.state.titleOptionMajValue : null;
      }
    },

    async executeMaj(RequestUrl, ObjectParams, DataUpdate) {
      if (RequestUrl != null && RequestUrl.trim() != '' && ObjectParams != null) {
        await axios.post(RequestUrl, ObjectParams).then(({ data }) => {
          if (data.error == "false") {
            document.getElementById("modal_close").dispatchEvent(new Event("click"));
            this.toast.success("La mise à jour a été fait avec succès !!!");

            if (DataUpdate.includes('prenom')) {
              this.$store.state.user_auth.fistnameUser = ObjectParams.fistnameUser;
            } else if (DataUpdate.includes('nom')) {
              this.$store.state.user_auth.nameUser = ObjectParams.nameUser;
            } else if (DataUpdate.includes('surname')) {
              this.$store.state.user_auth.nicknameUser = ObjectParams.nicknameUser;
            } else if (DataUpdate.includes('genre')) {
              this.$store.state.user_auth.sexUser = ObjectParams.sexUser;
            } else if (DataUpdate.includes('mobile')) {
              this.$store.state.user_auth.mobileUser = ObjectParams.numberUser;
            } else if (DataUpdate.includes('town')) {
              this.$store.state.user_auth.townUser = ObjectParams.townUser;
            } else if (DataUpdate.includes('business')) {
              this.$store.state.user_auth.businessUser = ObjectParams.businessUser;
            } else if (DataUpdate.includes('birthday')) {
              this.$store.state.user_auth.birthdayUser = ObjectParams.birthdayUser;
            }

            this.$store.commit('AUTHENTIFICATE_USER', this.$store.state.user_auth);
            localStorage.setItem('AUTHENTIFICATE_USER', JSON.stringify(this.$store.state.user_auth));
            this.gb_loadReFreshUser();

          } else if (data.error == "true") {
            this.toast.error(data.message, {
              timeout: 5000
            });
          } else {
            this.toast.error(data.message, {
              timeout: 5000
            });
          }
        });

        var divs = document.getElementById('modalMajInfosUser');
        divs.dispatchEvent(new Event("click"));
      }
    },

    loadStateVisibilityElement(idElement, state) {
      if (state != null && state.trim() != "" && idElement != null && idElement != "") {
        this.formVisibility.stateElement = state;
        this.formVisibility.idElement = idElement;
      }
    },

    async executeMajVisibility() {
      if (this.bg_Online) {
        if (this.formVisibility.stateElement != null && this.formVisibility.stateElement.trim() != "" && this.formVisibility.idElement != null && this.formVisibility.idElement.trim() != "") {
          var ObjectParams = {
            "mailUser" : this.$store.state.user_auth.mailUser,
            "codingUser" : this.$store.state.user_auth.codingUser,
            "etat": this.formVisibility.stateElement,
            "index" : this.formVisibility.idElement
          };
  
          await axios.post("https://www.update.state.ci.user.schoolbac.com", ObjectParams).then(({ data }) => {
            if (data.error == "false") {
              this.$store.state.visibilityElement = this.formVisibility.stateElement;
              var objectRefresh = {
                "state": this.formVisibility.stateElement,
                "idElement": this.formVisibility.idElement
              };
              this.$emit("refreshModalAction", objectRefresh);
              this.formVisibility.stateElement = null;
              this.formVisibility.idElement = null;
              document.getElementById("modal_close_visibility").dispatchEvent(new Event("click"));
              this.toast.success("La mise à jour fait avec succès !!!");
            } else if (data.error == "true") {
              this.toast.error(data.message, {
                timeout: 5000
              });
            } else {
              this.toast.error(data.message, {
                timeout: 5000
              });
            }
          });
  
          // var divs = document.getElementById('modalMajInfosUser');
          // divs.dispatchEvent(new Event("click"));
        }
      }
    },

  },

  computed: {
    //   getfilter_sondages_actives() {
    //     return this.Allsondages.filter((sonds) => {
    //       return (
    //         this.format_Minutes(sonds.sondage_end_date) > 0
    //       );
    //     });
    //   },
  },
}
</script>
