<template>
  <div v-if="$route.path == '/pictures-user'" class="container-fluid px-lg-5 mt-lg-4 mt-2 px-1">
    <div class="row justify-content-center g-lg-4 g-3">
      <div class="col-lg-12 col-xl-12 col-xxl-12">
        <div class="card card-lg-tools">
          <h2 class="title-page">Pictures <span v-if="ImgsUser != null" class="badge">{{ this.ImgsUser.length }}</span></h2>
         <div class="row justify-content-center g-lg-4 g-3">
          <div class="col-lg-12 col-xl-12 col-xxl-12">
            <ul class="nav nav-tabs nav-sm" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button @click.prevent="blockImageView('alls_photos')" class="nav-link active" id="home-tab" data-bs-toggle="tab"
                  data-bs-target="#all-picture" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Toutes
                  les photos</button>
              </li>
              <li class="nav-item" role="presentation">
                <button @click.prevent="blockImageView('photos_avatars')" class="nav-link" id="profile-tab" data-bs-toggle="tab"
                  data-bs-target="#profile-photo" type="button" role="tab" aria-controls="profile-tab-pane"
                  aria-selected="false">Photos Avatar</button>
              </li>
              <li class="nav-item" role="presentation">
                <button @click.prevent="blockImageView('mes_photos')" class="nav-link" id="profile-tab" data-bs-toggle="tab"
                  data-bs-target="#profile-photo" type="button" role="tab" aria-controls="profile-tab-pane"
                  aria-selected="false">Mes photos</button>
              </li>
              <li class="nav-item" role="presentation">
                <button @click.prevent="blockImageView('photos_camarades')" class="nav-link" id="contact-tab" data-bs-toggle="tab"
                  data-bs-target="#cover-photo" type="button" role="tab" aria-controls="contact-tab-pane"
                  aria-selected="false">Photos camarades</button>
              </li>
              <li class="nav-item" role="presentation">
                <button @click.prevent="blockImageView('photos_posters')" class="nav-link" id="contact-tab" data-bs-toggle="tab"
                  data-bs-target="#album" type="button" role="tab" aria-controls="contact-tab-pane"
                  aria-selected="false">Posters</button>
              </li>
              <li class="nav-item" role="presentation">
                <button @click.prevent="blockImageView('albums')" class="nav-link" id="contact-tab" data-bs-toggle="tab"
                  data-bs-target="#album" type="button" role="tab" aria-controls="contact-tab-pane"
                  aria-selected="false">Albums</button>
              </li>
              <li class="nav-item" role="presentation">
                <button @click.prevent="corbeille()" class="nav-link text-danger" id="contact-tab" data-bs-toggle="tab"
                  data-bs-target="#album" type="button" role="tab" aria-controls="contact-tab-pane"
                  aria-selected="false">Corbeille <i class="bx bx-trash text-danger"></i></button>
              </li>
            </ul>
          </div>
          <!-- <div class="col-lg-4 col-xl-4 col-xxl-4 text-right">
            <div class="block-items-about">
              <div class="item-about">
                <a href="#" class="add-items" data-bs-toggle="modal" data-bs-target="#modalMajInfosUser"
                  @click.prevent="gb_modalMajTitre('Ajouter un nouveau contact', 'Nouveau contact', '', 'bx bxs-phone')">
                  <i class="bx bx-plus"></i>
                  <span>Ajouter une photo</span>
                </a>
              </div>
            </div>
          </div> -->
          </div>
          <div class="container-fluid p-0">
            <component-imgs v-if="ImgsUser != null"  :ImgsUser = "this.ImgsUser"  :typeImgs="this.typeImages" @reloadImgs="reload"></component-imgs>
            <p v-else class="text-center">Chargement ...</p>
          </div>
        </div>
      </div>
    </div>
    <!-- <maj-infos ></maj-infos> -->
  </div>

  <!-- // Camarade -->
  <div v-if="($route.path == '/profil-pictures-user/' + $route.params.friend) && this.$store.state.params == $route.params.friend" class="container-fluid px-lg-5 mt-lg-4 mt-2 px-1">
    <div class="row justify-content-center g-lg-4 g-3">
      <div class="col-lg-12 col-xl-12 col-xxl-12">
        <div class="card card-lg-tools">
          <h2 class="title-page">Pictures <span v-if="ImgsUser != null" class="badge">{{ this.ImgsUser.length }}</span></h2>
         <div class="row justify-content-center g-lg-4 g-3">
          <div class="col-lg-12 col-xl-12 col-xxl-12">
            <ul class="nav nav-tabs nav-sm" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button @click.prevent="blockImageView('alls_photos')" class="nav-link active" id="home-tab" data-bs-toggle="tab"
                  data-bs-target="#all-picture" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Toutes
                  les photos</button>
              </li>
              <li class="nav-item" role="presentation">
                <button @click.prevent="blockImageView('photos_avatars')" class="nav-link" id="profile-tab" data-bs-toggle="tab"
                  data-bs-target="#profile-photo" type="button" role="tab" aria-controls="profile-tab-pane"
                  aria-selected="false">Photos Avatar</button>
              </li>
              <li class="nav-item" role="presentation">
                <button @click.prevent="blockImageView('mes_photos')" class="nav-link" id="profile-tab" data-bs-toggle="tab"
                  data-bs-target="#profile-photo" type="button" role="tab" aria-controls="profile-tab-pane"
                  aria-selected="false">Mes photos</button>
              </li>
              <li class="nav-item" role="presentation">
                <button @click.prevent="blockImageView('photos_camarades')" class="nav-link" id="contact-tab" data-bs-toggle="tab"
                  data-bs-target="#cover-photo" type="button" role="tab" aria-controls="contact-tab-pane"
                  aria-selected="false">Photos camarades</button>
              </li>
              <li class="nav-item" role="presentation">
                <button @click.prevent="blockImageView('photos_posters')" class="nav-link" id="contact-tab" data-bs-toggle="tab"
                  data-bs-target="#album" type="button" role="tab" aria-controls="contact-tab-pane"
                  aria-selected="false">Posters</button>
              </li>
              <li class="nav-item" role="presentation">
                <button @click.prevent="blockImageView('albums')" class="nav-link" id="contact-tab" data-bs-toggle="tab"
                  data-bs-target="#album" type="button" role="tab" aria-controls="contact-tab-pane"
                  aria-selected="false">Albums</button>
              </li>
              <li class="nav-item" role="presentation">
                <button @click.prevent="corbeille()" class="nav-link text-danger" id="contact-tab" data-bs-toggle="tab"
                  data-bs-target="#album" type="button" role="tab" aria-controls="contact-tab-pane"
                  aria-selected="false">Corbeille <i class="bx bx-trash text-danger"></i></button>
              </li>
            </ul>
          </div>
          </div>
          <div class="container-fluid p-0">
            <component-imgs v-if="ImgsUser != null"  :ImgsUser = "this.ImgsUser"  :typeImgs="this.typeImages" @reloadImgs="reload"></component-imgs>
            <p v-else class="text-center">Chargement ...</p>
          </div>
        </div>
      </div>
    </div>
    <!-- <maj-infos ></maj-infos> -->
  </div>
</template>

<script>

import axios from 'axios';
import { useToast } from "vue-toastification";
import ComponentImgs from './ComponentImgs.vue';
// import MajInfos from '../infos/MajInfos.vue';

export default {
  name: 'PicturesUser',
  components: {
    ComponentImgs,
    // MajInfos
  },
  data() {
    return {
      user: null,
      ImgsUserData: [],
      ImgsUser:null,
      requesting: false,
      typeImages: "alls_photos",
      requestingText: "Chargement des données",
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  mounted() {
    var setLoad = setInterval(() => {
      if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {
        if (this.typeImages == "corbeille") {
          this.corbeille();
        } else {
          this.load();
        }
        clearInterval(setLoad);

        setInterval(() => {
          if (this.typeImages == "corbeille") {
            this.corbeille();
          } else {
            this.load();
          }
        }, 120000);
      }
    }, 7000);

   
  },


  methods: {
    load() {
      if (this.bg_Online) {
        if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {


          var postData = new Object();
          if (!this.gb_viewUserOrOtherUser(this.$route) && this.$store.state.user_auth != null) {
            postData.mailUser = this.$store.state.user_auth.mailUser;
            postData.codingUser = this.$store.state.user_auth.codingUser;

          } else if (this.gb_viewUserOrOtherUser(this.$route) && this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.codingUserCamarade != null && this.$store.state.user_auth_other.codingUserCamarade.trim() != '' && this.$store.state.params == this.$route.params.friend) {
            postData.mailUser = this.$store.state.user_auth_other.mailCamarade.trim();
            postData.codingUser = this.$store.state.user_auth_other.codingUserCamarade.trim();
          }


          if (postData.mailUser != null && postData.mailUser.trim() != "" && postData.codingUser != null && postData.codingUser.trim() != "") {
            axios.post("https://www.all.img.schoolbac.com", postData)
              .then(({ data }) => {
                if (data.statut=="false") {
                  this.ImgsUserData = data;

                  this.blockImageView(this.typeImages);
                } else {
                  this.toast.error(data.message, {
                    timeout: 4000
                  });
                }
              })
              .catch((error) => {
                this.errors = error;
                // this.refresh();
              });
          }
        }
      } 
    },

    blockImageView(imageCall) {
      this.ImgsUser = null;
      this.typeImages = imageCall;
      if (this.ImgsUserData != null) {
        if (imageCall == "alls_photos") {
          this.ImgsUser = [];
          if (this.ImgsUserData.album_avatars != null) {
            this.ImgsUser = [...this.ImgsUser, ...this.ImgsUserData.album_avatars.images];
          }
          if (this.ImgsUserData.album_posters != null) {
            this.ImgsUser = [...this.ImgsUser, ...this.ImgsUserData.album_posters.images];
          }
          if (this.ImgsUserData.album_mes_photos != null) {
            this.ImgsUser = [...this.ImgsUser, ...this.ImgsUserData.album_mes_photos.images];
          }
          if (this.ImgsUserData.album_camarades != null) {
            this.ImgsUser = [...this.ImgsUser, ...this.ImgsUserData.album_camarades.images];
          }
        } else if (imageCall == "photos_avatars") {
          this.ImgsUser = [];
          if (this.ImgsUserData.album_avatars != null) {
            this.ImgsUser = [...this.ImgsUser, ...this.ImgsUserData.album_avatars.images];
          } else {
            this.ImgsUser = [];
          }
        } else if (imageCall == "mes_photos") {
          this.ImgsUser = [];
          if (this.ImgsUserData.album_mes_photos != null) {
            this.ImgsUser = [...this.ImgsUser, ...this.ImgsUserData.album_mes_photos.images];
          } else {
            this.ImgsUser = [];
          }
        } else if (imageCall == "photos_camarades") {
          this.ImgsUser = [];
          if (this.ImgsUserData.album_camarades !=null) {
            this.ImgsUser = [...this.ImgsUser, ...this.ImgsUserData.album_camarades.images];
          } else {
            this.ImgsUser = [];
          }
        } else if (imageCall == "photos_posters") {
          this.ImgsUser = [];
          if (this.ImgsUserData.album_posters != null) {
            this.ImgsUser = [...this.ImgsUser, ...this.ImgsUserData.album_posters.images];
          } else {
            this.ImgsUser = [];
          }
        } else if (imageCall == "albums") {
          this.ImgsUser = [];
        } else if (imageCall == "corbeille") {
          this.ImgsUser = [];
          if (this.ImgsUserData != null) {
            // this.ImgsUser = [...this.ImgsUser, ...this.ImgsUserData];
            for (let h = 0; h < this.ImgsUserData.length; h++) {
              var dataImg = {
                "targetpicture": this.ImgsUserData[h].contenu,
                "name_etat": "Public",
                "index_etat": "One",
                "id_pub": this.ImgsUserData[h].id_pub
              } 
              this.ImgsUser.push(dataImg);
            }
          } else {
            this.ImgsUser = [];
          }
        }
      }

    },

    reload(id_img) {
      var imgDiffs  = this.ImgsUser.filter(function (val) {
        return val.id_pub != id_img;
      });

      this.ImgsUser = imgDiffs;
    },

    corbeille() {
      if (this.bg_Online) {
        if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {

          // var postData = {
          //   "mailUser": this.$store.state.user_auth.mailUser,
          //   "codingUser": this.$store.state.user_auth.codingUser
          // };
          var postData;
          if (!this.gb_viewUserOrOtherUser(this.$route) && this.$store.state.user_auth != null) {
          postData = {
              "mailUser": this.$store.state.user_auth.mailUser,
              "codingUser": this.$store.state.user_auth.codingUser
            };

          } else if (this.gb_viewUserOrOtherUser(this.$route) && this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.codingUserCamarade != null && this.$store.state.user_auth_other.codingUserCamarade.trim() != '' && this.$store.state.params == this.$route.params.friend) {

            postData = {
              "mailUser": this.$store.state.user_auth_other.mailCamarade.trim(),
              "codingUser": this.$store.state.user_auth_other.codingUserCamarade.trim()
            };
          }

          if (postData.mailUser != null && postData.mailUser.trim() != "" && postData.codingUser != null && postData.codingUser.trim() != "") {
            axios.post("https://www.all.corbeille.schoolbac.com", postData)
              .then(({ data }) => {
                // if (data.statut == "false") {
                  this.ImgsUserData = data;
                  this.blockImageView("corbeille");
                // } else {
                //   this.toast.error(data.message, {
                //     timeout: 4000
                //   });
                // }
              })
              .catch((error) => {
                this.errors = error;
                // this.refresh();
              });
          }
        }
      }
    },
  },
}
</script>
