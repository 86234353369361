<template>
      <div v-if="$route.path == '/posters-user' || this.$store.state.params == $route.params.friend" class="container-fluid px-lg-5 mt-2 mt-lg-4 px-1">
      <div class="row justify-content-center g-lg-4 g-3">
        <div class="col-lg-3 order-2 order-lg-1">
          <div class="block-fixed block-fixed-left">
            <!-- Profil Resume -->
            <intro-profil></intro-profil>
            <my-photos></my-photos>
            <my-camarades></my-camarades>
            <!-- Calendar -->
            <calendar></calendar>
          </div>
        </div>

        <div  class="col-lg-6 col-xl-6 col-xxl-6 order-1 order-lg-2">
            <create-publish v-if="this.$route.path == '/posters-user' && !this.gb_viewUserOrOtherUser($route) && this.$store.state.user_auth != null && this.$store.state.user_auth.posterUser != null && this.$store.state.user_auth.posterUser != ''" @submitSuccess="publishingsComponentRefresh"></create-publish>

            <publishing  v-if="($route.path == '/posters-user') || (($route.path == '/profil-user/' + $route.params.friend) && this.$store.state.params == $route.params.friend)" ref="loadPublishings"></publishing>

              <div v-if="this.loadAddData == true" class="block-loader">
                <div class="spiner"></div>
              </div>
        </div>

        <div class="col-lg-3 order-3 order-lg-3">
          <div class="block-fixed block-fixed-right">
            <!-- Amis Suggérées -->
            <friend-suggere></friend-suggere>

            <!-- Anniversiare -->
            <birthays></birthays>

            <!-- Groupe Suggérees -->
            <group-suggere></group-suggere>
          </div>
        </div>

      </div>

    </div>
</template>

<script>
// import axios from 'axios';
import { useToast } from "vue-toastification";
import CreatePublish from './CreatePublish.vue';
import Publishing from './Publishing.vue';
import Birthays from './Birthays.vue';
import Calendar from './Calendar.vue';
import IntroProfil from './IntroProfil.vue';
import FriendSuggere from './FriendSuggere.vue';
import GroupSuggere from './GroupSuggere.vue';
import MyPhotos from './MyPhotos.vue';
import MyCamarades from './MyCamarades.vue';

export default {
  name: 'PanelPublishUserPage',
  components: {
    CreatePublish,
    Publishing,
    Birthays,
    Calendar,
    IntroProfil,
    FriendSuggere,
    GroupSuggere,
    MyPhotos,
    MyCamarades,
  },
  data() {
    return {
      user: null,
      publishs: [],
      stringLimit:150,
      loadAddData:0,
      requesting: false,
      requestingText: "Chargement des données",
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  created() {
    this.load();

    var setLoad = setInterval(() => {
      if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {
        this.publishingsComponent();
        this.$refs.loadPublishings.nonloadAddDatas();
        clearInterval(setLoad);
      }
    }, 7000);

    setInterval(() => {
      this.load();
    }, 240000);

    setInterval(() => {
      // this.loadAddData = this.$refs.loadPublishings.addPublish;

      if (this.loadAddData == true) {
        if (Math.round(window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {
              this.publishingsComponent();

              setTimeout(() => {
                this.$refs.loadPublishings.nonloadAddDatas();
              }, 9000);
          }
        }
      }

    }, 7000);
  },

  methods: {
    load() {
      this.requesting = true;
      this.requesting = false;
    },

    publishingsComponent() {
      if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {
        this.$refs.loadPublishings.loadPublish();
      }
    },

    publishingsComponentRefresh() {
      if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {
        this.$refs.loadPublishings.loadPublishRefresh();
      }
    },

   
  },
}
</script>
