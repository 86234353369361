<template>
  <div class="global-div">
    <div class="wrapper">
      <div class="block-login">
        <div class="avatar">
          <img src="assets/images/9.jpg" alt="">
        </div>
        <div class="avatar">
          <img src="assets/images/13.jpg" alt="">
        </div>
        <div class="avatar">
          <img src="assets/images/7.jpg" alt="">
        </div>
        <div class="avatar">
          <img src="assets/images/8.jpg" alt="">
        </div>
        <div class="logo-app">
          <router-link to="/">
            <img src="assets/images/logo-app.png" alt="">
          </router-link>
        </div>
        <div class="block-cube">
          <div class="cube cube-1">
            <i class="bx bx-book"></i>
          </div>
          <div class="cube cube-2">
            <i class="bx bxs-graduation"></i>
          </div>
          <div class="cube cube-3">
            <i class="bx bxs-envelope"></i>
          </div>
          <div class="cube cube-4">
            <i class="bx bx-music"></i>
          </div>
          <div class="cube cube-5">
            <i class="bx bx-book-open"></i>
          </div>
          <div class="cube cube-6">
            <i class="bx bx-mobile"></i>
          </div>
        </div>
        <div class="container">
          <div class="row justify-content-center">
            <div  class="col-lg-5 col-md-7 col-xl-4 col-xxl-4">
              <div class="card card-login">
                <div class="text-center">
                  <h2 class="title-lg">Vérification du compte</h2>
                </div>
                <form action="">
                  <div class="form-group row g-lg-4 g-3">
                    <div v-if="this.$store.state.user_auth != null"  class="col-12 text-center">
                      <p class="mb-0" style="color: #fff; font-size: 14px;font-family: 'Poppins-Regular';"> Un code de
                        confirmation a été envoyé au <span
                          style="font-family:'Poppins-SemiBold';">numéro de téléphone et à l'adresse email indiqué.</span></p>
                    </div>
                      <div class="col-12 text-center">
                        <p v-if="this.minutes >= 0" class="text-white text-center">
                          <span class="border border-2 border-warning p-2" style="border-radius:50%;">{{ this.minutes }}</span>
                        </p>
                        <p class="text-white text-center" style="font-size: 14px;"> Le code envoyé expire dans un espace d'une minute.</p>
                      </div>
                    <div class="col-12">
                      <div class="col-focus">
                        <input v-model="form.code_confirm" type="text" class="form-control" required="required" placeholder="code de confirmation">
                        <div class="fake-label">Code de confirmation</div>
                        <i class="bx bx-user"></i>
                      </div>
                    </div>
                    <div v-if="this.$store.state.user_auth != null && this.$store.state.user_auth != '' && this.$store.state.user_auth.codeUser != null && this.$store.state.user_auth.codeUser != ''" class="col-12 d-flex justify-content-center">
                      <button class="btn btn-primary"
                      @click.prevent="compte_verif">
                        <span>Confirmer</span>
                        <span>Confirmer</span>
                        <span>Confirmer</span>
                      </button>
                    </div>
                    <div class="col-12 text-center col-hidden">
                      <p>N'avez-vous pas reçu le code ?<br> <a href="" @click.prevent="renvoi_code_confirmation">Renvoyer</a></p>
                    </div>
                  </div>
                </form>
              </div>
              <div class="text-center col-hidden-mobile">
                <p class="paragraph">N'avez-vous pas reçu le code ?<br> 
                  <a href="" class="link-reset" @click.prevent="renvoi_code_confirmation">Renvoyer </a>
                  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useToast } from "vue-toastification";
export default {
  name: 'LoginPage',
  data() {
    return {
      user: null,
      form: {
        code_confirm: null,
        nbre_essayer: 0,
      },
      requesting: false,
      minutes: 60,
      compteur: '',
      requestingText: "Veuillez patienter",
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },

  mounted() {
    this.minutes = 60;
    
    if (this.$store.state.user_auth != null) {
      if (this.$store.state.user_auth.error == "false" && this.$store.state.user_auth.statut == "false") {
        this.decrementation_minutes();
      }
    } 

    setTimeout(() => {
      if (this.$store.state.user_auth != null) {
        if (this.$store.state.user_auth.error != "false" && this.$store.state.user_auth.statut != "false") {
          this.toast.error("Vous n'etes pas autorisé à accéder a ce contenu ou à cette page !!!", {
            timeout: 2000
          });
  
          if ((this.$store.state.user_auth.codingUser == "" || this.$store.state.user_auth.codingUser == null) && (this.$store.state.user_auth.codeUser == "" || this.$store.state.user_auth.codeUser == null)) {
            setTimeout(() => {
              this.$router.push({ name: 'Register' });
            }, 2001);
          } 
        } 
      } else {
        this.toast.error("Vous n'etes pas autorisé à accéder a ce contenu ou à cette page !!!", {
          timeout: 3000
        });
        setTimeout(() => {
          this.$router.push({ name: 'Register' });
        }, 3001);
      }
    }, 8000);
  },

  methods: {
    compte_verif() {
      if (this.form.nbre_essayer < 3) {
        if (this.form.code_confirm != null && this.form.code_confirm != "") {

          this.form.nbre_essayer = parseInt(this.form.nbre_essayer)+ 1;
          this.requesting = true;

          if (this.$store.state.user_auth.codeUser == this.form.code_confirm) {
                this.toast.success("Votre compte vient d'etre validé avec succès !!!", {
                  timeout: 6000
                });
            clearInterval(this.compteur);
            this.$router.push({ name: 'RegisterToComplete' });
          } else {
            this.toast.error("Le code de confirmation est invalide, veuillez réessayer !!!", {
              timeout: 6000
            });
            if (this.form.nbre_essayer == 3) {
              setTimeout(() => {
                this.$router.push({ name: 'Register' });
              }, 6001);
            }
          }
          this.requesting = false;
        } else {
          this.toast.error("Veuillez remplir les champs manquantes  !!!", {
            timeout: 2000
          });
        }
      }
    },

    renvoi_code_confirmation() {
      if (this.$store.state.user_auth != null && this.$store.state.user_auth.mobileUser != null && this.$store.state.user_auth.mobileUser != "" && this.$store.state.user_auth != '' && this.$store.state.user_auth.codeUser != null && this.$store.state.user_auth.codeUser != '') {
        var message = "Bonjour " + this.$store.state.user_auth.fistnameUser + ". Voici le code de confirmation du compte crée.  Code : " + this.$store.state.user_auth.codeUser+ " .  Ce code expire dans une minute, ne le partager pas.";
        let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=basiluantemo@gmail.com&password=systeminnov2022&message=" + message + "&expediteur=Schoolbac&telephone=" + this.$store.state.user_auth.mobileUser;
   
         axios.get(urlgetsms).then(() => { });
      }
    },

    decrementation_minutes() {
      this.compteur = setInterval(() => {
        if (this.minutes >= 0) {
            this.minutes = parseInt(this.minutes) - 1;
        } else {
          if (this.minutes  <= 0) {
            this.toast.error("Votre compte est en suspend, veuillez contactez le support  !!!", {
              timeout: 2000
            });
          }
          clearInterval(this.compteur);
          setTimeout(() => {
            this.$router.push({ name: 'Register' });
          }, 2001);
        }
      }, 1000);
    },
  }
}
</script>

