<template>
  <div class="item-tool">
    <a href="javascript:void(0)">
      <i class="bx bxs-user-plus"></i>
      <div class="notif">3</div>
    </a>
    <div class="block-drop">
      <div class="header-drop">
        <h5>Demandes d'amis</h5>
        <div class="block-btns">
          <div class="btn-icon">
            <i class="bx bx-search"></i>
          </div>
        </div>
      </div>
      <div class="body-message">
        <ul v-if="this.ApprouvedDemandesFriends.length > 0">
          <li v-for="(invit, index) in ApprouvedDemandesFriends" :key="index">
            <div class="block-items">
              <div class="avatar-message">
                <img :src="(invit.avatarCamarade != null && invit.avatarCamarade != '' ? invit.avatarCamarade : 'assets/images/team.jpg')" alt="">
              </div>
              <div class="block-info-message">
                <a href="#">
                  <h6>{{ invit.nameCamarade }}</h6>
                </a>
                <p>Vous a envoyé un demande d'ami</p>
                <a href="#" @click.prevent="submitaddStatusFriendSuggere(invit.mailCamarade,'index_2')" class="btn btn-add">Accepter</a>
                <a href="#" class="btn btn-annule">Annuler</a>
              </div>
            </div>
          </li>
        </ul>
        <ul v-if="this.ApprouvedDemandesFriends.length == 0">
           <li>
              <div class="block-items text-center">
                <div class="block-info-message text-center">
                  <p class="text-center">Pas d'invitation disponible à ce jour.</p>
                </div>
              </div>
            </li>
        </ul>
      </div>
      <a href="#" class="btn">Toutes les demandes</a>
    </div>
  </div>
</template>
<script>

import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
  name: 'ApprouvedDemandsFriendsUser',
  props: ['ApprouvedDemandesFriends'],
  setup() {
    const toast = useToast();
    return { toast }
  },

  methods: {
    submitaddStatusFriendSuggere(mailCamarade, statutSubmit) {
      if (this.bg_Online) {
        if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {
          if (this.$store.state.user_auth.mailUser != null && this.$store.state.user_auth.mailUser.trim() != "" && this.$store.state.user_auth.codingUser != null && this.$store.state.user_auth.codingUser.trim() != "" && mailCamarade != null && mailCamarade.trim() != "" && statutSubmit != null && statutSubmit.trim() != "") {

            if (this.bg_isEmail(mailCamarade)) {
              
              var postData = {
                "mailUser": this.$store.state.user_auth.mailUser,
                "codingUser": this.$store.state.user_auth.codingUser,
                "mailCamarade": mailCamarade,
                "statut": statutSubmit
              };
  
              axios.post("https://www.invitation.camarade.schoolbac.com", postData)
                .then(({ data }) => {
                  if (data.error == "false") {
                    this.gb_notifications(1, "Invitation acceptée avec sucess !!!");
                    this.$emit('reloadDemandsFriends');
                  } else {
                    this.gb_notifications(0, "Invitation a échouée, veuillez réessayer plus tard !!!");
                  }
                })
                .catch((error) => {
                  this.errors = error;
                });
            }
          } else {
            this.gb_notifications(2, "Veuillez selectionner l'invitation souhaité !!!");
          }
        }
      }
    },
  },
}
</script>
