<template>
  <div v-if="$route.path == '/about-user'" class="col-lg-8">
    <div class="block-items-about">
      <div
        v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.fistnameUser != null && this.$store.state.user_auth.fistnameUser.trim() != ''"
        class="item-about d-flex align-items-center justify-content-between">
        <div class="block-left">
          <div class="d-flex align-items-center">
            <i class="bx bxs-user"></i>
            <p class="mb-0">Prénom <span>{{ this.$store.state.user_auth.fistnameUser }}</span></p>
          </div>
        </div>
        <div class="block-right">
          <div class="d-flex align-items-center">
            <button class="btn btn-view" data-bs-toggle="modal" data-bs-target="#modalMajInfosUserVisibility">
              <!-- <i class="bx bxs-lock"></i> -->
              <i class="bx bx-globe"></i>
            </button>
            <button class="btn btn-edit" data-bs-toggle="modal" data-bs-target="#modalMajInfosUser"
              @click.prevent="gb_modalMajTitre('Modifier le prénom', 'Modifier votre prénom', this.$store.state.user_auth.fistnameUser, 'bx bx-user', 'UPDATE', 'prenom')">
              <i class="bx bxs-pencil"></i>
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.nameUser != null && this.$store.state.user_auth.nameUser.trim() != ''"
        class="item-about d-flex align-items-center justify-content-between">
        <div class="block-left">
          <div class="d-flex align-items-center">
            <i class="bx bxs-user"></i>
            <p class="mb-0">Nom <span>{{ this.$store.state.user_auth.nameUser }}</span></p>
          </div>
        </div>
        <div class="block-right">
          <div class="d-flex align-items-center">
            <button class="btn btn-view" data-bs-toggle="modal" data-bs-target="#modal-visibility">
              <!-- <i class="bx bxs-lock"></i> -->
              <i class="bx bx-globe"></i>
            </button>
            <button class="btn btn-edit" data-bs-toggle="modal" data-bs-target="#modalMajInfosUser"
              @click.prevent="gb_modalMajTitre('Modifier le nom', 'Modifier votre Nom', this.$store.state.user_auth.nameUser, 'bx bx-user', 'UPDATE', 'nom')">
              <i class="bx bxs-pencil"></i>
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.nicknameUser != null && this.$store.state.user_auth.nicknameUser.trim() != ''"
        class="item-about d-flex align-items-center justify-content-between">
        <div class="block-left">
          <div class="d-flex align-items-center">
            <i class="bx bxs-user"></i>
            <p class="mb-0">Surnom <span>{{ this.$store.state.user_auth.nicknameUser }}</span></p>
          </div>
        </div>
        <div class="block-right">
          <div class="d-flex align-items-center">
            <button class="btn btn-view">
              <!-- <i class="bx bxs-lock"></i> -->
              <i class="bx bx-globe"></i>
            </button>
            <button class="btn btn-edit" data-bs-toggle="modal" data-bs-target="#modalMajInfosUser"
              @click.prevent="gb_modalMajTitre('Modifier le surnom', 'Modifier votre surnom', this.$store.state.user_auth.nicknameUser, 'bx bx-user', 'UPDATE', 'surname')">
              <i class="bx bxs-pencil"></i>
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.sexUser != null && this.$store.state.user_auth.sexUser.trim() != ''"
        class="item-about d-flex align-items-center justify-content-between">
        <div class="block-left">
          <div class="d-flex align-items-center">
            <i class="bx bx-male"></i>
            <p class="mb-0">Genre <span>{{ this.$store.state.user_auth.sexUser == 'M' ? 'Homme' : 'Femme' }}</span></p>
          </div>
        </div>
        <div class="block-right">
          <div class="d-flex align-items-center">
            <button class="btn btn-view">
              <!-- <i class="bx bxs-lock"></i> -->
              <i class="bx bx-globe"></i>
            </button>
            <button class="btn btn-edit" data-bs-toggle="modal" data-bs-target="#modalMajInfosUser"
              @click.prevent="gb_modalMajTitre('Modifier votre  genre', 'Modifier le genre', this.$store.state.user_auth.sexUser, 'bx bx-male', 'UPDATE', 'genre')">
              <i class="bx bxs-pencil"></i>
            </button>
          </div>
        </div>
      </div>

      <div
        v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.businessUser != null && this.$store.state.user_auth.businessUser != ''"
        class="item-about d-flex align-items-center justify-content-between">
        <div class="block-left">
          <div class="d-flex align-items-center">
            <i class="bx bxs-briefcase"></i>
            <p class="mb-0">Travaille chez
              <span>{{ this.$store.state.user_auth.businessUser }}</span>
            </p>
          </div>
        </div>
        <div class="block-right">
          <div class="d-flex align-items-center">
            <button class="btn btn-view">
              <!-- <i class="bx bxs-lock"></i> -->
              <i class="bx bx-globe"></i>
            </button>
            <button class="btn btn-edit" data-bs-toggle="modal" data-bs-target="#modalMajInfosUser"
              @click.prevent="gb_modalMajTitre('Modifier la profession', 'Modifier la profession', this.$store.state.user_auth.businessUser, 'bx bxs-lock', 'UPDATE', 'business')">
              <i class="bx bxs-pencil"></i>
            </button>
            <!-- <button class="btn btn-edit" data-bs-toggle="modal" data-bs-target="#modalMajInfosUser"
              @click.prevent="gb_modalMajTitre('Modifier le travail', this.$store.state.user_auth.businessUser, this.$store.state.user_auth.businessUser)">
              <i class="bx bxs-pencil"></i>
            </button> -->
          </div>
        </div>
      </div>
      <div class="item-about d-flex align-items-center justify-content-between">
        <div class="block-left">
          <div class="d-flex align-items-center">
            <i class="bx bxs-graduation"></i>
            <p class="mb-0">A étudié à <span>Université de Kinshasa</span></p>
          </div>
        </div>
        <div class="block-right">
          <div class="d-flex align-items-center">
            <button class="btn btn-view">
              <i class="bx bx-globe"></i>
            </button>
            <button class="btn btn-edit" data-bs-toggle="modal" data-bs-target="#modalMajInfosUser"
              @click.prevent="gb_modalMajTitre('Modifier l\'université', 'Modifier l\'université', 'bx bxs-graduation')">
              <i class="bx bxs-pencil"></i>
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.townUser != null && this.$store.state.user_auth.townUser != ''"
        class="item-about d-flex align-items-center justify-content-between">
        <div class="block-left">
          <div class="d-flex align-items-center">
            <i class="bx bxs-home-alt-2"></i>
            <p class="mb-0">Habite à <span>{{ this.$store.state.user_auth.townUser }}</span></p>
          </div>
        </div>
        <div class="block-right">
          <div class="d-flex align-items-center">
            <button class="btn btn-view">
              <!-- <i class="bx bxs-lock"></i> -->
              <i class="bx bx-globe"></i>
            </button>
            <button class="btn btn-edit" data-bs-toggle="modal" data-bs-target="#modalMajInfosUser"
              @click.prevent="gb_modalMajTitre('Modifier le lieu de résidence', 'Modifier la ville de résidence', this.$store.state.user_auth.townUser, 'bx bxs-home-alt-2', 'UPDATE', 'town')">
              <i class="bx bxs-pencil"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- <div class="item-about d-flex align-items-center justify-content-between">
        <div class="block-left">
          <div class="d-flex align-items-center">
            <i class="bx bxs-map"></i>
            <p class="mb-0">De <span>Matadi</span></p>
          </div>
        </div>
        <div class="block-right">
          <div class="d-flex align-items-center">
            <button class="btn btn-view">
              <i class="bx bx-globe"></i>
            </button>
            <button class="btn btn-edit">
              <i class="bx bxs-pencil"></i>
            </button>
          </div>
        </div>
      </div> -->
      <!-- <div class="item-about d-flex align-items-center justify-content-between">
        <div class="block-left">
          <div class="d-flex align-items-center">
            <i class="bx bxs-heart"></i>
            <p class="mb-0">Célibataire</p>
          </div>
        </div>
        <div class="block-right">
          <div class="d-flex align-items-center">
            <button class="btn btn-view">
              <i class="bx bx-globe"></i>
            </button>
            <button class="btn btn-edit">
              <i class="bx bxs-pencil"></i>
            </button>
          </div>
        </div>
      </div> -->
      <div
        v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.mobileUser != null && this.$store.state.user_auth.mobileUser != ''"
        class="item-about d-flex align-items-center justify-content-between">
        <div class="block-left">
          <div class="d-flex align-items-center">
            <i class="bx bxs-phone"></i>
            <p class="mb-0">{{ this.$store.state.user_auth.mobileUser }}</p>
          </div>
        </div>
        <div class="block-right">
          <div class="d-flex align-items-center">
            <button class="btn btn-view">
              <!-- <i class="bx bxs-lock"></i> -->
              <i class="bx bx-globe"></i>
            </button>
            <button class="btn btn-edit" data-bs-toggle="modal" data-bs-target="#modalMajInfosUser"
              @click.prevent="gb_modalMajTitre('Modifier votre  contact', 'Modifier le numéro de téléphone', this.$store.state.user_auth.mobileUser, 'bx bxs-phone', 'UPDATE', 'mobile')">
              <i class="bx bxs-pencil"></i>
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.birthdayUser != null && this.$store.state.user_auth.birthdayUser != ''"
        class="item-about d-flex align-items-center justify-content-between">
        <div class="block-left">
          <div class="d-flex align-items-center">
            <i class="bx bxs-calendar"></i>
            <p class="mb-0">{{ this.$store.state.user_auth.birthdayUser }}</p>
          </div>
        </div>
        <div class="block-right">
          <div class="d-flex align-items-center">
            <button class="btn btn-view">
              <!-- <i class="bx bxs-lock"></i> -->
              <i class="bx bx-globe"></i>
            </button>
            <button class="btn btn-edit" data-bs-toggle="modal" data-bs-target="#modalMajInfosUser"
              @click.prevent="gb_modalMajTitre('Modifier votre  date de naissance', 'Modifier le numéro de téléphone', this.$store.state.user_auth.birthdayUser, 'bx bxs-calendar', 'UPDATE', 'birthday')">
              <i class="bx bxs-pencil"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="($route.path == '/profil-about-user/' + $route.params.friend) && this.$store.state.params == $route.params.friend" class="col-lg-8">
    <div class="block-items-about">
      <div
        v-if="this.$store.state.user_auth_other != null && this.ListDataOtherViewUser != null && this.ListDataOtherViewUser.fistnameUser != null && this.ListDataOtherViewUser.fistnameUser.trim() != ''"
        class="item-about d-flex align-items-center justify-content-between">
        <div class="block-left">
          <div class="d-flex align-items-center">
            <i class="bx bxs-user"></i>
            <p class="mb-0">Prénom <span>{{ this.ListDataOtherViewUser.fistnameUser }}</span></p>
          </div>
        </div>
        <div class="block-right">
          <div class="d-flex align-items-center">
            <button class="btn btn-view" data-bs-toggle="modal" data-bs-target="#modalMajInfosUserVisibility">
              <!-- <i class="bx bxs-lock"></i> -->
              <i class="bx bx-globe"></i>
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="this.$store.state.user_auth_other != null && this.ListDataOtherViewUser != null && this.ListDataOtherViewUser.nameUser != null && this.ListDataOtherViewUser.nameUser.trim() != ''"
        class="item-about d-flex align-items-center justify-content-between">
        <div class="block-left">
          <div class="d-flex align-items-center">
            <i class="bx bxs-user"></i>
            <p class="mb-0">Nom <span>{{ this.ListDataOtherViewUser.nameUser }}</span></p>
          </div>
        </div>
        <div class="block-right">
          <div class="d-flex align-items-center">
            <button class="btn btn-view" data-bs-toggle="modal" data-bs-target="#modal-visibility">
              <!-- <i class="bx bxs-lock"></i> -->
              <i class="bx bx-globe"></i>
            </button>
          </div>
        </div>
      </div>
      <div
         v-if="this.$store.state.user_auth_other != null && this.ListDataOtherViewUser != null && this.ListDataOtherViewUser.nicknameUser != null && this.ListDataOtherViewUser.nicknameUser.trim() != ''"
        class="item-about d-flex align-items-center justify-content-between">
        <div class="block-left">
          <div class="d-flex align-items-center">
            <i class="bx bxs-user"></i>
            <p class="mb-0">Surnom <span>{{ this.ListDataOtherViewUser.nicknameUser }}</span></p>
          </div>
        </div>
        <div class="block-right">
          <div class="d-flex align-items-center">
            <button class="btn btn-view">
              <!-- <i class="bx bxs-lock"></i> -->
              <i class="bx bx-globe"></i>
            </button>
          </div>
        </div>
      </div>
      <div
       v-if="this.$store.state.user_auth_other != null && this.ListDataOtherViewUser != null && this.ListDataOtherViewUser.sexUser != null && this.ListDataOtherViewUser.sexUser.trim() != ''"
        class="item-about d-flex align-items-center justify-content-between">
        <div class="block-left">
          <div class="d-flex align-items-center">
            <i class="bx bx-male"></i>
            <p class="mb-0">Genre <span>{{ this.ListDataOtherViewUser.sexUser == 'M' ? 'Homme' : 'Femme' }}</span></p>
          </div>
        </div>
        <div class="block-right">
          <div class="d-flex align-items-center">
            <button class="btn btn-view">
              <!-- <i class="bx bxs-lock"></i> -->
              <i class="bx bx-globe"></i>
            </button>
          </div>
        </div>
      </div>

      <div
       v-if="this.$store.state.user_auth_other != null && this.ListDataOtherViewUser != null && this.ListDataOtherViewUser.businessUser != null && this.ListDataOtherViewUser.businessUser.trim() != ''"
        class="item-about d-flex align-items-center justify-content-between">
        <div class="block-left">
          <div class="d-flex align-items-center">
            <i class="bx bxs-briefcase"></i>
            <p class="mb-0">Travaille chez
              <span>{{ this.ListDataOtherViewUser.businessUser }}</span>
            </p>
          </div>
        </div>
        <div class="block-right">
          <div class="d-flex align-items-center">
            <button class="btn btn-view">
              <!-- <i class="bx bxs-lock"></i> -->
              <i class="bx bx-globe"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="item-about d-flex align-items-center justify-content-between">
        <div class="block-left">
          <div class="d-flex align-items-center">
            <i class="bx bxs-graduation"></i>
            <p class="mb-0">A étudié à <span>Université de Kinshasa</span></p>
          </div>
        </div>
        <div class="block-right">
          <div class="d-flex align-items-center">
            <button class="btn btn-view">
              <i class="bx bx-globe"></i>
            </button>
            <button class="btn btn-edit" data-bs-toggle="modal" data-bs-target="#modalMajInfosUser"
              @click.prevent="gb_modalMajTitre('Modifier l\'université', 'Modifier l\'université', 'bx bxs-graduation')">
              <i class="bx bxs-pencil"></i>
            </button>
          </div>
        </div>
      </div>
      <div
       v-if="this.$store.state.user_auth_other != null && this.ListDataOtherViewUser != null && this.ListDataOtherViewUser.townUser != null && this.ListDataOtherViewUser.townUser.trim() != ''"
        class="item-about d-flex align-items-center justify-content-between">
        <div class="block-left">
          <div class="d-flex align-items-center">
            <i class="bx bxs-home-alt-2"></i>
            <p class="mb-0">Habite à <span>{{ this.ListDataOtherViewUser.townUser }}</span></p>
          </div>
        </div>
        <div class="block-right">
          <div class="d-flex align-items-center">
            <button class="btn btn-view">
              <!-- <i class="bx bxs-lock"></i> -->
              <i class="bx bx-globe"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- <div class="item-about d-flex align-items-center justify-content-between">
        <div class="block-left">
          <div class="d-flex align-items-center">
            <i class="bx bxs-map"></i>
            <p class="mb-0">De <span>Matadi</span></p>
          </div>
        </div>
        <div class="block-right">
          <div class="d-flex align-items-center">
            <button class="btn btn-view">
              <i class="bx bx-globe"></i>
            </button>
            <button class="btn btn-edit">
              <i class="bx bxs-pencil"></i>
            </button>
          </div>
        </div>
      </div> -->
      <!-- <div class="item-about d-flex align-items-center justify-content-between">
        <div class="block-left">
          <div class="d-flex align-items-center">
            <i class="bx bxs-heart"></i>
            <p class="mb-0">Célibataire</p>
          </div>
        </div>
        <div class="block-right">
          <div class="d-flex align-items-center">
            <button class="btn btn-view">
              <i class="bx bx-globe"></i>
            </button>
            <button class="btn btn-edit">
              <i class="bx bxs-pencil"></i>
            </button>
          </div>
        </div>
      </div> -->
      <div
         v-if="this.$store.state.user_auth_other != null && this.ListDataOtherViewUser != null && this.ListDataOtherViewUser.mobileUser != null && this.ListDataOtherViewUser.mobileUser.trim() != ''"
        class="item-about d-flex align-items-center justify-content-between">
        <div class="block-left">
          <div class="d-flex align-items-center">
            <i class="bx bxs-phone"></i>
            <p class="mb-0">{{ this.ListDataOtherViewUser.mobileUser }}</p>
          </div>
        </div>
        <div class="block-right">
          <div class="d-flex align-items-center">
            <button class="btn btn-view">
              <!-- <i class="bx bxs-lock"></i> -->
              <i class="bx bx-globe"></i>
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="this.$store.state.user_auth_other != null && this.ListDataOtherViewUser != null && this.ListDataOtherViewUser.birthdayUser != null && this.ListDataOtherViewUser.birthdayUser.trim() != ''"
        class="item-about d-flex align-items-center justify-content-between">
        <div class="block-left">
          <div class="d-flex align-items-center">
            <i class="bx bxs-calendar"></i>
            <p class="mb-0">{{ this.ListDataOtherViewUser.birthdayUser }}</p>
          </div>
        </div>
        <div class="block-right">
          <div class="d-flex align-items-center">
            <button class="btn btn-view">
              <!-- <i class="bx bxs-lock"></i> -->
              <i class="bx bx-globe"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
  name: 'InfosUser',
  data() {
    return {
      user: null,
      requesting: false,
      ListDataOtherViewUser: null,
      requestingText: "Chargement des données",
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  mounted() {

    if (this.$store.state.user_auth != null) {
      this.user = this.$store.state.user_auth;
      this.load();
    }

    setInterval(() => {
      this.load();
    }, 360000);
  },

  methods: {
    load() {
      this.requesting = true;
      this.requesting = false;


      if (this.gb_viewUserOrOtherUser(this.$route) && this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.codingUserCamarade != null && this.$store.state.user_auth_other.codingUserCamarade.trim() != '' && this.$store.state.params == this.$route.params.friend) {
        this.ListDataOtherViewUser = this.gb_objectRequestTheBase();

        axios.post("https://www.number.mobile.user.schoolbac.com", this.gb_objectRequestTheBase())
          .then(({ data }) => {
            if (data.error == "false") {
              this.ListDataOtherViewUser = { ...this.ListDataOtherViewUser, ...{ "mobileUser": data.numberUser } };
            }
          })
          .catch((error) => {
            this.errors = error;
          });

        axios.post("https://www.sex.user.schoolbac.com", this.gb_objectRequestTheBase())
          .then(({ data }) => {
            if (data.error == "false") {
              this.ListDataOtherViewUser = { ...this.ListDataOtherViewUser, ...{ "sexUser": data.sexUser } };
            }
          })
          .catch((error) => {
            this.errors = error;
          });

        axios.post("https://www.birthday.user.schoolbac.com", this.gb_objectRequestTheBase())
          .then(({ data }) => {
            if (data.error == "false") {
              this.ListDataOtherViewUser = { ...this.ListDataOtherViewUser, ...{ "birthdayUser": data.birthday } };
            } else {
              this.ListDataOtherViewUser = { ...this.ListDataOtherViewUser, ...{ "birthdayUser": null } };
            }

          })
          .catch((error) => {
            this.errors = error;
          });

          // Business
         axios.post("https://www.business.user.schoolbac.com", this.gb_objectRequestTheBase())
          .then(({ data }) => {
            if (data.error == "false") {
              this.ListDataOtherViewUser = { ...this.ListDataOtherViewUser, ...{ "businessUser": data.business } };
            } else {
              this.ListDataOtherViewUser = { ...this.ListDataOtherViewUser, ...{ "businessUser": null } };
            }

          })
          .catch((error) => {
            this.errors = error;
          });


          // Town
          axios.post("https://www.town.user.schoolbac.com", this.gb_objectRequestTheBase())
          .then(({ data }) => {
            if (data.error == "false") {
              this.ListDataOtherViewUser = { ...this.ListDataOtherViewUser, ...{ "townUser": data.town } };
            } else {
              this.ListDataOtherViewUser = { ...this.ListDataOtherViewUser, ...{ "townUser": null } };
            }

          })
          .catch((error) => {
            this.errors = error;
          });

        // Name
        axios.post("https://www.name.user.schoolbac.com", this.gb_objectRequestTheBase())
          .then(({ data }) => {
            if (data.error == "false") {
              this.ListDataOtherViewUser = { ...this.ListDataOtherViewUser, ...{ "nameUser": data.nameUser } };
            } else {
              this.ListDataOtherViewUser = { ...this.ListDataOtherViewUser, ...{ "nameUser": null } };
            }

          })
          .catch((error) => {
            this.errors = error;
          });


        // firstname
        axios.post("https://www.firstname.user.schoolbac.com", this.gb_objectRequestTheBase())
          .then(({ data }) => {
            if (data.error == "false") {
              this.ListDataOtherViewUser = { ...this.ListDataOtherViewUser, ...{ "birthdayUser": data.birthday } };
            } else {
              this.ListDataOtherViewUser = { ...this.ListDataOtherViewUser, ...{ "birthdayUser": null } };
            }

          })
          .catch((error) => {
            this.errors = error;
          });

            // Nickname
        axios.post("https://www.nickname.user.schoolbac.com", this.gb_objectRequestTheBase())
          .then(({ data }) => {
            if (data.error == "false") {
              this.ListDataOtherViewUser = { ...this.ListDataOtherViewUser, ...{ "nicknameUser": data.nickname } };
            } else {
              this.ListDataOtherViewUser = { ...this.ListDataOtherViewUser, ...{ "nicknameUser": null } };
            }

          })
          .catch((error) => {
            this.errors = error;
          });
      } else {
         axios.post("https://www.number.mobile.user.schoolbac.com", this.gb_objectRequestTheBase())
          .then(({ data }) => {
            console.log(data);
          })
          .catch((error) => {
            this.errors = error;
          });
      }
    },

  },

}
</script>
