<template>
<div class="global-div">
    <div class="wrapper">
      <div class="loading">
        <div id="loader"></div>
      </div>
      <header class="header">
        <nav class="navbar navbar-expand-lg">
          <div class="container-fluid px-lg-5">
            <div class="row w-100 align-items-center">
              <div class="col-lg-6 col-3 order-1 order-md-1 order-lg-1 col-sm-6 col-md-6">
                <a class="navbar-brand" href="#">
                  <img src="assets/images/logo-app.png" alt="">
                </a>
              </div>
              <div class="col-lg-6 col-9 order-2 oder-md-3 order-lg-3 col-sm-6 col-md-6">
                <div class="d-flex justify-content-end net-work">
                  <a href="/login" class="btn">
                    <span>Se connecter</span>
                    <span>Se connecter</span>
                    <span>Se connecter</span>
                  </a>
                  <a href="/register" class="btn">
                    <span>S'inscrire</span>
                    <span>S'inscrire</span>
                    <span>S'inscrire</span>
                  </a>
                  <div class="menu-toggle">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>



          </div>
        </nav>
      </header>
      <div class="block-info-app">
        <div class="container">
          <div class="row g-lg-5 g-4">
            <div class="col-lg-3" style="border-right: 1px solid rgba(0,0,0,0.05);">
              <nav>
                <ul class="nav nav-tabs flex-lg-column" id="nav-tab" role="tablist">
                  <li class="title mb-2">
                    SchollBac
                  </li>
                  <li>
                    <button :class="'nav-link text-lg-start '+ (this.$store.state.helpRubrique == '/about#descr'  || this.$store.state.helpRubrique =='' ? 'active':'')" id="nav-home-tab" data-bs-toggle="tab"
                      data-bs-target="#descr" type="button" role="tab" aria-controls="nav-home"
                      aria-selected="true">Description plateforme</button>
                  </li>
                  <li>
                    <button :class="'nav-link text-lg-start '+ (this.$store.state.helpRubrique == '/about#int_ped' ? 'active':'')" id="nav-profile-tab" data-bs-toggle="tab"
                      data-bs-target="#int_ped" type="button" role="tab" aria-controls="nav-profile"
                      aria-selected="false">Intérêt Pédagogique </button>
                  </li>
                  <li>
                    <button :class="'nav-link text-lg-start '+ (this.$store.state.helpRubrique == '/about#int_sci' ? 'active':'')" id="nav-contact-tab" data-bs-toggle="tab"
                      data-bs-target="#int_sci" type="button" role="tab" aria-controls="nav-contact"
                      aria-selected="false">Intérêt scientifique </button>
                  </li>
                  <li>
                    <button :class="'nav-link text-lg-start '+ (this.$store.state.helpRubrique == '/about#prod' ? 'active':'')" id="nav-contact-tab" data-bs-toggle="tab"
                      data-bs-target="#prod" type="button" role="tab" aria-controls="nav-contact"
                      aria-selected="false">Produits</button>
                  </li>
                  <li class="title mb-2 mt-3">
                    Aide & details
                  </li>
                  <li>
                    <button :class="'nav-link text-lg-start '+ (this.$store.state.helpRubrique == '/about#apropos' ? 'active':'')" id="nav-contact-tab" data-bs-toggle="tab"
                      data-bs-target="#apropos" type="button" role="tab" aria-controls="nav-contact"
                      aria-selected="false">A propos</button>
                  </li>
                  <li>
                    <button :class="'nav-link text-lg-start '+ (this.$store.state.helpRubrique == '/about#histo' ? 'active':'')" id="nav-contact-tab" data-bs-toggle="tab"
                      data-bs-target="#histo" type="button" role="tab" aria-controls="nav-contact"
                      aria-selected="false">Historique</button>
                  </li>
                  <li>
                    <button :class="'nav-link text-lg-start '+ (this.$store.state.helpRubrique == '/about#pub' ? 'active':'')" id="nav-contact-tab" data-bs-toggle="tab"
                      data-bs-target="#pub" type="button" role="tab" aria-controls="nav-contact"
                      aria-selected="false">Faire la publicité</button>
                  </li>
                  <li>
                    <button :class="'nav-link text-lg-start ' + (this.$store.state.helpRubrique == '/about#contact' ? 'active' : '')" id="nav-contact-tab" data-bs-toggle="tab"
                      data-bs-target="#contact" type="button" role="tab" aria-controls="nav-contact"
                      aria-selected="false">Contactez-nous</button>
                  </li>

                  <li class="title mb-2 mt-3">
                    Mention Légale
                  </li>
                  <li>
                    <button  :class="'nav-link text-lg-start ' + (this.$store.state.helpRubrique == '/about#mention' ? 'active' : '')"
                      id="nav-contact-tab" data-bs-toggle="tab"
                      data-bs-target="#mention" type="button" role="tab"
                      aria-controls="nav-contact"
                      aria-selected="false">Condition d’utilisation</button>
                  </li>
                  <li>
                    <button :class="'nav-link text-lg-start ' + (this.$store.state.helpRubrique == '/about#confi' ? 'active' : '')"
                      id="nav-contact-tab" data-bs-toggle="tab"
                      data-bs-target="#confi" type="button" role="tab"
                      aria-controls="nav-contact"
                      aria-selected="false">Confidentialité </button>
                  </li>
                  <li>
                    <button  :class="'nav-link text-lg-start ' + (this.$store.state.helpRubrique == '/about#donnees' ? 'active' : '')"
                      id="nav-contact-tab" data-bs-toggle="tab"
                      data-bs-target="#donnees" type="button" role="tab"
                      aria-controls="nav-contact"
                      aria-selected="false">Données personnelles </button>
                  </li>
                  <li>
                    <button :class="'nav-link text-lg-start ' + (this.$store.state.helpRubrique == '/about#signal' ? 'active' : '')"
                      id="nav-contact-tab" data-bs-toggle="tab"
                      data-bs-target="#signal" type="button" role="tab"
                      aria-controls="nav-contact"
                      aria-selected="false">Signaler un contenu </button>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="col-lg-9">
              <div class="tab-content" id="nav-tabContent">
                <div :class="'tab-pane fade '+ (this.$store.state.helpRubrique == '/about#descr' || this.$store.state.helpRubrique =='' ? 'show active':'')" id="descr" role="tabpanel" aria-labelledby="nav-home-tab">
                  <div>
                    <h2 class="title-tab mb-lg-3 mb-3">Description de la
                      plateforme Schoolbac</h2>
                    <p>
                      La plateforme Schoolbac combine les fonctions de CMS
                      (système de gestion de
                      contenu) et LMS (système de gestion de l’apprentissage).
                      Cet espace numérique
                      de travail rassemble tous les acteurs de
                      l’école/université et possède une
                      messagerie intégrée qui facilite la communication. Il
                      permet :
                    </p>
                    <ul class="list-desc">
                      <li>
                        <i class="bx bx-check-circle"></i>
                        Le partage d’informations
                      </li>
                      <li>
                        <i class="bx bx-check-circle"></i>
                        L’édition de ressources multimédia
                      </li>
                      <li>
                        <i class="bx bx-check-circle"></i>
                        Du travail collaboratif
                      </li>
                      <li>
                        <i class="bx bx-check-circle"></i>
                        De la vidéo-conférence et appels VOIP
                      </li>
                      <li>
                        <i class="bx bx-check-circle"></i>
                        De la co-construction de documents ou de projets
                      </li>
                    </ul>
                    <p>
                      Cette plateforme d’apprentissage en ligne fait
                      office de structure virtuelle permettant aux enseignants
                      et aux
                      élèves/étudiants de travailler comme dans une
                      école/université physique. À
                      ce titre, elle intègre de nombreuses fonctionnalités
                      dont :
                    </p>
                    <ul class="list-desc">
                      <li>
                        <i class="bx bx-check-circle"></i>
                        La création des écoles ou universités virtuelles avec
                        la dotation des
                        bibliothèques digitales qui contiennent plusieurs
                        livres qui cadre avec le
                        programme nationa
                      </li>
                      <li>
                        <i class="bx bx-check-circle"></i>
                        Aux enseignants d’utiliser son interface ERP
                        (Enterprise Ressource
                        Planning) afin de soumettre les notes des évaluations
                        à l’administration, de
                        prendre connaissance de l’emploi du temps prévisionnel
                        et de mettre en
                        ligne ses notes des cours sous formes des fichiers
                        pdf, audio et vidéos
                      </li>
                      <li>
                        <i class="bx bx-check-circle"></i>
                        A l’étudiant/élève de pouvoir travailler à partir d'un
                        point d'accès lié au
                        réseau internet sans être obligé de se trouvé dans à
                        l’école ou à l'université.
                        Cet accès à distance lui donne la possibilité de
                        consulter les documents
                        actualisés par les enseignants, de vérifier les
                        travaux à réaliser et les délais
                        de remise, de discuter avec ses camarades sur les
                        problèmes et difficultés
                        rencontrés et même les soumettre à leurs enseignants
                        pour les orienter
                      </li>
                      <li>
                        <i class="bx bx-check-circle"></i>
                        De la vidéo-conférence et appels VOIP
                      </li>
                      <li>
                        <i class="bx bx-check-circle"></i>
                        De la co-construction de documents ou de projets
                      </li>
                    </ul>
                  </div>
                </div>
                <div :class="'tab-pane fade '+ (this.$store.state.helpRubrique == '/about#int_ped' ? 'show active':'')" id="int_ped" role="tabpanel" aria-labelledby="nav-profile-tab">
                  <div>
                    <h2 class="title-tab mb-lg-3 mb-3">Intérêt pédagogique</h2>
                    <p>
                      Cette plateforme d’apprentissage en ligne fait
                      office de structure virtuelle permettant aux enseignants
                      et aux
                      élèves/étudiants de travailler comme dans une école
                      physique. À ce titre, elle
                      intègre de nombreuses fonctionnalités dont
                    </p>
                    <ul class="list-desc">
                      <li>
                        <i class="bx bx-check-circle"></i>
                        La création des écoles ou universités virtuelles avec
                        la dotation des
                        bibliothèques digitales qui contiennent plusieurs
                        livres qui cadre avec le
                        programme national
                      </li>
                      <li>
                        <i class="bx bx-check-circle"></i>
                        Aux enseignants d’utiliser son interface ERP
                        (Enterprise Ressource
                        Planning) afin de soumettre les notes des évaluations
                        à l’administration, de
                        prendre connaissance de l’emploi du temps prévisionnel
                        et de mettre en
                        ligne ses notes des cours sous formes des fichiers
                        pdf, audio et vidéos
                      </li>
                      <li>
                        <i class="bx bx-check-circle"></i>
                        A l’étudiant/élève de pouvoir travailler à partir d'un
                        point d'accès lié au
                        réseau internet sans être obligé de se trouvé dans à
                        l’école ou à l'université.
                        Cet accès à distance lui donne la possibilité de
                        consulter les documents
                        actualisés par les enseignants, de vérifier les
                        travaux à réaliser et les délais
                        de remise, de discuter avec ses camarades sur les
                        problèmes et difficultés
                        rencontrés et même les soumettre à leurs enseignants
                        pour les oriente
                      </li>
                    </ul>
                  </div>
                </div>
                <div :class="'tab-pane fade '+ (this.$store.state.helpRubrique == '/about#int_sci' ? 'show active':'')" id="int_sci" role="tabpanel" aria-labelledby="nav-contact-tab">
                 <div>
                    <h2 class="title-tab mb-lg-3 mb-3">Intérêt scientifique</h2>
                    <p>
                      Cette plateforme propose à ses différents
                      utilisateurs plusieurs modules des formations et
                      séminaires scientifiques :
                    </p>
                    <ul class="list-desc">
                      <li>
                        <i class="bx bx-check-circle"></i>
                        <strong>Linguistique</strong> : En anglais, Arabe,
                        Chinois, Français et Russe
                      </li>
                      <li>
                        <i class="bx bx-check-circle"></i>
                        <strong>Informatique</strong> : En bureautique,
                        Intelligence artificielle, langages des
                        programmations, Photoshop, etc.
                      </li>
                      <li>
                        <i class="bx bx-check-circle"></i>
                        <strong>Logiciel</strong> : Architecture, Arts,
                        Comptabilité, Structures, etc.
                      </li>
                      <li>
                        <i class="bx bx-check-circle"></i>
                        <strong>Séminaire</strong> : Droits humains, Santé
                        publique, théologie, etc
                      </li>
                    </ul>
                    <p>
                      Notons que ces différentes formations sont certifiées
                      par un certificat reconnu
                      par plusieurs structures scientifiques et académiques
                    </p>
                    <h3
                      class="title-tab-sm text-center mb-lg-4 mg-3 mt-lg-4 mt-3">
                      Différents Profils des utilisateurs
                    </h3>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Questions </th>
                            <th scope="col">Elèves (étudiants) </th>
                            <th scope="col">Professeurs</th>
                            <th scope="col">Personne morale</th>
                            <th scope="col">Parents</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">
                              Quel est leur profil ?
                            </th>
                            <td>Apprenant</td>
                            <td>Enseignant</td>
                            <td>@Formateur ou encadreur</td>
                            <td>Educateur (suivi)</td>
                          </tr>
                          <tr>
                            <th scope="row">
                              Quels sont leurs intérêts ?
                            </th>
                            <td>De trouver les contenus
                              instructifs liés à leurs
                              formations au cursus
                              choisi</td>
                            <td>De mieux préparer ses
                              cours sur bas de la
                              documentation en ligne et
                              de développer sa propre
                              pratique pédagogique</td>
                            <td>
                              De trouver les profils
                              recherchés au sein de
                              leurs institutions.
                              D’incuber les différents
                              utilisateurs et de donner
                              aussi des formations
                              professionnelles
                            </td>
                            <td>
                              De faire le suivie d leur
                              enfant et d’être informé
                              en temps et heure sur
                              les activités scolaires ou
                              académiques de l’enfant
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              Où sont-ils situés ?
                            </th>
                            <td>
                              Institutions éducatives
                            </td>
                            <td>
                              Idem au profil 1
                            </td>
                            <td>
                              Entreprise ou fondation
                            </td>
                            <td>
                              A la maison
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              Pourquoi achèteront-ils nos services ?
                            </th>
                            <td>
                              Les contenus sont offerts
                              à moindre coût pour un
                              abonnement mensuel
                            </td>
                            <td>
                              Protection intellectuelle et
                              droit d’auteur de leurs
                              publications sont garantis
                            </td>
                            <td>
                              Faire valoriser
                              l’institution et
                              augmenter la visibilité
                              sur l’internet
                            </td>
                            <td>
                              Idem profil 1
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div :class="'tab-pane fade '+ (this.$store.state.helpRubrique == '/about#prod' ? 'show active':'')" id="prod" role="tabpanel" aria-labelledby="nav-contact-tab">
                  <div>
                    <h2 class="title-tab mb-lg-3 mb-3">Produits</h2>
                    <p>
                      Les produits vendus par la plateforme SCHOOLBAC sont à
                      caractères
                      intellectuels, scientifiques et professionnels, qui sont
                      :
                    </p>
                    <h3 class="title-tab-sm">
                      Education
                    </h3>
                    <p>
                      Le module éducation propose des services intuitifs pour
                      le développement
                      intellectuel des apprenants, notamment :
                    </p>
                    <ul class="list-desc">
                      <li>
                        <i class="bx bx-check-circle"></i>
                        <strong>Mes leçons</strong>
                        <p class="sm mb-0 mt-2">
                          C’est un espace qui regroupe des milliers des cours
                          des plusieurs disciplines et
                          filiales qui existent dans le monde scolaire ou
                          universitaire. Ces contenus sont
                          proposés par les professeurs des renoms et il suffit
                          que l’utilisateur choisisse sa
                          promotion et sa filière. On y trouve aussi les
                          travaux pratiques, les devoirs et les
                          interrogations.
                        </p>
                      </li>
                      <li>
                        <i class="bx bx-check-circle"></i>
                        <strong>Maquis</strong>
                        <p class="sm mb-0 mt-2">
                          C’est un espace de forum scientifique qui permet aux
                          utilisateurs d’échangés sur
                          des questions scolaires ou académiques en vue de
                          renforcer leurs liens sur la
                          plateforme.
                        </p>
                      </li>
                      <li>
                        <i class="bx bx-check-circle"></i>
                        <strong>Bibliothèque</strong>
                        <p class="sm mb-0 mt-2">
                          La plateforme met à la disposition des utilisateurs
                          un nombre impressionnant
                          des livres qui cadrent avec le programme national de
                          leurs pays. (Par exemple
                          pour la République Démocratique du Congo, nous avons
                          les livres tels que : Au
                          gré des vagues, Maitriser les maths, Via nova, Mon
                          second calcul, etc.). On y
                          trouve aussi les sujets de travaux de fin de cycle
                          et études ainsi que les
                          mémoires et DEA.
                        </p>
                      </li>
                      <li>
                        <i class="bx bx-check-circle"></i>
                        <strong>Mes professeurs</strong>
                        <p class="sm mb-0 mt-2">
                          C’est un espace qui permet aux apprenants de
                          consulter les publications des
                          professeurs des renoms et ainsi d’être en contact
                          permanent avec ses professeurs
                          24h/24 afin de leurs soumettre les préoccupations.
                          Ce service permet aux
                          utilisateurs d’être répondus en temps et en heures à
                          leurs préoccupations.
                        </p>
                      </li>
                      <li>
                        <i class="bx bx-check-circle"></i>
                        <strong>Exétat/baccalauréat</strong>
                        <p class="sm mb-0 mt-2">
                          C’est un logiciel intégré au sein de la plateforme
                          qui permet aux finalistes de
                          consulter les sujets d’épreuves et d’imprimer aussi
                          avec leurs corrigés.
                        </p>
                      </li>
                      <li>
                        <i class="bx bx-check-circle"></i>
                        <strong>Scientifique</strong>
                        <p class="sm mb-0 mt-2">
                          Le module scientifique propose aux utilisateurs des
                          contenus plus adaptés à
                          leurs formations et leurs apprentissages. On y
                          trouver, notamment :
                        </p>
                        <ul class="list-sm">
                          <li>
                            <strong>Concours et prix scientifiques</strong>
                            <p class="sm">
                              En vue d’encourager les utilisateurs à prendre
                              au sérieux leurs formations
                              plusieurs concours seront destinés pour
                              permettre aux utilisateurs de hisser leurs
                              niveaux intellectuels. Les prix scientifiques
                              seront discernés aux meilleurs
                              écoles ou universités sur bases des résultats
                              obtenus par leurs candidats aux
                              concours.
                            </p>
                          </li>
                          <li>
                            <strong>Documentaires</strong>
                            <p class="sm">
                              Grâce à des contenus à caractère didactiques ou
                              informatifs qui visent
                              principalement à restituer les apparences de les
                              réalités, les documentaires
                              permettent à l’utilisateur de développer ses
                              connaissances sur le sujet qu’il
                              étudie en vue de renforcer ses capacités
                              intellectuelles.
                            </p>
                          </li>
                          <li>
                            <strong>Séminaires scientifiques</strong>
                            <p class="sm">
                              Le but premier de la pratique des séminaires est
                              de familiariser les utilisateurs
                              avec les méthodes convenant à un sujet qu’ils
                              ont choisi, et de leur permettre de
                              discuter en groupe sur les problèmes pratiques
                              qui émergent pendant leur travail
                              de recherche. On dispense des séminaires : en
                              droit humain, santé publique,
                              masculinité positive, etc
                            </p>
                          </li>
                          <li>
                            <strong>Colloques scientifiques</strong>
                            <p class="sm">
                              L’organisation des colloques scientifiques
                              permettent de mettre en valeur des
                              résultats de recherche sur un thème
                              scientifique, augmenter la visibilité des
                              travaux de recherche d’une institution,
                              consolider les liens de collaboration entre
                              institutions et utilisateurs.
                            </p>
                          </li>
                          <li>
                            <strong>Librairies</strong>
                            <p class="sm">
                              Cette option permet aux maisons de librairies et
                              aux scientifiques de vendre
                              leurs livres en toute sécurité.
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <i class="bx bx-check-circle"></i>
                        <strong>Centre de formation</strong>
                        <p class="sm mb-0 mt-2">
                          C’est un espace de formation professionnelle et
                          scientifique sanctionnant un
                          certificat à la fin de la formation. On y trouve,
                          notamment :
                        </p>
                        <ul class="list-sm">
                          <li>
                            <strong>Linguistique </strong>
                            <p class="sm">
                              En anglais, Arabe, Chinois, Français et Russe
                            </p>
                          </li>
                          <li>
                            <strong>Informatique</strong>
                            <p class="sm">
                              En bureautique, Intelligence artificielle,
                              langages des
                              programmations, Photoshop, etc
                            </p>
                          </li>
                          <li>
                            <strong>Logiciel</strong>
                            <p class="sm">
                              Architecture, Arts, Comptabilité, Structures,
                              etc.
                            </p>
                          </li>
                          <li>
                            <strong>Entreprenariat</strong>
                            <p class="sm">
                              autonomisation des femmes et des hommes,
                              incubation et
                              accompagnements des idées, etc.
                            </p>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <div :class="'tab-pane fade '+ (this.$store.state.helpRubrique == '/about#apropos' ? 'show active':'')" id="apropos" role="tabpanel" aria-labelledby="nav-contact-tab">
                  <div>
                    <h2 class="title-tab mb-lg-3 mb-3">
                      A-propos
                    </h2>
                    <p>
                      L’accès facile aux portables, Smart phone, Androïde,
                      iPhone mais aussi de la
                      connexion internet avec le wifi et les forfaits internet
                      a vraiment révolutionné le
                      monde et changé les comportements individuels des jeunes
                    </p>
                    <p>
                      Néanmoins, il y a des influences considérables qui sont
                      méconnues où bien très
                      souvent négligées par les jeunes. Si l’on parle de
                      réseaux sociaux, on parle de
                      virtuel mais à force de les utiliser, on finit par
                      prendre ces choses pour la réalité.
                      Les réseaux sociaux ont complétement bouleversé le mode
                      de vie des jeunes. Ils
                      sont devenus presque indispensables pour ces jeunes.
                    </p>
                    <p>
                      Les jeunes d’aujourd’hui préfèrent passer leur temps sur
                      les réseaux sociaux
                      plutôt qu’à étudier. Pour la plupart les réseaux sociaux
                      occupent tout leur temps,
                      ils peuvent s’y connecter à longueur de journée voir
                      même 24h/24. Les enfants
                      et les adolescents scolarisés (entre l’école primaire et
                      le lycée) très souvent
                      connectés sont moins bons à l’école. L’utilisation
                      abusive entraine surtout la
                      déconcentration.
                    </p>
                    <p>
                      Des heures perdues, à <strong>scoller</strong> à
                      l’infini, à passer de profil en profil, de photo
                      en photo. Pourtant, demain, ils feront la même chose. Et
                      le surlendemain aussi.
                      En oubliant à chaque fois cette impression qu’on a perdu
                      son temps pour rien.
                      Conséquence, moins de temps pour les études et baissent
                      du niveau à l’école.
                    </p>
                    <p>
                      Par ailleurs la baisse de niveau peut-être considérer
                      comme une sécheresse de
                      connaissances ou un manque crucial de vocabulaire. Elle
                      se manifeste par la
                      non maîtrise par les élèves des connaissances de base
                      supposées acquises depuis
                      le primaire. Il faudrait souligner que les réseaux
                      sociaux ne devraient pas être
                      des menaces pour les jeunes si ces derniers en font bon
                      usage. D’ailleurs la
                      définition même nous en dit plus.
                    </p>
                    <p>
                      Dans ce registre, l’entreprise <strong>SCHOOLBAC</strong>
                      consciente de la gravité de la
                      situation qui touche la jeunesse planétaire. Soucieuse
                      de contribuer au
                      développement de la jeunesse et plus clairement
                      participer à la formation d’une
                      élite sociale et responsable de conduire la société.
                      Elle a mis à leur disposition le
                      réseau social <strong>SCHOOLBAC</strong>.
                    </p>
                  </div>
                </div>
                <div :class="'tab-pane fade '+ (this.$store.state.helpRubrique == '/about#histo' ? 'show active':'')" id="histo" role="tabpanel" aria-labelledby="nav-contact-tab">
                  <div>
                      <h2 class="title-tab mb-lg-3 mb-3">
                        Historique
                      </h2>
                      <p>
                        SCHOOLBAC est un réseau social en ligne purement
                        académique qui permet à
                        ses utilisateurs de développer le système de la
                        recherche scientifique et de
                        réaliser la compétitivité, d’organiser des conférences
                        et colloques scientifiques,
                        et de hisser leurs niveaux intellectuels. En dehors du
                        cadre académique,
                        SCHOOLBAC permet aussi à ses utilisateurs de publier des
                        images, des photos,
                        des vidéos, des fichiers et documents, d’échanger des
                        messages, joindre et créer
                        des groupes et d’utiliser une variété d’applications.
                      </p>
                      <p>
                        SCHOOLBAC fut créé de 2017-2018 à l’université de
                        Kinshasa l’ingénieur
                        <strong>Blaise Degay</strong> (Diplômé de l’Institut
                        National du Bâtiment et des Travaux
                        Publics en Hydraulique et Environnement) qui est son
                        fondateur.
                      </p>
                      <div
                        class="row g-lg-3 g-3 align-items-center py-lg-4 py-2">
                        <div class="col-lg-4 col-sm-6">
                          <div class="card card-photo">
                            <img src="assets/images/img1.jpeg"
                              alt="image de blaise degay">
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                          <div class="card card-photo">
                            <img src="assets/images/img2.jpeg"
                              alt="image de blaise degay">
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                          <figcaption>
                            <h3 class="title-tab-sm" style="font-size: 16px;">
                              Blaise Degay
                            </h3>
                            <p class="sm">
                              Créateur de Schoolbac, dans sa chambre en <strong>2017</strong>.
                            </p>
                          </figcaption>
                        </div>
                      </div>
                      <p>
                        Le nom de ce réseau social provient d’un mixage de deux
                        langues (l’anglais et le
                        français). <strong>SCHOOL</strong> qui veut dire école
                        et <strong>BAC</strong> qui est le
                        diminutif du
                        baccalauréat développant ainsi chez l’utilisateur un
                        esprit scientifique à l’école
                        afin d’obtenir son baccalauréat. Slogan : la
                        connaissance à la portée de tous.
                        Devise : science, conscience et âme
                      </p>
                      <ul class="list-desc">
                        <li>
                          <i class="bx bx-check-circle"></i>
                          <strong>Slogan</strong>
                          <p class="sm mb-0 mt-2">
                            la connaissance à la portée de tous.
                          </p>
                        </li>
                        <li>
                          <i class="bx bx-check-circle"></i>
                          <strong>Devise </strong>
                          <p class="sm mb-0 mt-2">
                            science, conscience et âme
                          </p>
                        </li>
                      </ul>
                  </div>
                </div>
                <div  :class="'tab-pane fade ' + (this.$store.state.helpRubrique == '/about#pub' ? 'show active' : '')"  id="pub" role="tabpanel"
                  aria-labelledby="nav-contact-tab">
                  <div>
                    <h2 class="title-tab mb-lg-3 mb-3">Faire la publicité</h2>
                    <p>
                      Les publicités ne doivent pas contenir d'injures ou de
                      fautes de grammaire et de
                      ponctuation. Les symboles, chiffres et lettres doivent
                      être correctement utilisés,
                      en aucun cas dans le but de contourner notre processus d'examen
                      des publicités
                      ou d'autres systèmes d'application des règles.
                    </p>
                    <p>
                      Les gouvernements, les personnalités politiques et les
                      groupes de sensibilisation
                      continuent de communiquer avec leurs électeurs et leurs
                      soutiens sur les
                      plateformes publicitaires de Schoolbac, c’est pourquoi
                      nous souhaitons mettre
                      en avant quelques règles qui contribueront à la réussite
                      de vos campagnes. Nos
                      Standards publicitaires fournissent des conseils sur les
                      types de contenu publicitaire autorisés sur nos
                      plateformes. Lorsque les annonceurs diffusent des
                      publicités, chaque publicité est examinée par rapport à
                      nos règles.
                    </p>
                    <p>
                      Pour vous aider à mettre en œuvre des campagnes
                      publicitaires conformes qui
                      offrent une expérience positive aux utilisateurs, nous
                      avons mis en avant certains
                      domaines faisant souvent l’objet de confusions, afin de
                      mieux vous expliquer
                      comment adhérer à ces Standards publicitaires pour
                      réussir vos campagnes, que vous soyez candidat, membre
                      du gouvernement ou défenseur d’une cause.
                    </p>
                    <h3 class="title-tab-sm">
                      Le texte dans les images publicitaires
                    </h3>
                    <p>
                      Une autre de nos règles publicitaires souvent mal
                      comprise est celle relative à
                      l’excès de texte. La création de publicités attrayantes
                      contenant peu de texte
                      présente des avantages à la fois pour votre audience et
                      pour vos objectifs de
                      campagne. Nous vous recommandons d’essayer d’utiliser le
                      moins de texte
                      possible, voire pas de texte du tout, dans vos images.
                      Nous avons constaté que
                      les images contenant moins de 20 % de texte sont plus
                      performantes. Par
                      conséquent, afin de créer la meilleure expérience
                      possible pour les audiences et
                      les annonceurs, toutes les publicités sont soumises à un
                      processus de vérification
                      qui analyse la quantité de texte utilisée dans l’image
                      de votre publicité. Bien
                      qu’une publicité contenant plus de 20 % de texte ne soit
                      pas refusée, il se peut
                      qu’elle soit moins performante.
                    </p>
                    <h3 class="title-tab-sm">
                      Page de destination non fonctionnelle
                    </h3>
                    <p>
                      ’une de nos règles stipule que les publicités ne peuvent
                      pas rediriger vers des
                      pages de destination non fonctionnelles. Cela inclut les
                      pages de destination dont
                      le contenu empêche les visiteurs de les quitter si elles
                      ou ils le souhaitent. Par
                      exemple, vous ne devez pas rediriger votre audience vers
                      une page contenant
                      des erreurs, une page en construction, un fichier PDF ou
                      JPEG, une page qui ne
                      correspond pas au produit ou service affiché dans la
                      publicité, une page de
                      destination téléchargeant des fichiers automatiquement
                      sur l’ordinateur de la ou
                      du visiteur·, ou encore un groupe Schoolbac fermé ou
                      secret. Apprenez-en
                      davantage sur nos règles relatives aux pages de
                      destination
                    </p>
                    <h3 class="title-tab-sm">
                      Contenu de qualité médiocre ou perturbant
                    </h3>
                    <p>
                      En plus de notre règle relative aux pages de destination
                      non fonctionnelles, nous
                      disposons également d’une règle sur <a href="https://www.facebook.com/policies/ads/prohibited_content/low_quality_or_disruptive_experiences">le contenu de
                        qualité médiocre</a> ou
                      perturbant dans les pages de destination. Les publicités
                      ne doivent pas comporter
                      de contenu renvoyant à des pages de destination externes
                      qui proposent une
                      expérience déroutante ou envahissante. Cela inclut
                      l’utilisation de publicités
                      trompeuses comprenant des titres sensationnels, incitant
                      l’audience à interagir avec la publicité de manière non
                      authentique ou la redirigeant vers des pages de
                      destination contenant peu de contenu original pour une
                      majorité de contenus
                      publicitaires hors de propos ou de mauvaise qualité.
                      Pour en savoir plus sur les
                      contenus que nous considérons de mauvaise qualité,
                      consultez nos <a href="https://www.facebook.com/business/help/1767120243598011">pages d’aide
                        sur les publicités</a>.

                    </p>
                    <h3 class="title-tab-sm">
                      Injures
                    </h3>
                    <p>
                      En plus de nos règles publicitaires sur l’excès de texte
                      et la fonctionnalité des
                      publicités, nous disposons d’une règle sur <a href="https://www.facebook.com/policies/ads/prohibited_content/grammar">la grammaire
                        et les injures</a>. Les
                      publicités ne doivent pas contenir d’injures ou de
                      fautes de grammaire et de
                      ponctuation. Les symboles, chiffres et lettres doivent
                      être correctement utilisés,
                      en aucun cas dans le but de contourner notre processus
                      d’examen des publicités
                      ou d’autres systèmes d’application des règles. Vous
                      trouverez l’intégralité de
                      cette règle <a href="https://www.facebook.com/policies/ads/prohibited_content/grammar">ici.</a>
                    </p>
                    <h3 class="title-tab-sm">
                      À éviter :
                    </h3>
                    <ul class="list-desc">
                      <li>
                        <i class='bx bx-x'></i>
                        Une utilisation abusive de majuscules, comme la mise
                        en majuscules de la
                        totalité du texte de votre publicité ;
                      </li>
                      <li>
                        <i class='bx bx-x'></i>
                        Une utilisation excessive de symboles, caractères ou
                        signes de
                        ponctuation ;
                      </li>
                      <li>
                        <i class='bx bx-x'></i>
                        Les propos vulgaires, y compris les injures
                        partiellement masquées par
                        des astérisques ou des symboles ;
                      </li>
                      <li>
                        <i class='bx bx-x'></i>
                        Des publicités aux contenus pornographiques ;
                      </li>
                      <li>
                        <i class='bx bx-x'></i>
                        Une publicité d’homosexualité, Schoolbac est une
                        plateforme
                        conservatrice de valeur humaine. L’orientation
                        sexuelle qui met en avant
                        Schoolbac Homme-Femme pour une procréation de l’espèce
                        humaine.
                      </li>
                    </ul>
                    <h3 class="title-tab-sm">
                      Boissons alcoolisées
                    </h3>
                    <p>
                      En tant qu’annonceur, vous devez connaître nos règles
                      sur les boissons
                      alcoolisées. Les publicités qui font la promotion de
                      boissons alcoolisées ou qui y
                      font référence doivent respecter les lois locales en
                      vigueur, les codes de
                      l’industrie, qu’ils soient établis ou recommandés, les
                      règlements, les licences et
                      les approbations. Par ailleurs, elles doivent comprendre
                      des critères de ciblage
                      par âge et par pays conformes aux politiques de ciblage
                      de Schoolbac et aux lois
                      locales applicables. Pour vous assurer que vos
                      publicités sont conformes et
                      éviter tout problème, envisagez de ne cibler que les
                      personnes majeures, ou
                      indiquez clairement le type de boisson dont vous faites
                      la publicité. Par
                      exemple : <strong>Gagnez un café...</strong>
                      Pour plus d’informations sur nos règles en matière de
                      boissons alcoolisées,
                      <a
                        href="https://www.facebook.com/policies/ads/restricted_content/alcohol">cliquez
                        ici</a>
                    </p>
                    <h3 class="title-tab-sm">
                      Publicités politiques ou liées à des débats d’intérêt
                      général
                    </h3>
                    <p>
                      Enfin, si vous prévoyez de diffuser des publicités
                      politiques ou liées à des débats
                      d’intérêt général, vous pourrez avoir à réaliser notre
                      processus d’autorisation
                      publicitaire. Néanmoins, les exigences relatives à la
                      diffusion de publicités
                      politiques ou liées à des débats d’intérêt général
                      varient d’un pays à l’autre.
                      Apprenez-en davantage sur notre processus d’autorisation
                      des publicités
                      politiques en cliquant <a
                        href="https://www.facebook.com/business/help/167836590566506">ici</a>
                      ou consultez nos <a
                        href="https://www.facebook.com/business/help/1838453822893854">
                        pages d’aide sur les publicités.</a>
                    </p>
                    <p>
                      Si vous respectez l’ensemble de nos Standards
                      publicitaires et <a
                        href="https://www.facebook.com/communitystandards/">Standards
                        de la
                        communauté</a>, vous créerez des expériences positives
                      pour les personnes qui
                      comptent pour vous sur Schoolbac et de millier de
                      Schoolbackers. En respectant
                      ces règles, vous pourrez mieux atteindre, éduquer et
                      impliquer votre audience
                    </p>
                  </div>
                </div>
                <div :class="'tab-pane fade ' + (this.$store.state.helpRubrique == '/about#contact' ? 'show active' : '')" id="contact" role="tabpanel"
                  aria-labelledby="nav-contact-tab">
                  <div>
                    <h2 class="title-tab mb-lg-3 mb-3">Contactez-nous</h2>
                    <p>

                      <a href="tel:+243815810397">+243 815 810 397</a>; <a
                        href="mailto:infos@schoolbac.com">infos@schoolbac.com</a>;
                      Kinshasa-Rdc pour les pays
                      Francophones.
                    </p>
                  </div>
                </div>
                <div v :class="'tab-pane fade ' + (this.$store.state.helpRubrique == '/about#mention' ? 'show active' : '')" id="mention" role="tabpanel"
                      aria-labelledby="nav-contact-tab">
                      <div>
                        <h2 class="title-tab mb-lg-3 mb-3">Condition d’utilisation</h2>
                        <p>

                          Schoolbac conçoit des technologies et des services qui
                          permettent à chacun et à
                          chacune d’entrer en contact, de former des communautés
                          scientifiques et de
                          développer les activités de leur entreprise. Les
                          présentes Conditions générales
                          régissent votre utilisation de Schoolbac.
                        </p>
                        <p>
                          Nous ne vous facturons pas l’utilisation de Schoolbac ou
                          des autres produits et
                          services inclus dans les présentes Conditions, sauf
                          indication contraire de notre
                          part. En revanche, des entreprises, des organisations,
                          des établissements
                          éducatifs et d’autres personnes nous payent pour vous
                          montrer des publicités
                          pour leurs produits et services. En utilisant nos
                          Produits, vous acceptez que nous
                          vous montrions des publicités que nous estimons
                          pertinentes pour vous en
                          fonction de vos intérêts. Nous utilisons vos données à
                          caractère personnel afin
                          de définir les publicités personnalisées à vous montrer.
                        </p>
                        <p>
                          Nous ne vendons pas vos données à caractère personnel
                          aux annonceurs et ne
                          partageons pas avec eux d’informations permettant de
                          vous identifier
                          directement (telles que votre nom, votre adresse e-mail
                          ou toutes autres
                          coordonnées), sauf autorisation expresse de votre part.
                          À la place, les
                          annonceurs nous donnent des informations, telles que le
                          type d’audience à qui
                          ils souhaitent montrer leurs publicités, et nous
                          diffusons ces publicités auprès
                          des personnes susceptibles d’être intéressées. Nous
                          envoyons aux annonceurs
                          des rapports sur les performances de leurs publicités
                          pour les aider à comprendre
                          comment les personnes interagissent avec leur contenu.
                          Consultez la section 2
                          ci-dessous pour en savoir plus sur le fonctionnement des
                          publicités
                          personnalisées sur les Produits Meta en vertu des
                          présentes Conditions.
                        </p>
                        <p>
                          Notre 
                          <a href="#">
                            Politique de confidentialité </a> explique comment
                          nous collectons et utilisons
                          vos données à caractère personnel afin de définir les
                          publicités que vous voyez
                          et de vous fournir tous les services décrits ci-dessous.
                          Vous pouvez également
                          accéder à tout moment aux pages de <a
                            href="#">paramètres</a>
                          du Produit Schoolbac
                          correspondant afin d’examiner les options de
                          confidentialité dont vous disposez
                          concernant l’utilisation que nous faisons de vos données
                        </p>
                      </div>
                </div>
                <div v :class="'tab-pane fade ' + (this.$store.state.helpRubrique == '/about#confi' ? 'show active' : '')" id="confi" role="tabpanel"
                  aria-labelledby="nav-contact-tab">
                  <div>
                    <h2 class="title-tab mb-lg-3 mb-3">Confidentialité</h2>
                    <p>
                      Chez Schoolbac, nous souhaitons que vous compreniez
                      quelles
                      informations
                      nous recueillons et comment nous les utilisons et les
                      partageons. C’est pourquoi
                      nous vous encourageons à lire notre Politique de
                      confidentialité. Cela vous
                      permet d’utiliser les 
                      <!-- <a href="https://mbasic.facebook.com/privacy/policy/?annotations%5B0%5D=0.ex.0-WhatProductsDoesThis&back_id=ref-0&entry=0">Produits</a> -->
                      <a href="#">Produits</a>
                      Schoolbac de la manière qui vous convient le
                      mieux.
                    </p>
                    <p>
                      Dans cette Politique de confidentialité, nous expliquons
                      comment nous
                      recueillons, utilisons, partageons, stockons et
                      transférons
                      les informations. Nous
                      vous faisons également savoir quels sont vos droits.
                      Chaque
                      section de la
                      Politique comprend des exemples utiles et un langage
                      simple
                      pour rendre nos
                      pratiques plus simples à comprendre. Nous avons
                      également
                      ajouté des liens
                      vers des ressources qui vous permettent d’en savoir plus
                      sur
                      les thèmes relatifs à
                      la confidentialité susceptibles de vous intéresser.
                    </p>
                    <p>
                      Il nous importe que vous sachiez comment contrôler votre
                      confidentialité, c’est
                      pourquoi nous vous montrons également où vous pouvez
                      gérer
                      vos informations
                      dans les paramètres que vous utilisez. Vous pouvez 
                      <!-- <a href="https://free.facebook.com/privacy/policy/logging/?target=POLICY_PLATFORM_LINK_COLLECTION%3APLATFORM_SETTINGS&element=privacy_policy_inline_text&redirect_uri=https%3A%2F%2Fmbasic.facebook.com%2Fprivacy%2Fpolicy%2F%3Fback_id%3Dlink-collection-0%26link_dialog%3DPLATFORM_SETTINGS%26entry%3D0">modifier
                        ces paramètres</a> -->
                      <a href="#">modifier
                        ces paramètres</a>
                      pour moduler votre expérience
                    </p>
                  </div>
                </div>
                <div v :class="'tab-pane fade ' + (this.$store.state.helpRubrique == '/about#donnees' ? 'show active' : '')" id="donnees" role="tabpanel"
                  aria-labelledby="nav-contact-tab">
                  <div>
                    <h2 class="title-tab mb-lg-3 mb-3">Données personnelles</h2>
                    <h3 class="title-tab-sm">
                      Quels types d’informations recueillons-nous ?
                    </h3>
                    <p>
                      En fonction des Services auxquels vous avez recours,
                      nous recueillons différents
                      types d’informations vous concernant ou venant de vous.
                    </p>
                    <h3 class="title-tab-sm">
                      Vos activités et les informations que vous fournissez.
                    </h3>
                    <p>
                      Nous recueillons le contenu ainsi que d’autres types
                      d’informations que vous
                      fournissez lorsque vous avez recours à nos Services,
                      notamment lorsque vous
                      créez un compte, créez ou partagez du contenu ou encore
                      lorsque vous
                      communiquez avec d’autres personnes. Ceci peut
                      comprendre des informations
                      concernant le contenu que vous partagez, telles que le
                      lieu d’une photo ou
                      encore la date à laquelle un fichier a été créé. Nous
                      recueillons également des
                      informations concernant la manière dont vous utilisez
                      nos Services, telles que
                      les types de contenu que vous consultez ou avec lesquels
                      vous interagissez, ou
                      encore la fréquence et la durée de vos activités.
                    </p>
                    <h3 class="title-tab-sm">
                      Les activités des autres personnes et les informations
                      qu’elles fournissent.
                    </h3>
                    <p>
                      Nous recueillons également les contenus et informations
                      que les autres
                      personnes fournissent lorsqu’elles ont recours à nos
                      Services, notamment des
                      informations vous concernant, par exemple lorsqu’elles
                      partagent une photo de
                      vous, vous envoient un message ou encore lorsqu’elles
                      téléchargent,
                      synchronisent ou importent vos coordonnées.
                    </p>
                    <h3 class="title-tab-sm">
                      Vos réseaux de contacts.
                    </h3>
                    <p>
                      Nous recueillons des informations sur les personnes et
                      les groupes avec lesquels
                      vous êtes en contact, ainsi que la manière dont vous
                      interagissez avec eux (par
                      exemple, les personnes avec qui vous communiquez le plus
                      ou encore les
                      groupes au sein desquels vous aimez vous exprimer). Nous
                      recueillons
                      également les coordonnées que vous téléchargez,
                      synchronisez ou importez (un
                      carnet d’adresses, par exemple) à partir d’un appareil.
                    </p>
                    <h3 class="title-tab-sm">
                      Informations relatives aux paiements.
                    </h3>
                    <p>
                      Si vous avez recours à nos Services pour effectuer un
                      achat ou une transaction
                      financière (par exemple, lorsque vous effectuez un achat
                      d’un livre sur
                      Schoolbac, ou encore lorsque vous faites un don à une
                      école), nous recueillons
                      des données concernant cet achat ou cette transaction.
                      Ceci comprend vos
                      informations de paiement, telles que le numéro de votre
                      carte de crédit ou de
                      débit et d’autres informations concernant votre carte,
                      ainsi que d’autres données
                      de compte et informations d’authentification, vos
                      données de facturation, de
                      livraison et vos coordonnées.
                    </p>
                    <h3 class="title-tab-sm">
                      Informations relatives aux paiements.
                    </h3>
                    <p>
                      Nous recueillons des informations sur les ordinateurs,
                      téléphones et autres
                      appareils sur lesquels vous installez ou utilisez nos
                      Services, en fonction des
                      autorisations que vous avez octroyées. Nous pouvons
                      rassembler les
                      informations que nous recueillons à partir de vos
                      différents appareils afin de
                      vous offrir des Services cohérents sur l’ensemble de vos
                      appareils. Voici
                      quelques exemples d’informations sur les appareils que
                      nous recueillons :
                    </p>
                    <ul class="list-desc">
                      <li>
                        <i class="bx bx-check-circle"></i>
                        Des données telles que le système d’exploitation, la
                        version du matériel,
                        les paramètres de l’appareil, les noms et les types de
                        fichier et de logiciel,
                        le niveau de la batterie et l’intensité du signal,
                        ainsi que les numéros
                        d’identification de l’appareil ;
                      </li>
                      <li>
                        <i class="bx bx-check-circle"></i>
                        Les données d’emplacement de l’appareil, notamment les
                        données
                        d’emplacement géographique précises recueillies à
                        travers les signaux
                        GPS, Bluetooth ou Wi-Fi ;
                      </li>
                      <li>
                        <i class="bx bx-check-circle"></i>
                        des informations de connexion telles que le nom de
                        votre opérateur
                        mobile ou de votre fournisseur d’accès à Internet, le
                        type de navigateur
                        que vous utilisez, votre langue et le fuseau horaire
                        dans lequel vous vous
                        situez, votre numéro de téléphone mobile et votre
                        adresse IP.
                      </li>
                    </ul>
                    <h3 class="title-tab-sm">
                      Informations provenant des sites web et des applications
                      qui ont recours à
                      nos Services.
                    </h3>
                    <p>
                      Nous recueillons des informations lorsque vous visitez
                      ou utilisez des sites web
                      et des applications de tiers qui ont recours à nos
                      Services (par exemple,
                      lorsqu’ils incluent nos boutons J’aime ou Se connecter
                      avec Schoolbac, ou
                      encore lorsqu’ils font appel à nos services de mesure et
                      de publicité). Ceci
                      comprend des informations sur les sites web et les
                      applications que vous
                      consultez, votre utilisation de nos Services sur ces
                      sites web et applications,ainsi que les informations que
                      le développeur ou l’éditeur de l’application ou du
                      site web partagent avec vous ou avec nous.
                    </p>
                    <h3 class="title-tab-sm">
                      Informations provenant de partenaires tiers.
                    </h3>
                    <p>
                      Nous recevons des informations sur vous et vos
                      activités, sur et en dehors de
                      Schoolbac, en provenance de nos partenaires tiers,
                      telles que des informations
                      provenant de partenaires avec qui nous avons collaboré
                      pour offrir un service,
                      ou encore d’annonceurs au sujet de votre expérience ou
                      de votre interaction avec
                      ce dernier.
                    </p>
                  </div>
                </div>
                <div v :class="'tab-pane fade ' + (this.$store.state.helpRubrique == '/about#signal' ? 'show active' : '')" id="signal" role="tabpanel"
                  aria-labelledby="nav-contact-tab">
                  <div>
                    <h2 class="title-tab mb-lg-3 mb-3">Signaler un contenu</h2>
                  
                    <p>
                      Lorsqu'un contenu est signalé à Schoolbac, <strong>nous l'examinons et nous prenons
                        des mesures sur tout ce qui, selon nous, enfreint nos Standards de la
                        communauté</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer id="footer">
        <div class="container">
          <div class="row g-4">
            <div class="col-lg-3 col-6 col-sm-3 col-md-3">
              <div class="text-star">
                <h5>SchoolBac</h5>
                <ul>
                  <li>
                    <router-link to="/about" class="btn" @click.prevent="this.gb_helpRubrique('/about#descr')">
                      Description plateforme
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/about" class="btn" @click.prevent="this.gb_helpRubrique('/about#int_ped')">
                      Intérêt Pédagogique
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/about" class="btn" @click.prevent="this.gb_helpRubrique('/about#int_sci')">
                      Intérêt scientifique
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/about" class="btn" @click.prevent="this.gb_helpRubrique('/about#prod')">
                      Produits
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-6 col-sm-3 col-md-3">
              <div class="text-star">
                <h5>Aide & Details</h5>
                <ul>
                  <li>
                    <router-link to="/about" class="btn" @click.prevent="this.gb_helpRubrique('/about#apropos')">
                      A propos
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/about" class="btn" @click.prevent="this.gb_helpRubrique('/about#histo')">
                      Historique
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/about" class="btn" @click="this.gb_helpRubrique('/about#descr')">
                      Faire la publicité
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/about" class="btn" @click="this.gb_helpRubrique('/about#contact')">
                      Contactez-nous
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-6 col-sm-3 col-md-3">
              <div class="text-star">
                <h5>Mention Légale</h5>
                <ul>
                  <li>
                    <router-link to="/about" class="btn" @click="this.gb_helpRubrique('/about#mention')">
                      Condition d’utilisation
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/about" class="btn" @click="this.gb_helpRubrique('/about#confi')">
                      Confidentialité
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/about" class="btn" @click="this.gb_helpRubrique('/about#donnees')">
                      Données personnelles
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/about" class="btn" @click="this.gb_helpRubrique('/about#signal')">
                      Signaler un contenu
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-6 col-sm-3 col-md-3">
              <div class="text-star">
                <h5>Langues</h5>
                <ul>
                  <li>
                    <a href="#">
                      Français
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Anglais
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="text-center">
            <button class="btn btn-top">
              <i class="bx bx-up-arrow-alt"></i>
            </button>
            <small>Tout droit reservé © Copyright 2022 </small>
          </div>
        </div>
      </footer>
    </div>
  </div>

</template>

<script>
export default {
  name: 'HelpPage',

  data() {
    return {
      user: null,
      requesting: false,
    };
  },
}
</script>
