<template>
  <ul v-if="!this.gb_viewUserOrOtherUser($route) && this.$store.state.user_auth != null && this.$store.state.user_auth.posterUser != null && this.$store.state.user_auth.posterUser != ''" class="list-nav">
    <li>
        <router-link  to="/about-user"  :class="($route.path == '/about-user' ? 'active' : '')"> 
          Vue d'ensemble
        </router-link>
    </li>
    <li>
      <router-link  to="/about-user-education" :class="($route.path == '/about-user-education' ? 'active' : '')">
        Emploi et scolarité
      </router-link>
    </li>
    <li>
      <router-link to="/about-user-places"  :class="($route.path == '/about-user-places' ? 'active' : '')">
      Lieux de résidence
      </router-link>
    </li>
    <li>
        <router-link to="/about-user-infos" :class="($route.path == '/about-user-infos' ? 'active' : '')">
          Informations générales et coordonnées
        </router-link>
    </li>
  </ul>

  <ul v-if="this.gb_viewUserOrOtherUser($route) && this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.codingUserCamarade != null && this.$store.state.user_auth_other.codingUserCamarade.trim() != '' && this.$store.state.params == $route.params.friend"  class="list-nav">
    <li>
        <router-link   :to="'/profil-about-user/' + $route.params.friend"  :class="($route.path == '/profil-about-user/' + $route.params.friend ? 'active' : '')"> 
          Vue d'ensemble
        </router-link>
    </li>
    <li>
      <router-link  :to="'/profil-about-user-education/'+ $route.params.friend" :class="($route.path == '/profil-about-user-education/'+ $route.params.friend ? 'active' : '')">
        Emploi et scolarité
      </router-link>
    </li>
    <li>
      <router-link :to="'/profil-about-user-places/'+ $route.params.friend"  :class="($route.path == '/profil-about-user-places/'+ $route.params.friend ? 'active' : '')">
      Lieux de résidence
      </router-link>
    </li>
    <li>
        <router-link :to="'/profil-about-user-infos/'+ $route.params.friend" :class="($route.path == '/profil-about-user-infos/' + $route.params.friend ? 'active' : '')">
          Informations générales et coordonnées
        </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'LinkImportantUser',
}
</script>
