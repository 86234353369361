import Home from './components/Home.vue'
import ViewUser from './components/user/ViewUser.vue'
import ViewOtherUser from './components/user/ViewOtherUser.vue'
import Login from './components/Login.vue'
import VerifConnexion from './components/VerifConnexion.vue'
import ResetPassword from './components/ResetPassword.vue'
import ConfirmCompte from './components/ConfirmCreateCompte.vue'
import Register from './components/Register.vue'
import RegisterToComplete from './components/RegisterToComplete.vue'
import Index from './components/Index.vue'
import Help from './components/Help.vue'
import { createRouter, createWebHistory } from 'vue-router'
const routes = [{
    name: "LoadPage",
    component: Index,
    path: '/'
},  {
    name: "about",
    component: Help,
    path: '/about'
},{
    name: "Home",
    component: Home,
    path: '/home'
}, {
    name: "Login",
    component: Login,
    path: '/login'
}, {
    name: "VerifConnexion",
    component: VerifConnexion,
    path: '/verif-connexion'
}, {
    name: "RestPassword",
    component: ResetPassword,
    path: '/reset-password'
}, {
    name: "Confirm",
    component: ConfirmCompte,
    path: '/confirm'
}, {
    name: "Register",
    component: Register,
    path: '/register'
}, {
    name: "RegisterToComplete",
    component: RegisterToComplete,
    path: '/register_complete'
}, {
    name: "AboutUser",
    component: ViewUser,
    path: '/about-user'
}, {
    name: "AboutUserEducation",
    component: ViewUser,
    path: '/about-user-education'
}, {
    name: "AboutUserPlaces",
    component: ViewUser,
    path: '/about-user-places'
}, {
    name: "AboutUserBasicInfos",
    component: ViewUser,
    path: '/about-user-infos'
}, {
    name: "VideosUser",
    component: ViewUser,
    path: '/videos-user'
}, {
    name: "FriendsUser",
    component: ViewUser,
    path: '/friends-user'
}, {
    name: "PicturesUser",
    component: ViewUser,
    path: '/pictures-user'
}, {
    name: "PostersUser",
    component: ViewUser,
    path: '/posters-user'
}, {
    name: "AllViewFriendsDemands",
    component: ViewUser,
    path: '/all-view-friends-demands'
}, {
    name: "AllViewFriendsSuggestions",
    component: ViewUser,
    path: '/all-view-friends-suggestions'
}, {
    name: "AllViewFriendsBirthday",
    component: ViewUser,
    path: '/all-view-friends-birthday'
}, {
    name: "Camarade",
    component: ViewOtherUser,
    path: '/profil-user/:friend'
}, {
    name: "CamaradePicture",
    component: ViewOtherUser,
    path: '/profil-pictures-user/:friend'
}, {
    name: "CamaradeFriend",
    component: ViewOtherUser,
    path: '/profil-friends-user/:friend'
}, {
    name: "CamaradeAboutUserPlaces",
    component: ViewOtherUser,
    path: '/profil-about-user-places/:friend'
}, {
    name: "CamaradeAboutUser",
    component: ViewOtherUser,
    path: '/profil-about-user/:friend'
}, {
    name: "CamaradeAboutUserEducation",
    component: ViewOtherUser,
    path: '/profil-about-user-education/:friend'
}, {
    name: "CamaradeAboutUserBasicInfos",
    component: ViewOtherUser,
    path: '/profil-about-user-infos/:friend'
}, ];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});
export default router;
// ViewOtherUser