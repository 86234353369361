<template>
    <div class="global-div">
      <div class="wrapper">
        <header class="fixed-top header-app">
          <div class="topbar">
            <div class="container-fluid px-lg-3">
              <div class="row">
                <div class="col-lg-3 d-flex ps-0">
                  <div class="menu">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <div class="col-lg-6 d-flex align-items-center justify-content-center">
                  <ul>
                    <li><a href="#">Ecole</a></li>
                    <li><a href="#">Institut supérieur</a></li>
                    <li><a href="#">Université</a></li>
                  </ul>
                </div>
                <div class="col-lg-3 d-flex align-items-center justify-content-end pe-0">
                  <div class="user-conv">
                    <div class="bling"></div>
                    <i class="bx bx-chat"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <nav class="navbar navbar-expand-lg nav-app">
            <div class="container-fluid px-lg-3">
              <div class="row w-100 ms-0 align-items-center">
                <div class="col-lg-3">
                  <div class="block-logo">
                    <a class="navbar-brand" href="#">
                      <img src="assets/images/logo-app.png" alt="" />
                    </a>
                    <div class="block-search d-flex align-items-center">
                      <i class="bx bx-search"></i>
                      <input type="text" class="form-control" placeholder="Recherche...">
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 d-flex justify-content-center">
                  <ul class="navbar-nav mb-lg-0">
    
                    <li class="nav-item">
                      <a class="nav-link active" href="#">
                        <i class="bx bx-home-alt-2 icon-unactive"></i>
                        <i class="bx bxs-home-alt-2 icon-active"></i>
                      </a>
                      <div class="indicator">
                        Fil d'actualité
                      </div>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">
                        <i class="bx bx-book-open"></i>
                      </a>
                      <div class="indicator">
                        Cours
                      </div>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">
                        <i class="bx bx-movie-play"></i>
                      </a>
                      <div class="indicator">
                        Vidéos
                      </div>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">
                        <i class="bx bx-book"></i>
                      </a>
                      <div class="indicator">
                        Biblothèque
                      </div>
                    </li>
    
                  </ul>
                </div>
                <div class="col-lg-3">
                  <div class="block-user d-flex">
                    <div class="block-tools">
                      <div class="item-tool">
                        <a href="#">
                          <i class="bx bxs-user-plus"></i>
                          <div class="notif">9+</div>
                        </a>
                        <div class="block-drop">
                          <div class="header-drop">
                            <h5>Demandes d'amis</h5>
                            <div class="block-btns">
                              <div class="btn-icon">
                                <i class="bx bx-search"></i>
                              </div>
                            </div>
                          </div>
                          <div class="body-message">
                            <ul>
                              <li>
                                <div class="block-items">
                                  <div class="avatar-message">
                                    <img src="assets/images/team1.jpg" alt="" />
                                  </div>
                                  <div class="block-info-message">
                                    <a href="#">
                                      <h6>John Doe</h6>
                                    </a>
                                    <p>Vous a envoyé un demande d'ami</p>
                                    <a href="#" class="btn btn-add">Accepter</a>
                                    <a href="#" class="btn btn-annule">Annuler</a>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="block-items">
                                  <div class="avatar-message">
                                    <img src="assets/images/team1.jpg" alt="" />
                                  </div>
                                  <div class="block-info-message">
                                    <a href="#">
                                      <h6>John Doe</h6>
                                    </a>
                                    <p>Vous a envoyé un demande d'ami</p>
                                    <a href="#" class="btn btn-add">Accepter</a>
                                    <a href="#" class="btn btn-annule">Annuler</a>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="block-items">
                                  <div class="avatar-message">
                                    <img src="assets/images/team1.jpg" alt="" />
                                  </div>
                                  <div class="block-info-message">
                                    <a href="#">
                                      <h6>John Doe</h6>
                                    </a>
                                    <p>Vous a envoyé un demande d'ami</p>
                                    <a href="#" class="btn btn-add">Accepter</a>
                                    <a href="#" class="btn btn-annule">Annuler</a>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="block-items">
                                  <div class="avatar-message">
                                    <img src="assets/images/team1.jpg" alt="" />
                                  </div>
                                  <div class="block-info-message">
                                    <a href="#">
                                      <h6>John Doe</h6>
                                    </a>
                                    <p>Vous a envoyé un demande d'ami</p>
                                    <a href="#" class="btn btn-add">Accepter</a>
                                    <a href="#" class="btn btn-annule">Annuler</a>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <a href="#" class="btn">Toutes les demandes</a>
                        </div>
                      </div>
                      <div class="item-tool">
                        <a href="#">
                          <div class="notif">9+</div>
                          <i class="bx bxs-message"></i>
                        </a>
                        <div class="block-drop">
                          <div class="header-drop">
                            <h5>Messages</h5>
                            <div class="block-btns">
                              <div class="btn-icon">
                                <i class="bx bx-search"></i>
                              </div>
                              <div class="btn-icon">
                                <i class="bx bx-message-alt-edit"></i>
                              </div>
                            </div>
                          </div>
                          <div class="body-message">
                            <ul>
                              <li>
                                <div class="block-items">
                                  <div class="avatar-message">
                                    <img src="assets/images/team1.jpg" alt="" />
                                  </div>
                                  <div class="block-info-message">
                                    <h6>John Doe</h6>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, quas.</p>
                                    <span class="date">Il ya 2min</span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="block-items">
                                  <div class="avatar-message">
                                    <img src="assets/images/team1.jpg" alt="" />
                                  </div>
                                  <div class="block-info-message">
                                    <h6>John Doe</h6>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, quas.</p>
                                    <span class="date">Il ya 2min</span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="block-items">
                                  <div class="avatar-message">
                                    <img src="assets/images/team1.jpg" alt="" />
                                  </div>
                                  <div class="block-info-message">
                                    <h6>John Doe</h6>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, quas.</p>
                                    <span class="date">Il ya 2min</span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="block-items">
                                  <div class="avatar-message">
                                    <img src="assets/images/team1.jpg" alt="" />
                                  </div>
                                  <div class="block-info-message">
                                    <h6>John Doe</h6>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, quas.</p>
                                    <span class="date">Il ya 2min</span>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <a href="#" class="btn">Tous les messages</a>
                        </div>
                      </div>
                      <div class="item-tool">
                        <a href="#">
                          <div class="notif">3</div>
                          <i class="bx bxs-bell"></i>
                        </a>
                        <div class="block-drop">
                          <div class="header-drop">
                            <h5>Notifications</h5>
    
                          </div>
                          <div class="body-message">
                            <ul>
                              <li>
                                <div class="block-items">
                                  <div class="avatar-message">
                                    <img src="assets/images/team1.jpg" alt="" />
                                  </div>
                                  <div class="block-info-message">
                                    <h6>John Doe</h6>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, quas.</p>
                                    <span class="date">Il ya 2min</span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="block-items">
                                  <div class="avatar-message">
                                    <img src="assets/images/team1.jpg" alt="" />
                                  </div>
                                  <div class="block-info-message">
                                    <h6>John Doe</h6>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, quas.</p>
                                    <span class="date">Il ya 2min</span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="block-items">
                                  <div class="avatar-message">
                                    <img src="assets/images/team1.jpg" alt="" />
                                  </div>
                                  <div class="block-info-message">
                                    <h6>John Doe</h6>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, quas.</p>
                                    <span class="date">Il ya 2min</span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="block-items">
                                  <div class="avatar-message">
                                    <img src="assets/images/team1.jpg" alt="" />
                                  </div>
                                  <div class="block-info-message">
                                    <h6>John Doe</h6>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, quas.</p>
                                    <span class="date">Il ya 2min</span>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <a href="#" class="btn">Toutes les notifications</a>
                        </div>
                      </div>
                    </div>
                    <div class="block-avatar-user-nav">
                      <div class="avatar">
                        <img src="assets/images/team.jpg" alt="">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    
    
            </div>
          </nav>
        </header>
        <div class="block-app">
          <div class="container-fluid px-lg-3">
            <div class="row justify-content-center g-lg-5 g-3">
              <div class="col-lg-3">
                <div class="block-fixed block-fixed-left">
                  <div class="card card-user">
                    <div class="block-avatar-card-user">
                      <div class="avatar">
                        <img src="assets/images/team.jpg" alt="">
                      </div>
                    </div>
                    <div class="block-name-user text-center">
                      <a href="#">
                        <h5 class="name-user">John doe</h5>
                      </a>
                      <p class="type-user">Etudiant</p>
                    </div>
                    <div class="block-profil-progress">
                      <div class="circle">
                        <h6>30<span>%</span></h6>
                      </div>
                      <div class="profil-etat">
                        <div class="block-etat bad">
                          <!-- <img src="assets/images/star.png" alt=""> -->
                        <img src="assets/images/team.jpg" alt="">
                          <!-- <span>Médiocre</span> -->
                          <span>Profil Incomplet</span>
                        </div>
                        <a href="#"  class="link">Compléter Votre profil</a>
                      </div>
                    </div>
                  </div>
                  <div class="card card-calendar">
                    <div class="header-calendar">
                      <p class="currentDate">Octobre 2022</p>
                      <div class="block-chevron">
                        <span>
                          <i class="bx bx-chevron-left"></i>
                        </span>
                        <span>
                          <i class="bx bx-chevron-right"></i>
                        </span>
                      </div>
                    </div>
                    <div class="body-calendar">
                      <ul class="weeks">
                        <li>Di</li>
                        <li>Lu</li>
                        <li>Ma</li>
                        <li>Me</li>
                        <li>Je</li>
                        <li>Ve</li>
                        <li>Sa</li>
                      </ul>
                      <ul class="days">
                        <li class="unactive">28</li>
                        <li class="unactive">29</li>
                        <li class="unactive">30</li>
                        <li class="unactive">31</li>
                        <li>1</li>
                        <li>2</li>
                        <li>3</li>
                        <li>4</li>
                        <li class="active">5</li>
                        <li>6</li>
                        <li>7</li>
                        <li>8</li>
                        <li>9</li>
                        <li>10</li>
                        <li>11</li>
                        <li>12</li>
                        <li>13</li>
                        <li>14</li>
                        <li>15</li>
                        <li>16</li>
                        <li>17</li>
                        <li>18</li>
                        <li>19</li>
                        <li>20</li>
                        <li>21</li>
                        <li>22</li>
                        <li>23</li>
                        <li>24</li>
                        <li>25</li>
                        <li>26</li>
                        <li>27</li>
                        <li>28</li>
                        <li>29</li>
                        <li>30</li>
                        <li class="unactive">1</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-xl-6 col-xxl-6">
    
                <div class="card card-statut">
                  <div class="d-flex justify-content-between align-items-center block">
                    <h5 class="title-card">
                      Recent Stories
                    </h5>
                    <a href="#" class="like-more">Voir plus</a>
                  </div>
                  <div class="btn btn-scroll">
                    <i class="bx bx-chevron-right"></i>
                  </div>
                  <div class="block-statut">
                    <div class="item-statut add">
                      <div class="block-add-statut">
                        <div class="icon">
                          <i class="bx bx-plus"></i>
                        </div>
                        <p>Ajouter un story</p>
                      </div>
                    </div>
                    <div class="item-statut">
                      <div class="block-avatar-post">
                        <a href="#">
                          <div class="avatar">
                            <img src="assets/images/team.jpg" alt="">
                          </div>
                        </a>
                      </div>
                      <div class="block-img-story">
                        <img src="assets/images/cel2.jpg" alt="">
                        <div class="name-user">
                          John doe
                        </div>
                      </div>
                    </div>
                    <div class="item-statut">
                      <div class="block-avatar-post">
                        <a href="#">
                          <div class="avatar">
                            <img src="assets/images/team.jpg" alt="">
                          </div>
                        </a>
                      </div>
                      <div class="block-img-story">
                        <img src="assets/images/cel3.jpg" alt="">
                        <div class="name-user">
                          John doe
                        </div>
                      </div>
                    </div>
                    <div class="item-statut">
                      <div class="block-avatar-post">
                        <a href="#">
                          <div class="avatar">
                            <img src="assets/images/team.jpg" alt="">
                          </div>
                        </a>
                      </div>
                      <div class="block-img-story">
                        <div class="block-avatar-post">
                          <a href="#">
                            <div class="avatar">
                              <img src="assets/images/team.jpg" alt="">
                            </div>
                          </a>
                        </div>
                        <img src="assets/images/bg-5.jpg" alt="">
                        <div class="name-user">
                          John doe
                        </div>
                      </div>
                    </div>
                    <div class="item-statut">
                      <div class="block-avatar-post">
                        <a href="#">
                          <div class="avatar">
                            <img src="assets/images/team.jpg" alt="">
                          </div>
                        </a>
                      </div>
                      <div class="block-img-story">
                        <img src="assets/images/bg-5.jpg" alt="">
                        <div class="name-user">
                          John doe
                        </div>
                      </div>
                    </div>
                    <div class="item-statut">
                      <div class="block-avatar-post">
                        <a href="#">
                          <div class="avatar">
                            <img src="assets/images/team.jpg" alt="">
                          </div>
                        </a>
                      </div>
                      <div class="block-img-story">
                        <img src="assets/images/1.jpg" alt="">
                        <div class="name-user">
                          John doe
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card card-post">
                  <h5 class="title-card text-center">
                    Create New Post
                  </h5>
                  <div class="form-group row">
                    <div class="col-12 position-relative">
                      <div class="block-avatar-user-post">
                        <div class="avatar">
                          <img src="assets/images/team.jpg" alt="">
                        </div>
                      </div>
                      <textarea name="" id="" cols="30" rows="10" class="form-control"
                        placeholder="Salut la joie, Quoi de neuf ?"></textarea>
                    </div>
                  </div>
                  <div class="block-tool-post justify-content-center">
                    <div class="items">
                      <a href="#">
                        <i class="bx bx-image"></i>
                        <span>Photo/Vidéo</span>
                      </a>
                    </div>
                    <div class="items">
                      <a href="#">
                        <i class="bx bx-smile"></i>
                        <span>Feeling/Activity</span>
                      </a>
                    </div>
                    <div class="items">
                      <a href="#">
                        <i class="bx bx-video"></i>
                        <span>Live Stream</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="card card-post-actu">
                  <div class="block-header-post">
                    <div class="block-header-avatar">
                      <div class="avatar-post">
                        <span class="etat"></span>
                        <img src="assets/images/team.jpg" alt="">
                      </div>
                      <div class="block-user-post">
                        <h6><a href="#">Peter kalala</a> <span>a publié un photo</span></h6>
                        <p>
                          <i class="bx bx-globe"></i>
                          <span>Public:</span>
                          <span class="date">Oct,05 2020</span>
                        </p>
                      </div>
                    </div>
                    <div class="block-option">
                      <div class="block-dots">
                        <i class="bx bx-dots-vertical-rounded"></i>
                      </div>
                    </div>
                  </div>
                  <div class="block-body-post">
                    <div class="block-img-post">
                      <img src="assets/images/3.jpg" alt="">
                    </div>
                    <div class="block-content-text">
                      <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eos provident quisquam reiciendis
                        corporis impedit placeat inventore. Enim fuga soluta accusamus.</p>
                    </div>
                    <div class="block-footer-post">
                      <div class="block-left">
                        <a href="#" class="like">
                          <i class="bx bx-heart"></i>
                          <span>18</span>
                        </a>
                        <div class="block-users-friends">
                          <a href="#">
                            <div class="avatar-user">
                              <img src="assets/images/team1.jpg" alt="">
                            </div>
                          </a>
                          <a href="#">
                            <div class="avatar-user">
                              <img src="assets/images/team1.jpg" alt="">
                            </div>
                          </a>
                          <a href="#">
                            <div class="avatar-user">
                              <img src="assets/images/team1.jpg" alt="">
                            </div>
                          </a>
                          <a href="#">
                            <div class="avatar-user">
                              <img src="assets/images/team1.jpg" alt="">
                            </div>
                          </a>
                          <a href="#">
                            <div class="avatar-user">
                              <img src="assets/images/team1.jpg" alt="">
                            </div>
                          </a>
                        </div>
                        <div class="block-likes">
                          <a href="#">Vous</a>,
                          <a href="#">Emilie</a> et <br> 22 autres aimés ça
                        </div>
                      </div>
                      <div class="block-right">
                        <a href="#" class="comment-like">
                          <i class="bx bx-comment"></i>
                          <span>22</span>
                        </a>
                        <a href="#" class="share-like">
                          <i class="bx bx-share-alt"></i>
                          <span>22</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card card-post-actu">
                  <div class="block-header-post">
                    <div class="block-header-avatar">
                      <div class="avatar-post">
                        <img src="assets/images/team.jpg" alt="">
                      </div>
                      <div class="block-user-post">
                        <h6><a href="#">Peter kalala</a></h6>
                        <p>
                          <i class="bx bx-globe"></i>
                          <span>Public:</span>
                          <span class="date">Oct,05 2020</span>
                        </p>
                      </div>
                    </div>
                    <div class="block-option">
                      <div class="block-dots">
                        <i class="bx bx-dots-vertical-rounded"></i>
                      </div>
                    </div>
                  </div>
                  <div class="block-body-post">
                    <div class="block-content-text">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi ipsam placeat sapiente totam dicta,
                        laboriosam eveniet repudiandae dolorem ex consequatur vero commodi esse doloremque excepturi impedit
                        possimus, eius
                        incidunt. Voluptatem neque praesentium esse aperiam iste dolorem sint. Obcaecati, eos repudiandae..
                      </p>
                    </div>
                    <div class="block-footer-post">
                      <div class="block-left">
                        <a href="#" class="like">
                          <i class="bx bx-heart"></i>
                          <span>18</span>
                        </a>
                        <div class="block-users-friends">
                          <a href="#">
                            <div class="avatar-user">
                              <img src="assets/images/team1.jpg" alt="">
                            </div>
                          </a>
                          <a href="#">
                            <div class="avatar-user">
                              <img src="assets/images/team1.jpg" alt="">
                            </div>
                          </a>
                          <a href="#">
                            <div class="avatar-user">
                              <img src="assets/images/team1.jpg" alt="">
                            </div>
                          </a>
                          <a href="#">
                            <div class="avatar-user">
                              <img src="assets/images/team1.jpg" alt="">
                            </div>
                          </a>
                          <a href="#">
                            <div class="avatar-user">
                              <img src="assets/images/team1.jpg" alt="">
                            </div>
                          </a>
                        </div>
                        <div class="block-likes">
                          <a href="#">Vous</a>,
                          <a href="#">Emilie</a> et <br> 22 autres aimés ça
                        </div>
                      </div>
                      <div class="block-right">
                        <a href="#" class="comment-like">
                          <i class="bx bx-comment"></i>
                          <span>22</span>
                        </a>
                        <a href="#" class="share-like">
                          <i class="bx bx-share-alt"></i>
                          <span>22</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card card-post-actu">
                  <div class="block-header-post">
                    <div class="block-header-avatar">
                      <div class="avatar-post">
                        <span class="etat"></span>
                        <img src="assets/images/team.jpg" alt="">
                      </div>
                      <div class="block-user-post">
                        <h6><a href="#">Peter kalala</a> <span>a publié une vidéo</span></h6>
                        <p>
                          <i class="bx bx-globe"></i>
                          <span>Public:</span>
                          <span class="date">Oct,05 2020</span>
                        </p>
                      </div>
                    </div>
                    <div class="block-option">
                      <div class="block-dots">
                        <i class="bx bx-dots-vertical-rounded"></i>
                      </div>
                    </div>
                  </div>
                  <div class="block-body-post">
                    <div class="block-img-post block-video-post">
                      <img src="assets/images/4.jpg" alt="">
                      <a href="#" class="btn-play">
                        <i class="bx bx-play"></i>
                      </a>
                    </div>
                    <div class="block-content-text">
                      <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eos provident quisquam reiciendis
                        corporis impedit placeat inventore. Enim fuga soluta accusamus.</p>
                    </div>
                    <div class="block-footer-post">
                      <div class="block-left">
                        <a href="#" class="like">
                          <i class="bx bx-heart"></i>
                          <span>18</span>
                        </a>
                        <div class="block-users-friends">
                          <a href="#">
                            <div class="avatar-user">
                              <img src="assets/images/team1.jpg" alt="">
                            </div>
                          </a>
                          <a href="#">
                            <div class="avatar-user">
                              <img src="assets/images/team1.jpg" alt="">
                            </div>
                          </a>
                          <a href="#">
                            <div class="avatar-user">
                              <img src="assets/images/team1.jpg" alt="">
                            </div>
                          </a>
                          <a href="#">
                            <div class="avatar-user">
                              <img src="assets/images/team1.jpg" alt="">
                            </div>
                          </a>
                          <a href="#">
                            <div class="avatar-user">
                              <img src="assets/images/team1.jpg" alt="">
                            </div>
                          </a>
                        </div>
                        <div class="block-likes">
                          <a href="#">Vous</a>,
                          <a href="#">Emilie</a> et <br> 22 autres aimés ça
                        </div>
                      </div>
                      <div class="block-right">
                        <a href="#" class="comment-like">
                          <i class="bx bx-comment"></i>
                          <span>22</span>
                        </a>
                        <a href="#" class="share-like">
                          <i class="bx bx-share-alt"></i>
                          <span>22</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="block-fixed block-fixed-right">
                  <div class="card card-sugg">
                    <div class="block-header">
                      <h5>Amis suggérés</h5>
                      <a href="#">Voir plus</a>
                    </div>
                    <div class="block-sugg">
                      <div class="items-block">
                        <div class="avatar-friend">
                          <img src="assets/images/team.jpg" alt="">
                        </div>
                        <div class="block-name-friend d-flex w-100 align-items-center justify-content-start">
                          <div class="block-content">
                            <a href="#">
                              <h6>Evariste Kabongo</h6>
                            </a>
                            <p>Pas d'amis en commun</p>
                          </div>
                          <a href="#" class="add ms-auto"><i class="bx bx-user-plus"></i></a>
                        </div>
                      </div>
                    </div>
                    <div class="block-sugg">
                      <div class="items-block">
                        <div class="avatar-friend">
                          <img src="assets/images/team.jpg" alt="">
                        </div>
                        <div class="block-name-friend d-flex w-100 align-items-center justify-content-start">
                          <div class="block-content">
                            <a href="#">
                              <h6>Evariste Kabongo</h6>
                            </a>
                            <p>Pas d'amis en commun</p>
                          </div>
                          <a href="#" class="add ms-auto"><i class="bx bx-user-plus"></i></a>
                        </div>
                      </div>
                    </div>
                    <div class="block-sugg">
                      <div class="items-block">
                        <div class="avatar-friend">
                          <img src="assets/images/team.jpg" alt="">
                        </div>
                        <div class="block-name-friend d-flex w-100 align-items-center justify-content-start">
                          <div class="block-content">
                            <a href="#">
                              <h6>Evariste Kabongo</h6>
                            </a>
                            <p>Pas d'amis en commun</p>
                          </div>
                          <a href="#" class="add ms-auto"><i class="bx bx-user-plus"></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card card-sugg">
                    <div class="block-header">
                      <h5>Anniversaires</h5>
                      <a href="#">Voir plus</a>
                    </div>
                    <div class="block-sugg">
                      <div class="items-block items-anniv">
                        <div class="avatar-friend">
                          <i class="bx bx-cake"></i>
                        </div>
                        <div class="block-name-friend">
                          <a href="#">
                            C'est l'anniversaire de <span class="user-anniv">Estalla Nguma et 8 autres personnes</span>
                            aujourd'hui
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card card-sugg">
                    <div class="block-header">
                      <h5>Groupes suggérés</h5>
                      <a href="#">Voir plus</a>
                    </div>
                    <div class="block-sugg">
                      <div class="items-block items-group">
                        <div class="banner-group"></div>
                        <div class="d-flex block-group-info">
                          <div class="avatar-friend">
                            <img src="assets/images/team.jpg" alt="">
                          </div>
                          <div class="block-name-friend">
                            <a href="#">
                              <h6>Evariste Kabongo</h6>
                            </a>
                            <p>120 Membres</p>
                            <a href="#" class="add"><i class="bx bx-plus"></i> Ajouter</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a href="#" class="btn-float">
            <i class="bx bx-message-alt-edit"></i>
          </a>
    
        </div>
      </div>
    </div>
    <div class="block-all-discussion">
      <div class="header-block">
        <h5>Discussions</h5>
      </div>
      <div class="block-contact">
        <div class="btn-open-menu">
          <i class="bx bx-chevron-left"></i>
        </div>
        <div class="header d-flex align-items-center justify-content-between">
          <h6>Contacts</h6>
          <span class="num">(100)</span>
        </div>
        <div class="body-contact">
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>Emilie Smith</h6>
              <p>Contecté</p>
            </div>
            <div class="statut online">
            </div>
          </div>
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team1.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>John Smith</h6>
              <p>Déconnecté</p>
            </div>
            <div class="statut offline">
            </div>
          </div>
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>Emilie Smith</h6>
              <p>Contecté</p>
            </div>
            <div class="statut online">
            </div>
          </div>
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team1.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>John Smith</h6>
              <p>Déconnecté</p>
            </div>
            <div class="statut offline">
            </div>
          </div>
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>Emilie Smith</h6>
              <p>Contecté</p>
            </div>
            <div class="statut online">
            </div>
          </div>
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team1.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>John Smith</h6>
              <p>Déconnecté</p>
            </div>
            <div class="statut offline">
            </div>
          </div>
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>Emilie Smith</h6>
              <p>Contecté</p>
            </div>
            <div class="statut online">
            </div>
          </div>
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team1.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>John Smith</h6>
              <p>Déconnecté</p>
            </div>
            <div class="statut offline">
            </div>
          </div>
        </div>
        <div class="header d-flex align-items-center justify-content-between">
          <h6>Groupes</h6>
          <span class="num">(100)</span>
        </div>
        <div class="body-contact">
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>Emilie Smith</h6>
            </div>
            <div class="statut online">
            </div>
          </div>
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team1.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>John Smith</h6>
            </div>
            <div class="statut offline">
            </div>
          </div>
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>Emilie Smith</h6>
            </div>
            <div class="statut online">
            </div>
          </div>
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team1.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>John Smith</h6>
            </div>
            <div class="statut offline">
            </div>
          </div>
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>Emilie Smith</h6>
            </div>
            <div class="statut online">
            </div>
          </div>
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team1.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>John Smith</h6>
            </div>
            <div class="statut offline">
            </div>
          </div>
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>Emilie Smith</h6>
            </div>
            <div class="statut online">
            </div>
          </div>
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team1.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>John Smith</h6>
            </div>
            <div class="statut offline">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="back-drop"></div>
    <div class="block-add-story">
      <div class="card card-post-statut">
        <div class="header-card">
          <h5>Nouveau statut</h5>
        </div>
        <form action="">
          <div class="body-card">
            <div class="content-text">
              <textarea name="" id="" cols="30" rows="10" class="form-control"
                placeholder="Evrivez votre statut"></textarea>
            </div>
          </div>
          <div class="footer-card">
            <button class="btn btn-post">
              Poster
            </button>
          </div>
        </form>
      </div>
    </div>
</template>

<script>
// import Header from './layouts/Header.vue';
// import Sidebar from './layouts/Sidebar.vue';

// import axios from 'axios';
import { useToast } from "vue-toastification";

import moment from 'moment';
import "moment/locale/fr";

export default {
  name: 'HomePage',
  // components: {
  //   Header,
  //   Sidebar,
  // },
  data() {
    return {
      user: null,
      Allcitations: [],
      Allsondages: [],
      Allnews: [],
      AllNumberCountUsers: 0,
      form: {
        code: null
      },
      requesting: false,
      requestingText: "Chargement des données",
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  mounted() {

    this.gb_loadReFreshUser();

    this.requesting = true;
    this.load();
    this.requesting = false;

    setInterval(() => {
      this.load();
    }, 60000);
  },

  methods: {
    load() {
      this.requesting = true;
      this.requesting = false;
    },

    format_date(value) {
      moment.locale("fr");
      if (value != null && value != "") {
        if (value) {
          return moment(value, "YYYYMMDDHHSS").fromNow();
        }
      }
    },

    format_Minutes(value) {
      if (value != null && value != "") {
        var date = moment(new Date(value))
        if (date) {
          return parseInt(date.diff(new Date(), 'minutes'));
        }
      }
    },
  },

}
</script>
