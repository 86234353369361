<template>
  <div v-if="$route.path == '/posters-user' && this.publishings.length != 0 && this.publishings_view.length != 0 && !requesting">
    <div v-for="(pub, index) in publishings_view" :key="index" class="card card-post-actu">
      <div class="block-header-post">
        <div class="block-header-avatar">
          <div class="avatar-post">
            <span class="etat"></span>
            <img
              v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.avatarUser != null && this.$store.state.user_auth.avatarUser.trim() != ''"
              :src="this.$store.state.user_auth.avatarUser" alt="">
          </div>
          <div class="block-user-post">
            <h6 v-if="pub.legende != null && pub.legende.trim() != ''"> <span>{{ pub.legende }}</span></h6>
            <p>
              <i
                :class="'bx' + (pub.index_etat == 'One' ? 'bx-globe' : (pub.index_etat == 'Two' ? 'bxs-user' : (pub.index_etat == 'Three' ? 'bxs-lock' : '')))"></i>
              <span v-if="pub.name_etat != null && pub.name_etat.trim() != ''">{{ pub.name_etat }},  </span>
              <span class="date">{{ this.bg_format_date(pub.date, 'MM,DD YYYY') }}</span>
            </p>
          </div>
        </div>
        <div class="block-option dropdown">
          <div class="block-dots" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bx bx-dots-vertical-rounded"></i>
          </div>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
            <h6>Actions</h6>
            <li v-if="pub.type != 'image' && pub.type != 'video'"><a class="dropdown-item" href="#"><i
                  class="bx bxs-pencil"></i>Modifier</a></li>
            <li v-if="pub.type != 'pensee'"><a class="dropdown-item" href="#"><i class="bx bxs-download"></i>
                Enregistrer</a></li>
            <li><a class="dropdown-item" href="#" @click.prevent="OnePublishSelect(pub.id_pub)"><i
                  class="bx bx-trash"></i>
                Supprimer</a></li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <h6>Visibilté</h6>
            <li><a class="dropdown-item" href="#"><i class="bx bx-globe"></i> Public <i  v-if="pub.index_etat != null && pub.index_etat == 'One'" class="bx bx-check text-success"></i> </a>
            </li>
            <li><a class="dropdown-item" href="#"><i class="bx bxs-user"></i>Amis <i  v-if="pub.index_etat != null && pub.index_etat == 'Two'" class="bx bx-check text-success"></i></a>
            </li>
            <li><a class="dropdown-item" href="#"><i class="bx bxs-lock"></i>Privé <i v-if="pub.index_etat != null && pub.index_etat == 'Three'" class="bx bx-check text-success"></i></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="block-body-post">
        <!-- News  01/2/2023-->
        <!-- Plusieurs photo -->
        <div v-if="pub.type == 'image' && pub.contenu != null && pub.contenu.trim() != '' && pub.contenu.length > 1"
          class="block-img-post invisible position-absolute">
          <div class="block-grid-img">
            <div class="img-grid grid-2 grid-5">
              <img src="images/3.jpg" alt="">
            </div>
            <div class="img-grid grid-2 grid-5">
              <img src="images/2.jpg" alt="">
            </div>
            <div class="img-grid grid-2 grid-3 grid-4 grid-5">
              <img src="images/9.jpg" alt="">
            </div>
            <div class="img-grid grid-2 grid-4 grid-5">
              <div class="block-num-photo">
                +1
              </div>
              <img src="images/10.jpg" alt="">
            </div>
          </div>
        </div>

        <div v-if="pub.type == 'image' && pub.contenu != null && pub.contenu.trim() != '' && pub.contenu.length != 1"
          class="block-img-post">
          <div class="block-grid-img">
            <div class="img-grid" data-bs-toggle="modal" data-bs-target="#modal-show-post" @click.prevent="gb_modalOnePublish(pub)">
              <img :src="pub.contenu" alt="">
            </div>
          </div>
        </div>

        <div v-if="pub.type == 'video' && pub.contenu != null && pub.contenu.trim() != ''"
          class="block-img-post block-video-post">
          <video id="player" :src="pub.contenu" playsinline controls autoplay="false" data-poster="images/1.jpg">
          </video>
        </div>

        <!--  Second  block -->
        <div v-if="pub.type == 'pensee' && pub.contenu != null && pub.contenu.trim() != ''" class="block-content-text">
          <p>{{ pub.contenu }}</p>
        </div>
        <!-- End Second  block -->

        <!-- Three Block -->
        <div class="block-footer-post">
          <div class="block-left">
            <div class="d-flex align-items-center block-user-like">
              <a href="#" class="like">
                <i class="bx bx-heart"></i>
                <span>{{ pub.nbrjadore }}</span>
              </a>
              <div class="block-users-friends">
                <a href="#">
                  <div class="avatar-user">
                    <img src="images/team1.jpg" alt="">
                  </div>
                </a>
                <a href="#">
                  <div class="avatar-user">
                    <img src="images/team1.jpg" alt="">
                  </div>
                </a>
                <a href="#">
                  <div class="avatar-user">
                    <img src="images/team1.jpg" alt="">
                  </div>
                </a>
                <a href="#">
                  <div class="avatar-user">
                    <img src="images/team1.jpg" alt="">
                  </div>
                </a>
                <a href="#">
                  <div class="avatar-user">
                    <img src="images/team1.jpg" alt="">
                  </div>
                </a>
              </div>
            </div>
            <div class="block-likes">
              <a href="#">Vous</a>,
              <a href="#">Emilie</a> et <br> {{ pub.nbrjaime }} autres aimés ça
            </div>
          </div>
          <div class="block-right">
            <a href="#" class="comment-like">
              <i class="bx bx-comment"></i>
              <span>{{ pub.nbrargument }}</span>
            </a>
            <a href="#" class="share-like">
              <i class="bx bx-share-alt"></i>
              <span>{{ pub.nbrpartage }}</span>
            </a>
          </div>
        </div>
        <!-- End Three Block -->

        <!-- Fourth Block -->
        <div class="block-comment-user">
          <form action="" class="form-comment">
            <div class="block-content-comment d-flex justify-content-between">
              <div
                v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.avatarUser != null && this.$store.state.user_auth.avatarUser.trim() != ''"
                class="block-avatar-comment">
                <img :src="this.$store.state.user_auth.avatarUser" alt="image de">
              </div>
              <div class="block-input-comment">
                <textarea name="" id="" cols="30" rows="0" class="form-control"
                  placeholder="laissez un commentaire"></textarea>
              </div>
              <button class="btn"><i class="bx bxs-comment"></i><span>Commentez</span></button>
            </div>
          </form>
        </div>
        <!-- End Fourth -->
        <!-- End News -->
      </div>
    </div>
  </div>

  <div v-if="$route.path == '/posters-user' && this.publishings.length == 0 && this.publishings_view.length == 0 && !requesting"
    class="card card-post-actu">
    <div v-if="this.$store.state.user_auth != null" class="block-header-post">
      <div class="block-header-avatar">
        <div class="avatar-post">
          <img
            v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.avatarUser != null && this.$store.state.user_auth.avatarUser != ''"
            :src="this.$store.state.user_auth.avatarUser" :alt="this.$store.state.user_auth.avatarUser">
        </div>
        <div class="block-user-post">
          <h6><a href="#">{{ (this.$store.state.user_auth.fistnameUser != null &&
            this.$store.state.user_auth.fistnameUser != "" ?
            this.$store.state.user_auth.fistnameUser : "") + ' ' + (this.$store.state.user_auth.nameUser != null &&
              this.$store.state.user_auth.nameUser != "" ?
              this.$store.state.user_auth.nameUser : "") }}</a></h6>
          <p>
            <i class="bx bx-globe"></i>
            <span>Public:</span>
            <span class="date">Oct,05 2020</span>
          </p>
        </div>
      </div>
    </div>
    <div class="block-body-post">
      <div class="block-content-text text-center">
        <p>Aucune publication à ce jour</p>
      </div>
    </div>
  </div>

  <!-- Camarade -->
    <div v-if="($route.path == '/profil-user/' + $route.params.friend) && this.$store.state.params == $route.params.friend && this.publishings.length != 0 && this.publishings_view.length != 0 && !requesting">
      <div v-for="(pub, index) in publishings_view" :key="index" class="card card-post-actu">
        <div class="block-header-post">
          <div class="block-header-avatar">
            <div class="avatar-post">
              <span class="etat"></span>
              <img
                v-if="this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.codingUserCamarade != null && this.$store.state.user_auth_other.codingUserCamarade.trim() != '' && this.$store.state.user_auth_other.avatarCamarade != null && this.$store.state.user_auth_other.avatarCamarade.trim() != ''"
                :src="this.$store.state.user_auth_other.avatarCamarade.trim()" alt="">
            </div>
            <div class="block-user-post">
              <h6 v-if="pub.legende != null && pub.legende.trim() != ''"> <span>{{ pub.legende }}</span></h6>
              <p>
                <i
                  :class="'bx' + (pub.index_etat == 'One' ? 'bx-globe' : (pub.index_etat == 'Two' ? 'bxs-user' : (pub.index_etat == 'Three' ? 'bxs-lock' : '')))"></i>
                <span v-if="pub.name_etat != null && pub.name_etat.trim() != ''">{{ pub.name_etat }},  </span>
                <span class="date">{{ this.bg_format_date(pub.date, 'MM,DD YYYY') }}</span>
              </p>
            </div>
          </div>
          <div class="block-option dropdown">
            <div class="block-dots" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bx bx-dots-vertical-rounded"></i>
            </div>
            <ul v-if="pub.type != 'pensee'" class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
              <h6>Actions</h6>
              <li><a class="dropdown-item" href="#"><i class="bx bxs-download"></i>
                  Enregistrer</a></li>
            </ul>
          </div>
        </div>
        <div class="block-body-post">
          <!-- News  01/2/2023-->
          <!-- Plusieurs photo -->
          <div v-if="pub.type == 'image' && pub.contenu != null && pub.contenu.trim() != '' && pub.contenu.length > 1"
            class="block-img-post invisible position-absolute">
            <div class="block-grid-img">
              <div class="img-grid grid-2 grid-5">
                <img src="images/3.jpg" alt="">
              </div>
              <div class="img-grid grid-2 grid-5">
                <img src="images/2.jpg" alt="">
              </div>
              <div class="img-grid grid-2 grid-3 grid-4 grid-5">
                <img src="images/9.jpg" alt="">
              </div>
              <div class="img-grid grid-2 grid-4 grid-5">
                <div class="block-num-photo">
                  +1
                </div>
                <img src="images/10.jpg" alt="">
              </div>
            </div>
          </div>

          <div v-if="pub.type == 'image' && pub.contenu != null && pub.contenu.trim() != '' && pub.contenu.length != 1"
            class="block-img-post">
            <div class="block-grid-img">
              <div class="img-grid" data-bs-toggle="modal" data-bs-target="#modal-show-post" @click.prevent="gb_modalOnePublish(pub)">
                <img :src="pub.contenu" alt="">
              </div>
            </div>
          </div>

          <div v-if="pub.type == 'video' && pub.contenu != null && pub.contenu.trim() != ''"
            class="block-img-post block-video-post">
            <video id="player" :src="pub.contenu" playsinline controls autoplay="false" data-poster="images/1.jpg">
            </video>
          </div>

          <!--  Second  block -->
          <div v-if="pub.type == 'pensee' && pub.contenu != null && pub.contenu.trim() != ''" class="block-content-text">
            <p>{{ pub.contenu }}</p>
          </div>
          <!-- End Second  block -->

          <!-- Three Block -->
          <div class="block-footer-post">
            <div class="block-left">
              <div class="d-flex align-items-center block-user-like">
                <a href="#" class="like">
                  <i class="bx bx-heart"></i>
                  <span>{{ pub.nbrjadore }}</span>
                </a>
                <div class="block-users-friends">
                  <a href="#">
                    <div class="avatar-user">
                      <img src="images/team1.jpg" alt="">
                    </div>
                  </a>
                  <a href="#">
                    <div class="avatar-user">
                      <img src="images/team1.jpg" alt="">
                    </div>
                  </a>
                  <a href="#">
                    <div class="avatar-user">
                      <img src="images/team1.jpg" alt="">
                    </div>
                  </a>
                  <a href="#">
                    <div class="avatar-user">
                      <img src="images/team1.jpg" alt="">
                    </div>
                  </a>
                  <a href="#">
                    <div class="avatar-user">
                      <img src="images/team1.jpg" alt="">
                    </div>
                  </a>
                </div>
              </div>
              <div class="block-likes">
                <a href="#">Vous</a>,
                <a href="#">Emilie</a> et <br> {{ pub.nbrjaime }} autres aimés ça
              </div>
            </div>
            <div class="block-right">
              <a href="#" class="comment-like">
                <i class="bx bx-comment"></i>
                <span>{{ pub.nbrargument }}</span>
              </a>
              <a href="#" class="share-like">
                <i class="bx bx-share-alt"></i>
                <span>{{ pub.nbrpartage }}</span>
              </a>
            </div>
          </div>
          <!-- End Three Block -->

          <!-- Fourth Block -->
          <div class="block-comment-user">
            <form action="" class="form-comment">
              <div class="block-content-comment d-flex justify-content-between">
                <div
                  v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.avatarUser != null && this.$store.state.user_auth.avatarUser.trim() != ''"
                  class="block-avatar-comment">
                  <img :src="this.$store.state.user_auth.avatarUser" alt="image de">
                </div>
                <div class="block-input-comment">
                  <textarea name="" id="" cols="30" rows="0" class="form-control"
                    placeholder="laissez un commentaire"></textarea>
                </div>
                <button class="btn"><i class="bx bxs-comment"></i><span>Commentez</span></button>
              </div>
            </form>
          </div>
          <!-- End Fourth -->
          <!-- End News -->
        </div>
      </div>
    </div>

      <div v-if="($route.path == '/profil-user/' + $route.params.friend) && this.publishings.length == 0 && this.publishings_view.length == 0 && !requesting"
      class="card card-post-actu">
      <div v-if="this.$store.state.user_auth != null" class="block-header-post">
        <div class="block-header-avatar">
          <div class="avatar-post">
           <img
                  v-if="this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.codingUserCamarade != null && this.$store.state.user_auth_other.codingUserCamarade.trim() != '' && this.$store.state.user_auth_other.avatarCamarade != null && this.$store.state.user_auth_other.avatarCamarade.trim() != ''"
                  :src="this.$store.state.user_auth_other.avatarCamarade.trim()" alt="">
          </div>
          <div class="block-user-post">
            <h6><a href="#">{{ this.$store.state.user_auth_other.nameCamarade.trim() }}</a></h6>
            <p>
              <i class="bx bx-globe"></i>
              <span>Public:</span>
              <span class="date">Oct,05 2020</span>
            </p>
          </div>
        </div>
      </div>
      <div class="block-body-post">
        <div class="block-content-text text-center">
          <p>Aucune publication à ce jour</p>
        </div>
      </div>
    </div>

  <!-- End Camarade -->

  <div v-if="(this.$store.state.user_auth != null || this.$store.state.user_auth_other != null   ) && requesting" class="card card-post-actu">
    <div class="block-body-post">
      <div class="block-content-text text-center">
        <p>
          <lottie-player class="text-center" :src="this.$store.state.app_url_img_localhost+'/assets/lotties/loading/load7.json'" background="transparent" speed="1" style="width: 100%; height: 90px;" loop autoplay></lottie-player>
        </p>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
  name: 'PublishingUser',
  data() {
    return {
      publishings: [],
      publishings_view: [],
      Selectpublishing: null,
      NumberPublisings: 15,
      pageNext: 0,
      pagePreview: 1,
      requesting: true,
      addPublish: false,
      requestingText: "Chargement des données",
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },

  // created() {
  //   this.loadPublish();

  //   setInterval(() => {
  //     this.loadPublish();
  //   }, 120000);
  // },

  methods: {
    loadPublish() {
      if (this.pageNext == 0) {
        this.requesting = true;
      }
      if (this.bg_Online && this.$store.state.modulePublish) {
        if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {

          // var postData = new Object();
          // postData.mailUser = this.$store.state.user_auth.mailUser;
          // postData.codingUser = this.$store.state.user_auth.codingUser;
          // postData.nbrClick = this.pageNext;
          // var postData = {
          //   "mailUser": this.$store.state.user_auth.mailUser,
          //   "codingUser": this.$store.state.user_auth.codingUser,
          //   "nbrClick": this.pageNext,
          // }

          var postData;
           if (!this.gb_viewUserOrOtherUser(this.$route) && this.$store.state.user_auth != null) {
            postData = {
              "mailUser": this.$store.state.user_auth.mailUser,
              "codingUser": this.$store.state.user_auth.codingUser,
              "nbrClick": this.pageNext,
            }
          } else if (this.gb_viewUserOrOtherUser(this.$route) && this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.codingUserCamarade != null && this.$store.state.user_auth_other.codingUserCamarade.trim() != '' && this.$store.state.params == this.$route.params.friend) {
            postData = {
              "mailUser": this.$store.state.user_auth_other.mailCamarade.trim(),
              "codingUser": this.$store.state.user_auth_other.codingUserCamarade.trim(),
              "nbrClick": this.pageNext,
            };
          }

          if (postData.mailUser != null && postData.mailUser.trim() != "" && postData.codingUser != null && postData.codingUser.trim() != "") {
            axios.post("https://www.all.pub.schoolbac.com", postData)
              .then(({ data }) => {
                // if (data.statut == "false") {
                this.publishings = data;


                if (this.pageNext == 0) {
                  this.requesting = false;
                }
                // this.publishings_view = [this.publishings_view, ...this.publishings.slice(0, 15)];
                this.LoaNext()
                // } else {
                //   this.toast.error(data.message, {
                //     timeout: 4000
                //   });
                // }
              })
              .catch((error) => {
                this.errors = error;
                this.requesting = false;
              });
          }
        }
      }
    },

    LoaNext() {
      if (this.publishings.length > 0) {
        this.addPublish = true;

        var news_tableau = [...this.publishings_view, ...this.publishings];
        // news_tableau = [...new Set(news_tableau)];

        /// Esssaie
        var jsonObject = news_tableau.map(JSON.stringify);
        var uniqueSet = new Set(jsonObject);
        var uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        this.publishings_view = uniqueArray;
        /// End Esssaie

        this.pageNext += 1;
        this.NumberPublisings = parseInt(this.NumberPublisings) * parseInt(this.pageNext);
        // this.NumberPublisings = parseInt(this.NumberPublisings)*2;
        // } 
      } else {
        this.addPublish = false;
      }
    },

    nonloadAddDatas() {
      this.addPublish = false;
    },

    OnePublishSelect(id_pub) {
      this.Selectpublishing = null;

      if (this.publishings_view.length > 0) {

        var publish = this.publishings_view.filter(function (val) {
          return val.id_pub == id_pub;
        });

        if (publish != null && publish.length > 0) {
          this.Selectpublishing = publish[0];

          // bloc_delete

          var postData = new Object();
          postData.mailUser = this.$store.state.user_auth.mailUser;
          postData.codingUser = this.$store.state.user_auth.codingUser;
          postData.id_pub = this.Selectpublishing.id_pub;

          if (postData.mailUser != null && postData.mailUser.trim() != "" && postData.codingUser != null && postData.codingUser.trim() != "") {
            axios.post("https://www.delete.pub.schoolbac.com", postData)
              .then(({ data }) => {
                if (data.error == "false") {
                  let filtered = this.publishings_view.filter(o => o.id_pub !== this.Selectpublishing.id_pub);
                  this.publishings_view = filtered;

                } else {
                  this.toast.error(data.message, {
                    timeout: 4000
                  });
                }
              })
              .catch((error) => {
                this.errors = error;
                this.requesting = false;
              });
          }
        }
      }
    },

    deletePublish(id) {
      if (this.bg_Online) {
        if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false" && id.trim() != "" && id != null) {

          // var postData = new Object();
          // postData.mailUser = this.$store.state.user_auth.mailUser;
          // postData.codingUser = this.$store.state.user_auth.codingUser;
          // postData.id_pub = id;

          var postData;
          if (!this.gb_viewUserOrOtherUser(this.$route) && this.$store.state.user_auth != null) {
            postData = {
              "mailUser": this.$store.state.user_auth.mailUser,
              "codingUser": this.$store.state.user_auth.codingUser,
              "id_pub": id,
            }
          } else if (this.gb_viewUserOrOtherUser(this.$route) && this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.codingUserCamarade != null && this.$store.state.user_auth_other.codingUserCamarade.trim() != '' && this.$store.state.params == this.$route.params.friend) {
            postData = {
              "mailUser": this.$store.state.user_auth_other.mailCamarade.trim(),
              "codingUser": this.$store.state.user_auth_other.codingUserCamarade.trim(),
              "id_pub": id,
            };
          }


          if (postData.mailUser != null && postData.mailUser.trim() != "" && postData.codingUser != null && postData.codingUser.trim() != "") {
            axios.post("https://www.delete.pub.schoolbac.com", postData)
              .then(({ data }) => {
                if (data.error == "false") {
                  this.publishings = data;
                }
              })
              .catch((error) => {
                this.errors = error;
              });
          }
        }
      }
    },

    loadPublishRefresh() {
      this.pageNext = 0;
      this.publishings= [];
      this.publishings_view = [];
      this.loadDataPublishRefresh();
    },

    loadDataPublishRefresh() {
      if (this.bg_Online && this.$store.state.modulePublish) {
        if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {

          // var postData = {
          //   "mailUser": this.$store.state.user_auth.mailUser,
          //   "codingUser": this.$store.state.user_auth.codingUser,
          //   "nbrClick": this.pageNext
          // };

          var postData;
          if (!this.gb_viewUserOrOtherUser(this.$route) && this.$store.state.user_auth != null) {
            postData = {
              "mailUser": this.$store.state.user_auth.mailUser,
              "codingUser": this.$store.state.user_auth.codingUser,
              "nbrClick": this.pageNext,
            }
          } else if (this.gb_viewUserOrOtherUser(this.$route) && this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.codingUserCamarade != null && this.$store.state.user_auth_other.codingUserCamarade.trim() != '' && this.$store.state.params == this.$route.params.friend) {
            postData = {
              "mailUser": this.$store.state.user_auth_other.mailCamarade.trim(),
              "codingUser": this.$store.state.user_auth_other.codingUserCamarade.trim(),
              "nbrClick": this.pageNext,
            };
          }

          if (postData.mailUser != null && postData.mailUser.trim() != "" && postData.codingUser != null && postData.codingUser.trim() != "") {
            axios.post("https://www.all.pub.schoolbac.com", postData)
              .then(({ data }) => {
                this.publishings = data;
                this.LoaNext();
              })
              .catch((error) => {
                this.errors = error;
                this.requesting = false;
              });
          }
        }
      }
    },

  },
}
</script>
