<template>
  <div  v-if="$route.path == '/videos-user'" class="container-fluid px-lg-5 mt-lg-4 mt-2 px-1">
    <div class="row justify-content-center g-lg-4 g-3">
      <div class="col-lg-12 col-xl-12 col-xxl-12">
        <div class="card card-lg-tools">
          <h2 class="title-page">Vidéos <span class="badge">30</span></h2>
          <ul class="nav nav-tabs nav-sm" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#all-picture"
                type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Toutes les vidéos</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-photo" type="button"
                role="tab" aria-controls="profile-tab-pane" aria-selected="false">Most views</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#cover-photo" type="button"
                role="tab" aria-controls="contact-tab-pane" aria-selected="false">Newest</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#album" type="button"
                role="tab" aria-controls="contact-tab-pane" aria-selected="false">Albums</button>
            </li>
          </ul>
          <div class="container-fluid p-0">
            <div class="row g-3">
              <div class="col-lg-3 col-6">
                <div class="card card-img-post">
                  <a href="#">
                    <div class="btn-play">
                      <i class="bx bx-play"></i>
                    </div>
                    <img src="assets/images/5.jpg" alt="">
                  </a>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="card card-img-post">
                  <a href="#">
                    <div class="btn-play">
                      <i class="bx bx-play"></i>
                    </div>
                    <img src="assets/images/11.jpg" alt="">
                  </a>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="card card-img-post">
                  <a href="#">
                    <div class="btn-play">
                      <i class="bx bx-play"></i>
                    </div>
                    <img src="assets/images/6.jpg" alt="">
                  </a>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="card card-img-post">
                  <a href="#">
                    <div class="btn-play">
                      <i class="bx bx-play"></i>
                    </div>
                    <img src="assets/images/10.jpg" alt="">
                  </a>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="card card-img-post">
                  <a href="#">
                    <div class="btn-play">
                      <i class="bx bx-play"></i>
                    </div>
                    <img src="assets/images/5.jpg" alt="">
                  </a>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="card card-img-post">
                  <a href="#">
                    <div class="btn-play">
                      <i class="bx bx-play"></i>
                    </div>
                    <img src="assets/images/11.jpg" alt="">
                  </a>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="card card-img-post">
                  <a href="#">
                    <div class="btn-play">
                      <i class="bx bx-play"></i>
                    </div>
                    <img src="assets/images/6.jpg" alt="">
                  </a>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="card card-img-post">
                  <a href="#">
                    <div class="btn-play">
                      <i class="bx bx-play"></i>
                    </div>
                    <img src="assets/images/10.jpg" alt="">
                  </a>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="card card-img-post">
                  <a href="#">
                    <div class="btn-play">
                      <i class="bx bx-play"></i>
                    </div>
                    <img src="assets/images/5.jpg" alt="">
                  </a>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="card card-img-post">
                  <a href="#">
                    <div class="btn-play">
                      <i class="bx bx-play"></i>
                    </div>
                    <img src="assets/images/11.jpg" alt="">
                  </a>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="card card-img-post">
                  <a href="#">
                    <div class="btn-play">
                      <i class="bx bx-play"></i>
                    </div>
                    <img src="assets/images/6.jpg" alt="">
                  </a>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="card card-img-post">
                  <a href="#">
                    <div class="btn-play">
                      <i class="bx bx-play"></i>
                    </div>
                    <img src="assets/images/10.jpg" alt="">
                  </a>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="card card-img-post">
                  <a href="#">
                    <div class="btn-play">
                      <i class="bx bx-play"></i>
                    </div>
                    <img src="assets/images/5.jpg" alt="">
                  </a>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="card card-img-post">
                  <a href="#">
                    <div class="btn-play">
                      <i class="bx bx-play"></i>
                    </div>
                    <img src="assets/images/11.jpg" alt="">
                  </a>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="card card-img-post">
                  <a href="#">
                    <div class="btn-play">
                      <i class="bx bx-play"></i>
                    </div>
                    <img src="assets/images/6.jpg" alt="">
                  </a>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="card card-img-post">
                  <a href="#">
                    <div class="btn-play">
                      <i class="bx bx-play"></i>
                    </div>
                    <img src="assets/images/10.jpg" alt="">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  </div>
</template>

<script>
// import axios from 'axios';
import { useToast } from "vue-toastification";


export default {
  name: 'HomePage',
  data() {
    return {
      user: null,
      videos: [],
      form: {
        code: null
      },
      requesting: false,
      requestingText: "Chargement des données",
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  mounted() {
    this.load();

    setInterval(() => {
      this.load();
    }, 120000);
  },

  methods: {
    load() {
      this.requesting = true;
      this.requesting = false;
    },
  },

}
</script>
