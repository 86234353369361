<template>
  <div v-if="this.ImgsUser.length != 0" class="row g-3">
    <div v-for="(imgs, index) in ImgsUser" :key="index" class="col-lg-3 col-6">
      <div class="card card-img-post">
        <div class="block-option dropdown">
          <div class="block-dots" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bx bx-dots-vertical-rounded"></i>
          </div>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
            <h6>Actions</h6>
            <li v-if="!this.gb_viewUserOrOtherUser($route) && this.$store.state.user_auth != null && this.$store.state.user_auth.posterUser != null && this.$store.state.user_auth.posterUser != '' && this.typeImgs !='corbeille'"><a class="dropdown-item" href="#" @click.prevent="submitAvatarPoster(imgs.id_pub, 'AVATAR')"><i
                  class="bx bx-user"></i> Définir comme avatar</a></li>
            <li v-if="!this.gb_viewUserOrOtherUser($route) && this.$store.state.user_auth != null && this.$store.state.user_auth.posterUser != null && this.$store.state.user_auth.posterUser != '' && this.typeImgs != 'corbeille'"><a class="dropdown-item" href="#" @click.prevent="submitAvatarPoster(imgs.id_pub, 'POSTER')"><i
                  class="bx bx-image"></i> Définir comme poster</a></li>
            <!-- <li><a class="dropdown-item" href="#"><i class="bx bxs-pencil"></i>Modifier</a></li> -->
            <li v-if="this.typeImgs != 'corbeille'"><a  class="dropdown-item" @click.prevent="remoteUrl(imgs.targetpicture)"><i class="bx bxs-download"></i> Enregistrer</a></li>
            <li v-if="!this.gb_viewUserOrOtherUser($route) &&  this.$store.state.user_auth != null && this.$store.state.user_auth.posterUser != null && this.$store.state.user_auth.posterUser != '' && this.typeImgs != 'corbeille'"><a class="dropdown-item" href="#" @click.prevent="deleteAvatarPoster(imgs.id_pub, 'POSTER')"><i class="bx bx-trash"></i> Supprimer</a></li>
            <li v-if="!this.gb_viewUserOrOtherUser($route) && this.$store.state.user_auth != null && this.$store.state.user_auth.posterUser != null && this.$store.state.user_auth.posterUser != ''  && this.typeImgs == 'corbeille'"><a class="dropdown-item" href="#" @click.prevent="restorePub(imgs.id_pub)"><i class="bx bx-reset"></i> Restaurer</a></li>
          </ul>
        </div>
        <a href="#" v-if="!this.gb_viewUserOrOtherUser($route) && this.$store.state.user_auth != null && this.$store.state.user_auth.posterUser != null && this.$store.state.user_auth.posterUser != ''  && this.typeImgs == 'corbeille' && imgs.type =='image'">
          <img :src="imgs.targetpicture" alt="" data-bs-toggle="modal" data-bs-target="#modal-show-post" @click.prevent="gb_modalOnePublishVsPicture(imgs)">
        </a>
        <a href="#" v-if="this.typeImgs != 'corbeille'">
          <img :src="imgs.targetpicture" alt="" data-bs-toggle="modal" data-bs-target="#modal-show-post" @click.prevent="gb_modalOnePublishVsPicture(imgs)">
        </a>
      </div>
    </div>
  </div>
  <div v-else class="text-center">
    <p class="text-secondary text-xs">Aucune image disponible !!!</p>
  </div>
</template>

<script>

import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
  name: 'ImagesUser',
  props: ['ImgsUser', 'typeImgs'],
  data() {
    return {
      requesting: false,
      requestingText: "Chargement des données",
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },

  mounted() {
    this.requesting = true;
    this.load();
    this.requesting = false;
    setInterval(() => {
      this.load();
    }, 60000);
  },

  methods: {
    load() {
      this.requesting = true;
      this.requesting = false;
    },

    submitAvatarPoster(id_avatar, type) {
      if (this.bg_Online) {
        if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {

          if (this.ImgsUser.length > 0) {

            var imgs = this.ImgsUser.filter(function (val) {
              return val.id_pub == id_avatar;
            });

            if (imgs != null && imgs.length > 0) {

              var postData = new Object();
              postData.mailUser = this.$store.state.user_auth.mailUser;
              postData.codingUser = this.$store.state.user_auth.codingUser;
              console.log(type);
              if (type == "AVATAR") {
                postData.index = "index_1";
              }else if (type == "POSTER") {
                postData.index = "index_2";
              }else {
                postData.index = "index_4";
              }

              postData.id_pub = imgs[0].id_pub;

              if (postData.mailUser != null && postData.mailUser.trim() != "" && postData.codingUser != null && postData.codingUser.trim() != "") {
                axios.post("https://www.add.image.schoolbac.com", postData)
                  .then(({ data }) => {
                    if (data.statut == "false") {
                      
                      if (type == "AVATAR") {
                        this.toast.success("Votre avatar  vient d'etre actualisé!", {
                          timeout: 4000
                        });

                        var avatarObj = new Object();
                        avatarObj.mailUser = this.$store.state.user_auth.mailUser;
                        avatarObj.codingUser = this.$store.state.user_auth.codingUser;

                        axios.post("https://www.avatar.user.schoolbac.com", avatarObj)
                          .then(({
                            data
                          }) => {
                            if (data.error == "false") {
                              this.$store.state.user_auth.avatarUser = data.avatarUser;
                              var news_dataUser = this.$store.state.user_auth;

                              this.$store.commit('AUTHENTIFICATE_USER', news_dataUser);
                              localStorage.setItem('AUTHENTIFICATE_USER', JSON.stringify(news_dataUser));
                              this.$store.state.user_auth = news_dataUser;

                            } else {
                              this.toast.info(data.message, {
                                timeout: 4000
                              });

                            }
                          })
                          .catch((error) => {
                            this.errors = error;
                            this.refresh();
                          });


                      }else  if (type == "POSTER") {
                       this.toast.success("Votre poster vient d'etre actualisé!", {
                          timeout: 4000
                        });


                        var posterObj = new Object();
                        posterObj.mailUser = this.$store.state.user_auth.mailUser;
                        posterObj.codingUser = this.$store.state.user_auth.codingUser;

                        axios.post("https://www.poster.user.schoolbac.com", posterObj)
                          .then(({
                            data
                          }) => {
                            if (data.error == "false") {
                              this.$store.state.user_auth.posterUser = data.posterUser;
                              var news_dataUser = this.$store.state.user_auth;

                              this.$store.commit('AUTHENTIFICATE_USER', news_dataUser);
                              localStorage.setItem('AUTHENTIFICATE_USER', JSON.stringify(news_dataUser));
                              this.$store.state.user_auth = news_dataUser;

                            } else {
                              this.toast.info(data.message, {
                                timeout: 4000
                              });

                            }
                          })
                          .catch((error) => {
                            this.errors = error;
                            this.refresh();
                          });

                      }else {
                         this.toast.success("Votre photo vient d'etre républier!", {
                          timeout: 4000
                        });
                      }

                    } else {
                      this.toast.error(data.message, {
                        timeout: 4000
                      });
                    }
                  })
                  .catch((error) => {
                    this.errors = error;
                    // this.refresh();
                  });
              }
            }

          }


        }
      }
    },

    deleteAvatarPoster(id_img, type) {
      if (this.bg_Online) {
        if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {

          if (this.ImgsUser.length > 0) {

            var imgs = this.ImgsUser.filter(function (val) {
              return val.id_pub == id_img;
            });

            if (imgs != null && imgs.length > 0) {

              var postData = new Object();
              postData.mailUser = this.$store.state.user_auth.mailUser;
              postData.codingUser = this.$store.state.user_auth.codingUser;
              if (type == "AVATAR") {
                postData.statut_index = "index_1";
              }else if (type == "POSTER") {
                postData.statut_index = "index_2";
              }

              if (postData.mailUser != null && postData.mailUser.trim() != "" && postData.codingUser != null && postData.codingUser.trim() != "") {
                axios.post("https://www.delete.img.schoolbac.com", postData)
                  .then(({ data }) => {
                    if (data.statut == "false") {
                      
                      this.toast.success("Votre photo vient d'etre supprimée!", {
                        timeout: 4000
                      });
                      this.$emit('reloadImgs', id_img);
                    } else {
                      this.toast.error(data.message, {
                        timeout: 4000
                      });
                    }
                  })
                  .catch((error) => {
                    this.errors = error;
                    // this.refresh();
                  });
              }
            }

          }


        }
      }
    },

    remoteUrl(imgUrl){
      const link = document.createElement('a');
      link.href = imgUrl;
      link.setAttribute('download', 'file.png'); //or any other extension
      link.setAttribute('private', 'false'); //or any other extension
      document.body.appendChild(link);
      link.click(function (e) {
        e.preventDefault();
      });
       document.body.removeChild(link);
    },

    restorePub(id_img) {
      if (this.bg_Online) {
        if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {

          if (this.ImgsUser.length > 0) {

            var imgs = this.ImgsUser.filter(function (val) {
              return val.id_pub == id_img;
            });

            if (imgs != null && imgs.length > 0) {

              var postData = {
                "mailUser": this.$store.state.user_auth.mailUser,
                "codingUser": this.$store.state.user_auth.codingUser,
                "id_pub": imgs[0].id_pub
              };

              if (postData.mailUser != null && postData.mailUser.trim() != "" && postData.codingUser != null && postData.codingUser.trim() != "") {
                axios.post("https://www.restore.pub.schoolbac.com", postData)
                  .then(({ data }) => {
                    if (data.statut == "false") {

                      this.toast.success("Votre publication vient d'etre restauré!", {
                        timeout: 4000
                      });
                      this.$emit('reloadImgs', id_img);
                    } else {
                      this.toast.error(data.message, {
                        timeout: 4000
                      });
                    }
                  })
                  .catch((error) => {
                    this.errors = error;
                    // this.refresh();
                  });
              }
            }

          }


        }
      }
    },

  },
}
</script>

