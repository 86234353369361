<template>
    <div v-if="this.$store.state.user_auth != null" class="card card-post mt-0">
        <ul class="nav nav-tabs mb-0 nav-folder block-tool-post" id="myTab" role="tablist" style="flex-wrap: nowrap;">
            <li class="nav-item items" role="presentation">
                <a class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#pensee" type="button"
                    role="tab" aria-controls="pensee" aria-selected="true">
                    <i class="bx bx-message-detail"></i>
                    <span>Pensée</span>
                </a>
            </li>
            <li class="nav-item items" role="presentation">
                <a class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#photo" type="button" role="tab"
                    aria-controls="photo" aria-selected="false">
                    <i class="bx bx-image"></i>
                    <span>Photos/Images</span>
                </a>
            </li>
            <!-- <li class="nav-item items" role="presentation">
                <a class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#video" type="button" role="tab"
                    aria-controls="video" aria-selected="false">
                    <i class="bx bx-video"></i>
                    <span>Vidéos</span>
                </a>
            </li> -->
        </ul>

        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="pensee" role="tabpanel" aria-labelledby="home-tab">
                <form action="">
                    <div class="form-group row g-3">
                        <!-- <div class="col-12">
                            <div class="block-btns-action d-flex align-items-center">
                                <div class="dropdown">
                                    <button class="btn" id="dropdownMenuButton2" data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        <i class="bx bx-globe"></i> Public
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                        <li><a class="dropdown-item" href="#"><i class="bx bx-globe"></i> Public</a></li>
                                        <li><a class="dropdown-item" href="#"><i class="bx bxs-user"></i>Amis</a></li>
                                        <li><a class="dropdown-item" href="#"><i class="bx bxs-lock"></i>Privé</a></li>
                                    </ul>
                                </div>
                                <div class="dropdown">
                                    <button class="btn" id="dropdownMenuButton2" data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        <i class="bx bx-smile"></i> Content
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                        <li><a class="dropdown-item" href="#"><i class="bx bx-globe"></i> Public</a></li>
                                        <li><a class="dropdown-item" href="#"><i class="bx bxs-user"></i>Amis</a></li>
                                        <li><a class="dropdown-item" href="#"><i class="bx bxs-lock"></i>Privé</a></li>
                                    </ul>
                                </div>
                                <div class="dropdown">
                                    <a class="btn" data-bs-toggle="modal" data-bs-target="#modal-identif">
                                        <i class="bx bx-tag"></i> Identifier quelqu'un
                                    </a>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="col-12">
                            <ul class="list-person">
                                <li>
                                    @John doe
                                    <span>
                                        <i class="bx bx-x"></i>
                                    </span>
                                </li>
                                <li>
                                    @John doe
                                    <span>
                                        <i class="bx bx-x"></i>
                                    </span>
                                </li>
                                <li>
                                    @John doe
                                    <span>
                                        <i class="bx bx-x"></i>
                                    </span>
                                </li>

                            </ul>
                        </div> -->
                        <div class="col-12 position-relative">
                            <div v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.avatarUser != null && this.$store.state.user_auth.avatarUser != ''"
                                class="block-avatar-user-post">
                                <div class="avatar">
                                    <img :src="this.$store.state.user_auth.avatarUser" alt="">
                                </div>
                            </div>
                            <div class="d-flex align-items-start">
                                <textarea v-model="this.form.describe" name="" id="" cols="30" rows="4" class="form-control"
                                    :placeholder="'Salut ' + (this.$store.state.user_auth.fistnameUser != null && this.$store.state.user_auth.fistnameUser != '' ? this.$store.state.user_auth.fistnameUser : '') + ' ' + (this.$store.state.user_auth.nameUser != null && this.$store.state.user_auth.nameUser != '' ?
                                        this.$store.state.user_auth.nameUser : '') + ', Quoi de neuf ?'"
                                    @keyup="queryForKeywords"  @click.prevent="this.emojiVisibility = false;">
                                </textarea>
                                <button class="btn btn-emoji mt-2" @click.prevent="toggleEmojiVisibility">
                                    <i class='bx bxs-smile'></i>
                                </button>
                            </div>

                            <emoji-picker v-if="this.emojiVisibility" class="light emoji-picker" style="position: absolute;right: 0;z-index: 1;top: 34%;" @click.prevent="changeValueEmoji"></emoji-picker>
                        </div>
                    </div>
                    <div class="footer-card d-flex justify-content-between align-items-end">
                        <div class="block-num-car d-flex align-items-center">
                            <p class="mb-0">150/</p>
                            <p class="mb-0">{{ this.form.stringLimit }}</p>
                        </div>

                        <button class="btn btn-post" v-if="this.form.describe != null && this.form.describe.trim() != ''"
                            @click.prevent="submitPublishUser">
                            Poster
                        </button>
                    </div>
                </form>
            </div>
            <div class="tab-pane fade" id="photo" role="tabpanel" aria-labelledby="home-tab">
                <form action="">
                    <div class="form-group row g-3">
                        <!-- <div class="col-12">
                            <div class="block-btns-action d-flex align-items-center">
                                <div class="dropdown">
                                    <button class="btn" id="dropdownMenuButton2" data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        <i class="bx bx-globe"></i> Public
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                        <li><a class="dropdown-item" href="#"><i class="bx bx-globe"></i> Public</a></li>
                                        <li><a class="dropdown-item" href="#"><i class="bx bxs-user"></i>Amis</a></li>
                                        <li><a class="dropdown-item" href="#"><i class="bx bxs-lock"></i>Privé</a></li>
                                    </ul>
                                </div>
                                <div class="dropdown">
                                    <button class="btn" id="dropdownMenuButton2" data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        <i class="bx bx-smile"></i> Content
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                        <li><a class="dropdown-item" href="#"><i class="bx bx-globe"></i> Public</a></li>
                                        <li><a class="dropdown-item" href="#"><i class="bx bxs-user"></i>Amis</a></li>
                                        <li><a class="dropdown-item" href="#"><i class="bx bxs-lock"></i>Privé</a></li>
                                    </ul>
                                </div>
                                <div class="dropdown">
                                    <button class="btn" id="dropdownMenuButton2" data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        <i class="bx bx-tag"></i> Identifier quelqu'un
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                        <li><a class="dropdown-item" href="#"><i class="bx bx-globe"></i> Public</a></li>
                                        <li><a class="dropdown-item" href="#"><i class="bx bxs-user"></i>Amis</a></li>
                                        <li><a class="dropdown-item" href="#"><i class="bx bxs-lock"></i>Privé</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-12 position-relative">
                            <div class="block-photo-upload">
                                <!-- <input  type="file" id="upload-photo" class="d-none" accept="image/*" @change="previewfiles"  multiple> -->
                                <input type="file" id="upload-photo" class="d-none" accept="image/*" @change="previewfiles"
                                    multiple>
                                <label for="upload-photo">
                                    <i class="bx bx-upload"></i>
                                    <span>Uploader une ou plusieurs photos</span>
                                    <!-- <span>Uploader une ou plusieurs photos en meme temps</span> -->
                                </label>
                            </div>
                        </div>
                        <div v-if="this.form.imagesUpload.length > 0" class="col-12">
                            <div class="block-grid-img">
                                <div class="img-grid" v-for="(elementImg, index) in form.imagesUpload" :key="index">
                                    <div class="delete-img"
                                        @click.prevent="deleteImagesAUploaoder(elementImg.file_post_url)">
                                        <i class="bx bx-x"></i>
                                    </div>
                                    <img :src="elementImg.file_post_url" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 position-relative invisible position-absolute">
                            <div v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.avatarUser != null && this.$store.state.user_auth.avatarUser != ''"
                                class="block-avatar-user-post">
                                <div class="avatar">
                                    <img :src="this.$store.state.user_auth.avatarUser" alt="">
                                </div>
                            </div>
                            <textarea name="" id="" cols="30" rows="4" class="form-control " :placeholder="'Salut ' + (this.$store.state.user_auth.fistnameUser != null && this.$store.state.user_auth.fistnameUser != '' ? this.$store.state.user_auth.fistnameUser : '') + ' ' + (this.$store.state.user_auth.nameUser != null && this.$store.state.user_auth.nameUser != '' ?
                                this.$store.state.user_auth.nameUser : '') + ', Quoi de neuf ?'"
                                @keyup="queryForKeywords"></textarea>
                        </div>
                    </div>
                    <div class="footer-card d-flex justify-content-between align-items-end">
                        <div class="block-num-car d-flex align-items-center  invisible">
                            <p class="mb-0">150/</p>
                            <p class="mb-0">{{ this.form.stringLimit }}</p>
                        </div>
                        <button v-if="this.form.imagesUpload.length > 0" class="btn btn-post"
                            @click.prevent="submitcreateposterImagesPost">
                            {{ !this.requesting ? "Poster" : "Veuillez Patienter..." }}
                        </button>
                    </div>
                </form>
            </div>
            <div class="tab-pane fade" id="video" role="tabpanel" aria-labelledby="home-tab">
                <form action="">
                    <div class="form-group row g-3">
                        <div class="col-12">
                            <div class="block-btns-action d-flex align-items-center">
                                <div class="dropdown">
                                    <button class="btn" id="dropdownMenuButton2" data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        <i class="bx bx-globe"></i> Public
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                        <li><a class="dropdown-item" href="#"><i class="bx bx-globe"></i> Public</a></li>
                                        <li><a class="dropdown-item" href="#"><i class="bx bxs-user"></i>Amis</a></li>
                                        <li><a class="dropdown-item" href="#"><i class="bx bxs-lock"></i>Privé</a></li>
                                    </ul>
                                </div>
                                <div class="dropdown">
                                    <button class="btn" id="dropdownMenuButton2" data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        <i class="bx bx-smile"></i> Content
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                        <li><a class="dropdown-item" href="#"><i class="bx bx-globe"></i> Public</a></li>
                                        <li><a class="dropdown-item" href="#"><i class="bx bxs-user"></i>Amis</a></li>
                                        <li><a class="dropdown-item" href="#"><i class="bx bxs-lock"></i>Privé</a></li>
                                    </ul>
                                </div>
                                <div class="dropdown">
                                    <button class="btn" id="dropdownMenuButton2" data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        <i class="bx bx-tag"></i> Identifier quelqu'un
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                        <li><a class="dropdown-item" href="#"><i class="bx bx-globe"></i> Public</a></li>
                                        <li><a class="dropdown-item" href="#"><i class="bx bxs-user"></i>Amis</a></li>
                                        <li><a class="dropdown-item" href="#"><i class="bx bxs-lock"></i>Privé</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 position-relative">
                            <div class="block-photo-upload">
                                <input type="file" id="upload-video" class="d-none" accept="video/*">
                                <label for="upload-video">
                                    <i class="bx bx-upload"></i>
                                    <span>Uploader une vidéo</span>
                                </label>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="block-video-uload">
                                <div class="delete-img">
                                    <i class="bx bx-x"></i>
                                </div>
                                <video src="assets/video/video.mp4" controls></video>
                            </div>
                        </div>
                        <div class="col-12 position-relative">
                            <div v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.avatarUser != null && this.$store.state.user_auth.avatarUser != ''"
                                class="block-avatar-user-post">
                                <div class="avatar">
                                    <img :src="this.$store.state.user_auth.avatarUser" alt="">
                                </div>
                            </div>
                            <textarea name="" id="" cols="30" rows="4" class="form-control" :placeholder="'Salut ' + (this.$store.state.user_auth.fistnameUser != null && this.$store.state.user_auth.fistnameUser != '' ? this.$store.state.user_auth.fistnameUser : '') + ' ' + (this.$store.state.user_auth.nameUser != null && this.$store.state.user_auth.nameUser != '' ?
                                this.$store.state.user_auth.nameUser : '') + ', Quoi de neuf ?'"
                                @keyup="queryForKeywords"></textarea>
                        </div>
                    </div>
                    <div class="footer-card d-flex justify-content-between align-items-end">
                        <div class="block-num-car d-flex align-items-center">
                            <p class="mb-0">150/</p>
                            <p class="mb-0">{{ this.form.stringLimit }}
                            </p>
                        </div>
                        <button class="btn btn-post">
                            Poster
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
import { useToast } from "vue-toastification";
import 'emoji-picker-element';



export default {
    name: 'CreatePublishUser',
    data() {
        return {
            form: {
                content: null,
                describe: "",
                stringLimit: 0,
                imagesUpload: []
            },
            emojiVisibility: false,
            requesting: false,
            numberx:0
        };
    },
    setup() {
        const toast = useToast();
        return { toast }
    },

    methods: {
        submitPublishUser() {
            if (this.bg_Online) {
                if (this.form.describe != null && this.form.describe.trim() != "" && this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {

                    var postData = new Object();
                    postData.mailUser = this.$store.state.user_auth.mailUser;
                    postData.codingUser = this.$store.state.user_auth.codingUser;
                    postData.contenu = this.form.describe;

                    axios.post("https://www.add.pensee.schoolbac.com", postData).then(({ data }) => {
                        if (data.error == "false") {
                            this.toast.success("La pensée a été pubié avec succés !!!", {
                                timeout: 2000
                            });
                            this.$emit('submitSuccess');
                        }
                    }).catch(function (error) {
                        console.log(error);
                        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
                            timeout: 2000
                        });
                    });

                } else {
                    this.toast.success("Veuillez remplir le champ manquant !!!");
                }
            }
        },

        queryForKeywords(event) {
            const value = event.target.value
            // this.keywords = value
            // valeur = value.replace(/\D+/, '');
            // console.log(valeur);
            // this.formUp.telephone = valeur;
            // this.content = valeur;
            if (this.form.describe == null || this.form.content == null || value.length < 151) {
                this.form.content = value;
                this.form.stringLimit = value.length;
                this.form.describe = this.form.content;
            } else {
                if (value.length <= 150) {
                    this.form.content = value;
                    this.form.stringLimit -= 1;
                    this.form.describe = this.form.content;

                } else {
                    this.form.describe = this.form.content;
                }
            }
        },

        previewfiles(e) {
            e.preventDefault();
            if (e.target.files.length > 0) {
                for (let index = 0; index < e.target.files.length; index++) {

                    if (this.bg_verif_extension_img(e.target.files[index].name)) {
                        if (this.bg_verif_size_img(e.target.files[index].size)) {
                            var postObj = new Object();
                            postObj.picture = e.target.files[index].name;
                            postObj.file_post = e.target.files[index];
                            postObj.file_post_url = window.URL.createObjectURL(e.target.files[index]);
                            this.form.imagesUpload.push(postObj);
                        }
                    }
                }
            }

            // this.form.picture = e.target.files[0].name;
            // this.form.file_avatar = e.target.files[0];

            // if (this.bg_verif_extension_img(e.target.files[0].name)) {

            //   if (this.bg_verif_size_img(e.target.files[0].size)) {
            //     var image = document.getElementById("avatar_loading");
            //     image.src = window.URL.createObjectURL(e.target.files[0]);

            //     if (image != null) {
            //       this.submitcreateposter();
            //     }
            //     var fileselectx = document.getElementById('avatar_file').files;
            //     if (fileselectx.length > 0) {
            //       var fileReader = new FileReader();

            //       fileReader.onload = function (fileLoadedEvent) {
            //         var scrData = fileLoadedEvent.target.result;
            //         document.getElementById("#avatar_loading").attr('src', scrData);
            //       }
            //     }

            //   }
            // }
        },

        deleteImagesAUploaoder(url_image) {
            if (url_image != null && url_image.trim() != "") {
                if (this.form.imagesUpload.length > 0) {

                    var images = this.form.imagesUpload.filter(function (val) {
                        return val.file_post_url != url_image;
                    });

                    if (images != null) {
                        this.form.imagesUpload = images;
                    }
                }
            }
        },

        submitcreateposterImagesPost() {
            if (this.Online()) {
                if (this.$store.state.user_auth != null && this.form.imagesUpload.length > 0) {
                    this.requesting = true;

                    if (this.form.imagesUpload.length > 0) {


                        for (let j = 0; j < this.form.imagesUpload.length; j++) {
                            const config = {
                                headers: {
                                    "content-type": "multipart/form-data",
                                },
                            };
                            // form data
                            let formData = new FormData();
                            formData.append("file", this.form.imagesUpload[j].file_post);
                            axios
                                .post("https://www.upload.img.schoolbac.com/uploader_image.php", formData, config)
                                .then(({ data }) => {
                                    if (data.status == 200) {
                                        if (this.form.imagesUpload.length == 1) {
                                            this.requesting = false;
                                        } else {
                                            if (parseInt(this.form.imagesUpload.length) - 1 == j) {
                                                this.requesting = false;
                                            }
                                        }

                                        var postData = new Object();
                                        postData.mailUser = this.$store.state.user_auth.mailUser;
                                        postData.codingUser = this.$store.state.user_auth.codingUser;
                                        postData.statut_index = "index_4";
                                        postData.name_file = this.form.imagesUpload[j].picture;

                                        axios.post("https://www.add.image.schoolbac.com", postData)
                                            .then(({ data }) => {
                                                if (data.error == "false") {

                                                    if (this.form.imagesUpload.length == 1) {
                                                        this.toast.success("Votre post vient d'etre fait avec succès", {
                                                            timeout: 4000
                                                        });
                                                        this.form.imagesUpload = [];
                                                        this.$emit("submitSuccess");
                                                    } else {
                                                        if (parseInt(this.form.imagesUpload.length) - 1 == j) {
                                                            this.toast.success("Vos posts viennent d'etre fait avec succès", {
                                                                timeout: 4000
                                                            });
                                                            this.form.imagesUpload = [];
                                                            this.$emit("submitSuccess");
                                                        }
                                                    }
                                                } else {
                                                    if (data.statut == "dev") {
                                                        this.toast.info("Erreur survenu lors de la  sauvegarde de la photo, veuillez réessayer  !!!", {
                                                            timeout: 4000
                                                        });
                                                        this.form.imagesUpload = [];
                                                    } else {
                                                        this.toast.error(data.error, {
                                                            timeout: 4000
                                                        });
                                                        this.form.imagesUpload = [];
                                                    }
                                                }
                                            })
                                            .catch((error) => {
                                                this.errors = error;
                                                this.refresh();
                                            });


                                    } else {
                                        this.toast.info(data.message, {
                                            timeout: 4000
                                        });
                                    }
                                }).catch(function (error) {
                                    this.errors = error;
                                    console.log(error);
                                });
                        }
                        // this.form.imagesUpload.forEach(element => {

                        // });

                    } else {
                        this.toast.error("Veuillez compléter les images a publiés", {
                            timeout: 4000
                        });
                    }

                } else {
                    this.toast.error("Veuillez compléter les images a publiés", {
                        timeout: 4000
                    });
                }
            }
        },

        toggleEmojiVisibility() {
            this.emojiVisibility = !this.emojiVisibility;
            if (this.emojiVisibility) {
                 setTimeout(() => {
                     var emojiw = document.querySelector('emoji-picker');
                     emojiw.click();
                  }, 600);
            }
        },

        changeValueEmoji(event) {
         

            console.log("fameux event");
            console.log(event);
            // console.log(event.innerText);
            document.querySelector('emoji-picker')
                .addEventListener('emoji-click', event => {

                    console.log("nombre");
                    console.log(this.form.describe.length);


                    var emoji = event.detail.unicode;
                    console.log(emoji);
                    // var emo = "";
                    // if (emoji.length > 1) {
                    //     emo = emoji.substring(0, 1);
                    //     console.log("seulll");
                    //     console.log(emo);
                    // } else {
                    //     emo = emoji;
                    //     console.log(emo);
                    // }
                    // console.log("kekekek");
                    // // console.log(emoji.substring(0, 1));
                    // console.log(this.form.describe);
                    // // this.form.describe = this.form.describe + "" + emoji.substring(0,1) ;
                    // console.log(event.detail.unicode);
                    // this.form.describe = this.form.describe + "" + event.detail.unicode ;


                    this.form.describe = this.form.describe.trim() + "" + emoji;
                    emoji = "";
                    console.log("number");
                    console.log(this.form.describe.length);

                    if (this.form.describe.length > 0) {
                        var x = this.form.describe.length / 2;
                        console.log("test");
                        console.log(x);
                        console.log(this.form.describe);
                        console.log(this.form.describe.substring(0,x));
                    }
                    this.numberx++;
                    console.log(this.numberx);


                    
                    document.querySelector('emoji-picker')
                        .removeEventListener('emoji-click', event => {
                            console.log("leyu");
                            console.log(event);
                        });

                });  


            // document.querySelector('emoji-picker')
            //     .addEventListener('emoji-click', event => this.form.describe += event.detail.unicode);  

        },


    },
}
</script>
