<template>
    <!-- <div v-if="this.FriendsUserSuggereData.length > 0"  class="card card-sugg"> -->
    <div class="card card-sugg">
      <div class="block-header">
        <h5>Amis suggérés</h5>
        <!-- <a href="#">Voir plus</a> -->
        <router-link  to="/all-view-friends-demands">
          Voir plus
        </router-link>
      </div>
      <div v-for="(friend,index) in FriendsUserSuggereDataRandom.slice(0,5)"  :key="index"   class="block-sugg">
        <div class="items-block">
          <div class="avatar-friend">
            <img :src="(friend.avatarCamarade !=null && friend.avatarCamarade != '' ?  friend.avatarCamarade : 'assets/images/team.jpg')" alt="">
          </div>
          <div class="block-name-friend d-flex w-100 align-items-center justify-content-start">
            <div class="block-content">
              <a href="#">
                <h6>{{ friend.nameCamarade }}</h6>
              </a>
              <p>{{ friend.nbrCamaradeCommun }}  {{ parseInt(friend.nbrCamaradeCommun) > 1 ? ' amis(es)' : ' ami(e)' }} en commun</p>
            </div>
            <a v-if="friend.statutCamarade='Ajouter comme Camarade'"  @click.prevent="submitaddStatusFriendSuggere(friend.mailCamarade, 'index_1')" href="#" class="add ms-auto"><i class="bx bx-user-plus"></i></a>
          </div>
        </div>
      </div>
    </div>
</template>
<script>

import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
  name: 'FriendSuggere',
  data() {
    return {
      FriendsUserSuggereData: [],
      FriendsUserSuggereDataRandom: [],
      FriendsUserSuggere: null,
      requesting: false,
      requestingText: "Chargement des données",
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },

  methods: {
    load() {
      if (this.bg_Online) {
        if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {
          this.FriendsUserSuggereData = [];

          var postData = {
            "mailUser": this.$store.state.user_auth.mailUser,
            "codingUser": this.$store.state.user_auth.codingUser,
            "suggests": 50
          };

          if (postData.mailUser != null && postData.mailUser.trim() != "" && postData.codingUser != null && postData.codingUser.trim() != "") {

            axios.post("https://www.camarade.schoolbac.com", postData)
              .then(({ data }) => {
                if (data != null && data.length > 0) {
                  this.FriendsUserSuggereData = [...data];
                  this.shuffle();
                } else {
                  this.FriendsUserSuggereData = [];
                }
              })
              .catch((error) => {
                this.errors = error;
                this.FriendsUserSuggereData = [];
              });

            var postData2 = {
              "mailUser": this.$store.state.user_auth.mailUser,
              "codingUser": this.$store.state.user_auth.codingUser,
              "statut": 'index_9'
            };

            axios.post("https://www.camarade.schoolbac.com", postData2)
              .then(({ data }) => {
                console.log("je suis 22");
                console.log(data);
                if (data !=null && data.length > 0) {
                  this.FriendsUserSuggereData = [... this.FriendsUserSuggereData,...data];
                  this.shuffle();
                } 
              })
              .catch((error) => {
                this.errors = error;
              });
          }
        }
      }
    },

    submitaddStatusFriendSuggere(mailCamarade, statutSubmit) {
      if (this.bg_Online) {
        if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {
          if (this.$store.state.user_auth.mailUser != null && this.$store.state.user_auth.mailUser.trim() != "" && this.$store.state.user_auth.codingUser != null && this.$store.state.user_auth.codingUser.trim() != "" && mailCamarade != null && mailCamarade.trim() != "" && statutSubmit != null && statutSubmit.trim() != "") {

            if (this.bg_isEmail(mailCamarade)) {
              
              var postData = {
                "mailUser": this.$store.state.user_auth.mailUser,
                "codingUser": this.$store.state.user_auth.codingUser,
                "mailCamarade": mailCamarade,
                "statut": statutSubmit
              };
  
              axios.post("https://www.invitation.camarade.schoolbac.com", postData)
                .then(({ data }) => {
                  if (data.error == "false") {
                    this.gb_notifications(1, "Demande envoyée !!!");
                    this.load();
                  } else {
                    this.gb_notifications(0, "Demande a échouée, veuillez réessayer plus tard !!!");
                  }
                })
                .catch((error) => {
                  this.errors = error;
                  this.FriendsUserSuggereData = [];
                });
            }
          } else {
            this.gb_notifications(2, "Veuillez selectionner le camarade souhaité !!!");
          }
        }
      }
    },

    shuffle() {
      if (this.FriendsUserSuggereData.length > 0) {
          let numbers = [...this.FriendsUserSuggereData];
        let first,
          second,
          temp,
          count = numbers.length;
        for (let i = 0; i < 10; i++) {
          first = Math.floor(Math.random() * count);
          second = Math.floor(Math.random() * count);
          temp = numbers[first];
          numbers[first] = numbers[second];
          numbers[second] = temp;
        }
        this.FriendsUserSuggereDataRandom = numbers;
        
      } else {
        this.FriendsUserSuggereDataRandom = [];
      }
    }
  },

  mounted() {
      
    var getLoad = setInterval(() => {
        if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {
        this.load();
        clearInterval(getLoad);
      }
    }, 7000);

    setInterval(() => {
      this.load();
    }, 240000);

    // setInterval(() => {
    //   this.load();
    // }, 120000);
  },
}
</script>
