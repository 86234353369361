<template>
  <div class="card card-sugg">
    <div class="block-header d-flex aling-items-center justify-content-between">
      <h5>Photos</h5>
      <a href="#">Voir plus</a>
    </div>
    <div class="block-details">
      <div class="row g-2">
        <div v-for="(phot, index) in gb_shuffle(ImgsUser).slice(0, 6)"  :key="index"  class="col-lg-4">
          <div class="block-img-picture">
            <img  :src="(phot.targetpicture != null && phot.targetpicture != '' ? phot.targetpicture : 'assets/images/1.jpg')" alt="">
          </div>
        </div>
        <div v-if="this.ImgsUser.length == 0 && this.requeteNumber > 0" class="col-lg-11 text-center">
          <p class="small"> Pas des photos à ce jour.</p>
        </div>
        <div v-if="this.ImgsUser.length == 0 && this.requeteNumber == 0" class="col-lg-11">
            <lottie-player class="text-center" :src="this.$store.state.app_url_img_localhost + '/assets/lotties/loading/load7.json'" background="transparent" speed="1" style="width: 100%; height: 90px;" loop autoplay></lottie-player>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
  name: 'MyPhotos',
  data() {
    return {
      user: null,
      ImgsUserData: [],
      ImgsUser: [],
      requesting: false,
      typeImages: "alls_photos",
      requeteNumber: 0
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  mounted() {
    var setLoad = setInterval(() => {
      if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {
        this.load();
        clearInterval(setLoad);

        setInterval(() => {
          this.load();
        }, 300000);
      }
    }, 7000);
  },


  methods: {
    load() {
      if (this.bg_Online && this.$store.state.modulePublish) {
        if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {

          // var postData = new Object();
          // postData.mailUser = this.$store.state.user_auth.mailUser;
          // postData.codingUser = this.$store.state.user_auth.codingUser;

          var postData = {
            "mailUser": this.$route.path=="/posters-user" ? this.$store.state.user_auth.mailUser : (this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.mailCamarade != null && this.$store.state.user_auth_other.mailCamarade.trim() != "" ? this.$store.state.user_auth_other.mailCamarade.trim() : ''),
            "codingUser": this.$route.path=="/posters-user" ? this.$store.state.user_auth.codingUser : (this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.codingUserCamarade != null && this.$store.state.user_auth_other.codingUserCamarade.trim() != "" ? this.$store.state.user_auth_other.codingUserCamarade.trim() : '')
          };

          if (postData.mailUser != null && postData.mailUser.trim() != "" && postData.codingUser != null && postData.codingUser.trim() != "") {
            axios.post("https://www.all.img.schoolbac.com", postData)
              .then(({ data }) => {
                if (data.statut == "false") {
                  this.ImgsUserData = data;
                  this.requeteNumber++;
                  this.blockImageView(this.typeImages);
                } else {
                  this.toast.error(data.message, {
                    timeout: 4000
                  });
                }
              })
              .catch((error) => {
                this.errors = error;
              });
          }
        }
      }
    },

    blockImageView(imageCall) {
      this.ImgsUser = null;
      this.typeImages = imageCall;
      if (this.ImgsUserData != null) {
        if (imageCall == "alls_photos") {
          this.ImgsUser = [];
          if (this.ImgsUserData.album_avatars != null) {
            this.ImgsUser = [...this.ImgsUser, ...this.ImgsUserData.album_avatars.images];
          }
          if (this.ImgsUserData.album_posters != null) {
            this.ImgsUser = [...this.ImgsUser, ...this.ImgsUserData.album_posters.images];
          }
          if (this.ImgsUserData.album_mes_photos != null) {
            this.ImgsUser = [...this.ImgsUser, ...this.ImgsUserData.album_mes_photos.images];
          }
          if (this.ImgsUserData.album_camarades != null) {
            this.ImgsUser = [...this.ImgsUser, ...this.ImgsUserData.album_camarades.images];
          }
        } 
      }
    },
  },
}
</script>
