<template>
  <div class="card card-sugg">
    <div class="block-header">
      <h5>Camarades <br> <span style="color: var(--colorParagraph); font-family: 'Poppins-Regular'; font-size: 11px;"> 4030 Camarades</span></h5>
      <!-- <a href="#">Voir plus</a> -->
        <router-link  to="/all-view-friends-demands">
          Voir plus
        </router-link>
    </div>
    <div  v-for="(myfriend, index) in gb_shuffle(DatasFriendsUser).slice(0, 6)"  :key="index" class="block-sugg">
      <div class="items-block">
        <div class="avatar-friend">
          <img  :src="(myfriend.avatarCamarade != null && myfriend.avatarCamarade != '' ? myfriend.avatarCamarade : 'assets/images/team.jpg')" alt="">
        </div>
        <div class="block-name-friend d-flex w-100 align-items-center justify-content-start">
          <div class="block-content">
            <a href="#">
              <h6>{{ myfriend.nameCamarade }}</h6>
            </a>
            <p>{{ myfriend.nbrCamaradeCommun }}  {{ parseInt(myfriend.nbrCamaradeCommun) > 1 ? ' amis(es)' : ' ami(e)' }} en commun</p>
            <!-- <p>Pas d'amis en commun</p> -->
          </div>
          <div class="block-option dropdown ms-auto">
            <a href="#" class="add" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false"><i class="bx bx-dots-vertical-rounded" ></i></a>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
              <li><a class="dropdown-item" href="#" @click.prevent="OtherPorfilCamarade(myfriend.codingUserCamarade)"><i class="bx bxs-user"></i>Voir le profil</a></li>
              <li><a class="dropdown-item" href="#"><i class="bx bxs-message"></i> Envoyer un message</a></li>
            </ul>
          </div>
      
        </div>
      </div>
    </div>
    <div v-if="this.DatasFriendsUser.length == 0  && this.requeteNumber > 0" class="block-sugg text-center">
      <p class="small"> Pas d'amis(es)s à ce jour.</p>
    </div>
    <div v-if="this.DatasFriendsUser.length == 0 && this.requeteNumber == 0" class="block-sugg">
         <lottie-player class="text-center" :src="this.$store.state.app_url_img_localhost + '/assets/lotties/loading/load7.json'" background="transparent" speed="1" style="width: 100%; height: 90px;" loop autoplay></lottie-player>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import { useToast } from "vue-toastification";


export default {
  name: 'FriendUserPage',
  data() {
    return {
      user: null,
      DatasFriendsUser: [],
      requesting: false,
      requeteNumber: 0
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  mounted() {
    this.gb_loadReFreshUser();

    if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {
      this.requeteNumber = 0;
      this.load();

      setInterval(() => {
        this.load();
      }, 120000);
    }
  },

  methods: {
    load() {
      if (this.bg_Online) {
        if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {

          if (this.$store.state.user_auth.mailUser != null && this.$store.state.user_auth.mailUser.trim() != "" && this.$store.state.user_auth.codingUser != null && this.$store.state.user_auth.codingUser.trim() != "") {

            var postData = {
              "mailUser": !this.$store.state.viewOtherUser  ?  this.$store.state.user_auth.mailUser : (this.$store.state.viewOtherUser  && this.$store.state.user_auth_other !=null && this.$store.state.user_auth_other.mailCamarade != null && this.$store.state.user_auth_other.mailCamarade.trim() != "" ? this.$store.state.user_auth_other.mailCamarade.trim() : ''),
              "codingUser": !this.$store.state.viewOtherUser ? this.$store.state.user_auth.codingUser : (this.$store.state.viewOtherUser && this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.codingUserCamarade != null && this.$store.state.user_auth_other.codingUserCamarade.trim() != "" ? this.$store.state.user_auth_other.codingUserCamarade.trim() : ''),
              "statut": "index_1"
            };

            axios.post("https://www.camarade.schoolbac.com", postData)
              .then(({ data }) => {
                if (data.length > 0) {
                  this.DatasFriendsUser = data;
                  this.requeteNumber++;
                } else {
                  this.gb_notifications(0, data.message);
                }
                this.$store.state.viewOtherUser = false;
              })
              .catch((error) => {
                this.errors = error;
              });
          }
        }
      }
    },

    OtherPorfilCamarade(codingUserCamarade) {
      if (this.$store.state.user_auth.mailUser != null && this.$store.state.user_auth.mailUser.trim() != "" && this.$store.state.user_auth.codingUser != null && this.$store.state.user_auth.codingUser.trim() != "") {
        if (codingUserCamarade.trim() !="" && codingUserCamarade !=null) {
           this.$store.state.viewOtherUser = true;
          if (this.DatasFriendsUser.length > 0) {
            var camarade = this.DatasFriendsUser.find((friend) => friend.codingUserCamarade == codingUserCamarade);
            if (camarade != null) {
              this.$store.state.user_auth_other = camarade;
              if (this.$store.state.user_auth_other.codingUserCamarade != null && this.$store.state.user_auth_other.codingUserCamarade.trim() != "" &&  this.$store.state.user_auth_other.mailCamarade != null && this.$store.state.user_auth_other.mailCamarade.trim() != "") {
                // this.$router.push({ name: 'PostersUser2'});
                this.$store.state.params = this.$store.state.user_auth_other.nameCamarade.toLowerCase().replace(' ', '-');
                this.$router.push({ name: 'Camarade', params: { friend: this.$store.state.params } });
                this.load();
              }
            }
          }
        } 
      }
      
    },


  },

}
</script>
