<template>
  <div  v-if="!this.gb_viewUserOrOtherUser($route) && this.$store.state.user_auth != null && this.$store.state.user_auth.posterUser != null && this.$store.state.user_auth.posterUser != ''" class="container-fluid px-lg-5 mt-lg-4 mt-2 px-1">
    <div class="row justify-content-center g-lg-4 g-3">
      <div class="col-lg-12 col-xl-12 col-xxl-12">
        <div class="card card-lg-tools">
          <div class="row g-lg-5 g-3">
            <div class="col-lg-4 position-relative col-tools">
              <h2 class="title-page">A propos</h2>
              <link-important-user></link-important-user>
            </div>
            <infos v-if="$route.path == '/about-user'"  @openModal="loadModalParams" />
            <basic-infos v-if="$route.path == '/about-user-infos'"/>
            <education :InfosDatas= "EmploieScolarite" :ObjectRefreshElement="ObjectRefreshElement"  @emitSuccess ="cancelObject" v-if="$route.path == '/about-user-education'" />
            <places :InfosDatas= "blockPlaces"  :ObjectRefreshElement="ObjectRefreshElement"  @emitSuccess ="cancelObject" v-if="$route.path == '/about-user-places'" />
          </div>
        </div>
      </div>
    </div>
    <maj-infos ref="refloadParams" @refreshModalAction="refreshActionModal" ></maj-infos>
  </div>
  <div  v-if="this.gb_viewUserOrOtherUser($route) && this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.codingUserCamarade != null && this.$store.state.user_auth_other.codingUserCamarade.trim() != '' && this.$store.state.params == $route.params.friend" class="container-fluid px-lg-5 mt-lg-4 mt-2 px-1">
    <div class="row justify-content-center g-lg-4 g-3">
      <div class="col-lg-12 col-xl-12 col-xxl-12">
        <div class="card card-lg-tools">
          <div class="row g-lg-5 g-3">
            <div class="col-lg-4 position-relative col-tools">
              <h2 class="title-page">A propos</h2>
              <link-important-user></link-important-user>
            </div>
            <infos  :to="'/profil-about-user/' + $route.params.friend"  @openModal="loadModalParams" />
            <basic-infos  v-if="$route.path == '/profil-about-user-infos/' + $route.params.friend"/>
            <education :InfosDatas= "EmploieScolarite" :ObjectRefreshElement="ObjectRefreshElement"  @emitSuccess ="cancelObject" v-if="$route.path == '/profil-about-user-education/' + $route.params.friend" />
            <places :InfosDatas= "blockPlaces"  :ObjectRefreshElement="ObjectRefreshElement"  @emitSuccess ="cancelObject" v-if="$route.path == '/profil-about-user-places/' + $route.params.friend" />
          </div>
        </div>
      </div>
    </div>
    <maj-infos ref="refloadParams" @refreshModalAction="refreshActionModal" ></maj-infos>
  </div>
</template>

<script>
import LinkImportantUser from '../fieldscomponents/LinkImportantUser.vue';
import Infos from './Infos.vue';
import BasicInfos from './BasicInfos.vue';
import Education from './Education.vue';
import Places from './Places.vue';
import MajInfos from './MajInfos.vue';

import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
  name: 'AboutUser',
  props: ['Titles'],
  components: {
    LinkImportantUser,
    Infos,
    BasicInfos,
    Education,
    Places,
    MajInfos,
  },
  data() {
    return {
      form: {
        code: null
      },
      blockPlaces:[],
      EmploieScolarite:[],
      blockInfos:[],
      blockBiblios:[],
      AllFormStatus:[],
      requesting: false,
      requestingText: "Chargement des données",
      ObjectRefreshElement:null
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  // created() {
  //   this.requesting = true;
  //   this.gb_loadReFreshUser();
  //   this.loadStatementInfos();
  //   this.requesting = false;

  //   if (this.Titles != null) {
  //     this.load();
  //   }
  //   setInterval(() => {
  //     this.load();
  //   }, 60000);
  // },

  mounted() {
    this.requesting = true;
    this.loadStatementInfos();
    this.requesting = false;

    if (this.Titles != null) {
      this.load();
    }
    setInterval(() => {
      this.load();
    }, 60000);
  },

  methods: {
    load() {
      this.requesting = true;
      this.requesting = false;

      if (this.Titles != null) {
        
        var array_EmploieScolarite = this.Titles.filter(function (val) { return val.titre_principal == "Emploi et scolarité"; });

        var array_places = this.Titles.filter(function (val) { return val.titre_principal == "Lieux de résidence"; });

        this.blockInfos = this.Titles.filter(function (val) { return val.titre_principal == "Informations générales et coordonnées"; });

        this.blockBiblios = this.Titles.filter(function (val) { return val.titre_principal == "Bibliographie et culture"; });

        if (array_EmploieScolarite.length > 0) {
          this.EmploieScolarite = array_EmploieScolarite[0];
        }

        if (array_places.length > 0) {
          this.blockPlaces = array_places[0];
        }
        
      }

    },

    loadStatementInfos() {
      if (this.$store.state.user_auth != null) {
        
        if (this.$store.state.user_auth.statut == "false") {
          if (this.Online()) {

            var ListStatementInfosUser = new Object();
            ListStatementInfosUser.mailUser = this.$store.state.user_auth.mailUser;
            ListStatementInfosUser.codingUser = this.$store.state.user_auth.codingUser;

            axios.post("https://www.etat.user.schoolbac.com", ListStatementInfosUser)
              .then(({ data }) => {
                if (data.error == "false") {
                  this.AllFormStatus = data
                } else {
                  this.toast.info(data.message, {
                    timeout: 4000
                  });
                }
              })
              .catch((error) => {
                this.errors = error;
                console.log(error);
              });

          }

        }
      }
    },

    loadModalParams() {
      // execution d'une methode enfant dans MajInfo
      this.$refs.refloadParams.loadMajElement();
    },
    refreshActionModal(event) {
      this.ObjectRefreshElement = event;
    },

    cancelObject() {
      this.ObjectRefreshElement = null;
      this.$store.state.visibilityElementParent = null;
      this.$store.state.visibilityElement = null;
      this.$store.state.visibilityElementId = null;
    },

  },
}
</script>
