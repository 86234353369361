<template>
  <div class="global-div">
    <div class="wrapper">
      <div class="loading">
        <div id="loader"></div>
      </div>
      <header class="header">
        <nav class="navbar navbar-expand-lg">
          <div class="container-fluid px-lg-5">
            <div class="row w-100 align-items-center">
              <div class="col-lg-6 col-3 order-1 order-md-1 order-lg-1 col-sm-6 col-md-6">
                <router-link to="/" class="navbar-brand">
                  <img src="assets/images/logo-app.png" alt="">
                </router-link>
              </div>
              <div class="col-lg-6 col-9 order-2 oder-md-3 order-lg-3 col-sm-6 col-md-6">
                <div class="d-flex justify-content-end net-work">
                  <a href="/login" class="btn">
                    <span>Se connecter</span>
                    <span>Se connecter</span>
                    <span>Se connecter</span>
                  </a>
                  <a href="/register" class="btn">
                    <span>S'inscrire</span>
                    <span>S'inscrire</span>
                    <span>S'inscrire</span>
                  </a>
                  <div class="menu-toggle">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>



          </div>
        </nav>
      </header>
      <div class="banner-home">

        <div class="banner-content">
          <div class="cube cube-1">
            <i class="bx bx-book"></i>
          </div>
          <div class="cube cube-2">
            <i class="bx bxs-graduation"></i>
          </div>
          <div class="cube cube-3">
            <i class="bx bxs-envelope"></i>
          </div>
          <div class="cube cube-4">
            <i class="bx bx-music"></i>
          </div>
          <div class="cube cube-5">
            <i class="bx bx-book-open"></i>
          </div>
          <div class="cube cube-6">
            <i class="bx bx-mobile"></i>
          </div>
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <h2>Schoolbac</h2>
                <h1>
                  La <span>connaissance</span>
                  à la portée de tous
                </h1>
                <a href="/register" class="btn">
                    <span>Lancez-vous</span>
                    <span>Lancez-vous</span>
                    <span>Lancez-vous</span>
                </a>
              </div>
            </div>
          </div>

          <div class="banner-img">
            <div class="block-circle">
              <div class="card-phone"></div>

              <div class="block-users w-100 d-flex align-items-center justify-content-center">
                <div class="avatar">
                  <img src="assets/images/3.jpg" alt="">
                </div>
                <div class="avatar">
                  <img src="assets/images/9.jpg" alt="">
                </div>
                <div class="avatar">
                  <img src="assets/images/8.jpg" alt="">
                </div>
                <div class="avatar">
                  <img src="assets/images/11.jpg" alt="">
                </div>
                <div class="avatar">
                  <img src="assets/images/7.jpg" alt="">
                </div>
                <div class="avatar">
                  <img src="assets/images/13.jpg" alt="">
                </div>
                <div class="avatar">
                  <img src="assets/images/prof1.jpg" alt="">
                </div>
              </div>
            </div>
            <div class="content-img">
            </div>
          </div>
          <a href="#footer" class="btn-mouse scrollTop">
            <i class="bx bx-down-arrow-alt"></i>
          </a>
        </div>
      </div>

      <footer id="footer">
        <div class="container">
          <div class="row g-4">
            <div class="col-lg-3 col-6 col-sm-3 col-md-3">
              <div class="text-star">
                <h5>SchoolBac</h5>
                <ul>
                  <li>
                    <router-link to="/about" class="btn" @click.prevent="this.gb_helpRubrique('/about#descr')">
                      Description plateforme
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/about" class="btn" @click.prevent="this.gb_helpRubrique('/about#int_ped')">
                      Intérêt Pédagogique
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/about" class="btn" @click.prevent="this.gb_helpRubrique('/about#int_sci')">
                      Intérêt scientifique
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/about" class="btn" @click.prevent="this.gb_helpRubrique('/about#prod')">
                      Produits
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-6 col-sm-3 col-md-3">
              <div class="text-star">
                <h5>Aide & Details</h5>
                <ul>
                  <li>
                    <router-link to="/about" class="btn" @click.prevent="this.gb_helpRubrique('/about#apropos')">
                      A propos
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/about" class="btn" @click.prevent="this.gb_helpRubrique('/about#histo')">
                      Historique
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/about" class="btn" @click.prevent="this.gb_helpRubrique('/about#pub')">
                      Faire la publicité
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/about" class="btn" @click.prevent="this.gb_helpRubrique('/about#contact')">
                      Contactez-nous
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-6 col-sm-3 col-md-3">
              <div class="text-star">
                <h5>Mention Légale</h5>
                <ul>
                  <li>
                    <router-link to="/about" class="btn" @click.prevent="this.gb_helpRubrique('/about#mention')">
                      Condition d’utilisation
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/about" class="btn" @click.prevent="this.gb_helpRubrique('/about#confi')">
                      Confidentialité
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/about" class="btn" @click.prevent="this.gb_helpRubrique('/about#donnees')">
                      Données personnelles
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/about" class="btn" @click.prevent="this.gb_helpRubrique('/about#signal')">
                      Signaler un contenu
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-6 col-sm-3 col-md-3">
              <div class="text-star">
                <h5>Langues</h5>
                <ul>
                  <li>
                    <a href="#">
                      Français
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Anglais
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="text-center">
            <button class="btn btn-top">
              <i class="bx bx-up-arrow-alt"></i>
            </button>
            <small>Tout droit reservé © Copyright 2022 </small>
          </div>
        </div>
      </footer>
      <!-- <footer id="footer">
        <div class="container">
          <div class="row g-4">
            <div class="col-lg-3 col-6 col-sm-3 col-md-3">
              <div class="text-star">
                <h5>SchoolBac</h5>
                <ul>
                  <li>
                    <a href="#">
                      Bibiothèque
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Epreuves nationales
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Librairie
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Prix scientifique
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      SchoolAcademia
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-6 col-sm-3 col-md-3">
              <div class="text-star">
                <h5>Aide & Details</h5>
                <ul>
                  <li>
                    <router-link to="/about" class="btn">
                      A propos
                    </router-link>
                    <a href="/about">
                      A propos
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Contactez-nous
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Gagner un prix
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Obtenir une Bourse
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Organiser un Séminaire
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-6 col-sm-3 col-md-3">
              <div class="text-star">
                <h5>Mention Légale</h5>
                <ul>
                  <li>
                    <a href="#">
                      Condition d'utilisation
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Confidentialité
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Données personnelles
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Faire la Publicité
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Signaler un contenu
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-6 col-sm-3 col-md-3">
              <div class="text-star">
                <h5>Langues</h5>
                <ul>
                  <li>
                    <a href="#">
                      Français
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Anglais
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="text-center">
            <button class="btn btn-top">
              <i class="bx bx-up-arrow-alt"></i>
            </button>
            <small>Tout droit reservé © Copyright 2022 </small>
          </div>
        </div>
      </footer> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadPage',

  data() {
    return {
      user: null,
      requesting: false,
    };
  },
}
</script>
