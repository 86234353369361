<template>
  <div class="global-div">
    <div class="wrapper">

      <header class="fixed-top header-app">
        <nav class="navbar navbar-expand-lg nav-app">
          <div class="container-fluid px-lg-2 px-1">
            <div class="row w-100 ms-0 align-items-center">
              <div class="col-lg-3 col-2">
                <div class="block-logo">
                  <a class="navbar-brand" href="#">
                    <img src="assets/images/logo-app.png" alt="">
                  </a>
                    <div v-if="this.$store.state.user_auth != null" class="block-search d-flex align-items-center">
                      <i class="bx bx-search"></i>
                      <!-- // User -->
                      <input type="text" class="form-control" :placeholder="'@' + (this.$store.state.user_auth.fistnameUser != null && this.$store.state.user_auth.fistnameUser
                        != '' ? this.$store.state.user_auth.fistnameUser : '') + (this.$store.state.user_auth.nameUser != null &&
                        this.$store.state.user_auth.nameUser != '' ? this.$store.state.user_auth.nameUser : '')">
                    </div>
                </div>
              </div>
              <div class="col-lg-6 d-flex justify-content-center col-nav">
                <ul class="navbar-nav mb-lg-0">
      
                  <li class="nav-item">
                    <a class="nav-link active" href="#">
                      <i class="bx bx-home icon-unactive"></i>
                      <i class="bx bxs-home icon-active"></i>
                    </a>
                    <div class="indicator">
                      Fil d'actualité
                    </div>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#">
                      <i class="bx bx-camera-movie icon-unactive"></i>
                      <i class="bx bxs-camera-movie icon-active"></i>
                    </a>
                    <div class="indicator">
                      Documentaires
                    </div>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#">
                      <i class="bx bx-book"></i>
                    </a>
                    <div class="indicator">
                      Biblothèque
                    </div>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#">
                      <i class="bx bx-book-open"></i>
                    </a>
                    <div class="indicator">
                      Cours
                    </div>
                  </li>
                  <li class="nav-item invisible position-absolute">
                    <a class="nav-link" href="#">
                      <i class="bx bx-movie-play"></i>
                    </a>
                    <div class="indicator">
                      Vidéos
                    </div>
                  </li>
                </ul>
              </div>
              <div class="col-lg-3 col-10">
                <div class="block-user d-flex">
                  <div class="block-tools">
                    <approuved-demands-friends-user :ApprouvedDemandesFriends="this.DemandsFriends"  @reloadDemandsFriends="loadApprouvedDemandsFriends"></approuved-demands-friends-user>
                    <div class="item-tool">
                      <a href="javascript:void(0)">
                        <div class="notif"></div>
                        <i class="bx bxs-chat"></i>
                      </a>
                      <div class="block-drop">
                        <div class="header-drop">
                          <h5>Messages</h5>
                          <div class="block-btns">
                            <div class="btn-icon">
                              <i class="bx bx-search"></i>
                            </div>
                            <div class="btn-icon">
                              <i class="bx bx-message-alt-edit"></i>
                            </div>
                          </div>
                        </div>
                        <div class="body-message">
                          <ul>
                            <li>
                              <div class="block-items">
                                <div class="avatar-message">
                                  <img src="images/team1.jpg" alt="">
                                </div>
                                <div class="block-info-message">
                                  <h6>John Doe</h6>
                                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, quas.</p>
                                  <span class="date">Il ya 2min</span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="block-items">
                                <div class="avatar-message">
                                  <img src="images/team1.jpg" alt="">
                                </div>
                                <div class="block-info-message">
                                  <h6>John Doe</h6>
                                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, quas.</p>
                                  <span class="date">Il ya 2min</span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="block-items">
                                <div class="avatar-message">
                                  <img src="images/team1.jpg" alt="">
                                </div>
                                <div class="block-info-message">
                                  <h6>John Doe</h6>
                                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, quas.</p>
                                  <span class="date">Il ya 2min</span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="block-items">
                                <div class="avatar-message">
                                  <img src="images/team1.jpg" alt="">
                                </div>
                                <div class="block-info-message">
                                  <h6>John Doe</h6>
                                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, quas.</p>
                                  <span class="date">Il ya 2min</span>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="#" class="btn">Tous les messages</a>
                      </div>
                    </div>
                    <div class="item-tool">
                      <a href="javascript:void(0)">
                        <div class="notif"></div>
                        <i class="bx bxs-bell"></i>
                      </a>
                      <div class="block-drop">
                        <div class="header-drop">
                          <h5>Notifications</h5>
      
                        </div>
                        <div class="body-message">
                          <ul>
                            <li>
                              <div class="block-items">
                                <div class="avatar-message">
                                  <img src="images/team1.jpg" alt="">
                                </div>
                                <div class="block-info-message">
                                  <h6>John Doe</h6>
                                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, quas.</p>
                                  <span class="date">Il ya 2min</span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="block-items">
                                <div class="avatar-message">
                                  <img src="images/team1.jpg" alt="">
                                </div>
                                <div class="block-info-message">
                                  <h6>John Doe</h6>
                                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, quas.</p>
                                  <span class="date">Il ya 2min</span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="block-items">
                                <div class="avatar-message">
                                  <img src="images/team1.jpg" alt="">
                                </div>
                                <div class="block-info-message">
                                  <h6>John Doe</h6>
                                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, quas.</p>
                                  <span class="date">Il ya 2min</span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="block-items">
                                <div class="avatar-message">
                                  <img src="images/team1.jpg" alt="">
                                </div>
                                <div class="block-info-message">
                                  <h6>John Doe</h6>
                                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, quas.</p>
                                  <span class="date">Il ya 2min</span>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="#" class="btn">Toutes les notifications</a>
                      </div>
                    </div>
                  </div>
                  <div  v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.avatarUser != null && this.$store.state.user_auth.avatarUser != ''" class="block-avatar-user-nav">
                    <div class="avatar">
                      <img :src="this.$store.state.user_auth.avatarUser" alt="">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <div class="topbar">
          <div class="container-fluid px-lg-2 px-0">
            <div class="row">
              <div class="col-lg-2 d-flex ps-0 col-2">
                <div class="menu">
                  <i class="bx bx-search"></i>
                </div>
              </div>
              <div class="col-lg-8 d-flex align-items-center justify-content-center col-8">
                <ul>
                  <li><a href="#">Ecole</a></li>
                  <li><a href="#">Institut supérieur</a></li>
                  <li><a href="#">Université</a></li>
                  <li><a href="#">Centre de formations</a></li>
                </ul>
              </div>
              <div class="col-lg-2 d-flex align-items-center justify-content-end pe-0 col-2">
                <div class="user-conv">
                  <div class="bling"></div>
                  <i class="bx bx-chat"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      

      <div v-if="this.$store.state.params == $route.params.friend" class="block-app">
        <bloc-user-avatar-in-profil :DatasFriendsUser="this.DatasFriendsUser"></bloc-user-avatar-in-profil>
        <about-user :Titles="Titles"
          v-if="$route.path == '/about-user' || $route.path == '/about-user-education' || $route.path == '/about-user-places' || $route.path == '/about-user-infos' || $route.path == '/profil-about-user-places/' + $route.params.friend || $route.path == '/profil-about-user-education/' + $route.params.friend || $route.path == '/profil-about-user/' + $route.params.friend || $route.path == '/profil-about-user-infos/' + $route.params.friend"/>
        <videos-user v-if="$route.path == '/videos-user'" />
        <friends-user v-if="$route.path == '/profil-friends-user/'+$route.params.friend"/>
        <pictures-user v-if="$route.path == '/profil-pictures-user/'+$route.params.friend"/>
        <posters-user v-if="$route.path == '/profil-user/' + $route.params.friend && this.$store.state.params == $route.params.friend"/>
        <a href="#" class="btn-float">
          <i class="bx bx-message-alt-edit"></i>
        </a>
      </div>

      <div class="nav-mobile">
        <div class="conainer-fluid px-lg-3 px-1">
          <div class="items-nav">
            <a href="#" class="item-nav active">
              <i class="bx bx-home-alt-2 icon-unactive"></i>
              <i class="bx bxs-home-alt-2 icon-active"></i>
              <span>Fil d'act</span>
            </a>
            <a class="item-nav" href="#">
              <i class="bx bx-camera-movie icon-unactive"></i>
              <i class="bx bxs-camera-movie icon-active"></i>
            </a>
            <a class="item-nav" href="#">
              <i class="bx bx-book icon-unactive"></i>
              <i class="bx bxs-book icon-active"></i>
            </a>
            <a class="item-nav" href="#">
              <i class="bx bx-book-open icon-unactive"></i>
              <i class="bx bxs-book-open icon-active"></i>
            </a>
            <a class="item-nav" href="#">
              <i class="bx bx-movie-play icon-unactive"></i>
              <i class="bx bxs-movie-play icon-active"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="block-all-discussion">
      <div class="header-block">
        <button class="btn btn-close-block">
          <i class="bx bx-plus"></i>
        </button>
        <h5>Discussions</h5>
      </div>
      <div class="block-contact">
        <div class="btn-open-menu">
          <i class="bx bx-chevron-left"></i>
        </div>
        <div class="header d-flex align-items-center justify-content-between">
          <h6>Contacts</h6>
          <span class="num">(100)</span>
        </div>
        <div class="body-contact">
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>Emilie Smith</h6>
              <p>Contecté</p>
            </div>
            <div class="statut online">
            </div>
          </div>
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team1.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>John Smith</h6>
              <p>Déconnecté</p>
            </div>
            <div class="statut offline">
            </div>
          </div>
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>Emilie Smith</h6>
              <p>Contecté</p>
            </div>
            <div class="statut online">
            </div>
          </div>
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team1.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>John Smith</h6>
              <p>Déconnecté</p>
            </div>
            <div class="statut offline">
            </div>
          </div>
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>Emilie Smith</h6>
              <p>Contecté</p>
            </div>
            <div class="statut online">
            </div>
          </div>
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team1.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>John Smith</h6>
              <p>Déconnecté</p>
            </div>
            <div class="statut offline">
            </div>
          </div>
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>Emilie Smith</h6>
              <p>Contecté</p>
            </div>
            <div class="statut online">
            </div>
          </div>
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team1.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>John Smith</h6>
              <p>Déconnecté</p>
            </div>
            <div class="statut offline">
            </div>
          </div>
        </div>
        <div class="header d-flex align-items-center justify-content-between">
          <h6>Groupes</h6>
          <span class="num">(100)</span>
        </div>
        <div class="body-contact">
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>Emilie Smith</h6>
            </div>
            <div class="statut online">
            </div>
          </div>
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team1.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>John Smith</h6>
            </div>
            <div class="statut offline">
            </div>
          </div>
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>Emilie Smith</h6>
            </div>
            <div class="statut online">
            </div>
          </div>
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team1.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>John Smith</h6>
            </div>
            <div class="statut offline">
            </div>
          </div>
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>Emilie Smith</h6>
            </div>
            <div class="statut online">
            </div>
          </div>
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team1.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>John Smith</h6>
            </div>
            <div class="statut offline">
            </div>
          </div>
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>Emilie Smith</h6>
            </div>
            <div class="statut online">
            </div>
          </div>
          <div class="item-user">
            <div class="avatar-user">
              <img src="assets/images/team1.jpg" alt="">
            </div>
            <div class="block-name">
              <h6>John Smith</h6>
            </div>
            <div class="statut offline">
            </div>
          </div>
        </div>
      </div>
    </div>

    <menu-widget></menu-widget>

    <div v-if="this.$store.state.user_auth != null" class="block-user-widget-search">
      <div class="block-header">
        <button class="btn btn-close-block">
          <i class="bx bx-plus"></i>
        </button>
        <h5>Recherche</h5>
        <div class="block-search d-flex align-items-center">
          <i class="bx bx-search"></i>
          <input type="text" class="form-control"  :placeholder="(this.$store.state.user_auth.fistnameUser != null && this.$store.state.user_auth.fistnameUser != '' ? this.$store.state.user_auth.fistnameUser  : '') + ' ' + (this.$store.state.user_auth.nameUser != null && this.$store.state.user_auth.nameUser != '' ? this.$store.state.user_auth.nameUser : '')">
        </div>
      </div>
      <div class="block-body">
        <ul class="list">
          <li>
            <a href="#">
              <div class="avatar-search">
                <img src="assets/images/1.jpg" alt="">
              </div>
              <div class="name-search">
                <h6>John doe</h6>
                <span>@johndoe</span>
              </div>
            </a>
          </li>
          <li>
            <a href="#">
              <div class="avatar-search">
                <img src="assets/images/1.jpg" alt="">
              </div>
              <div class="name-search">
                <h6>John doe</h6>
                <span>@johndoe</span>
              </div>
            </a>
          </li>
          <li>
            <a href="#">
              <div class="avatar-search">
                <img src="assets/images/1.jpg" alt="">
              </div>
              <div class="name-search">
                <h6>John doe</h6>
                <span>@johndoe</span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="back-drop"></div>
    <div class="block-add-story">
      <div class="card card-post-statut">
        <div class="header-card">
          <h5>Nouveau statut</h5>
        </div>
        <form action="">
          <div class="body-card">
            <div class="content-text">
              <textarea name="" id="" cols="30" rows="10" class="form-control"
                placeholder="Evrivez votre statut"></textarea>
            </div>
          </div>
          <div class="footer-card">
            <button class="btn btn-post">
              Poster
            </button>
          </div>
        </form>
      </div>
    </div>

      <div class="modal fade" id="modal-identif" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title d-flex align-items-center" id="exampleModalLabel">
                <span>Personnes à identifier</span>
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <i class="bx bx-plus"></i>
              </button>
            </div>
            <div class="modal-body">
              <form method="post" action="">
                <div class="form-group row g-4">
                  <div class="col-lg-12">
                    <div class="block-search-person">
                      <div class="block-search d-flex align-items-center">
                        <i class="bx bx-search"></i>
                        <input type="text" class="form-control" placeholder="Recherche...">
                      </div>
                    </div>
                    <div class="num-person-select d-flex justify-content-end">
                      <span class="num">0</span>
                      <span>Sections</span>
                    </div>
                    <div class="all-person">
                      <div class="block-person d-flex">
                        <input type="checkbox" name="">
                        <div class="avatar-person">
                          <img src="images/4.jpg" alt="">
                        </div>
                        <div class="block-name-person">
                          <h5>John doe</h5>
                        </div>
                        <div class="fake-check">
                          <i class="bx bx-check"></i>
                        </div>
                      </div>
                      <div class="block-person d-flex">
                        <input type="checkbox" name="">
                        <div class="avatar-person">
                          <img src="images/4.jpg" alt="">
                        </div>
                        <div class="block-name-person">
                          <h5>John doe</h5>
                        </div>
                        <div class="fake-check">
                          <i class="bx bx-check"></i>
                        </div>
                      </div>
                      <div class="block-person d-flex">
                        <input type="checkbox" name="">
                        <div class="avatar-person">
                          <img src="images/4.jpg" alt="">
                        </div>
                        <div class="block-name-person">
                          <h5>John doe</h5>
                        </div>
                        <div class="fake-check">
                          <i class="bx bx-check"></i>
                        </div>
                      </div>
                      <div class="block-person d-flex">
                        <input type="checkbox" name="">
                        <div class="avatar-person">
                          <img src="images/4.jpg" alt="">
                        </div>
                        <div class="block-name-person">
                          <h5>John doe</h5>
                        </div>
                        <div class="fake-check">
                          <i class="bx bx-check"></i>
                        </div>
                      </div>
                      <div class="block-person d-flex">
                        <input type="checkbox" name="">
                        <div class="avatar-person">
                          <img src="images/3.jpg" alt="">
                        </div>
                        <div class="block-name-person">
                          <h5>John doe</h5>
                        </div>
                        <div class="fake-check">
                          <i class="bx bx-check"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 text-end">
                    <button class="btn btn-add"><i class="bx bx-check"></i> Valider</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <!-- // 15/02/2023 -->
      <div class="modal fade" id="modal-show-post" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
        style="z-index:999999999 !important;">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen">
          <div v-if="this.$store.state.OnePubishView !=null && this.$store.state.OnePubishView.type == 'image' && this.$store.state.OnePubishView.contenu != null && this.$store.state.OnePubishView.contenu.trim() != '' && this.$store.state.OnePubishView.contenu.length != 1" class="modal-content">
            <div class="modal-body p-0">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="img-post">
                      <img :src="this.$store.state.OnePubishView.contenu"  alt="">
                    </div>
                  </div>
                  <div class="col-lg-6 col-line ps-0">
                    <div class="block-info-post">
                      <div class="header d-flex align-items-center">
                        <div class="avatar-post">
                          <avatar-user></avatar-user>
                        </div>
                        <div class="info-name">
                          <a href="#">
                            <h5>{{ (this.$store.state.user_auth.fistnameUser != null &&
                              this.$store.state.user_auth.fistnameUser != "" ? this.$store.state.user_auth.fistnameUser : "") + ' ' +
                              (this.$store.state.user_auth.nameUser != null && this.$store.state.user_auth.nameUser != "" ?
                                this.$store.state.user_auth.nameUser : "") }}</h5>
                          </a>
                          <span class="visibility">
                            <i  :class="'bx' + (this.$store.state.OnePubishView.index_etat == 'One' ? 'bx-globe' : (this.$store.state.OnePubishView.index_etat == 'Two' ? 'bxs-user' : (this.$store.state.OnePubishView.index_etat == 'Three' ? 'bxs-lock' : '')))"></i>
                            {{ this.$store.state.OnePubishView.name_etat }}
                          </span>
                        </div>
                        <div class="block-tools d-flex align-items-center">
                          <div class="dropdown">
                            <a href="#" class="btn" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                              <i class="bx bx-dots-vertical-rounded"></i>
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                              <h6>Actions</h6>
                              <li><a class="dropdown-item" href="#"><i class="bx bxs-pencil"></i>Modifier</a></li>
                              <li><a class="dropdown-item" href="#"><i class="bx bxs-download"></i> Enregistrer</a></li>
                              <li><a class="dropdown-item" href="#"><i class="bx bx-trash"></i> Supprimer</a></li>
                              <li>
                                <hr class="dropdown-divider">
                              </li>
                              <h6>Visibilté</h6>
                              <li><a class="dropdown-item" href="#"><i class="bx bx-globe"></i> Public</a></li>
                              <li><a class="dropdown-item" href="#"><i class="bx bxs-user"></i>Amis</a></li>
                              <li><a class="dropdown-item" href="#"><i class="bx bxs-lock"></i>Privé</a></li>
                            </ul>
                          </div>
                          <a href="#" class="btn" data-bs-dismiss="modal" aria-label="Close">
                            <i class="bx bx-x"></i>
                          </a>
                        </div>
                      </div>
                      <div class="body">
                        <p class="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores, tempora
                          consectetur ducimus atque blanditiis vel pariatur dolores, reiciendis iure beatae quaerat obcaecati
                          itaque! Assumenda, voluptas iusto dolor veniam enim quasi?</p>
                        <div class="block-show-comment">
                          <div class="block-all-comment">
                            <a href="#" style="pointer-events: none;">{{ this.$store.state.OnePubishView.nbrargument }} Commentaires</a>
                          </div>
                          <div class="content-block">
                            <div class="card-comment">
                              <div class="info-comment">
                                <a href="#" class="avatar-comment">
                                  <img src="images/6.jpg" alt="">
                                </a>
                                <div class="name-comment">
                                  <div class="content-name">
                                    <a href="#">John doe</a>
                                    <span>Il y 3min</span>
                                  </div>
                                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea reiciendis aperiam
                                    accusantium!</p>
                                </div>
                              </div>
      
                            </div>
                          </div>
                          <div class="content-block">
                            <div class="card-comment">
                              <div class="info-comment">
                                <a href="#" class="avatar-comment">
                                  <img src="images/6.jpg" alt="">
                                </a>
                                <div class="name-comment">
                                  <div class="content-name">
                                    <a href="#">John doe</a>
                                    <span>Il y 3min</span>
                                  </div>
                                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea reiciendis aperiam
                                    accusantium!</p>
                                </div>
                              </div>
      
                            </div>
                          </div>
                          <div class="content-block">
                            <div class="card-comment">
                              <div class="info-comment">
                                <a href="#" class="avatar-comment">
                                  <img src="images/7.jpg" alt="">
                                </a>
                                <div class="name-comment">
                                  <div class="content-name">
                                    <a href="#">John doe</a>
                                    <span>Il y 3min</span>
                                  </div>
                                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea reiciendis aperiam
                                    accusantium!</p>
                                </div>
                              </div>
      
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="footer">
                        <div class="block-footer-post">
                          <div class="block-left">
                            <div class="d-flex align-items-center block-user-like">
                              <a href="#" class="like">
                                <i class="bx bx-heart"></i>
                                <span>{{ this.$store.state.OnePubishView.nbrjadore }}</span>
                              </a>
                              <div class="block-users-friends">
                                <a href="#">
                                  <div class="avatar-user">
                                    <img src="images/team1.jpg" alt="">
                                  </div>
                                </a>
                                <a href="#">
                                  <div class="avatar-user">
                                    <img src="images/team1.jpg" alt="">
                                  </div>
                                </a>
                                <a href="#">
                                  <div class="avatar-user">
                                    <img src="images/team1.jpg" alt="">
                                  </div>
                                </a>
                                <a href="#">
                                  <div class="avatar-user">
                                    <img src="images/team1.jpg" alt="">
                                  </div>
                                </a>
                                <a href="#">
                                  <div class="avatar-user">
                                    <img src="images/team1.jpg" alt="">
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div class="block-likes">
                              <a href="#">Vous</a>,
                              <a href="#">Emilie</a>
                              et <br>
                              {{ this.$store.state.OnePubishView.nbrjaime }} autres aimés ça
                            </div>
                          </div>
                          <div class="block-right">
                            <a href="#" class="comment-like">
                              <i class="bx bx-comment"></i>
                              <span>{{ this.$store.state.OnePubishView.nbrargument }}</span>
                            </a>
                            <a href="#" class="share-like">
                              <i class="bx bx-share-alt"></i>
                              <span>{{ this.$store.state.OnePubishView.nbrpartage }}</span>
                            </a>
                          </div>
                        </div>
                        <div class="block-content-comment d-flex justify-content-between">
                          <div class="block-avatar-comment">
                            <!-- <img src="images/1.jpg" alt="image de"> -->
                            <avatar-user></avatar-user>
                          </div>
                          <div class="block-input-comment">
                            <textarea name="" id="" cols="30" rows="0" class="form-control"
                              placeholder="laissez un commentaire"></textarea>
                          </div>
                          <button class="btn"><i class="bx bxs-comment"></i><span>Commentez</span></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /End 15/02/2023 -->

  </div>
</template>

<script>
import BlocUserAvatarInProfil from './fieldscomponents/BlocUserAvatarInProfil.vue';
import ApprouvedDemandsFriendsUser from  './friends-user/ApprouvedDemandFriendsUser.vue';
import MenuWidget from './fieldscomponents/MenuWidget.vue';
import AboutUser from './infos/AboutUser.vue';
import VideosUser from './videos-user/VideosUser.vue';
import FriendsUser from './friends-user/FriendsUser.vue';
import PicturesUser from './pictures-user/PicturesUser.vue';
import PostersUser from './posters-user/PostersUser.vue';
import AvatarUser from './mapreta/AvatarUser.vue';


import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
  name: 'ViewUser',
  components: {
    BlocUserAvatarInProfil,
    ApprouvedDemandsFriendsUser,
    MenuWidget,
    AboutUser,
    VideosUser,
    FriendsUser,
    PicturesUser,
    PostersUser,
    AvatarUser,
  },
  data() {
    return {
      userListInfos: null,

      user: null,
      Titles: [],
      AllNumberCountUsers: 0,
      form: {
        code: null
      },
      errors: null,
      requesting: false,
      requestingText: "Chargement des données",
      DemandsFriends: [],
      DatasFriendsUser: [],
      requeteNumber: 0
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  mounted() {
    this.gb_loadReFreshUser();

    if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {
      this.requeteNumber = 0;
      this.loadDatasFriendsUser();
      this.loadTitles_v2();
      this.loadApprouvedDemandsFriends();
      
      setInterval(() => {
        this.loadApprouvedDemandsFriends();
        this.loadDatasFriendsUser();
      }, 120000);
    }
  },

  methods: {
    loadTitles() {
      if (this.bg_Online) {
        if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {

          // var postData = new Object();
          // postData.mailUser = this.$store.state.user_auth.mailUser;
          // postData.codingUser = this.$store.state.user_auth.codingUser;

          var postData = this.gb_objectRequestTheBase();

          if (postData.mailUser != null && postData.mailUser.trim() != "" && postData.codingUser != null && postData.codingUser.trim() != "") {
            axios.post("https://www.title.prime.schoolbac.com", postData)
              .then(({ data }) => {
  
                if (data.length > 0) {
                  for (let j = 0; j < data.length; j++) {
  
                    // Object
                    var objtitleprim = new Object();
                    objtitleprim.statut = false;
                    objtitleprim.message = data[j].message;
                    objtitleprim.title = data[j].titre_principal;
                    objtitleprim.title_index = data[j].index_titre;
                    objtitleprim.title_second = [];
                    this.Titles.push(objtitleprim);

                    // Request
                    var postDataSecondary = new Object();
                    postDataSecondary.mailUser = this.$store.state.user_auth.mailUser;
                    postDataSecondary.codingUser = this.$store.state.user_auth.codingUser;
                    postDataSecondary.index_titre = data[j].index_titre;

                    if (data[j].index_titre != null && data[j].index_titre.trim() !="") {
                      axios.post("https://www.title.secondary.schoolbac.com", postDataSecondary)
                        .then(({ data }) => {
                          var dataSecondTitle = data;
  
                          if (dataSecondTitle.length > 0) {

                            for (let g = 0; g < dataSecondTitle.length; g++) {

                              // Object
                              var objtitlesecond = new Object();
                              objtitlesecond.statut = false;
                              objtitlesecond.message = dataSecondTitle[g].message;
                              objtitlesecond.titlesecond = dataSecondTitle[g].titre_secondaire;
                              objtitlesecond.soustitle = dataSecondTitle[g].sous_titre;
                              objtitlesecond.second_index_statut = dataSecondTitle[g].index_statut;
                              objtitlesecond.elements = [];
                              this.Titles[j].title_second.push(objtitlesecond);

                              // Request
                              var postObjBlockElem = new Object();
                              postObjBlockElem.mailUser = this.$store.state.user_auth.mailUser;
                              postObjBlockElem.codingUser = this.$store.state.user_auth.codingUser;
                              postObjBlockElem.index_statut = dataSecondTitle[g].index_statut;

                              if (dataSecondTitle[g].index_statut != null && dataSecondTitle[g].index_statut .trim() != "") {
                                axios.post("https://www.element.ci.schoolbac.com", postObjBlockElem)
                                  .then(({ data }) => {
                                    var dataE = data;
                                    this.Titles[j].title_second[g].elements = dataE;
                                  })
                                  .catch((error) => {
                                    this.errors = error;
                                    this.bg_messagesError();
                                  });
                              }
                            }
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                          this.bg_messagesError();
                        });
                    }

                  }
                }
  
              })
              .catch((error) => {
                this.errors = error;
                // this.refresh();
              });
          }
        }
      } 
    },

    loadTitles_v2() {
      if (this.bg_Online) {
        if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {

          if (this.$store.state.user_auth.mailUser != null &&  this.$store.state.user_auth.mailUser.trim() != "" && this.$store.state.user_auth.codingUser != null && this.$store.state.user_auth.codingUser.trim() != "") {

            // var postData = new Object();
            // postData.mailUser = this.$store.state.user_auth.mailUser;
            // postData.codingUser = this.$store.state.user_auth.codingUser;
          var postData = this.gb_objectRequestTheBase();


            if ( postData.mailUser != null &&  postData.mailUser.trim() != "" && postData.codingUser != null && postData.codingUser.trim() != "") {
              axios.post("https://www.title.prime.schoolbac.com", postData)
                .then(({ data }) => {
    
                  if (data.length > 0) {
                    this.Titles = data;
                  }
                })
                .catch((error) => {
                  this.errors = error;
                  // this.refresh();
                });
           }

          }
        }
      } 
    },

   tcheck_Avatar() {
      if (this.bg_Online) {
        if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {

          if (this.$store.state.user_auth.mailUser != null && this.$store.state.user_auth.mailUser.trim() != "" && this.$store.state.user_auth.codingUser != null && this.$store.state.user_auth.codingUser.trim() != "") {

            // var postData = new Object();
            // postData.mailUser = this.$store.state.user_auth.mailUser;
            // postData.codingUser = this.$store.state.user_auth.codingUser;
          var postData = this.gb_objectRequestTheBase();

            if (postData.mailUser != null && postData.mailUser.trim() != "" && postData.codingUser != null && postData.codingUser.trim() != "") {
              axios.post("https://www.avatar.user.check.schoolbac.com", postData)
                .then(({ data }) => {

                  if (data.statut =="user") {

                    this.toast.success("Il serait souhaitable que vous définissiez de votre choix !!!", {
                      timeout: 4000
                    });
                  }
                })
                .catch((error) => {
                  this.errors = error;
                });
            }

          }
        }
      }
    },

    loadApprouvedDemandsFriends() {
      if (this.bg_Online) {
        if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {

          if (this.$store.state.user_auth.mailUser != null && this.$store.state.user_auth.mailUser.trim() != "" && this.$store.state.user_auth.codingUser != null && this.$store.state.user_auth.codingUser.trim() != "") {
            var postData = {
              "mailUser": this.$store.state.user_auth.mailUser,
              "codingUser": this.$store.state.user_auth.codingUser,
              "statut": "index_3"
            };
            axios.post("https://www.invitation.camarade.schoolbac.com", postData)
              .then(({ data }) => {
                if (data.length > 0) {
                  this.DemandsFriends = data;
                } else {
                  this.DemandsFriends = [];
                }
              })
              .catch((error) => {
                this.errors = error;
                this.DemandsFriends = [];
              });
          }
        }
      }
    },

    loadDatasFriendsUser() {
      if (this.bg_Online) {
        if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {

          if (this.$store.state.user_auth.mailUser != null && this.$store.state.user_auth.mailUser.trim() != "" && this.$store.state.user_auth.codingUser != null && this.$store.state.user_auth.codingUser.trim() != "") {

            var postData = {
              "mailUser": this.$route.path == "/posters-user" ? this.$store.state.user_auth.mailUser : (this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.mailCamarade != null && this.$store.state.user_auth_other.mailCamarade.trim() != "" ? this.$store.state.user_auth_other.mailCamarade.trim() : ''),
              "codingUser": this.$route.path == "/posters-user" ? this.$store.state.user_auth.codingUser : (this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.codingUserCamarade != null && this.$store.state.user_auth_other.codingUserCamarade.trim() != "" ? this.$store.state.user_auth_other.codingUserCamarade.trim() : ''),
              "statut": "index_1"
            };

            axios.post("https://www.camarade.schoolbac.com", postData)
              .then(({ data }) => {
                if (data.length > 0) {
                  this.DatasFriendsUser = data;
                  this.requeteNumber++;
                } else {
                  this.gb_notifications(0, data.message);
                }
              })
              .catch((error) => {
                this.errors = error;
                // this.refresh();
              });

          }
        }
      }
    },
  },

}
</script>
