<template>
  <div class="card card-sugg">
    <div class="block-header">
      <h5>Groupes suggérés</h5>
      <a href="#">Voir plus</a>
    </div>
    <div class="block-sugg">
      <div class="items-block items-group">
        <div class="banner-group"></div>
        <div class="d-flex block-group-info">
          <div class="avatar-friend">
            <img src="assets/images/team.jpg" alt="">
          </div>
          <div class="block-name-friend">
            <a href="#">
              <h6>Evariste Kabongo</h6>
            </a>
            <p>120 Membres</p>
            <!-- <a href="#" class="add"><i class="bx bx-plus"></i> Ajouter</a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

// import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
  name: 'GroupSuggeres',
  data() {
    return {
      requesting: false,
      requestingText: "Chargement des données",
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },

  mounted() {
    this.load();

    setInterval(() => {
      this.load();
    }, 120000);
  },

  methods: {
    load() {
      this.requesting = true;
      this.requesting = false;
    },
  },
}
</script>
