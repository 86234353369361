<template>
  <div class="global-div">
    <div class="wrapper">
      <div class="block-login">
        <div class="avatar">
          <img src="assets/images/9.jpg" alt="">
        </div>
        <div class="avatar">
          <img src="assets/images/13.jpg" alt="">
        </div>
        <div class="avatar">
          <img src="assets/images/7.jpg" alt="">
        </div>
        <div class="avatar">
          <img src="assets/images/8.jpg" alt="">
        </div>
        <div class="logo-app">
          <router-link to="/">
            <img src="assets/images/logo-app.png" alt="">
          </router-link>
        </div>
        <div class="block-cube">
          <div class="cube cube-1">
            <i class="bx bx-book"></i>
          </div>
          <div class="cube cube-2">
            <i class="bx bxs-graduation"></i>
          </div>
          <div class="cube cube-3">
            <i class="bx bxs-envelope"></i>
          </div>
          <div class="cube cube-4">
            <i class="bx bx-music"></i>
          </div>
          <div class="cube cube-5">
            <i class="bx bx-book-open"></i>
          </div>
          <div class="cube cube-6">
            <i class="bx bx-mobile"></i>
          </div>
        </div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-5 col-md-7 col-xl-4 col-xxl-4">
              <div class="card card-login">
                <div class="text-center">
                  <h2 class="title-lg">Compléter l'inscription</h2>
                </div>
                <form @submit.prevent="RegistertoCompleteUser">
                  <div class="form-group row g-lg-3 g-3">
                    <div class="col-12">
                      <div class="col-focus">
                        <input v-model="this.form.nicknameUser" type="text" class="form-control" required="required"
                          placeholder="surmon">
                        <div class="fake-label">Surnom</div>
                        <i class="bx bx-user"></i>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="col-focus">
                        <input v-model="this.form.businessUser" type="text" class="form-control" required="required"
                          placeholder="Profession">
                        <div class="fake-label">Profession</div>
                        <i class="bx bx-briefcase"></i>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="col-focus">
                        <input v-model="this.form.townUser" type="text" class="form-control" required="required"
                          placeholder="ville">
                        <div class="fake-label">Ville</div>
                        <i class="bx bxs-map"></i>
                      </div>
                    </div>
                    <div class="col-12" style="margin-bottom: -10px">
                      <label for="" style="color: #fff;font-size:14px;">Date d'anniversaire</label>
                    </div>
                    <div class="col-12 col-md-12 col-sm-12">
                      <div class="col-focus">
                        <input v-model="this.form.birthdayUser_format" type="date" class="form-control"
                          required="required">
                        <i class="bx bx-cake"></i>
                      </div>
                    </div>
                    <div class="col-12 d-flex justify-content-center">
                      <button class="btn btn-primary" v-if="!this.submit_setTimout">
                        <span>Valider</span>
                        <span>Valider</span>
                        <span>Valider</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div class="text-center col-hidden-mobile">
                <p v-if="!this.submit_setTimout" class="paragraph">
                  <a href="#" class="link-reset" @click.prevent="ingore_register_complete">Complétez plutard</a>
                </p>
                <p v-if="this.submit_setTimout" class="paragraphtext-center text-white">Veuillez patientez...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useToast } from "vue-toastification";
export default {
  name: 'RegisterToComplete',
  data() {
    return {
      user: null,
      messageErrors: null,
      form: {
        nicknameUser: null,
        businessUser: null,
        townUser: null,
        birthdayUser: null,
        birthdayUser_format: null,
        mailUser: null,
        codingUser: null,
      },
      form_ignore: {
        mailUser: null,
        codingUser: null,
      },
      isProfession: true,
      requesting: false,
      submit_setTimout: false,
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },

  methods: {
    async RegistertoCompleteUser() {
      if (this.$store.state.user_auth.mailUser != null && this.$store.state.user_auth.mailUser != "" && this.$store.state.user_auth.codingUser != null && this.$store.state.user_auth.codingUser != "") {

        this.form.mailUser = this.$store.state.user_auth.mailUser;
        this.form.codingUser = this.$store.state.user_auth.codingUser;

        if (this.form.nicknameUser != null && this.form.businessUser != null && this.form.townUser != null && this.form.birthdayUser_format != null && this.form.mailUser != null && this.form.codingUser != null) {
          if (this.form.nicknameUser.trim().length >= 4 && this.form.nicknameUser.trim().length <= 16) {
            this.ingore_register_complete = true;
            this.form.nicknameUser = this.capitalisingFormat(this.form.nicknameUser.trim());
            this.form.birthdayUser = this.bg_format_date(this.form.birthdayUser_format, "YYYY-MM-DD");

            this.submit_setTimout = true;
            await axios.post("https://www.complete.inscription.schoolbac.com", this.form).then(({ data }) => {
              if (data.error == "false") {
                this.user = data;
                this.$store.state.user_auth = data;
                this.refreshForm();
                this.authentification();
                setTimeout(() => {
                  this.toast.success("Vos informatons viennent d'être actualisé  !!!");
                }, 8000);
              } else if (data.error == "true") {
                this.toast.error(data.message, {
                  timeout: 5000
                });
                this.ingore_register_complete = false;
              } else {
                this.toast.error(data.message, {
                  timeout: 5000
                });
                this.ingore_register_complete = false;
              }
            });
          } else {
            if (this.form.nicknameUser.trim().length < 4 || this.form.nicknameUser.trim().length < 4) {
              this.toast.info("Le surnom ne doit pas etre inférieur à 4 caractères !!!", {
                timeout: 6000
              });
            } else {
              this.toast.info("Le surnom ne doit pas dépasser 16 caractères !!!", {
                timeout: 6000
              });
            }
            // this.messageErrors = "Le surnom ne doivent pas dépasser 16 caractères !!!";
          }
        } else {
          this.toast.error("Veuillez completer les champs manquantes !!!", {
            timeout: 5000
          });
        }
      } else {
        this.toast.error("Vous n'etes pas autorisé à manipuler cette page !!!", {
          timeout: 5000
        });
      }
    },

    refreshForm() {
      this.form.nicknameUser = null,
        this.form.businessUser = null,
        this.form.townUser = null,
        this.form.birthdayUser = null,
        this.form.birthdayUser_format = null,
        this.form.mailUser = null,
        this.form.codingUser = null,
        this.messageErrors = null,
        this.ingore_register_complete = false
    },

    async ingore_register_complete() {
      if (this.$store.state.user_auth.codeUser != "" && this.$store.state.user_auth.codeUser != null && this.$store.state.user_auth.codingUser != "" && this.$store.state.user_auth.codingUser != null && this.$store.state.user_auth.mailUser != "" && this.$store.state.user_auth.mailUser != null) {

        this.form_ignore.mailUser = this.$store.state.user_auth.mailUser;
        this.form_ignore.codingUser = this.$store.state.user_auth.codingUser;
        this.ingore_register_complete = true;
        this.submit_setTimout = true;

        await axios.post("https://www.complete.inscription.after.schoolbac.com ", this.form_ignore).then(({ data }) => {
          if (data.error == "false") {
            this.user = data;
            // this.$store.state.user_auth = data;
            this.refreshForm();
            this.authentification();
            setTimeout(() => {
              this.toast.success("Vous devriez finaliser votre inscription pour permettre à vos camarades de vous suivre");
            }, 8000);
          } else {
            this.toast.error(data.message, {
              timeout: 6000
            });
            this.ingore_register_complete = false;

          }
        }).catch(function (error) {
          console.log(error);
          this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
            timeout: 2000
          });
        });

      } else {
        this.toast.error("Désolée, aucune de vos informations n'est disponible, vous permettant d'avoir accès  à cette page. Veuillez réessayer ", {
          timeout: 6000
        });
        setTimeout(() => {
          this.$router.push({ name: 'Register' });
        }, 6001);
      }
    },

    capitalisingFormat(value) {
      return value.charAt(0).toUpperCase() +
        value.slice(1).toLowerCase();
    },

    authentification() {
      if (this.$store.state.user_store != null && this.$store.state.user_store.mailUser != null && this.$store.state.user_store_password != null && this.$store.state.user_store_password != "") {
        var postData = new Object();
        postData.login = this.$store.state.user_store.mailUser;
        postData.password = this.$store.state.user_store_password;

        axios.post("https://www.connexion.schoolbac.com", postData).then(({ data }) => {
          if (data.error == "false") {
            this.$store.commit('AUTHENTIFICATE_USER', data.user);
            localStorage.setItem('AUTHENTIFICATE_USER', JSON.stringify(data.user));
            this.$store.state.user_auth = data;
            // this.$router.push({ name: 'PostersUser' });
            this.$router.push({ name: 'AboutUser' });
            // this.$router.push({ name: 'Home' });
          }
        }).catch(function (error) {
          console.log(error);
          this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
            timeout: 2000
          });
        });
      }
    },


  }
}
</script>

