<template>
  <div class="card card-calendar">
    <div class="header-calendar">
      <p class="currentDate">Octobre 2022</p>
      <div class="block-chevron">
        <span>
          <i class="bx bx-chevron-left"></i>
        </span>
        <span>
          <i class="bx bx-chevron-right"></i>
        </span>
      </div>
    </div>
    <div class="body-calendar">
      <ul class="weeks">
        <li>Di</li>
        <li>Lu</li>
        <li>Ma</li>
        <li>Me</li>
        <li>Je</li>
        <li>Ve</li>
        <li>Sa</li>
      </ul>
      <ul class="days">
        <li class="unactive">28</li>
        <li class="unactive">29</li>
        <li class="unactive">30</li>
        <li class="unactive">31</li>
        <li>1</li>
        <li>2</li>
        <li>3</li>
        <li>4</li>
        <li class="active">5</li>
        <li>6</li>
        <li>7</li>
        <li>8</li>
        <li>9</li>
        <li>10</li>
        <li>11</li>
        <li>12</li>
        <li>13</li>
        <li>14</li>
        <li>15</li>
        <li>16</li>
        <li>17</li>
        <li>18</li>
        <li>19</li>
        <li>20</li>
        <li>21</li>
        <li>22</li>
        <li>23</li>
        <li>24</li>
        <li>25</li>
        <li>26</li>
        <li>27</li>
        <li>28</li>
        <li>29</li>
        <li>30</li>
        <li class="unactive">1</li>
      </ul>
    </div>
  </div>
</template>

<script>

// import axios from 'axios';

export default {
  name: 'Calendar',
  data() {
    return {
      requesting: false,
      requestingText: "Chargement des données",
    };
  },

  mounted() {
    this.load();
    setInterval(() => {
      this.load();
    }, 120000);
  },

  methods: {
    load() {
      this.requesting = true;
      this.requesting = false;
    },
  },
}
</script>
