<template>
  <div v-if="$route.path == '/about-user-infos'" class="col-lg-8">
    <div class="block-items-about">
      <div class="item-about">
        <h6 class="title-about">Coordonnées</h6>
        <!-- <a href="#" class="add-items" data-bs-toggle="modal" data-bs-target="#modalMajInfosUser" @click.prevent="gb_modalMajTitre('Ajouter un nouveau contact', 'Nouveau contact', '', 'bx bxs-phone')"> -->
        <a href="#" class="add-items" data-bs-toggle="modal" data-bs-target="#modalMajInfosUser" @click.prevent="gb_modalMajTitre('Modifier votre  contact', 'Modifier le numéro de téléphone', this.$store.state.user_auth.mobileUser, 'bx bxs-phone', 'STORE', 'mobile')">
          <i class="bx bx-plus"></i>
          <span>Ajouter un contact</span>
        </a>
        <div class="d-flex align-items-center justify-content-between">
          <div class="block-left">
            <div v-if="this.$store.state.user_auth !=null && this.$store.state.user_auth.mobileUser != null && this.$store.state.user_auth.mobileUser !=''" class="d-flex align-items-center">
              <i class="bx bxs-phone"></i>
              <p class="mb-0">Mobile <span>{{ this.$store.state.user_auth.mobileUser }}</span></p>
            </div>
          </div>
          <div class="block-right">
            <div class="d-flex align-items-center">
              <button class="btn btn-view">
                <i class="bx bxs-lock"></i>
              </button>
              <button class="btn btn-edit" data-bs-toggle="modal" data-bs-target="#modalMajInfosUser"  @click.prevent="gb_modalMajTitre('Modifier votre  contact', 'Modifier le numéro de téléphone', this.$store.state.user_auth.mobileUser, 'bx bxs-phone', 'UPDATE', 'mobile')">
                <i class="bx bxs-pencil"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="item-about">
        <div class="d-flex align-items-center justify-content-between">
          <div class="block-left">
            <div v-if="this.$store.state.user_auth !=null && this.$store.state.user_auth.mailUser != null && this.$store.state.user_auth.mailUser !=''" class="d-flex align-items-center">
              <i class="bx bxs-envelope"></i>
              <p class="mb-0">Email <span>{{ this.$store.state.user_auth.mailUser }}</span></p>
            </div>
          </div>
          <div class="block-right">
            <div class="d-flex align-items-center">
              <button class="btn btn-view">
                <i class="bx bxs-lock"></i>
              </button>
              <button class="btn btn-edit" data-bs-toggle="modal" data-bs-target="#modalMajInfosUser" @click.prevent="gb_modalMajTitre('Modifier Email', 'Modifier l\'email', this.$store.state.user_auth.mailUser, 'bx bxs-envelope', 'UPDATE', 'email')">
                <i class="bx bxs-pencil"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="item-about">
        <h6 class="title-about">Sites web et liens sociaux</h6>
        <a href="#" class="add-items" data-bs-toggle="modal" data-bs-target="#modalMajInfosUser" @click.prevent="gb_modalMajTitre('Ajouter un site web', 'Ajouter un site web',null,'bx bx-globe')">
          <i class="bx bx-plus"></i>
          <span>Ajoutez un site web</span>
        </a>
      </div>
      <div class="item-about">
        <a href="#" class="add-items" data-bs-toggle="modal" data-bs-target="#modalMajInfosUser" @click.prevent="gb_modalMajTitre('Ajouter un lien social', 'Ajouter un lien social',null,'bx bx-globe')">
          <i class="bx bx-plus"></i>
          <span>Ajouter un lien social</span>
        </a>
      </div>
      <div class="item-about">
        <h6 class="title-about">Infos générales</h6>
        <div class="d-flex align-items-center justify-content-between">
          <div v-if="this.$store.state.user_auth !=null && this.$store.state.user_auth.sexUser != null && this.$store.state.user_auth.sexUser !=''" class="block-left">
            <div class="d-flex align-items-center">
              <i class="bx bx-male"></i>
              <p class="mb-0">Genre <span>{{ this.$store.state.user_auth.sexUser == 'M' ? 'Homme':'Femme' }}</span></p>
            </div>
          </div>
          <div class="block-right">
            <div class="d-flex align-items-center">
              <button class="btn btn-view">
                <i class="bx bxs-lock"></i>
              </button>
              <button class="btn btn-edit" data-bs-toggle="modal" data-bs-target="#modalMajInfosUser" @click.prevent="gb_modalMajTitre('Modifier votre  genre','Modifier le genre',(this.$store.state.user_auth.sexUser == 'M' ? 'Homme' : 'Femme'),'bx bx-male')">
                <i class="bx bxs-pencil"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="item-about"  v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.mailUser != null && this.$store.state.user_auth.mailUser != '' && this.$store.state.user_auth.birthdayUser.trim() != '' && this.$store.state.user_auth.birthdayUser !=null">
        <div class="d-flex align-items-center justify-content-between">
          <div class="block-left">
            <div class="d-flex align-items-center">
              <i class="bx bxs-cake"></i>
              <p class="mb-0">Date de naissance <span>{{ new Date(this.$store.state.user_auth.birthdayUser).getFullYear() }}</span></p>
            </div>
          </div>
          <div class="block-right">
            <div class="d-flex align-items-center">
              <button class="btn btn-view">
                <i class="bx bxs-lock"></i>
              </button>
              <button class="btn btn-edit" data-bs-toggle="modal" data-bs-target="#modalMajInfosUser" @click.prevent="gb_modalMajTitre('Modifier la date de Naissance', 'Modifier la date de naissance', this.$store.state.user_auth.birthdayUser,'bx bxs-cake')">
                <i class="bx bxs-pencil"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <maj-infos></maj-infos> -->
  </div>
  <div v-if="($route.path == '/profil-about-user-infos/' + $route.params.friend) && this.$store.state.params == $route.params.friend" class="col-lg-8">
    <div class="block-items-about">
      <div class="item-about">
        <h6 class="title-about">Coordonnées</h6>
        <div class="d-flex align-items-center justify-content-between">
          <div class="block-left">
            <div v-if="this.$store.state.user_auth_other !=null && this.ListDataOtherViewUser != null && this.ListDataOtherViewUser.mobileUser != null && this.ListDataOtherViewUser.mobileUser.trim() !=''" class="d-flex align-items-center">
              <i class="bx bxs-phone"></i>
              <p class="mb-0">Mobile <span>{{ this.ListDataOtherViewUser.mobileUser }}</span></p>
            </div>
          </div>
          <div class="block-right">
            <div class="d-flex align-items-center">
              <button class="btn btn-view">
                <i class="bx bxs-lock"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="item-about">
        <div class="d-flex align-items-center justify-content-between">
          <div class="block-left">
            <div v-if="this.$store.state.user_auth_other !=null && this.$store.state.user_auth_other.mailCamarade != null && this.$store.state.user_auth_other.mailCamarade.trim() !=''" class="d-flex align-items-center">
              <i class="bx bxs-envelope"></i>
              <p class="mb-0">Email <span>{{ this.$store.state.user_auth_other.mailCamarade }}</span></p>
            </div>
          </div>
          <div class="block-right">
            <div class="d-flex align-items-center">
              <button class="btn btn-view">
                <i class="bx bxs-lock"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="item-about">
        <h6 class="title-about">Sites web et liens sociaux</h6>
      </div>
      <div class="item-about">
        <h6 class="title-about">Infos générales</h6>
        <div class="d-flex align-items-center justify-content-between">
          <div v-if="this.$store.state.user_auth_other !=null && this.ListDataOtherViewUser != null && this.ListDataOtherViewUser.sexUser != null && this.ListDataOtherViewUser.sexUser.trim() != ''" class="block-left">
            <div class="d-flex align-items-center">
              <i class="bx bx-male"></i>
              <p class="mb-0">Genre <span>{{ this.ListDataOtherViewUser.sexUser == 'M' ? 'Homme':'Femme' }}</span></p>
            </div>
          </div>
          <div class="block-right">
            <div class="d-flex align-items-center">
              <button class="btn btn-view">
                <i class="bx bxs-lock"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="item-about"  v-if="this.$store.state.user_auth_other != null  && this.ListDataOtherViewUser != null && this.ListDataOtherViewUser.birthdayUser != null && this.ListDataOtherViewUser.birthdayUser.trim() != ''">
        <div class="d-flex align-items-center justify-content-between">
          <div class="block-left">
            <div class="d-flex align-items-center">
              <i class="bx bxs-cake"></i>
              <p class="mb-0">Date de naissance <span>{{ new Date(this.ListDataOtherViewUser.birthdayUser).getFullYear() }}</span></p>
            </div>
          </div>
          <div class="block-right">
            <div class="d-flex align-items-center">
              <button class="btn btn-view">
                <i class="bx bxs-lock"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <maj-infos></maj-infos> -->
  </div>
</template>

<script>
import axios from 'axios';
import { useToast } from "vue-toastification";
// import MajInfos from './MajInfos.vue';


export default {
  // components: { MajInfos },
  name: 'BasicInfos',
  data() {
    return {
      user: null,
      Allcitations: [],
      Allsondages: [],
      Allnews: [],
      AllNumberCountUsers: 0,
      form: {
        code: null
      },
      ListDataOtherViewUser:null,
      requesting: false,
      requestingText: "Chargement des données",
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  mounted() {

    if (this.$store.state.user_auth != null) {
      this.user = this.$store.state.user_auth;
      this.load();
    }

    setInterval(() => {
      this.load();
    }, 60000);
  },

  methods: {
    load() {
      this.requesting = true;
      this.requesting = false;

      if (this.gb_viewUserOrOtherUser(this.$route) && this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.codingUserCamarade != null && this.$store.state.user_auth_other.codingUserCamarade.trim() != '' && this.$store.state.params == this.$route.params.friend) {
        this.ListDataOtherViewUser =  this.gb_objectRequestTheBase();

        axios.post("https://www.number.mobile.user.schoolbac.com", this.gb_objectRequestTheBase())
          .then(({ data }) => {
            if (data.error == "false") {
              this.ListDataOtherViewUser = { ...this.ListDataOtherViewUser, ...{ "mobileUser": data.numberUser } };
            }
          })
          .catch((error) => {
            this.errors = error;
          });

        axios.post("https://www.sex.user.schoolbac.com", this.gb_objectRequestTheBase())
          .then(({ data }) => {
            if (data.error == "false") {
              this.ListDataOtherViewUser = { ...this.ListDataOtherViewUser, ...{ "sexUser": data.sexUser } };
            }
          })
          .catch((error) => {
            this.errors = error;
          });

        axios.post("https://www.birthday.user.schoolbac.com", this.gb_objectRequestTheBase())
          .then(({ data }) => {
            if (data.error == "false") {
              this.ListDataOtherViewUser = { ...this.ListDataOtherViewUser, ...{ "birthdayUser": data.birthday } };
            } else {
              this.ListDataOtherViewUser = { ...this.ListDataOtherViewUser, ...{ "birthdayUser": null } };
            }
            
          })
          .catch((error) => {
            this.errors = error;
          });
      }
    },
  },

}
</script>
