<template>
<div class="global-div">
  <div class="wrapper">
    <div class="block-login">
      <div class="avatar">
        <img src="assets/images/9.jpg" alt="">
      </div>
      <div class="avatar">
        <img src="assets/images/13.jpg" alt="">
      </div>
      <div class="avatar">
        <img src="assets/images/7.jpg" alt="">
      </div>
      <div class="avatar">
        <img src="assets/images/8.jpg" alt="">
      </div>
      <div class="logo-app">
        <router-link to="/">
          <img src="assets/images/logo-app.png" alt="">
        </router-link>
      </div>
      <div class="block-cube">
        <div class="cube cube-1">
          <i class="bx bx-book"></i>
        </div>
        <div class="cube cube-2">
          <i class="bx bxs-graduation"></i>
        </div>
        <div class="cube cube-3">
          <i class="bx bxs-envelope"></i>
        </div>
        <div class="cube cube-4">
          <i class="bx bx-music"></i>
        </div>
        <div class="cube cube-5">
          <i class="bx bx-book-open"></i>
        </div>
        <div class="cube cube-6">
          <i class="bx bx-mobile"></i>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 col-md-7 col-xl-4 col-xxl-4">
            <div class="card card-login">
              <div class="text-center">
                <h2 class="title-lg">Erreur sur le mot de passe</h2>
              </div>
              <form @submit.prevent="authentification">
                <div v-if="this.$store.state.user_auth != null  && this.$store.state.user_auth.statut  !=null && this.$store.state.user_auth.statut == 'password'" class="form-group row g-lg-3 g-3">
                  <div class="col-12">
                    <div class="avatar-user">
                      <img  v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.statut != null && this.$store.state.user_auth.statut == 'password' && this.$store.state.user_auth.avatarUser != ''" :src="this.$store.state.user_auth.avatarUser" alt="">
                    </div>
                  </div>
                  <div class="col-12 text-center">
                    <p  v-if="this.$store.state.user_auth != null  && this.$store.state.user_auth.statut  !=null && this.$store.state.user_auth.statut == 'password'" class="mb-0" style="color: #fff; font-size: 14px;font-family: 'Poppins-Regular';">
                      {{ this.$store.state.user_auth.mailUser }}. <br> Ce n'est pas vous ?</p>
                  </div>
                  <div class="col-12">
                      <div class="col-focus">
                        <input v-model="this.form.password" :type="!this.type_input_password ? 'password' : 'text'"
                          class="form-control input-password" required="required" placeholder="mot de passe">
                        <div class="fake-label">Mot de passe</div>
                        <i class="bx bx-lock"></i>
                        <div :class="'block-show-password ' + (this.form.password != null && this.form.password.length > 0 ? 'show ' : ' ')"
                          @click.prevent="change_type_input">
                          <i :class="'bx bx-hide' + (!this.type_input_password ? ' ' : ' bx-show ')"></i>
                        </div>
                      </div>
                  </div>
                  <div class="col-12 text-center">
                    <router-link to="/reset-password" class="link-reset-1">
                      Mot de passe oublie ?
                    </router-link>
                  </div>
                  <div class="col-12 d-flex justify-content-center">
                    <button class="btn btn-primary">
                      <span>Se connecter</span>
                      <span>Se connecter</span>
                      <span>Se connecter</span>
                    </button>
                  </div>
                  <div class="col-12 text-center col-hidden">
                    <p>Vous n'avez pas de compte ? <br> 
                      <router-link to="/register">
                        Créer
                        maintenant
                      </router-link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
            <div class="text-center col-hidden-mobile">
              <p class="paragraph">Vous n'avez pas de compte? <br> 
                  <router-link to="/register" class="link-reset">
                    Créer
                    maintenant
                  </router-link>
                </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios';
import { useToast } from "vue-toastification";
export default {
  name: 'VerifConnexion',
  data() {
    return {
      user: null,
      form: {
        login: null,
        password: null,
        nbre_essayer:0
      },
      requesting: false,
      minutes: 300,
      type_input_password: false,
      requestingText: "Veuillez patienter",
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  mounted() {
    if (this.$store.state.user_auth != null) {
      if (this.$store.state.user_auth.statut != "password") {
        this.toast.error("Vous n'etes pas autorisé à acceder a ce contenu ou à cette page !!!", {
          timeout: 3000
        });

        if (this.$store.state.user_auth != null  && this.$store.state.user_auth.codingUser != "" && this.$store.state.user_auth.codingUser != null) {
          setTimeout(() => {
            this.$router.push({ name: 'Login' });
          }, 3001);

        } else {
          setTimeout(() => {
            this.$router.push({ name: 'Login' });
          }, 3001);
        }
      } else {
        this.form.login = this.$store.state.user_auth.mailUser;
      }
    } else {
      this.toast.error("Vous n'etes pas autorisé à acceder a ce contenu ou à cette page !!!", {
        timeout: 3000
      });
      setTimeout(() => {
        this.$router.push({ name: 'Login' });
      }, 3001);
    }
  },

  methods: {
    authentification() {
      if (this.Online()) { 
        if (this.form.login != null && this.form.password != null) {
          this.requesting = true;
          axios.post("https://www.connexion.schoolbac.com", this.form).then(({ data }) => {
            this.requesting = false;
            if (data.error == "false") {
              this.$store.commit('AUTHENTIFICATE_USER', data);
              localStorage.setItem('AUTHENTIFICATE_USER', JSON.stringify(data));
              this.$store.state.user_auth = data;
              this.$router.push({ name: 'AboutUser' });
            } else {
              if (data.statut == "password") {
                this.$store.state.user_auth = data;
                this.toast.error("Votre mot de passe est incorrect, veuillez réessayer !!!", {
                  timeout: 4000
                });
              } else if (data.statut == "bloc") {
                this.$store.state.user_auth = data;
                this.$store.state.user_pass_reinitialisation = this.form.login;
                this.toast.error("Le compte  a subit plusieurs tentatives d'accès, veuillez le réinitialiser !!!", {
                  timeout: 6000
                });
                setTimeout(() => {
                  this.$router.push({ name: 'RestPassword' });
                }, 6000);
              } else {
                this.toast.error("Ce compte n'existe pas, veuillez vous inscrire !!!", {
                  timeout: 6000
                });
              }
            }
          }).catch(function (error) {
            console.log(error);
            this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
              timeout: 2000
            });
          });
        } else {
          this.toast.error("Veuillez remplir les champs manquants  !!!", {
            timeout: 2000
          });
        }
      } 
    },

    change_type_input() {
      if (this.type_input_password == false) {
        this.type_input_password = true;
      } else {
        this.type_input_password = false;
      }
    },

    decrementation_minutes() {
      setInterval(() => {
        if (this.minutes != 0) {
          this.minutes = parseInt(this.minutes) - 1;
        } else {
          this.nbre_essayer = 0;
        }
      }, 1000);
    },

    Online() {
      if (navigator.onLine) {
        return true;
      } else {
        this.toast.info("Vous n'etes pas connecté   !!!", {
          timeout: 3000
        });
        return false;
      }
    },
  }
}
</script>

