<template>
  <div class="col-lg-9 col-xl-9 col-xxl-9 order-1 order-lg-2">
    <h5 class="title-page">
      Invitations
    </h5>
    <div class="row g-lg-3 g-3">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="card card-friend-sugg-lg">
          <div class="d-flex align-items-center bock-content-card">
            <div class="avatar-camara-sugg">
              <a href="#">
                <img src="assets/images/1.jpg" alt="">
              </a>
            </div>
            <div class="content-text">
              <h5>
                <a href="#">John doe</a>
              </h5>
              <p>Pas de camarade en commun</p>
              <div class="d-flex btns">
                <a href="#" class="btn">
                  <i class="bx bxs-user-check"></i>
                  <span>Accepter comme camarade</span>
                </a>
                <a href="#" class="btn">
                  <i class="bx bx-trash"></i>
                  <span>Supprimer</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="card card-friend-sugg-lg">
          <div class="d-flex align-items-center bock-content-card">
            <div class="avatar-camara-sugg">
              <a href="#">
                <img src="assets/images/3.jpg" alt="">
              </a>
            </div>
            <div class="content-text">
              <h5>
                <a href="#">John doe</a>
              </h5>
              <p>Pas de camarade en commun</p>
              <div class="d-flex btns">
                <a href="#" class="btn">
                  <i class="bx bxs-user-check"></i>
                  <span>Accepter comme camarade</span>
                </a>
                <a href="#" class="btn">
                  <i class="bx bx-trash"></i>
                  <span>Supprimer</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="card card-friend-sugg-lg">
          <div class="d-flex align-items-center bock-content-card">
            <div class="avatar-camara-sugg">
              <a href="#">
                <img src="assets/images/1.jpg" alt="">
              </a>
            </div>
            <div class="content-text">
              <h5>
                <a href="#">John doe</a>
              </h5>
              <p>Pas de camarade en commun</p>
              <div class="d-flex btns">
                <a href="#" class="btn">
                  <i class="bx bxs-user-check"></i>
                  <span>Accepter comme camarade</span>
                </a>
                <a href="#" class="btn">
                  <i class="bx bx-trash"></i>
                  <span>Supprimer</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="card card-friend-sugg-lg">
          <div class="d-flex align-items-center bock-content-card">
            <div class="avatar-camara-sugg">
              <a href="#">
                <img src="assets/images/3.jpg" alt="">
              </a>
            </div>
            <div class="content-text">
              <h5>
                <a href="#">John doe</a>
              </h5>
              <p>Pas de camarade en commun</p>
              <div class="d-flex btns">
                <a href="#" class="btn">
                  <i class="bx bxs-user-check"></i>
                  <span>Accepter comme camarade</span>
                </a>
                <a href="#" class="btn">
                  <i class="bx bx-trash"></i>
                  <span>Supprimer</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="card card-friend-sugg-lg">
          <div class="d-flex align-items-center bock-content-card">
            <div class="avatar-camara-sugg">
              <a href="#">
                <img src="assets/images/1.jpg" alt="">
              </a>
            </div>
            <div class="content-text">
              <h5>
                <a href="#">John doe</a>
              </h5>
              <p>Pas de camarade en commun</p>
              <div class="d-flex btns">
                <a href="#" class="btn">
                  <i class="bx bxs-user-check"></i>
                  <span>Accepter comme camarade</span>
                </a>
                <a href="#" class="btn">
                  <i class="bx bx-trash"></i>
                  <span>Supprimer</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="card card-friend-sugg-lg">
          <div class="d-flex align-items-center bock-content-card">
            <div class="avatar-camara-sugg">
              <a href="#">
                <img src="assets/images/3.jpg" alt="">
              </a>
            </div>
            <div class="content-text">
              <h5>
                <a href="#">John doe</a>
              </h5>
              <p>Pas de camarade en commun</p>
              <div class="d-flex btns">
                <a href="#" class="btn">
                  <i class="bx bxs-user-check"></i>
                  <span>Accepter comme camarade</span>
                </a>
                <a href="#" class="btn">
                  <i class="bx bx-trash"></i>
                  <span>Supprimer</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="card card-friend-sugg-lg">
          <div class="d-flex align-items-center bock-content-card">
            <div class="avatar-camara-sugg">
              <a href="#">
                <img src="assets/images/1.jpg" alt="">
              </a>
            </div>
            <div class="content-text">
              <h5>
                <a href="#">John doe</a>
              </h5>
              <p>Pas de camarade en commun</p>
              <div class="d-flex btns">
                <a href="#" class="btn">
                  <i class="bx bxs-user-check"></i>
                  <span>Accepter comme camarade</span>
                </a>
                <a href="#" class="btn">
                  <i class="bx bx-trash"></i>
                  <span>Supprimer</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="card card-friend-sugg-lg">
          <div class="d-flex align-items-center bock-content-card">
            <div class="avatar-camara-sugg">
              <a href="#">
                <img src="assets/images/3.jpg" alt="">
              </a>
            </div>
            <div class="content-text">
              <h5>
                <a href="#">John doe</a>
              </h5>
              <p>Pas de camarade en commun</p>
              <div class="d-flex btns">
                <a href="#" class="btn">
                  <i class="bx bxs-user-check"></i>
                  <span>Accepter comme camarade</span>
                </a>
                <a href="#" class="btn">
                  <i class="bx bx-trash"></i>
                  <span>Supprimer</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="card card-friend-sugg-lg">
          <div class="d-flex align-items-center bock-content-card">
            <div class="avatar-camara-sugg">
              <a href="#">
                <img src="assets/images/1.jpg" alt="">
              </a>
            </div>
            <div class="content-text">
              <h5>
                <a href="#">John doe</a>
              </h5>
              <p>Pas de camarade en commun</p>
              <div class="d-flex btns">
                <a href="#" class="btn">
                  <i class="bx bxs-user-check"></i>
                  <span>Accepter comme camarade</span>
                </a>
                <a href="#" class="btn">
                  <i class="bx bx-trash"></i>
                  <span>Supprimer</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="card card-friend-sugg-lg">
          <div class="d-flex align-items-center bock-content-card">
            <div class="avatar-camara-sugg">
              <a href="#">
                <img src="assets/images/3.jpg" alt="">
              </a>
            </div>
            <div class="content-text">
              <h5>
                <a href="#">John doe</a>
              </h5>
              <p>Pas de camarade en commun</p>
              <div class="d-flex btns">
                <a href="#" class="btn">
                  <i class="bx bxs-user-check"></i>
                  <span>Accepter comme camarade</span>
                </a>
                <a href="#" class="btn">
                  <i class="bx bx-trash"></i>
                  <span>Supprimer</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
  name: 'PicturesUser',
  data() {
    return {
      user: null,
      DatasFriendsUser: [],
      requesting: false,
      requeteNumber: 0
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  mounted() {
    this.gb_loadReFreshUser();

    if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {
      this.requeteNumber = 0;
      this.load();

      setInterval(() => {
        this.load();
      }, 120000);
    }
  },

  methods: {
    load() {
      if (this.bg_Online) {
        if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {

          if (this.$store.state.user_auth.mailUser != null && this.$store.state.user_auth.mailUser.trim() != "" && this.$store.state.user_auth.codingUser != null && this.$store.state.user_auth.codingUser.trim() != "") {

            var postData = {
              "mailUser": !this.$store.state.viewOtherUser ? this.$store.state.user_auth.mailUser : (this.$store.state.viewOtherUser && this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.mailCamarade != null && this.$store.state.user_auth_other.mailCamarade.trim() != "" ? this.$store.state.user_auth_other.mailCamarade.trim() : ''),
              "codingUser": !this.$store.state.viewOtherUser ? this.$store.state.user_auth.codingUser : (this.$store.state.viewOtherUser && this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.codingUserCamarade != null && this.$store.state.user_auth_other.codingUserCamarade.trim() != "" ? this.$store.state.user_auth_other.codingUserCamarade.trim() : ''),
              "statut": "index_1"
            };

            axios.post("https://www.camarade.schoolbac.com", postData)
              .then(({ data }) => {
                if (data.length > 0) {
                  this.DatasFriendsUser = data;
                  this.requeteNumber++;
                } else {
                  this.gb_notifications(0, data.message);
                }
                this.$store.state.viewOtherUser = false;
              })
              .catch((error) => {
                this.errors = error;
              });
          }
        }
      }
    },

    OtherPorfilCamarade(codingUserCamarade) {
      if (this.$store.state.user_auth.mailUser != null && this.$store.state.user_auth.mailUser.trim() != "" && this.$store.state.user_auth.codingUser != null && this.$store.state.user_auth.codingUser.trim() != "") {
        if (codingUserCamarade.trim() != "" && codingUserCamarade != null) {
          this.$store.state.viewOtherUser = true;
          if (this.DatasFriendsUser.length > 0) {
            var camarade = this.DatasFriendsUser.find((friend) => friend.codingUserCamarade == codingUserCamarade);
            if (camarade != null) {
              this.$store.state.user_auth_other = camarade;
              if (this.$store.state.user_auth_other.codingUserCamarade != null && this.$store.state.user_auth_other.codingUserCamarade.trim() != "" && this.$store.state.user_auth_other.mailCamarade != null && this.$store.state.user_auth_other.mailCamarade.trim() != "") {
                // this.$router.push({ name: 'PostersUser2'});
                this.$store.state.params = this.$store.state.user_auth_other.nameCamarade.toLowerCase().replace(' ', '-');
                this.$router.push({ name: 'Camarade', params: { friend: this.$store.state.params } });
                this.load();
              }
            }
          }
        }
      }

    },


  },
}
</script>
