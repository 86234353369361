<template>
  <div class="card card-sugg">
    <div class="block-header">
      <h5>Profile Intro</h5>
    </div>
    <div class="block-details">
      <div class="items">
        <h6>About de moi:</h6>
        <p>
          Hi, I’m James, I’m 36 and I work as a Digital Designer for the “Daydreams” Agency in Pier 56.
        </p>
      </div>
      <div class="items">
        <h6>Emploi:</h6>
        <p>
          Radio Okapi
        </p>
      </div>
      <div class="items">
        <h6>Ville actuelle:</h6>
        <p>
          Matadi
        </p>
      </div>
      <div class="items">
        <h6>Ville de provenance:</h6>
        <p>
          Kinshasa
        </p>
      </div>
      <div class="items">
        <h6>Other Social Networks:</h6>
        <div class="block-network-user">
          <a href="#">
            <i class="bx bxl-facebook"></i>
            facebook
          </a>
          <a href="#">
            <i class="bx bxl-twitter"></i>
            twitter
          </a>
          <a href="#">
            <i class="bx bxl-whatsapp"></i>
            whatsapp
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
  name: 'IntroProfilUser',
  data() {
    return {
      requesting: false,
      requestingText: "Chargement des données",
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },

  mounted() {
    this.load();
    setInterval(() => {
      this.load();
    }, 120000);
  },

  methods: {
    load() {
      this.requesting = true;
      this.requesting = false;
    },
  },
}
</script>
