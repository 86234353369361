<template>
    <div class="col-lg-9 col-xl-9 col-xxl-9 order-1 order-lg-2">
      <h5 class="title-page">
        Camarades suggérés
      </h5>
      <div v-if="this.FriendsUserSuggereDataRandom.length > 0" class="row g-lg-3 g-3">
        <div v-for="(friend, index) in FriendsUserSuggereDataRandom"  :key="index" class="col-lg-6 col-md-6 col-sm-6">
          <div class="card card-friend-sugg-lg">
            <div class="d-flex align-items-center bock-content-card">
              <div class="avatar-camara-sugg">
                <a href="#">
                <img :src="(friend.avatarCamarade != null && friend.avatarCamarade != '' ? friend.avatarCamarade : 'assets/images/team.jpg')" alt="">
                </a>
              </div>
              <div class="content-text">
                <h5>
                  <a href="#">{{ friend.nameCamarade }}</a>
                </h5>
                <p v-if="friend.nbrCamaradeCommun == 0">Pas de camarade en commun</p>
                <p v-if="friend.nbrCamaradeCommun >= 1">{{ friend.nbrCamaradeCommun }}  {{ parseInt(friend.nbrCamaradeCommun) > 1 ? ' amis(es)' : ' ami(e)' }} en commun</p>
                <div class="d-flex btns">
                  <a v-if="friend.statutCamarade = 'Ajouter comme Camarade'" href="#" class="btn" @click.prevent="submitaddStatusFriendSuggere(friend.mailCamarade, 'index_1')">
                    <i class="bx bxs-user-plus"></i>
                    <span>Ajouter comme camarade</span>
                  </a>
                  <a href="#" class="btn">
                    <i class="bx bx-trash"></i>
                    <span>Supprimer</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="card card-friend-sugg-lg">
            <div class="d-flex align-items-center bock-content-card">
              <div class="avatar-camara-sugg">
                <a href="#">
                <img src="assets/images/3.jpg" alt="">
                </a>
              </div>
              <div class="content-text">
                <h5>
                  <a href="#">John doe</a>
                </h5>
                <p>Pas de camarade en commun</p>
                <div class="d-flex btns">
                  <a href="#" class="btn">
                    <i class="bx bxs-user-plus"></i>
                    <span>Ajouter comme camarade</span>
                  </a>
                  <a href="#" class="btn">
                    <i class="bx bx-trash"></i>
                    <span>Supprimer</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="card card-friend-sugg-lg">
            <div class="d-flex align-items-center bock-content-card">
              <div class="avatar-camara-sugg">
                <a href="#">
                <img src="assets/images/1.jpg" alt="">
                </a>
              </div>
              <div class="content-text">
                <h5>
                  <a href="#">John doe</a>
                </h5>
                <p>Pas de camarade en commun</p>
                <div class="d-flex btns">
                  <a href="#" class="btn">
                    <i class="bx bxs-user-plus"></i>
                    <span>Ajouter comme camarade</span>
                  </a>
                  <a href="#" class="btn">
                    <i class="bx bx-trash"></i>
                    <span>Supprimer</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="card card-friend-sugg-lg">
            <div class="d-flex align-items-center bock-content-card">
              <div class="avatar-camara-sugg">
                <a href="#">
                <img src="assets/images/3.jpg" alt="">
                </a>
              </div>
              <div class="content-text">
                <h5>
                  <a href="#">John doe</a>
                </h5>
                <p>Pas de camarade en commun</p>
                <div class="d-flex btns">
                  <a href="#" class="btn">
                    <i class="bx bxs-user-plus"></i>
                    <span>Ajouter comme camarade</span>
                  </a>
                  <a href="#" class="btn">
                    <i class="bx bx-trash"></i>
                    <span>Supprimer</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="card card-friend-sugg-lg">
            <div class="d-flex align-items-center bock-content-card">
              <div class="avatar-camara-sugg">
                <a href="#">
                <img src="assets/images/1.jpg" alt="">
                </a>
              </div>
              <div class="content-text">
                <h5>
                  <a href="#">John doe</a>
                </h5>
                <p>Pas de camarade en commun</p>
                <div class="d-flex btns">
                  <a href="#" class="btn">
                    <i class="bx bxs-user-plus"></i>
                    <span>Ajouter comme camarade</span>
                  </a>
                  <a href="#" class="btn">
                    <i class="bx bx-trash"></i>
                    <span>Supprimer</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="card card-friend-sugg-lg">
            <div class="d-flex align-items-center bock-content-card">
              <div class="avatar-camara-sugg">
                <a href="#">
                <img src="assets/images/3.jpg" alt="">
                </a>
              </div>
              <div class="content-text">
                <h5>
                  <a href="#">John doe</a>
                </h5>
                <p>Pas de camarade en commun</p>
                <div class="d-flex btns">
                  <a href="#" class="btn">
                    <i class="bx bxs-user-plus"></i>
                    <span>Ajouter comme camarade</span>
                  </a>
                  <a href="#" class="btn">
                    <i class="bx bx-trash"></i>
                    <span>Supprimer</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="card card-friend-sugg-lg">
            <div class="d-flex align-items-center bock-content-card">
              <div class="avatar-camara-sugg">
                <a href="#">
                <img src="assets/images/1.jpg" alt="">
                </a>
              </div>
              <div class="content-text">
                <h5>
                  <a href="#">John doe</a>
                </h5>
                <p>Pas de camarade en commun</p>
                <div class="d-flex btns">
                  <a href="#" class="btn">
                    <i class="bx bxs-user-plus"></i>
                    <span>Ajouter comme camarade</span>
                  </a>
                  <a href="#" class="btn">
                    <i class="bx bx-trash"></i>
                    <span>Supprimer</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="card card-friend-sugg-lg">
            <div class="d-flex align-items-center bock-content-card">
              <div class="avatar-camara-sugg">
                <a href="#">
                <img src="assets/images/3.jpg" alt="">
                </a>
              </div>
              <div class="content-text">
                <h5>
                  <a href="#">John doe</a>
                </h5>
                <p>Pas de camarade en commun</p>
                <div class="d-flex btns">
                  <a href="#" class="btn">
                    <i class="bx bxs-user-plus"></i>
                    <span>Ajouter comme camarade</span>
                  </a>
                  <a href="#" class="btn">
                    <i class="bx bx-trash"></i>
                    <span>Supprimer</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="card card-friend-sugg-lg">
            <div class="d-flex align-items-center bock-content-card">
              <div class="avatar-camara-sugg">
                <a href="#">
                <img src="assets/images/1.jpg" alt="">
                </a>
              </div>
              <div class="content-text">
                <h5>
                  <a href="#">John doe</a>
                </h5>
                <p>Pas de camarade en commun</p>
                <div class="d-flex btns">
                  <a href="#" class="btn">
                    <i class="bx bxs-user-plus"></i>
                    <span>Ajouter comme camarade</span>
                  </a>
                  <a href="#" class="btn">
                    <i class="bx bx-trash"></i>
                    <span>Supprimer</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="card card-friend-sugg-lg">
            <div class="d-flex align-items-center bock-content-card">
              <div class="avatar-camara-sugg">
                <a href="#">
                <img src="assets/images/3.jpg" alt="">
                </a>
              </div>
              <div class="content-text">
                <h5>
                  <a href="#">John doe</a>
                </h5>
                <p>Pas de camarade en commun</p>
                <div class="d-flex btns">
                  <a href="#" class="btn">
                    <i class="bx bxs-user-plus"></i>
                    <span>Ajouter comme camarade</span>
                  </a>
                  <a href="#" class="btn">
                    <i class="bx bx-trash"></i>
                    <span>Supprimer</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div v-else class="row g-lg-3 g-3">
        <p class="text-left small"> Pas de camarade en suggestion</p>
      </div>
    </div>
</template>

<script>

import axios from 'axios';
import { useToast } from "vue-toastification";
// import ComponentImgs from './ComponentImgs.vue';
// import MajInfos from '../infos/MajInfos.vue';

export default {
  name: 'PicturesUser',
  data() {
    return {
      FriendsUserSuggereData: [],
      FriendsUserSuggereDataRandom: [],
      FriendsUserSuggere: null,
      requesting: false,
      requestingText: "Chargement des données",
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },

  methods: {
    load() {
      if (this.bg_Online) {
        if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {
          this.FriendsUserSuggereData = [];

          var postData = {
            "mailUser": this.$store.state.user_auth.mailUser,
            "codingUser": this.$store.state.user_auth.codingUser,
            "suggests": 50
          };

          if (postData.mailUser != null && postData.mailUser.trim() != "" && postData.codingUser != null && postData.codingUser.trim() != "") {

            axios.post("https://www.camarade.schoolbac.com", postData)
              .then(({ data }) => {
                if (data != null && data.length > 0) {
                  this.FriendsUserSuggereData = [...data];
                  this.shuffle();
                } else {
                  this.FriendsUserSuggereData = [];
                }
              })
              .catch((error) => {
                this.errors = error;
                this.FriendsUserSuggereData = [];
              });

            var postData2 = {
              "mailUser": this.$store.state.user_auth.mailUser,
              "codingUser": this.$store.state.user_auth.codingUser,
              "statut": 'index_9'
            };

            axios.post("https://www.camarade.schoolbac.com", postData2)
              .then(({ data }) => {
                console.log("je suis 22");
                console.log(data);
                if (data != null && data.length > 0) {
                  this.FriendsUserSuggereData = [... this.FriendsUserSuggereData, ...data];
                  this.shuffle();
                }
              })
              .catch((error) => {
                this.errors = error;
              });
          }
        }
      }
    },

    submitaddStatusFriendSuggere(mailCamarade, statutSubmit) {
      if (this.bg_Online) {
        if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {
          if (this.$store.state.user_auth.mailUser != null && this.$store.state.user_auth.mailUser.trim() != "" && this.$store.state.user_auth.codingUser != null && this.$store.state.user_auth.codingUser.trim() != "" && mailCamarade != null && mailCamarade.trim() != "" && statutSubmit != null && statutSubmit.trim() != "") {

            if (this.bg_isEmail(mailCamarade)) {

              var postData = {
                "mailUser": this.$store.state.user_auth.mailUser,
                "codingUser": this.$store.state.user_auth.codingUser,
                "mailCamarade": mailCamarade,
                "statut": statutSubmit
              };

              axios.post("https://www.invitation.camarade.schoolbac.com", postData)
                .then(({ data }) => {
                  if (data.error == "false") {
                    this.gb_notifications(1, "Demande envoyée !!!");
                    this.load();
                  } else {
                    this.gb_notifications(0, "Demande a échouée, veuillez réessayer plus tard !!!");
                  }
                })
                .catch((error) => {
                  this.errors = error;
                  this.FriendsUserSuggereData = [];
                });
            }
          } else {
            this.gb_notifications(2, "Veuillez selectionner le camarade souhaité !!!");
          }
        }
      }
    },

    shuffle() {
      if (this.FriendsUserSuggereData.length > 0) {
        let numbers = [...this.FriendsUserSuggereData];
        let first,
          second,
          temp,
          count = numbers.length;
        for (let i = 0; i < 10; i++) {
          first = Math.floor(Math.random() * count);
          second = Math.floor(Math.random() * count);
          temp = numbers[first];
          numbers[first] = numbers[second];
          numbers[second] = temp;
        }
        this.FriendsUserSuggereDataRandom = numbers;

      } else {
        this.FriendsUserSuggereDataRandom = [];
      }
    }
  },

  mounted() {

    var getLoad = setInterval(() => {
      if (this.$store.state.user_auth != null && this.$store.state.user_auth.statut == "false") {
        this.load();
        clearInterval(getLoad);
      }
    }, 7000);

    setInterval(() => {
      this.load();
    }, 240000);

    // setInterval(() => {
    //   this.load();
    // }, 120000);
  },
}
</script>
